import {
	DataGrid,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	deDE,
} from "@mui/x-data-grid";
import type {
	GridColDef,
	GridSelectionModel,
	GridRowParams,
	GridCellParams,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../../../EditorState";
import {
	selectIsRefItemSelectable,
	selectStandard,
	selectState,
} from "../../../../../EditorState/selectors";
import type { CellParams, SpecificRefItem } from "./types";
import { RefType } from "./types";
import { getBasePath, typeLables, useReferenceModel } from "./helpers";
import SelectionTableCheckbox from "./SelectionTableCheckbox";
import useActiveRestriction from "../../../../useActiveRestriction";
import SelectionToolbar from "./SelectionToolbar";
import useActiveDataType from "../../../../useActiveDataType";
import type { EditorStateSelector } from "../../../../../EditorState/types";
import CheckAllCheckbox from "./CheckAllCheckbox";
import useUsedBy from "../../../../../SelectionTableProvider/useUsedBy";
import SelectionTableProvider from "../../../../../SelectionTableProvider";
import {
	createRenderElementname,
	createRenderSyntaxpfad,
} from "../../../../../SelectionTableProvider/helpers";
import SelectionTableContainer from "../../../../../SelectionTableProvider/SelectionTableContainer";
import { useAppSelector } from "../../../../../../redux/hooks";
import { selectIsCodeliste } from "../../../../../../redux/treeSlice";

export function getApplyQuickFilterFn(value: string) {
	return (params: GridCellParams<string, SpecificRefItem, string>): boolean => {
		const { row } = params;
		const relevantFields = [row.qnamePath, row.elementName];
		if (row.type === RefType.Datatype) {
			const { restrictionName, restrictionId } = row;
			if (restrictionName && restrictionId) {
				relevantFields.push(restrictionName);
				relevantFields.push(restrictionId);
			}
		}
		return relevantFields.some((field) =>
			field.toLowerCase().includes(value.toLowerCase()),
		);
	};
}

const columns: GridColDef[] = [
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		renderCell(params: CellParams<boolean>) {
			return <SelectionTableCheckbox params={params} />;
		},
		type: "boolean",
	},
	{
		field: "elementPath",
		headerName: "Elementname",
		width: 200,
		hideable: false,
		filterable: false,
		renderCell: createRenderElementname(getBasePath),
		getApplyQuickFilterFn,
		type: "string",
	},
	{
		field: "syntaxPath",
		headerName: "Syntaxpfad",
		hideable: false,
		filterable: false,
		flex: 1,
		renderCell: createRenderSyntaxpfad(getBasePath),
		getApplyQuickFilterFn,
		type: "string",
	},
	{
		field: "type",
		headerName: "Typ",
		renderCell: (params: CellParams) => params.row.type,
		getApplyQuickFilterFn,
		type: "singleSelect",
		valueOptions: Object.values(RefType),
		valueFormatter: (params: GridValueFormatterParams<RefType>) =>
			typeLables[params.value],
	},
];

const SelectionTable = ({
	modelSelector,
	isRestrictionSelection,
	isStandardView,
}: {
	modelSelector: EditorStateSelector<string[]>;
	isRestrictionSelection?: boolean;
	isStandardView?: boolean;
}): JSX.Element => {
	const { activeDataType: optionalActiveDataType } = useActiveDataType();
	// We check for the existance of the activeDataType in the parent component
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeNode = optionalActiveDataType!;
	// We're checking if activeRestriction exists in `RestrictionView`
	const activeRestriction = useActiveRestriction();
	const state = useStateSelector(selectState());
	const applyPatch = useApplyPatch();
	const { changeRestrictionSelection } = useEditorPatchCreators();

	const standard = useStateSelector(selectStandard());
	const usedBy = useUsedBy(activeNode, standard);

	const activeRestrictionId = activeRestriction?.get("id");
	const { references, selectionModel } = useReferenceModel(
		usedBy,
		modelSelector,
		isRestrictionSelection,
	);

	const checkIsRowSelectable = (params: GridRowParams<SpecificRefItem>) =>
		selectIsRefItemSelectable(params.row, activeRestriction)(state);

	const onSelectionChange = (newGridSelection: GridSelectionModel) => {
		if (activeRestrictionId) {
			const newSelection = newGridSelection as string[];
			applyPatch(
				changeRestrictionSelection({
					selection: newSelection,
					restrictionId: activeRestrictionId,
				}),
			);
		}
	};

	// TODO: Check wheter this is the correct way to identify a codelist node
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, activeNode));

	return (
		<SelectionTableContainer>
			<SelectionTableProvider>
				{({ getProps }) => (
					<DataGrid
						{...getProps()}
						columns={columns}
						rows={references}
						checkboxSelection
						disableSelectionOnClick
						components={{
							Toolbar: SelectionToolbar,
							// It is not really possible to customize the check all checkbox, but
							// since the checkboxes of the rows are rendered by another
							// component, the only remaining checkbox used in the datagrid is the
							// check all box. That's why we can override the `BaseCheckbox` to
							// supply a custom component to use as the check all checkbox
							BaseCheckbox: CheckAllCheckbox,
						}}
						columnVisibilityModel={{ type: false }}
						selectionModel={selectionModel}
						localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						onSelectionModelChange={onSelectionChange}
						isRowSelectable={checkIsRowSelectable}
						sx={
							!isCodeliste || (isCodeliste && isStandardView)
								? {
										"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
											{
												display: "none",
											},
								  }
								: {}
						}
					/>
				)}
			</SelectionTableProvider>
		</SelectionTableContainer>
	);
};

export default SelectionTable;
