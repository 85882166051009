import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { defaultTheme as xoevTheme } from "@xoev/theme";
import { TableContainer } from "../ui";
import "./ColorTable.scss";

type RgbColor = [r: number, g: number, b: number];

const themeEntries = Object.entries(xoevTheme);

const colorDiv = document.createElement("div");
colorDiv.style.display = "none";
document.body.append(colorDiv);

const pad = (component: string | number) =>
	component.toString().padStart(3, " ");

function formatHex(color: RgbColor) {
	const hex = color
		.map((component) => component.toString(16).padStart(2, "0"))
		.join("");
	return `#${hex}`;
}

function formatRgb([r, g, b]: RgbColor) {
	return `rgb(${pad(r)}, ${pad(g)}, ${pad(b)})`;
}

function formatHsl(hslString: string) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const [, h, s, l] = hslString.match(/hsl\((\d+),? (\d+)%,? (\d+)%\)/)!;
	return `hsl(${pad(h)}, ${pad(s)}%, ${pad(l)}%)`;
}

function hslToRgb(hslString: string): RgbColor {
	colorDiv.style.color = hslString;
	const rgb = window.getComputedStyle(colorDiv).color;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const [, r, g, b] = rgb.match(/rgb\((\d+), (\d+), (\d+)\)/)!;
	return [Number(r), Number(g), Number(b)];
}

function createColorTableData(colors: Record<string, string>) {
	return themeEntries
		.filter(
			([key]) =>
				key in colors && !key.startsWith("hsl-") && !key.startsWith("raw-"),
		)
		.map(([key, value]) => {
			const hsl = xoevTheme[`raw-${key}`];
			const rgbColor = hslToRgb(hsl);
			return {
				key,
				variable: value,
				hsl: formatHsl(hsl),
				hex: formatHex(rgbColor),
				rgb: formatRgb(rgbColor),
			};
		});
}

const MD_HEADER = `| Farbe | HSL | RGB | HEX |
| --- | --- | --- | --- |`;

export function createMdTable(title: string, colors: Record<string, string>) {
	const tableContents = createColorTableData(colors)
		.map(({ key, hsl, rgb, hex }) => {
			return `| ${key} | ${hsl} | ${rgb} | ${hex} |`;
		})
		.join("\n");
	return `# ${title}\n\n${MD_HEADER}\n${tableContents}\n`;
}

function ColorTable({
	colors,
	title,
}: {
	colors: Record<string, string>;
	title: string;
}): JSX.Element {
	return (
		<div>
			<h2>{title}</h2>
			<TableContainer className="color-table">
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>HSL</TableCell>
							<TableCell>RGB</TableCell>
							<TableCell>HEX</TableCell>
							<TableCell>Farbe</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{createColorTableData(colors).map(
							({ key, hex, hsl, rgb, variable }) => {
								return (
									<TableRow key={key}>
										<TableCell>
											<strong>{key}</strong>
										</TableCell>
										<TableCell>
											<code>{hsl}</code>
										</TableCell>
										<TableCell>
											<code>{rgb}</code>
										</TableCell>
										<TableCell>
											<code>{hex}</code>
										</TableCell>
										<TableCell>
											<div
												className="styleguide__swatch"
												style={{ backgroundColor: variable }}
											/>
										</TableCell>
									</TableRow>
								);
							},
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default ColorTable;
