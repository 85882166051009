import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

export default function QuickFilterToolbar(): JSX.Element {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter
				placeholder="Suche..."
				data-testid="project-search"
			/>
		</GridToolbarContainer>
	);
}
