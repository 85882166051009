import DatatypeStandardDetails from "./DatatypeStandardDetails";
import DatatypeRestrictionsTable from "./DatatypeRestrictionsTable";
import useCheckedActiveNode from "./useCheckedActiveNode";
import { useAppSelector } from "../../../../redux/hooks";
import { useStateSelector } from "../../../EditorState";
import { selectStandard } from "../../../EditorState/selectors";
import { selectQName } from "../../../../redux/treeSlice";

const DatatypeDetails = (): JSX.Element => {
	const activeNode = useCheckedActiveNode(DatatypeDetails.name);
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, activeNode));
	return (
		<div data-testid="datatype-editform" data-datatype-qname={qname}>
			<DatatypeStandardDetails />
			<DatatypeRestrictionsTable />
		</div>
	);
};

export default DatatypeDetails;
