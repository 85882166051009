import { useEffect, useMemo, useState } from "react";
import { useEventHandler } from "../../hooks";
import { useUpdateListener } from "../EditorState";

export default function useEditorField({
	value,
	name,
	onChange,
	onWrite,
}: {
	value: string;
	name: string;
	onChange?: (name: string, value: string) => void;
	onWrite?: (name: string, value: string) => void;
}) {
	const [localValue, setLocalValue] = useState(value);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	useUpdateListener(() => {
		setLocalValue(value);
	});

	const handleChange = useEventHandler(
		(event: { target: { name?: string; value: string } }) => {
			const { value: nextValue } = event.target;
			if (onChange) {
				onChange(name, nextValue);
			}
			setLocalValue(nextValue || "");
		},
	);

	const handleBlur = useEventHandler(() => {
		// Only write the value, when it has actually changed
		if (onWrite && localValue !== value) {
			onWrite(name, localValue);
		}
	});

	return useMemo(
		() => ({ handleChange, handleBlur, value: localValue }),
		[handleChange, handleBlur, localValue],
	);
}
