import type { PathFragment, TreeEntry } from "./types";

export const defaultGetNodeId = (_node: unknown, index: number) => index;

function findNodeById<TNode, TNodeId extends PathFragment>(
	nodes: TNode[],
	id: TNodeId,
	getNodeId: ((node: TNode, index: number) => TNodeId) | undefined,
): TNode | undefined {
	if (!getNodeId) {
		return nodes[id as number];
	}
	return nodes.find((node, i) => id === getNodeId(node, i));
}

/**
 * Find a node in a tree or subtree by its path. The path is a list of indices
 * that point to each node's position in its parent node's children array
 */
export function getNodeByPath<TNode>(
	root: TNode[],
	path: number[],
	getChildren: (node: TNode, path: number[]) => TNode[],
): TNode | null;
/**
 * Find a node in a tree or subtree by its path. The path is a list node ids
 * that uniquely identify a node. The id will be read using the `getNodeId`
 * callback
 */
export function getNodeByPath<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId: (node: TNode, index: number) => TNodeId,
): TNode | null;
export function getNodeByPath<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId?: (node: TNode, index: number) => TNodeId,
): TNode | null {
	const [firstId, ...restPath] = path;
	let node: TNode | undefined = findNodeById(root, firstId, getNodeId);
	for (const [pathIndex, childId] of restPath.entries()) {
		if (!node) return null;
		const children = getChildren(node, path.slice(0, pathIndex + 1));
		node = findNodeById(children, childId, getNodeId);
	}
	return node || null;
}

/**
 * Find a node's hierachy by its path
 */
export function getNodeHierachy<TNode>(
	root: TNode[],
	path: number[],
	getChildren: (node: TNode, path: number[]) => TNode[],
): TNode[] | null;
export function getNodeHierachy<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId: (node: TNode, index: number) => TNodeId,
): TNode[] | null;
export function getNodeHierachy<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId?: (node: TNode, index: number) => TNodeId,
): TNode[] | null {
	const [firstId, ...restPath] = path;
	let node: TNode | undefined = findNodeById(root, firstId, getNodeId);
	if (!node) return null;
	const ancestors: TNode[] = [];
	for (const [pathIndex, childId] of restPath.entries()) {
		if (!node) return null;
		ancestors.push(node);
		const children = getChildren(node, path.slice(0, pathIndex + 1));
		node = findNodeById(children, childId, getNodeId);
	}
	if (!node) return null;
	ancestors.push(node);
	return ancestors;
}

/**
 * Find a node's ancestors by its path
 */
export function getNodeAncestors<TNode>(
	root: TNode[],
	path: number[],
	getChildren: (node: TNode, path: number[]) => TNode[],
): TNode[] | null;
export function getNodeAncestors<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId: (node: TNode, index: number) => TNodeId,
): TNode[] | null;
export function getNodeAncestors<TNode, TNodeId extends PathFragment>(
	root: TNode[],
	path: TNodeId[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId?: (node: TNode, index: number) => TNodeId,
): TNode[] | null {
	const hierachy = getNodeHierachy(
		root,
		path,
		getChildren,
		getNodeId as Exclude<typeof getNodeId, undefined>,
	);
	if (!hierachy) return null;
	return hierachy.slice(0, -1);
}

function getNodeEntries<TNode, TNodeId extends PathFragment>(
	children: TNode[],
	parentPath: TNodeId[],
	getNodeId: ((node: TNode, index: number) => TNodeId) | undefined,
): TreeEntry<TNode, TNodeId>[] {
	const getId =
		getNodeId || (defaultGetNodeId as Exclude<typeof getNodeId, undefined>);
	return children.map((n, i) => {
		const entry: TreeEntry<TNode, TNodeId> = [[...parentPath, getId(n, i)], n];
		return entry;
	});
}

/**
 * Walk a tree breadth-first using a generator. This is ideal for searching for
 * nodes, when it's easy to eliminate whole branches of the tree from searching.
 * This way we'll never have to walk down these branches. Since this is a
 * generator, we can take advantange of js loop control flow such as `break`,
 * `continue`, `return`.
 */
export function walkTreeEntries<TNode>(
	tree: TNode[],
	getChildren: (node: TNode, path: number[]) => TNode[],
): Generator<TreeEntry<TNode, number>, void, void>;
export function walkTreeEntries<TNode, TNodeId extends PathFragment>(
	tree: TNode[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId: (node: TNode, index: number) => TNodeId,
): Generator<TreeEntry<TNode, TNodeId>, void, void>;
export function* walkTreeEntries<TNode, TNodeId extends PathFragment>(
	tree: TNode[],
	getChildren: (node: TNode, path: TNodeId[]) => TNode[],
	getNodeId?: (node: TNode, index: number) => TNodeId,
): Generator<TreeEntry<TNode, TNodeId>, void, void> {
	const queue: TreeEntry<TNode, TNodeId>[] = getNodeEntries(
		tree,
		[],
		getNodeId,
	);
	let currentEntry = queue[0];
	if (!currentEntry) return;
	while (true) {
		const entry = queue.shift();
		if (!entry) break;
		currentEntry = entry;
		yield entry;

		const [currentPath, currentNode] = currentEntry;
		const nodeChildren = getChildren(currentNode, currentPath);
		if (nodeChildren.length > 0) {
			const childNodeEntries = getNodeEntries(
				nodeChildren,
				currentPath,
				getNodeId,
			);
			queue.push(...childNodeEntries);
		}
	}
}

export function pathStartsWith<TFragment extends PathFragment = PathFragment>(
	path: TFragment[],
	subPath: TFragment[],
): boolean {
	return subPath.every((segment, i) => path[i] === segment);
}

export function pathEquals<TFragment extends PathFragment = PathFragment>(
	pathA: TFragment[],
	pathB: TFragment[],
): boolean {
	return pathA.length === pathB.length && pathStartsWith(pathA, pathB);
}
