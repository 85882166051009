import type { ImmutableMap } from "@xoev/immutable-map";
import type InfoNodeValues from "../../../../../types/InfoNodeValues";
import EditFormField from "../../../../EditFormField";
import type { MessageProfileValues } from "../../../../EditorState/types";
import type { FieldRendererComponentProps } from "../types";

const getStateValueByDefinition = (
	definitionName: keyof InfoNodeValues,
	state?: ImmutableMap<Partial<MessageProfileValues>> | null,
) => {
	switch (definitionName) {
		case "beispielWert":
		case "defaultWert":
		case "fixedWert":
		case "beispielWertNachricht":
			return state?.getIn(["konfiguration", definitionName]);
		case "codeliste":
			return null;
		case "eigenschaften":
			return null;
		default:
			return state?.get(definitionName);
	}
};

function SimpleRenderer<
	Keys extends keyof InfoNodeValues = keyof InfoNodeValues,
>({
	fields,
	profile,
	onBlur,
	placeholder,
}: FieldRendererComponentProps<Keys>): JSX.Element {
	const [definition] = fields;

	const stateValue = getStateValueByDefinition(definition.name, profile) as
		| string
		| undefined;

	return (
		<EditFormField
			key={definition.name}
			definition={definition}
			placeholder={placeholder}
			value={stateValue || ""}
			onBlur={onBlur}
		/>
	);
}

export default SimpleRenderer;
