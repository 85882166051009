import ConfirmDialog from "../../../../../../ConfirmDialog";

const ZeroCardinalityWarning = ({
	isOpen,
	onDismiss,
	onAccept,
}: {
	isOpen: boolean;
	onDismiss: () => void;
	onAccept: () => void;
}): JSX.Element => {
	return (
		<ConfirmDialog
			isOpen={isOpen}
			onAccept={onAccept}
			onDismiss={onDismiss}
			title="Kardinalität wirklich auf 0 setzen?"
			description={
				"Möchten sie die Kardnialität dieses Elements wirklich auf 0 setzen? " +
				"Profilierungen in den Kindknoten gehen dabei verloren."
			}
			data-testid="cardinality-warning"
			componentsProps={{
				cancelProps: { "data-testid": "dismiss" },
				acceptProps: { "data-testid": "accept" },
			}}
		/>
	);
};

export default ZeroCardinalityWarning;
