import type { LitePaket } from "../../../../../AppActor/actors/modellierungModel/schemas";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";

export default function SchemaPaketDisplay({
	activeNode,
}: DisplayRendererProps<LitePaket>): JSX.Element {
	return (
		<>
			<InfoRendererDisplay
				label="Präfix"
				value={activeNode.konfiguration?.prefix}
				fallback="Kein Präfix vorhanden"
			/>
			<InfoRendererDisplay
				label="Dateiname"
				value={activeNode.konfiguration?.schemaFile}
				fallback="Kein Dateiname vorhanden"
			/>
			<InfoRendererDisplay
				label="Namensraum"
				value={activeNode.konfiguration?.schemaLocation}
				fallback="Kein Namensraum vorhanden"
			/>
		</>
	);
}
