import { Alert, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RetryIcon from "@mui/icons-material/Replay";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { RequestStatus } from "../Api";
import type { RequestError } from "../Api/types";
import { RequestStatusCode } from "../Api/types";
import { GlobalSnackbarItem } from "../GlobalSnackbar";

function RequestErrorNotification({
	id,
	status,
	error,
	children,
	onRetry,
	onClose,
}: {
	id: string;
	status: RequestStatus;
	error: RequestError | null;
	children: ReactNode;
	onRetry?: () => void;
	onClose?: () => void;
}): JSX.Element {
	const [showError, setShowError] = useState(false);

	const statusCode = error?.status;
	const errorMessage = error?.message;
	useEffect(() => {
		if (
			status === RequestStatus.Failure &&
			statusCode !== RequestStatusCode.Aborted
		) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	}, [
		status,
		statusCode,
		// When the message or the id change, we have a new error, so we might need
		// to open the alert again
		errorMessage,
		id,
	]);

	const handleErrorClose = () => {
		if (onClose) {
			onClose();
		}
		setShowError(false);
	};

	return showError ? (
		<GlobalSnackbarItem id={id}>
			<Alert
				severity="error"
				action={
					<>
						{onRetry && (
							<Tooltip title="Erneut versuchen">
								<IconButton
									color="error"
									size="small"
									aria-label="Erneut versuchen"
									onClick={onRetry}
								>
									<RetryIcon fontSize="small" fill="currentColor" />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Schließen">
							<IconButton
								color="error"
								size="small"
								aria-label="Schließen"
								onClick={handleErrorClose}
							>
								<CloseIcon fontSize="small" fill="currentColor" />
							</IconButton>
						</Tooltip>
					</>
				}
			>
				{children}
				{error?.message && <>: {error?.message}</>}
			</Alert>
		</GlobalSnackbarItem>
	) : (
		<></>
	);
}

export default RequestErrorNotification;
