import classNames from "classnames";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import type { SelectArrowProps } from "./types";
import "./SelectArrow.scss";

const SelectArrow = ({ className }: SelectArrowProps): JSX.Element => (
	<span className={classNames("xui-select-arrow", className)}>
		<UnfoldMoreIcon />
	</span>
);

export default SelectArrow;
