import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useStableNavigate } from "../../hooks";

const DEFAULT_DOC_TITLE = "Deckblatt";

export default function useActiveDocTitle(): [
	string,
	(nextChapter: string) => void,
] {
	const { chapter = DEFAULT_DOC_TITLE } = useParams();
	const navigate = useStableNavigate();
	const changeChapter = useCallback(
		(nextChapter: string) => {
			navigate(nextChapter, { replace: true });
		},
		[navigate],
	);
	return [chapter, changeChapter];
}
