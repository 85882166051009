import ConstructionIcon from "@mui/icons-material/Construction";
import "./UnderConstruction.scss";

const UnderConstruction = (): JSX.Element => {
	return (
		<div className="under-construction">
			<ConstructionIcon className="under-construction__icon" />
			<span className="under-construction__text">Coming Soon</span>
		</div>
	);
};

export default UnderConstruction;
