import { memoize } from "@xoev/memo";
import type {
	Locator,
	ResultType,
	ValidationResult,
	ValidationTargetField,
} from "../AppActor/actors/modellierungModel/validation/validators/types";
import { Severity } from "../AppActor/actors/modellierungModel/validation/validators/types";
import { useValidationSelector } from "../AppActor/actors/modellierungModel/validation/hooks";
import { pathEquals, pathStartsWith } from "../Tree/treeHelpers";
import type { LiteId } from "../AppActor/actors/modellierungModel/schemas";
import { joinLitePath } from "../AppActor/actors/modellierungModel/schemas";

const EMPTY_RESULTS: never[] = [];

const selectResults = memoize(
	<TargetField extends ValidationTargetField = ValidationTargetField>(
		validationResults: ResultType[],
		locator: Locator<TargetField>,
	) => {
		type Result = ValidationResult<TargetField>;
		return validationResults.filter((result) => {
			let isMatch = true;
			if (locator?.targetField) {
				isMatch &&= locator.targetField === result.targetField;
			}
			if (locator?.nodeId) {
				isMatch &&= locator.nodeId === result.id;
			}
			if (locator?.path) {
				isMatch &&= pathEquals(locator.path, result.idPath);
			}
			return isMatch;
		}) as Result[];
	},
	{
		getCacheKeys: (validationResults, locator) => [
			validationResults,
			locator.targetField,
			locator.nodeId,
			locator.path && joinLitePath(locator.path),
		],
	},
);

export default function useValidationResults<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(locator?: Locator<TargetField>): ValidationResult<TargetField>[] {
	const results = useValidationSelector((context) => {
		return selectResults(context.validationErrors.results, locator || {});
	});
	return results || EMPTY_RESULTS;
}

export const getMaxSeverity = memoize(
	(validationResults: ResultType[]) => {
		const severities = validationResults.map((result) => result.severity);
		const severitySet = new Set(severities);
		if (severitySet.has(Severity.Error)) return Severity.Error;
		if (severitySet.has(Severity.Warning)) return Severity.Warning;
		if (severitySet.has(Severity.Info)) return Severity.Info;
		return undefined;
	},
	{ cacheSize: 64 },
);

const selectResultsByPath = memoize(
	(validationResults: ResultType[], path: LiteId[]) => {
		return validationResults.filter(
			(result) =>
				pathStartsWith(result.idPath, path) || pathEquals(path, result.idPath),
		);
	},
	{ cacheSize: 64 },
);

const selectSeverity = memoize(
	(validationResults: ResultType[], path: LiteId[]) => {
		return getMaxSeverity(selectResultsByPath(validationResults, path));
	},
	{
		getCacheKeys: (validationResults, path) => [
			validationResults,
			joinLitePath(path),
		],
		cacheSize: 64,
	},
);

export function useValidationSeverity(path: LiteId[]): Severity | undefined {
	const severity = useValidationSelector((context) => {
		return selectSeverity(context.validationErrors.results, path);
	});
	return severity || undefined;
}
