import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import type { ValidationQueryActorRef } from "./validation.query.machine";
import validationQueryMachine from "./validation.query.machine";
import type { ValidationCommandActorRef } from "./validation.command.machine";
import validationCommandMachine from "./validation.command.machine";
import type { ProjektId, RawLiteModel } from "../schemas";
import type { ProjektMeta } from "../../project/types";

// eslint-disable-next-line no-use-before-define
export type ValidationActorRef = ActorRefFrom<typeof validationMachine>;

export const createValidationQueryId = (projektId: ProjektId) =>
	`validation:query:${projektId}`;
export const createValidationCommandId = (projektId: ProjektId) =>
	`validation:command:${projektId}`;

const validationMachine = setup({
	types: {
		context: {} as ProjektMeta & {
			query: ValidationQueryActorRef;
			command: ValidationCommandActorRef;
		},
		input: {} as ProjektMeta & { rawModel: RawLiteModel },
	},
	actors: {
		query: validationQueryMachine,
		command: validationCommandMachine,
	},
}).createMachine({
	id: "validation",
	context: ({ input, spawn }) => ({
		...input,
		query: spawn("query", {
			systemId: createValidationQueryId(input.projektId),
			input,
		}),
		command: spawn("command", {
			systemId: createValidationCommandId(input.projektId),
			input,
		}),
	}),
});

export default validationMachine;
