import classNames from "classnames";
import React from "react";
import type { HeaderNavProps } from "./types";
import styles from "./HeaderNav.module.scss";

export default function HeaderNav({
	children,
	className,
	...props
}: HeaderNavProps): JSX.Element {
	return (
		<nav
			className={classNames(styles.menu, className)}
			aria-label="Hauptnavigation"
			{...props}
		>
			{children}
		</nav>
	);
}
