import useEditorField from "../../../EditFormField/useEditorField";
import type { DetailsComponentProps } from "../FormFieldRenderer/types";
import "./DetailsComponent.scss";

const DetailsComponent = ({
	label,
	name,
	value: valueProp,
	readOnly = false,
	onBlur,
}: DetailsComponentProps): JSX.Element => {
	const { handleBlur, handleChange, value } = useEditorField({
		value: valueProp,
		name,
		onWrite(_name, nextValue) {
			if (onBlur) {
				onBlur(name, nextValue);
			}
		},
	});

	return (
		<details>
			<summary className="details-component__summary">{label}</summary>
			<textarea
				value={value}
				className="details-component__details-textarea"
				readOnly={readOnly}
				onChange={handleChange}
				onBlur={handleBlur}
				name={name}
			/>
		</details>
	);
};

export default DetailsComponent;
