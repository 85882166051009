import { useSendStoreEvent } from "../../../AppActor/EventStore/hooks";
import {
	useActiveSearchPath,
	useIsSearchActive,
	useIsSearchLoading,
	useSearchTrie,
} from "../../../AppActor/actors/modellierungModel/search/hooks";
import SearchNav from "../../../SearchNav";
import { useActiveProjectId } from "../../../AppActor/actors/navigation/hooks";
import { useEventHandler } from "../../../../hooks";

export default function BausteinSearchNav({ id }: { id: string }): JSX.Element {
	const projektId = useActiveProjectId();
	const sendStoreEvent = useSendStoreEvent();
	const activeSearchPath = useActiveSearchPath();
	const isSearchLoading = useIsSearchLoading();
	const isSearchActive = useIsSearchActive();
	const searchTrie = useSearchTrie();

	const focusNode = useEventHandler((delta: number) => {
		if (!projektId) return;
		const payload = { projektId, delta };
		sendStoreEvent({ type: "SEARCH.SHIFT_RESULT_FOCUS", payload });
	});

	const focusNext = () => {
		focusNode(1);
	};

	const focusPrevious = () => {
		focusNode(-1);
	};

	return (
		<>
			{isSearchActive && searchTrie.size > 0 && (
				<SearchNav
					activePath={activeSearchPath}
					focusNext={focusNext}
					focusPrevious={focusPrevious}
					id={id}
					isLoading={isSearchLoading}
					trie={searchTrie}
				/>
			)}
		</>
	);
}
