import type { ShallowPrettify } from "./types";

export function objectKeys<Obj extends object>(obj: Obj): (keyof Obj)[] {
	return Object.keys(obj) as (keyof Obj)[];
}

export function objectValues<Obj extends object>(obj: Obj): Obj[keyof Obj][] {
	return Object.values(obj) as Obj[keyof Obj][];
}

export type Entries<Obj> = { [K in keyof Obj]: [K, Obj[K]] }[keyof Obj][];

export function objectEntries<Obj extends object>(obj: Obj): Entries<Obj> {
	return Object.entries(obj) as [keyof Obj, Obj[keyof Obj]][];
}

// We need `any` only for type inference here. It will never be used directly
// as a type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectFromEntries<K extends keyof any, V>(
	entries: [K, V][],
): { [Key in K]: V } {
	return Object.fromEntries(entries) as { [Key in K]: V };
}

export function omit<
	TObj extends { [K in PropertyKey]: unknown },
	TKey extends keyof TObj,
>(obj: TObj, keys: TKey[]): ShallowPrettify<Omit<TObj, TKey>> {
	const copy = { ...obj };
	for (const key of keys) {
		delete copy[key];
	}
	return copy;
}
