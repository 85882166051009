import { useEffect, useState } from "react";
import {
	createEmptyValidationResults,
	executeValidators,
} from "./validationExecution";
import type {
	AnyResultMap,
	UseValidatorResult,
	ValidatorExecResult,
	ValidatorMap,
} from "./types";
import { ValidationStatus } from "./types";

/**
 * Execute the validation each time the validators update
 *
 * @param validators The validators that should be checked
 * @param chunkSize The number of validation steps to run in one validation tick
 * @returns The result of the validation run
 */
export default function useValidators<ResultMap extends AnyResultMap>(
	validators: ValidatorMap<ResultMap>,
	chunkSize?: number,
): UseValidatorResult<ResultMap> {
	const [validationResults, setValidationResults] = useState<
		ValidatorExecResult<ResultMap>
	>(() => createEmptyValidationResults(validators));
	const [validationStatus, setValidationStatus] = useState<ValidationStatus>(
		ValidationStatus.Idle,
	);
	const [validationException, setValidationException] = useState<
		unknown | null
	>(null);

	// We need to keep exceptions, thrown during validation in local state, so
	// when we throw them again during reacts lifecycle. This way react knows
	// where it came from and can print a more helpful error message and stack
	// trace in development mode. It will also allow the error to be caught by an
	// error boundry
	useEffect(() => {
		if (validationException) {
			throw validationException;
		}
	}, [validationException]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (validators) {
			// Since the validation runs asynchronously, we want to be able to stop a
			// previous validation run, when we start the next cycle
			const controller = new AbortController();
			let isValidationRunning = true;
			const { signal } = controller;
			setValidationStatus(ValidationStatus.Running);
			executeValidators(validators, { signal, chunkSize })
				.then((result) => {
					// Ignore the result, when the next validation was started in the
					// meantime
					if (!signal.aborted) {
						setValidationResults(result);
						setValidationStatus(ValidationStatus.Completed);
					}
				})
				.catch((error: unknown) => {
					// Store the exception in local state, so when we throw it again
					// during a react lifecycle handler
					setValidationException(error);
				})
				.finally(() => {
					isValidationRunning = false;
				});
			return () => {
				// If the next update has already happened the previous validation is
				// now invalid, so we abort it
				if (isValidationRunning) {
					controller.abort();
				}
			};
		}
	}, [chunkSize, validators]);

	return { result: validationResults, status: validationStatus };
}
