import EditFormField from "../../../../../EditFormField";
import DynamicInfo from "../../../../../ui/DynamicInfo";
import { useCodeliste } from "../../fieldRendererHelpers";
import type { FieldRendererComponentProps } from "../../types";
import FieldRenderer from "../FieldRenderer";
import InfoRenderer from "../InfoRenderer";
import "./NutzungsArtRenderer.scss";

export function deriveNutzungsart({
	nutzungsArtStd,
	kennungState,
	versionState,
}: {
	nutzungsArtStd?: string;
	kennungState?: string;
	versionState?: string;
}) {
	switch (nutzungsArtStd) {
		case "1":
		case "2":
			return nutzungsArtStd;
		case "3":
			return versionState ? "2" : "3";
		case "4": {
			if (versionState && kennungState) return "2";
			if (kennungState) return "3";
			return "4";
		}
		default:
			return null;
	}
}

function NutzungsArtRenderer({
	activeNode,
	profile,
	isStandardRenderer,
	onBlur,
}: FieldRendererComponentProps): JSX.Element {
	const { nutzungsArt: nutzungsArtStd } = useCodeliste(activeNode);
	const kennungState = profile?.getIn([
		"konfiguration",
		"codeliste",
		"kennung",
	]);
	const versionState = profile?.getIn([
		"konfiguration",
		"codeliste",
		"version",
	]);
	const nutzungsArt = deriveNutzungsart({
		kennungState,
		versionState,
		nutzungsArtStd,
	});

	// If the standard nutzungsart is 1 or 2, it cannot be restricted further,
	// so displaying an information about generating the value is probably more
	// confusing than helpful
	const shouldShowTooltip =
		!isStandardRenderer && nutzungsArtStd !== "1" && nutzungsArtStd !== "2";
	const info = shouldShowTooltip ? (
		<DynamicInfo infoKey="nutzungsartInfo" />
	) : null;

	return (
		<>
			<FieldRenderer>
				<h3 className="nutzungs-art-renderer__heading">
					Daten der Codelistennutzung
				</h3>
			</FieldRenderer>
			<FieldRenderer>
				{!isStandardRenderer && (
					<EditFormField
						definition={{
							readOnly: true,
							name: "nutzungsArt",
							label: <>Nutzungsart {info}</>,
						}}
						value={nutzungsArt || ""}
						onBlur={onBlur}
					/>
				)}

				<InfoRenderer label="Nutzungsart" value={nutzungsArtStd || " "} />
			</FieldRenderer>
		</>
	);
}

export default NutzungsArtRenderer;
