import type { Falsy } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyGenerator = Generator<any, any, any>;
export type SimpleGenerator<T> = Generator<T, void, unknown>;
export type SimpleAsyncGenerator<T> = AsyncGenerator<T, void, unknown>;

export type GeneratorItem<GeneratorT extends AnyGenerator> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	GeneratorT extends Generator<infer ItemT, any, any> ? ItemT : never;
export type GeneratorReturn<GeneratorT extends AnyGenerator> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	GeneratorT extends Generator<any, infer ReturnT, any> ? ReturnT : never;
export type GeneratorNext<GeneratorT extends AnyGenerator> =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	GeneratorT extends Generator<any, any, infer NextT> ? NextT : never;

export function* toGenerator<T>(iter: Iterable<T>): SimpleGenerator<T> {
	for (const item of iter) {
		yield item;
	}
}

export function* empty<T = never>(): SimpleGenerator<T> {
	// yield nothing to produce an empty generator
}

export function map<Item, MappedItem>(
	mapper: (item: Item) => MappedItem,
): (
	generator: Generator<Item, unknown, unknown>,
) => SimpleGenerator<MappedItem> {
	return function* innerMap(generator) {
		for (const item of generator) {
			yield mapper(item);
		}
	};
}

export function scan<Item, AccValue>(
	mapper: (acc: AccValue, item: Item) => AccValue,
	initialValue: AccValue,
): (generator: Generator<Item, unknown, unknown>) => SimpleGenerator<AccValue> {
	let acc = initialValue;
	return function* innerMap(generator) {
		for (const item of generator) {
			acc = mapper(acc, item);
			yield acc;
		}
	};
}

export function filter<Item>(
	// We don't care about the predicates return type here, we only want to check
	// if it is falsy or truthy
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	predicate: (item: Item) => any,
): (generator: Generator<Item, unknown, unknown>) => SimpleGenerator<Item> {
	return function* innerFilter(generator) {
		for (const item of generator) {
			if (predicate(item)) {
				yield item;
			}
		}
	};
}

export function* filterFalsy<Item>(
	generator: Generator<Item, unknown, unknown>,
): SimpleGenerator<Exclude<Item, Falsy>> {
	for (const item of generator) {
		if (item) {
			yield item as Exclude<Item, Falsy>;
		}
	}
}

export function collect<Item>(
	generator: Generator<Item, unknown, unknown>,
): Item[] {
	const result: Item[] = [];
	for (const item of generator) {
		result.push(item);
	}
	return result;
}

export function* toAbortable<Item>(
	generator: Generator<Item, unknown, unknown>,
	signal: AbortSignal,
): SimpleGenerator<Item> {
	for (const item of generator) {
		if (signal.aborted) break;
		yield item;
	}
}

export async function* toAbortableAsync<Item>(
	generator: AsyncGenerator<Item, unknown, unknown>,
	signal: AbortSignal,
): SimpleAsyncGenerator<Item> {
	for await (const item of generator) {
		if (signal.aborted) break;
		yield item;
	}
}

export async function* toAsync<Item>(
	generator: Generator<Item, unknown, unknown>,
): SimpleAsyncGenerator<Item> {
	for (const item of generator) {
		yield item;
	}
}

export function mapAsync<Item, MappedItem>(
	mapper: (item: Item) => Promise<MappedItem> | MappedItem,
): (
	generator: AsyncGenerator<Item, unknown, unknown>,
) => SimpleAsyncGenerator<MappedItem> {
	return async function* innerMapAsync(generator) {
		for await (const item of generator) {
			yield mapper(item);
		}
	};
}

export function scanAsync<Item, AccValue>(
	mapper: (acc: AccValue, item: Item) => Promise<AccValue> | AccValue,
	initialValue: AccValue,
): (
	generator: AsyncGenerator<Item, unknown, unknown>,
) => SimpleAsyncGenerator<AccValue> {
	let acc = initialValue;
	return async function* innerMapAsync(generator) {
		for await (const item of generator) {
			acc = await mapper(acc, item);
			yield acc;
		}
	};
}

export function filterAsync<Item>(
	// We don't care about the predicates return type here, we only want to check
	// if it is falsy or truthy
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	predicate: (item: Item) => any,
): (
	generator: AsyncGenerator<Item, unknown, unknown>,
) => SimpleAsyncGenerator<Item> {
	return async function* innerFilterAsync(generator) {
		for await (const item of generator) {
			if (predicate(item)) {
				yield item;
			}
		}
	};
}

export async function* filterFalsyAsync<Item>(
	generator: AsyncGenerator<Item, unknown, unknown>,
): SimpleAsyncGenerator<Exclude<Item, Falsy>> {
	for await (const item of generator) {
		if (item) {
			yield item as Exclude<Awaited<Item>, Falsy>;
		}
	}
}

export async function collectAsync<Item>(
	generator: AsyncGenerator<Item, unknown, unknown>,
): Promise<Item[]> {
	const result: Item[] = [];
	for await (const item of generator) {
		result.push(item);
	}
	return result;
}
