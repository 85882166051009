import { Checkbox, IconButton, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import type { MouseEvent, SetStateAction, Dispatch } from "react";
import { useHtmlId } from "../../../../hooks";
import { validationIcons } from "../../../Validation/helpers";
import { Severity } from "../../../Validation/types";
import ValidationIcon from "../../ValidationIcon";
import type { MessageType } from "../types";
import MessageContextMenu from "../../MessageContextMenu";
import ValidationDialog from "../../ValidationDialog";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
	selectIsMessageChecked,
	setMessageChecked,
	setMessageUnchecked,
} from "../../../../redux/messagesSlice";

const INVALID_MESSAGE_NOTE =
	"Die Nachricht ist nicht gültig oder passt nicht zum ausgewählten Standard";

const ReportButton = ({
	message,
	standard,
	enableEdit,
	setEnableEdit,
	setEnableSingleEdit,
}: {
	message: MessageType;
	standard?: string;
	enableEdit?: string;
	setEnableEdit?: Dispatch<SetStateAction<string>>;
	setEnableSingleEdit?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openContextMenu, setOpenContextMenu] = useState<string>();
	const menuIdPrefix = useHtmlId();
	const labelIdPrefix = useHtmlId();

	const isMessageChecked = useAppSelector(selectIsMessageChecked(message.id));
	const dispatch = useAppDispatch();

	const menuId = `${menuIdPrefix}--message-context-menu--${message.id}`;
	const labelId = `${labelIdPrefix}--context-menu-button--${message.id}`;

	const handleInfoClick = () => {
		setIsDialogOpen(true);
	};

	const handleInfoClose = () => {
		setIsDialogOpen(false);
	};

	const handleOpenContextMenu = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenContextMenu(message.id);
	};

	const handleCloseContextMenu = () => {
		setAnchorEl(null);
		setOpenContextMenu("");
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		const messageId = message.id;
		if (checked) {
			dispatch(setMessageChecked({ messageId }));
		} else {
			dispatch(setMessageUnchecked({ messageId }));
		}
	};

	return (
		<>
			{message.standard === standard ? (
				<ValidationIcon message={message} handleInfoClick={handleInfoClick} />
			) : (
				<Tooltip title={INVALID_MESSAGE_NOTE}>
					<div className="message-overview__invalid-standard-icon">
						{validationIcons[Severity.Error]}
					</div>
				</Tooltip>
			)}
			<IconButton
				data-testid="open-message-menu"
				aria-controls={openContextMenu === message.id ? menuId : undefined}
				aria-haspopup="true"
				aria-label="Nachrichten Kontextmenü öffnen"
				id={labelId}
				aria-expanded={openContextMenu === message.id ? "true" : undefined}
				onClick={handleOpenContextMenu}
			>
				<MoreHorizIcon />
			</IconButton>
			<MessageContextMenu
				labelId={labelId}
				open={openContextMenu === message.id}
				menuId={menuId}
				message={message}
				handleClose={handleCloseContextMenu}
				anchorEl={anchorEl}
				enableEdit={enableEdit}
				setEnableEdit={setEnableEdit}
				setEnableSingleEdit={setEnableSingleEdit}
			/>
			{!setEnableSingleEdit && (
				<Checkbox checked={isMessageChecked} onChange={handleChange} />
			)}
			{message.standard === standard && (
				<ValidationDialog
					open={isDialogOpen}
					message={message}
					onClose={handleInfoClose}
				/>
			)}
		</>
	);
};

export default ReportButton;
