import { useEffect, useState } from "react";
import type { RendererComponent, RenderFn } from "./types";

// Cache the created renderers, so we don't create unnecessary renderers, that
// all use the same `renderFn`
const cacheMap = new WeakMap<RenderFn, RendererComponent>();

/**
 * Create a react component, that is capable of rendering markup into a html
 * container
 */
function createRendererComponent(renderFn: RenderFn): RendererComponent {
	const cachedRenderer = cacheMap.get(renderFn);
	if (cachedRenderer) {
		return cachedRenderer;
	}

	const Renderer: RendererComponent = ({ markup }) => {
		const [ref, setRef] = useState<HTMLElement | null>(null);

		useEffect(() => {
			if (ref) {
				renderFn(ref, markup);
			}
		}, [markup, ref]);

		return <div className="renderer" ref={setRef} />;
	};

	cacheMap.set(renderFn, Renderer);

	return Renderer;
}

export default createRendererComponent;
