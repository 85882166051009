import type { ReactNode } from "react";
import RequestErrorNotification from "../../RequestErrorNotification";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
	removeRejectedRequest,
	selectRejectedRequests,
} from "../../../redux/messagesSlice";
import { RequestStatus } from "../../Api";

const MessageRequestNotifications = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	const rejectedRequests = useAppSelector(selectRejectedRequests());
	const dispatch = useAppDispatch();
	const createOnNotificationClose = (endpointName: string) => () => {
		dispatch(removeRejectedRequest({ endpointName }));
	};

	return (
		<>
			{rejectedRequests.map(({ endpointName, error, notification }) => (
				<RequestErrorNotification
					key={endpointName}
					id={endpointName}
					error={error}
					status={RequestStatus.Failure}
					onClose={createOnNotificationClose(endpointName)}
				>
					{notification}
				</RequestErrorNotification>
			))}
			{children}
		</>
	);
};

export default MessageRequestNotifications;
