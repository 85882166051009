import type { FieldDefinition, FormHelpers } from "./types";

function createFormHelpers<FormValues>(
	fields: FieldDefinition<FormValues>[],
): FormHelpers<FormValues> {
	const visibleFields = fields.filter(({ isHidden }) => !isHidden);
	const hiddenFields = fields.filter(({ isHidden }) => isHidden);

	return { visibleFields, hiddenFields };
}

export default createFormHelpers;
