import { useContext } from "react";
import classNames from "classnames";
import { SelectContext } from "./SelectContext";
import PopoverMenuContext from "../PopoverMenu/PopoverMenuContext";
import PopoverMenu from "../PopoverMenu";
import type { SelectButtonProps } from "./types";
import "./SelectButton.scss";

const SelectButton = ({
	children = null,
	className,
	activeClassName,
	defaultClassName,
	valueClassName,
	placeholder = null,
	...props
}: SelectButtonProps): JSX.Element => {
	const { selectedItem, defaultItem, labelId, disabled } =
		useContext(SelectContext);
	const { buttonId, position } = useContext(PopoverMenuContext);
	const isDefault = selectedItem && selectedItem.id === defaultItem?.id;
	const showPlaceholder = !!(placeholder && !selectedItem?.data?.children);

	return (
		<PopoverMenu.Button
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			popupType="listbox"
			className={classNames(
				"xui-select-button",
				`xui-select-button--position-${position.replace(" ", "-")}`,
				isDefault && "xui-select-button--default",
				disabled && "xui-select-button--disabled",
				isDefault && defaultClassName,
				className,
			)}
			activeClassName={classNames("xui-select-button--open", activeClassName)}
			aria-labelledby={`${labelId} ${buttonId}`}
			disabled={disabled}
		>
			<span
				className={classNames(
					"xui-select-button__value",
					showPlaceholder && "xui-select-button__value--placeholder",
					valueClassName,
				)}
			>
				{showPlaceholder ? placeholder : selectedItem?.data?.children}
			</span>
			{children}
		</PopoverMenu.Button>
	);
};

export default SelectButton;
