import type { ReactNode } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStateContainerContext } from "../EditorState";
import { UnloadPrompt } from "../ui";
import EditorSavePointContext, {
	useIsProjectManuallyUnsaved,
	useSendSavePointEvent,
} from "./EditorSavePointContext";

// Don't prompt in cypress tests. There is no way to dismiss the prompt in the
// test environment
const IS_CYPRESS_TEST = "Cypress" in window;

function EditorSavePointsImpl(): JSX.Element {
	const stateContainer = useStateContainerContext();
	const { pathname } = useLocation();
	const sendSavePointEvent = useSendSavePointEvent();
	const shouldPrompt = useIsProjectManuallyUnsaved();

	useEffect(() => {
		sendSavePointEvent({ type: "REPORT_PATH_CHANGE", pathname });
	}, [sendSavePointEvent, pathname]);

	useEffect(() => {
		if (stateContainer) {
			sendSavePointEvent({ type: "SET_STATE_CONTAINER", stateContainer });
		}
	}, [sendSavePointEvent, stateContainer]);

	return IS_CYPRESS_TEST ? <></> : <UnloadPrompt when={shouldPrompt} />;
}

export default function EditorSavePointProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const { pathname } = useLocation();

	return (
		<EditorSavePointContext.Provider
			options={{ input: { pathname, prevPathname: pathname } }}
		>
			<EditorSavePointsImpl />
			{children}
		</EditorSavePointContext.Provider>
	);
}
