import { useMemo } from "react";
import createRootRenderer from "./Renderer";
import useGetRendererRegistry from "./useGetRendererRegistry";
import type { MessageType } from "../MessageOverview/types";
import { useGetMarkupQuery } from "../../../redux/messagesApiSlice";

const MessageViewer = ({
	message,
	className,
}: {
	message: MessageType;
	className?: string;
}): JSX.Element => {
	const { rendererRegistry, isRegistryComplete } =
		useGetRendererRegistry(message);

	const Renderer = useMemo(
		() => createRootRenderer(rendererRegistry),
		[rendererRegistry],
	);
	const markup = useGetMarkupQuery({
		messageId: message.id,
	}).data;

	return (
		<div className={className}>
			{isRegistryComplete && markup && <Renderer markup={markup} />}
		</div>
	);
};

export default MessageViewer;
