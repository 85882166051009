import eventStoreMachine from "./EventStore/eventStore.machine";
import effectApiMachine from "./actors/effectApi/effectApi.machine";
import notificationMachine from "./actors/notification/notification.machine";
import keyboardShortcutsMachine from "./actors/keyboardShortcuts/keyboardShortcuts.machine";
import navigationMachine from "./actors/navigation/navigation.machine";
import projectsMachine from "./actors/projects/projects.machine";
import actorSystemMachine from "./actors/actorSystem/actorSystem.machine";

type ActorSystemMachine = typeof actorSystemMachine;
type NotificationMachine = typeof notificationMachine;
type EventStoreMachine = typeof eventStoreMachine;
type NavigationMachine = typeof navigationMachine;
type ProjectsMachine = typeof projectsMachine;
type EffectApiMachine = typeof effectApiMachine;
type KeyboardShortcutsMachine = typeof keyboardShortcutsMachine;

// We need to extract the definition into its own type, because otherwise TS
// hits a limit of how much type information it can infer and errors
export type AppActorMap = {
	actorSystem: ActorSystemMachine;
	notification: NotificationMachine;
	eventStore: EventStoreMachine;
	navigation: NavigationMachine;
	projects: ProjectsMachine;
	effectApi: EffectApiMachine;
	keyboardShortcuts: KeyboardShortcutsMachine;
};

const appActors: AppActorMap = {
	actorSystem: actorSystemMachine,
	notification: notificationMachine,
	eventStore: eventStoreMachine,
	navigation: navigationMachine,
	projects: projectsMachine,
	effectApi: effectApiMachine,
	keyboardShortcuts: keyboardShortcutsMachine,
};

export default appActors;
