import type { ReactNode } from "react";
import EditorNavBarActions from "./EditorNavBarActions";
import NavBarDisplay from "../NavBarDisplay";

function EditorNavBar({
	children,
	className,
	...props
}: {
	[k: `data-${string}`]: string;
	children?: ReactNode;
	className?: string;
}): JSX.Element {
	return (
		<NavBarDisplay className={className} {...props}>
			<NavBarDisplay.Breadcrumbs>{children}</NavBarDisplay.Breadcrumbs>
			<NavBarDisplay.Actions>
				<EditorNavBarActions />
			</NavBarDisplay.Actions>
		</NavBarDisplay>
	);
}

export default EditorNavBar;
