import {
	GridToolbarQuickFilter,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";

// The `GridToolbarFilterButton` prop definitions are buggy. They likely forgot
// to wrap a `Partial<>` around the prop type
const DefaultToolbar = GridToolbarFilterButton as unknown as () => JSX.Element;

export default function SelectionToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter
				inputProps={{ "data-testid": "selection-quick-filter" }}
			/>
			<DefaultToolbar />
		</GridToolbarContainer>
	);
}
