import type { PropertyProfileMap } from "../../../EditorState/types";
import type { ProfileRule } from "../../types";
import { ValidationTargetField } from "../../types";
import { addTargetField } from "../helpers";

function getEmptyProperties(properties: PropertyProfileMap | undefined) {
	return properties
		?.filter((prop) => {
			const value = prop.get("value");
			return !value || value.trim().length === 0;
		})
		.valueSeq()
		.map((prop) => prop.get("name"));
}

const propertyRules = addTargetField<
	ValidationTargetField.Property,
	ProfileRule<ValidationTargetField.Property>
>(ValidationTargetField.Property, [
	{
		id: "property-values-are-not-empty",
		target: ["eigenschaften"],
		isValid({ profile }) {
			const properties = profile.get("eigenschaften");
			const emptyProps = getEmptyProperties(properties);
			properties?.filter((prop) => {
				const value = prop.get("value");
				return !value || value.trim().length === 0;
			});
			if (!emptyProps) return true;
			return emptyProps?.count() === 0;
		},
		message: ({ profile }) => {
			const properties = profile.get("eigenschaften");
			const emptyProps = getEmptyProperties(properties);
			if (!emptyProps)
				return "Die Werte der Eigenschaft dürfen nicht leer sein.";
			const quotedProps = emptyProps.map((prop) => `"${prop}"`);
			const first = quotedProps.butLast();
			const last = quotedProps.last();
			let list = first.join(", ");
			if (first.count() === 0) {
				list = last as string;
			} else {
				list += ` und ${last}`;
			}
			return `Die Werte von ${list} dürfen nicht leer sein.`;
		},
	},
]);

export default propertyRules;
