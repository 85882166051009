import { IconButton, Tooltip } from "@mui/material";
import AcceptIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import type { ImmutableMap } from "@xoev/immutable-map";
import type { KeyboardEvent } from "react";
import { useSyncedState } from "../../../../hooks";
import { useStateSelector } from "../../../EditorState";
import {
	selectHasRestriction,
	selectStandard,
} from "../../../EditorState/selectors";
import type { RestrictionProfileValues } from "../../../EditorState/types";
import DatatypeTableCell from "./DatatypeTableCell";
import useCheckedActiveNode from "./useCheckedActiveNode";
import { selectQName } from "../../../../redux/treeSlice";
import { useAppSelector } from "../../../../redux/hooks";
import "./DatatypeEditCells.scss";

export default function DatatypeEditCells({
	onSubmit,
	onDiscard,
	values,
}: {
	onSubmit: (restriction: ImmutableMap<RestrictionProfileValues>) => void;
	onDiscard?: (id: string) => void;
	values: ImmutableMap<RestrictionProfileValues>;
}): JSX.Element {
	const activeNode = useCheckedActiveNode(DatatypeEditCells.name);
	const standard = useStateSelector(selectStandard());
	const activeQName = useAppSelector(selectQName(standard, activeNode));

	const [state, setState] = useSyncedState(values);

	const handleChange = (event: { target: { name: string; value: string } }) => {
		const { name, value } = event.target;
		if (name === "name" || name === "beschreibung") {
			setState((prevState) => prevState.set(name, value));
		}
	};

	const hasRestriction = useStateSelector(
		selectHasRestriction(activeQName, state.get("name")),
	);
	const isAddPossible =
		state.get("name").trim().length !== 0 &&
		(!hasRestriction || values.get("name") === state.get("name"));

	const handleSubmit = () => {
		if (isAddPossible) {
			onSubmit(state);
		}
	};

	const handleDiscard = () => {
		setState(values);
		onDiscard?.(values.get("id"));
	};

	const handleKeyDown = (
		event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		if (event.key === "Enter" && event.ctrlKey) {
			handleSubmit();
		}
		if (event.key === "Escape") {
			handleDiscard();
		}
	};

	return (
		<>
			<DatatypeTableCell variant="name">
				<input
					// This form is not always rendered on the page, so it doesn't steal
					// focus while something else is happening. Instead, A button needs
					// to be clicked, in order to show this form. The next step will be
					// entering data into the form, so focusing the form does make sense
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					type="text"
					onChange={handleChange}
					value={state.get("name")}
					onKeyDown={handleKeyDown}
					name="name"
					className="datatype-edit-cells__input datatype-edit-cells__input--name"
					data-testid="new-restriction-name-input"
					placeholder="Neue Einschränkung hinzufügen"
					aria-label="Name der Einschränkung"
					aria-required="true"
				/>
			</DatatypeTableCell>
			<DatatypeTableCell variant="description">
				<textarea
					onChange={handleChange}
					name="beschreibung"
					value={state.get("beschreibung")}
					onKeyDown={handleKeyDown}
					className="datatype-edit-cells__input datatype-edit-cells__input--desc"
					data-testid="new-restriction-desc-input"
					placeholder="Neue Beschreibung hinzufügen"
					aria-label="Beschreibung der Einschränkung"
				/>
			</DatatypeTableCell>
			<DatatypeTableCell variant="actions">
				<div className="datatype-edit-cells__restriction-buttons">
					<Tooltip title="Änderungen bestätigen">
						<div>
							<IconButton
								size="small"
								onClick={handleSubmit}
								disabled={!isAddPossible}
								data-testid="restriction-edit-accept"
							>
								<AcceptIcon />
							</IconButton>
						</div>
					</Tooltip>
					<Tooltip title="Änderungen verwerfen">
						<IconButton
							size="small"
							onClick={handleDiscard}
							data-testid="restriction-edit-discard"
						>
							<CancelIcon />
						</IconButton>
					</Tooltip>
				</div>
			</DatatypeTableCell>
		</>
	);
}
