import classNames from "classnames";
import type { ReactNode } from "react";
import React from "react";
import type { AnyComponent, ExtendProps, PropsOf } from "../util";
import styles from "./MenuItemLink.module.scss";

export interface MenuItemLinkOwnProps<TComponent extends AnyComponent = "a"> {
	children?: ReactNode;
	className?: string;
	isActive: boolean;
	disabled?: boolean;
	as?: TComponent;
	disableWidthCompensation?: boolean;
}

export type MenuItemLinkProps<TComponent extends AnyComponent = "a"> =
	ExtendProps<PropsOf<TComponent>, MenuItemLinkOwnProps<TComponent>>;

export default function MenuItemLink<TComponent extends AnyComponent = "a">({
	isActive,
	as: As,
	children,
	className,
	disabled,
	disableWidthCompensation = false,
	...props
}: MenuItemLinkProps<TComponent>): JSX.Element {
	const Comp = As || "a";
	return (
		<Comp
			className={classNames(
				styles.link,
				isActive && styles.linkActive,
				disabled && styles.disabled,
				disableWidthCompensation && styles.widthCompensationDisabled,
				className,
			)}
			{...props}
		>
			{!disableWidthCompensation && (
				<span aria-hidden className={styles.constantWidthLinkText}>
					{children}
				</span>
			)}
			<span className={styles.linkText}>{children}</span>
		</Comp>
	);
}
