import { useCallback } from "react";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";
import {
	createEmptyActor,
	type Actor,
	type AnyActorLogic,
	type AnyActorRef,
	type EventObject,
	type SnapshotFrom,
	type StateValue,
} from "xstate";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";

/**
 * Create a stub implementation for a machine callback, that will throw an
 * error when it is not overwritten when the machine is interpreted.
 *
 * @param machineId The name of the machine
 * @param fnName The name of the callback function
 * @returns A stub function, that throws an error when it is called
 */
export function createUnimplementedCallbackHandler(
	machineId: string,
	fnName: string,
) {
	return () => {
		throw new Error(
			`Expected \`${machineId}\` machine instance, to be passed a ` +
				`\`${fnName}\` function via its \`context.callbacks\`, but none was ` +
				"found.",
		);
	};
}

/**
 * Turns a machine's state value object into a more readable string for
 * debugging purposes.
 *
 * @param stateValue The machines `state.value`
 * @returns A string representation of the state value
 * @example
 * ```js
 * stringifyState({ State: { NestedState: "DeeplyNestedState" } });
 * // -> State.NestedState.DeeplyNestedState
 * // Multiline output for parallel states:
 * stringifyState({ State: "NestedState", ParallelState: "NestedParallel" });
 * // ->
 * // State.NestedState
 * // ParallelState.NestedParallel
 * ```
 */
export function stringifyState(stateValue: StateValue): string {
	return JSON.stringify(stateValue)
		.replaceAll('"', "")
		.replaceAll("{", "")
		.replaceAll("}", "")
		.replaceAll(",", "\n")
		.replaceAll(":", ".");
}

/**
 * Assert function for narrowing events typed with discriminated unions
 */
export function assertEvent<
	TEvent extends EventObject,
	Type extends TEvent["type"],
>(ev: TEvent, type: Type): asserts ev is Extract<TEvent, { type: Type }> {
	if (ev.type !== type) {
		throw new Error("Unexpected event type.");
	}
}

export function assertIsActor<TLogic extends AnyActorLogic>(
	actor: unknown,
	logic: TLogic,
): asserts actor is Actor<TLogic> {
	if (
		actor &&
		(typeof actor === "object" || typeof actor === "function") &&
		"logic" in actor &&
		actor.logic === logic
	)
		return;
	throw new Error(`The actor did not match the provided actor logic`);
}

export function getFromSystem<TLogic extends AnyActorLogic>(
	system: AnyActorSystem,
	systemId: string,
): AnyActorLogic extends TLogic ? never : Actor<TLogic> {
	const actorRef = system.get(systemId);
	return actorRef as AnyActorLogic extends TLogic ? never : Actor<TLogic>;
}

export function sendInSystem<TLogic extends AnyActorLogic>(
	system: AnyActorSystem,
	systemId: string,
	event: Parameters<Actor<TLogic>["send"]>[0],
) {
	const actorRef = getFromSystem<TLogic>(system, systemId);
	actorRef.send(event);
}

type SyncExternalStoreSubscribe = Parameters<
	typeof useSyncExternalStoreWithSelector
>[0];

function defaultCompare<T>(a: T, b: T) {
	return a === b;
}

// This is taken from XState React. Since XState version 5.4.0,
// `actor.getSnapshot` checks whether the actor is initialized. If it is not,
// an error is thrown. In `useActorRefSelector` we want a selector, that allows
// for non existing or unitialized actors and only returns a value, when the
// actor is ready. That's why we need to implement our own `useSelector`, that
// accesses the snapshot in a try ... catch, to allow uninitialized actors to
// be used
export function useSafeXStateSelector<
	TActor extends Pick<AnyActorRef, "subscribe" | "getSnapshot"> | undefined,
	T,
>(
	actor: TActor,
	selector: (
		snapshot: TActor extends { getSnapshot(): infer TSnapshot }
			? TSnapshot
			: undefined,
	) => T,
	compare: (a: T, b: T) => boolean = defaultCompare,
): T {
	const subscribe: SyncExternalStoreSubscribe = useCallback(
		(handleStoreChange) => {
			if (!actor) {
				return () => {};
			}
			const { unsubscribe } = actor.subscribe(handleStoreChange);
			return unsubscribe;
		},
		[actor],
	);

	const boundGetSnapshot = useCallback(() => {
		// Wrap the `getSnapshot` call in a try ... catch and return undefined
		// if the actor is not ready yet
		try {
			return actor?.getSnapshot();
		} catch {
			return undefined;
		}
	}, [actor]);

	const selectedSnapshot = useSyncExternalStoreWithSelector(
		subscribe,
		boundGetSnapshot,
		boundGetSnapshot,
		selector,
		compare,
	);

	return selectedSnapshot;
}

const empty = createEmptyActor();

export function useActorRefSelector<TRef extends AnyActorRef, TReturn>(
	ref: TRef | null,
	selector: (snapshot: SnapshotFrom<TRef>) => TReturn,
): TReturn | null {
	return useSafeXStateSelector(ref ?? empty, (snapshot) =>
		ref && snapshot ? selector(snapshot as SnapshotFrom<TRef>) : null,
	);
}
