import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import { createCommandId, createQueryId } from "./helpers";
import type { ModellierungSearchCommandActorRef } from "./modellierungSearch.command.machine";
import modellierungSearchCommandMachine from "./modellierungSearch.command.machine";
import type { ModellierungSearchQueryActorRef } from "./modellierungSearch.query.machine";
import modellierungSearchQueryMachine from "./modellierungSearch.query.machine";
import type { ProjektMeta } from "../../project/types";

export type ModellierungSearchActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungSearchMachine
>;

const modellierungSearchMachine = setup({
	types: {
		context: {} as ProjektMeta & {
			query: ModellierungSearchQueryActorRef;
			command: ModellierungSearchCommandActorRef;
		},
		input: {} as ProjektMeta,
	},
	actors: {
		query: modellierungSearchQueryMachine,
		command: modellierungSearchCommandMachine,
	},
}).createMachine({
	id: "modellierungSearch",
	context: ({ input, spawn }) => ({
		...input,
		query: spawn("query", {
			systemId: createQueryId(input.projektId),
			id: createQueryId(input.projektId),
			input,
		}),
		command: spawn("command", {
			systemId: createCommandId(input.projektId),
			id: createCommandId(input.projektId),
			input,
		}),
	}),
});

export default modellierungSearchMachine;
