import type { MouseEventHandler } from "react";
import { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import { useDescendant } from "../util/descendants";
import { SelectContext, SelectDescendentsContext } from "./SelectContext";
import { normObject } from "../util";
import { useSyncedRef } from "../../../hooks";
import type { SelectOptionProps } from "./types";
import "./SelectOption.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const SelectOption = ({
	children,
	value,
	className,
	activeClassName,
	selectedClassName,
	onClick = noop,
	onActivate = noop,
	onSelect = noop,
	// `default` is a reserved keyword, so we need to rename it
	default: isDefault = false,
	id: idProp,
	...props
}: SelectOptionProps): JSX.Element => {
	const { activeItem, setActiveId, selectedItem, handleChange, idPrefix } =
		useContext(SelectContext);
	const options = useMemo(
		() => ({
			data: { value, children, default: isDefault },
			id: idProp || `${idPrefix}--${value}`,
		}),
		[children, isDefault, idPrefix, idProp, value],
	);
	const { id, setRef } = useDescendant(SelectDescendentsContext, options);
	const isActive = activeItem?.id === id;
	const isSelected = selectedItem?.id === id;
	const onActivateRef = useSyncedRef(onActivate);
	const onSelectRef = useSyncedRef(onSelect);
	const valueRef = useSyncedRef(value);

	useEffect(() => {
		if (isActive) {
			onActivateRef.current();
		}
	}, [isActive, onActivateRef]);
	useEffect(() => {
		if (isSelected) {
			onSelectRef.current({ value: valueRef.current });
		}
	}, [isSelected, onSelectRef, valueRef]);

	const handleEnter = () => setActiveId(id);
	const handleLeave = () => setActiveId(null);

	const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
		handleChange(e, value, "click");
		setActiveId(null);
		onClick(e);
	};

	return (
		// Keyboard events are handled in the `SelectImpl` component
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<li
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			id={id}
			ref={setRef}
			className={classNames(
				"xui-select-option",
				isActive && "xui-select-option--active",
				isSelected && "xui-select-option--selected",
				isActive && activeClassName,
				isSelected && selectedClassName,
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...normObject(isDefault, { "data-default-option": "true" })}
			data-value={value}
			role="option"
			aria-selected={isActive ? "true" : "false"}
			onMouseEnter={handleEnter}
			onMouseLeave={handleLeave}
			onClick={handleClick}
		>
			{children}
		</li>
	);
};

export default SelectOption;
