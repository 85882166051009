import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import type {
	ValidationErrorSchema,
	ValidationErrorSchematron,
} from "../../MessageOverview/types";
import "./ValidationTable.scss";

const ValidationTable = ({
	meldungen,
	title,
}: {
	meldungen: ValidationErrorSchema[] | ValidationErrorSchematron[];
	title: string;
}): JSX.Element => {
	const columns = Object.keys(meldungen[0]);
	return (
		<div
			className="validation-table"
			data-testid="validation-table"
			data-title={title}
		>
			<h3 data-testid="header">{title}</h3>
			<TableContainer>
				<Table data-testid="table">
					<TableHead data-testid="table-head">
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column}
									className="validation-table__header-cell"
									data-testid="header-cell"
								>
									{column}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody data-testid="table-body">
						{meldungen?.map((err, index) => (
							<TableRow
								// The list of messages will only change for a new message, so
								// the index is a stable key
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								data-testid="table-row"
							>
								{Object.values(err).map((value) => (
									<TableCell data-testid="table-cell" key={value}>
										{value}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default ValidationTable;
