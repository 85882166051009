import type { EventStoreLog } from "../../../../EventStore/eventStore.machine";
import ModellWorkerBridge from "../../ModellWorkerBridge";
import type { ModellHandlerMap } from "../../ModellWorkerBridge/types";
import type { ProjektId } from "../../schemas";
import type {
	ValidationResult,
	ValidationTargetField,
} from "../validators/types";
import type { ValidationActionMap } from "./types";

export default class ValidationWorkerBridge {
	worker: ModellWorkerBridge<ValidationActionMap>;

	constructor(worker: ModellWorkerBridge<ValidationActionMap>) {
		this.worker = worker;
	}

	static async fromEventLog(
		projektId: ProjektId,
		eventLog: EventStoreLog,
		createWorker: () => Worker,
	): Promise<ValidationWorkerBridge> {
		const worker = await ModellWorkerBridge.fromEventLog<ValidationActionMap>(
			projektId,
			eventLog,
			createWorker,
			1,
		);
		return new ValidationWorkerBridge(worker);
	}

	onEvent(): Promise<ValidationResult<ValidationTargetField>[]> {
		return this.worker.bridge.enqueue("onEvent", undefined);
	}

	static connectWorker(handlerMap: ModellHandlerMap<ValidationActionMap>) {
		ModellWorkerBridge.connectWorker(handlerMap);
	}
}
