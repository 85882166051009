import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { useStateSelector } from "../../../EditorState";
import { selectHasActiveProject } from "../../../EditorState/selectors";
import {
	CacheKeys,
	useRefreshViewerMutation,
} from "../../../../redux/messagesApiSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
	ValidationBase,
	selectValidationBase,
	setValidationBase,
} from "../../../../redux/messagesSlice";
import "./ValidationBaseSelect.scss";

const ValidationBaseSelect = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const validationBase = useAppSelector(selectValidationBase());
	const hasActiveProject = useStateSelector(selectHasActiveProject());

	const [requestRefresh] = useRefreshViewerMutation({
		fixedCacheKey: CacheKeys.Refresh,
	});

	const handleSelectChange = (event: SelectChangeEvent) => {
		const newBase = event.target.value as ValidationBase;
		if (newBase !== validationBase) {
			dispatch(setValidationBase({ validierungsBasis: newBase }));
			requestRefresh({ validierungsBasis: newBase });
		}
	};
	return (
		<Select
			className="validation-base-select"
			value={validationBase}
			onChange={handleSelectChange}
		>
			{hasActiveProject && (
				<MenuItem value={ValidationBase.ActiveProfile}>
					Validierung gegen geöffnete Profilierung
				</MenuItem>
			)}
			<MenuItem value={ValidationBase.Standard}>
				Validierung gegen zugrundeliegenden Standard
			</MenuItem>
		</Select>
	);
};

export default ValidationBaseSelect;
