import { IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {
	ExportModellierungProject,
	ExportStandardProject,
} from "./ExportProject";
import { useSendStoreEvent } from "../AppActor/EventStore/hooks";
import { useIsProjektDisplayOnly } from "../AppActor/actors/project/hooks";
import "../EditorNavBar/EditorNavBarActions/EditorNavBarActions.scss";
import {
	useCanRedo,
	useCanUndo,
} from "../AppActor/actors/modellierungModel/hooks";
import ValidationDialog from "./ValidationDialog";

function TabNavBarActions(): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const isReadOnly = useIsProjektDisplayOnly();
	const canUndo = useCanUndo();
	const canRedo = useCanRedo();

	const undo = () => {
		sendStoreEvent({ type: "SHORTCUT.UNDO" });
	};

	const redo = () => {
		sendStoreEvent({ type: "SHORTCUT.REDO" });
	};

	if (isReadOnly) return <ExportStandardProject />;
	return (
		<div
			className="editor-navbar-actions"
			data-testid="datatypes-navbar__buttons"
		>
			<ValidationDialog />
			<ExportModellierungProject />
			<IconButton
				onClick={undo}
				aria-label="Rückgängig"
				data-testid="undo-button"
				disabled={!canUndo}
			>
				<UndoIcon />
			</IconButton>
			<IconButton
				onClick={redo}
				aria-label="Wiederherstellen"
				data-testid="redo-button"
				disabled={!canRedo}
			>
				<RedoIcon />
			</IconButton>
		</div>
	);
}

export default TabNavBarActions;
