import { createContext, useContext } from "react";

const EditorSideBarContext = createContext({
	searchInputId: "editor-sidebar-search-input",
});

export function useEditorSideBar() {
	const ctx = useContext(EditorSideBarContext);
	return ctx;
}

export default EditorSideBarContext;
