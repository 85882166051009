import { createContext, useContext } from "react";
import type { AppSessionContextValue } from "./types";

export const AppSessionContext = createContext<AppSessionContextValue>({
	session: null,
});

export function useSession(): AppSessionContextValue {
	return useContext(AppSessionContext);
}
