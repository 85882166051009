export interface Deferred<ResolveT, RejectT = unknown> {
	resolve: (value: ResolveT | PromiseLike<ResolveT>) => void;
	reject: (reason: RejectT) => void;
	promise: Promise<ResolveT>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
export function createDeferred<
	ResolveT = unknown,
	RejectT = unknown,
>(): Deferred<ResolveT, RejectT> {
	let resolve: Deferred<ResolveT, RejectT>["resolve"] = noop;
	let reject: Deferred<ResolveT, RejectT>["reject"] = noop;
	const promise = new Promise<ResolveT>((res, rej) => {
		resolve = res;
		reject = rej;
	});
	return { resolve, reject, promise };
}
