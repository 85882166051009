import DynamicInfo from "../../../ui/DynamicInfo";
import "./NoSearchResultsInfo.scss";

function NoSearchResultsInfo({ infoKey }: { infoKey: string }): JSX.Element {
	return (
		<em className="no-results-info" data-testid="no-search-results">
			Keine Ergebnisse gefunden <DynamicInfo infoKey={infoKey} />
		</em>
	);
}

export default NoSearchResultsInfo;
