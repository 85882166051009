import { Menu } from "@mui/material";
import type { MouseEvent, ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { useEventHandler } from "../../../hooks";

type ContextMenuState = {
	mouseX: number;
	mouseY: number;
};

const ContextMenuContext = createContext<{
	contextMenu: ContextMenuState | null;
	handleClose: () => void;
}>(null as never);

function MenuPopup({ children }: { children: ReactNode }): JSX.Element {
	const { contextMenu, handleClose } = useContext(ContextMenuContext);
	return (
		<Menu
			open={contextMenu !== null}
			onClose={handleClose}
			anchorReference="anchorPosition"
			anchorPosition={
				contextMenu !== null
					? { top: contextMenu.mouseY, left: contextMenu.mouseX }
					: undefined
			}
		>
			{children}
		</Menu>
	);
}

export default function ContextMenu({
	children,
	disabled,
}: {
	children: (renderProps: {
		handleContextMenu: (event: MouseEvent) => void;
		closeMenu: () => void;
		isOpen: boolean;
	}) => ReactNode;
	disabled?: boolean;
}): JSX.Element {
	const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);
	const isOpen = !!contextMenu;

	const handleContextMenu = useEventHandler((event: MouseEvent) => {
		if (disabled) return;
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
						mouseX: event.clientX + 2,
						mouseY: event.clientY - 6,
				  }
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
				  // Other native context menus might behave different.
				  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
				  null,
		);
	});

	const handleClose = useEventHandler(() => {
		setContextMenu(null);
	});

	const ctx = useMemo(
		() => ({ contextMenu, handleClose }),
		[contextMenu, handleClose],
	);

	const renderProps = useMemo(
		() => ({ handleContextMenu, closeMenu: handleClose, isOpen }),
		[handleClose, handleContextMenu, isOpen],
	);

	return (
		<ContextMenuContext.Provider value={ctx}>
			{children(renderProps)}
		</ContextMenuContext.Provider>
	);
}

ContextMenu.Menu = MenuPopup;
