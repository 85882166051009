import { assertEvent, assign, fromCallback, raise, setup } from "xstate";
import type { ShorcutCommand } from "./types";
import {
	DEFAULT_CHORD_DELAY,
	extendMatch,
	getActiveModifiers,
	getFullMatch,
	getPartialMatches,
	getStoreEventFromCommand,
	isModifierKey,
	parseKeyMap,
	stringifyShortcut,
} from "./helpers";
import defaultKeyMap from "./keyMap";
import { sendToEventStore } from "../../EventStore/helpers";

type KeyboardShortcutsCommandEvent =
	| { type: "KEY_DOWN"; keyEvent: KeyboardEvent }
	| {
			type: "PROCESS_MATCH";
			fullMatch: ShorcutCommand | null;
			partialMatches: ShorcutCommand[];
			keyEvent: KeyboardEvent;
	  };

const keyboardShortcutsCommandMachine = setup({
	types: {
		events: {} as KeyboardShortcutsCommandEvent,
		context: {} as {
			activeMatch: string[];
			shortcutDefinition: ShorcutCommand[];
			chordDelay: number;
		},
	},
	actors: {
		// Forward all keydown events to the machine
		listenForKeyEvents: fromCallback<KeyboardShortcutsCommandEvent>(
			({ sendBack }) => {
				const keyDownListener = (event: KeyboardEvent) => {
					// Skip the keypress when it was a repitition or only a modifier,
					// such as `Shift` or `Control` was pressed
					if (event.repeat || isModifierKey(event.key)) return;
					sendBack({
						type: "KEY_DOWN",
						keyEvent: event,
					} satisfies KeyboardShortcutsCommandEvent);
				};
				document.addEventListener("keydown", keyDownListener);
				return () => {
					document.removeEventListener("keydown", keyDownListener);
				};
			},
		),
	},
	actions: {
		// When a key combination is pressed, we record it to the context, so we
		// can check if the keyMap defines a command, that matches the shortcut
		// or chord
		writeActiveMatch: assign({
			activeMatch: ({ context, event }) => {
				assertEvent(event, "KEY_DOWN");
				return extendMatch(
					context.activeMatch,
					stringifyShortcut(
						event.keyEvent.key,
						getActiveModifiers(event.keyEvent),
					),
				);
			},
		}),
		// After a match was found or no match was found, or a timeout elapsed, we
		// need to cleat the active match, so we can listen for the next matches
		clearActiveMatch: assign({ activeMatch: [] }),
		execPreventDefault: ({ event }) => {
			assertEvent(event, "PROCESS_MATCH");
			event.keyEvent.preventDefault();
		},
		// Calculate full and partial matches in the event, so we can match them in
		// our guard functions
		raiseProcessMatch: raise(({ context, event }) => {
			assertEvent(event, "KEY_DOWN");
			// A full match should always win against a partial match. Keyboard
			// shortcuts are evaluated greadily
			const fullMatch = getFullMatch(
				context.shortcutDefinition,
				context.activeMatch,
			);
			const partialMatches = getPartialMatches(
				context.shortcutDefinition,
				context.activeMatch,
			);
			return {
				type: "PROCESS_MATCH" as const,
				fullMatch,
				partialMatches,
				keyEvent: event.keyEvent,
			};
		}),
		// Publish the command event to the event store
		sendCommand: ({ event, system }) => {
			assertEvent(event, "PROCESS_MATCH");
			const { fullMatch } = event;
			if (!fullMatch) {
				throw new Error(
					"Expected full match to exist when sending event to store.",
				);
			}
			const type = fullMatch.command;
			const storeEvent = getStoreEventFromCommand(type);
			sendToEventStore(system, storeEvent);
		},
	},
	guards: {
		hasPartialMatch: ({ event }) => {
			assertEvent(event, "PROCESS_MATCH");
			return event.partialMatches.length > 0;
		},
		hasFullMatch: ({ event }) => {
			assertEvent(event, "PROCESS_MATCH");
			return event.fullMatch !== null;
		},
	},
	delays: {
		chordDelay: ({ context }) => context.chordDelay,
	},
}).createMachine({
	id: "keyboardShortcuts:command",
	context: {
		activeMatch: [],
		shortcutDefinition: parseKeyMap(defaultKeyMap),
		chordDelay: DEFAULT_CHORD_DELAY,
	},
	invoke: { src: "listenForKeyEvents" },
	initial: "Idle",
	states: {
		Idle: {},
		// A chord is a sequence of shortcuts. When one shortcut was pressed, we
		// set a timeout, in which the next shortcut of the chord must occur. If it
		// doesn't, we reset to the idle state
		ListeningForChord: {
			after: {
				chordDelay: { actions: "clearActiveMatch", target: "Idle" },
			},
		},
	},
	on: {
		KEY_DOWN: {
			actions: ["writeActiveMatch", "raiseProcessMatch"],
		},
		PROCESS_MATCH: [
			{
				guard: "hasFullMatch",
				actions: ["sendCommand", "clearActiveMatch", "execPreventDefault"],
				target: ".Idle",
			},
			{
				guard: "hasPartialMatch",
				target: ".ListeningForChord",
				actions: "execPreventDefault",
			},
			{ actions: "clearActiveMatch", target: ".Idle" },
		],
	},
});

export default keyboardShortcutsCommandMachine;
