import { useState } from "react";
import type { MouseEvent } from "react";
import { IconButton, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import type { GridFooterContainerProps } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import { useEventHandler, useHtmlId } from "../../../../hooks";
import MessageContextMenu from "../../MessageContextMenu";
import { CheckStatus } from "../../../../redux/messagesSlice";
import useMessageOverviewFooter from "./useMessageOverviewFooter";
import "./MessageOverviewFooter.scss";

// The `GridPagination` prop definitions are buggy. They likely forgot
// to wrap a `Partial<>` around the prop type
const DefaultPagination = GridPagination as unknown as () => JSX.Element;

export default function MessageOverviewFooter(
	props: GridFooterContainerProps,
): JSX.Element {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const isOpen = !!anchorEl;
	const menuId = useHtmlId("menu");
	const labelId = useHtmlId("label");
	const { checkStatus, handleCheckAll, downloadMessages, downloadReports } =
		useMessageOverviewFooter();

	const handleClose = useEventHandler(() => {
		setAnchorEl(null);
	});

	const handleDownloadMessages = () => {
		downloadMessages();
		handleClose();
	};

	const handleDownloadReports = () => {
		downloadReports();
		handleClose();
	};

	return (
		<>
			<GridFooterContainer {...props}>
				<Stack
					direction="row"
					justifyContent="flex-end"
					spacing={1}
					width="100%"
					paddingX={1}
				>
					<DefaultPagination />
					<div className="message-overview-footer__button-wrapper">
						<IconButton
							data-testid="message-overview-menu"
							aria-controls={isOpen ? menuId : undefined}
							aria-haspopup="true"
							aria-label="Nachrichten Kontextmenü öffnen"
							id={labelId}
							aria-expanded={isOpen ? "true" : undefined}
							onClick={(event: MouseEvent<HTMLButtonElement>) =>
								setAnchorEl(event.currentTarget)
							}
						>
							<MoreVertIcon />
						</IconButton>
					</div>
					<MessageContextMenu
						labelId={labelId}
						open={isOpen}
						menuId={menuId}
						handleClose={handleClose}
						anchorEl={anchorEl}
						handleDownloadMessages={handleDownloadMessages}
						handleDownloadReports={handleDownloadReports}
					/>
					<Checkbox
						checked={checkStatus === CheckStatus.All}
						indeterminate={checkStatus === CheckStatus.Partial}
						onChange={handleCheckAll}
					/>
				</Stack>
			</GridFooterContainer>
		</>
	);
}
