import { useEffect } from "react";

function useGlobalClass(prefix: string, activeVariant: string) {
	useEffect(() => {
		[...document.documentElement.classList]
			.filter((cl) => cl.startsWith(`${prefix}--`))
			.forEach((cl) => document.documentElement.classList.remove(cl));
		document.documentElement.classList.add(`${prefix}--${activeVariant}`);
	}, [activeVariant, prefix]);
}

export default useGlobalClass;
