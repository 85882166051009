import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
	useGetMessagesQuery,
	useLazyDownloadMultipleMessagesQuery,
	useLazyDownloadMultipleReportsQuery,
} from "../../../../redux/messagesApiSlice";
import {
	CheckStatus,
	selectCheckedAllStatus,
	setAllChecked,
	setAllUnchecked,
	selectCheckedMessageIds,
} from "../../../../redux/messagesSlice";

export default function useMessageOverviewFooter() {
	const dispatch = useAppDispatch();
	const { data: messages } = useGetMessagesQuery();
	const checkStatus = useAppSelector(selectCheckedAllStatus(messages));
	const checkedMessages = useAppSelector(selectCheckedMessageIds());

	const [reportsTrigger] = useLazyDownloadMultipleReportsQuery();
	const [messagesTrigger] = useLazyDownloadMultipleMessagesQuery();

	const handleCheckAll = () => {
		if (checkStatus === CheckStatus.None && messages) {
			dispatch(setAllChecked({ messages }));
		} else {
			dispatch(setAllUnchecked());
		}
	};

	const downloadMessages = () => {
		if (!checkedMessages.length) return;
		messagesTrigger({ messageIds: checkedMessages });
	};

	const downloadReports = () => {
		if (!checkedMessages.length) return;
		reportsTrigger({ messageIds: checkedMessages });
	};
	return {
		checkStatus,
		handleCheckAll,
		downloadMessages,
		downloadReports,
	};
}
