import { createContext } from "react";
import type { TabContextType } from "./types";
import type { DescendantsContextType } from "../util/descendants";

export const TabContext = createContext<TabContextType>(undefined as never);

export const TabDescendantsContext = createContext<DescendantsContextType>(
	undefined as never,
);

export const TabPanelDescendantsContext = createContext<DescendantsContextType>(
	undefined as never,
);
