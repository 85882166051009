import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import type { MessageType } from "../MessageOverview/types";
import ValidationTable from "./ValidationTable";
import "./ValidationDialog.scss";

const ValidationDialog = ({
	open,
	message,
	onClose,
}: {
	open: boolean;
	message: MessageType;
	onClose: () => void;
}): JSX.Element => {
	const { name, validierung } = message;
	const { schemaValidierung, schematronValidierung } = validierung || {};

	const valid =
		schemaValidierung &&
		schemaValidierung.valid &&
		(!schematronValidierung || schematronValidierung.valid);

	const summary =
		(schemaValidierung && [
			...schemaValidierung.zusammenfassung.pruefschritt,
			...(schematronValidierung?.zusammenfassung.pruefschritt || []),
		]) ||
		[];

	return (
		<Dialog
			className="validation-dialog"
			data-testid="validation-dialog"
			open={open}
			onClose={onClose}
			maxWidth={valid ? "md" : "xl"}
			fullWidth
		>
			<DialogTitle
				className="validation-dialog__title"
				data-testid="dialog-title"
			>
				Validierungen für {name}
			</DialogTitle>
			<DialogContent>
				<TableContainer sx={{ flexShrink: 0 }}>
					<Table data-testid="summary-table">
						<TableHead data-testid="summary-table-head">
							<TableRow>
								<TableCell>Fehler</TableCell>
								<TableCell>Warnungen</TableCell>
								<TableCell>Informationen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody data-testid="summary-table-body">
							{summary.map((elem, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<TableRow key={index}>
									<TableCell>{elem.fehler}</TableCell>
									<TableCell>{elem.warnungen}</TableCell>
									<TableCell>{elem.informationen}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{valid ? (
					<em className="validation-dialog__valid-msg">
						Keine Validierungsfehler vorhanden
					</em>
				) : (
					<>
						{schemaValidierung && !schemaValidierung.valid && (
							<ValidationTable
								title="Schemavalidierung"
								meldungen={schemaValidierung.meldungen}
							/>
						)}

						{schematronValidierung && !schematronValidierung.valid && (
							<ValidationTable
								title="Schematronvalidierung"
								meldungen={schematronValidierung.meldungen}
							/>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={onClose}
					data-testid="dialog-close-button"
				>
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ValidationDialog;
