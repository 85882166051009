import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import { translateStoreEvents } from "../../EventStore/helpers";
import type { Alert } from "./types";

export type NotificationQueryRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof notificationQueryMachine
>;
export type NotificationQueryContext = { alerts: Alert[] };
type NotificationQueryEvent =
	| { type: "ADD_ALERT"; alert: Alert }
	| { type: "REMOVE_ALERT"; id: string };

const notificationQueryMachine = setup({
	types: {
		events: {} as NotificationQueryEvent,
		context: {} as NotificationQueryContext,
	},
	actors: {
		translateStoreEvents: translateStoreEvents<NotificationQueryEvent>(
			{
				"NOTIFICATION.ALERT.ADD": ({ payload }) => ({
					type: "ADD_ALERT",
					alert: payload,
				}),
				"NOTIFICATION.ALERT.REMOVE": ({ payload: { id } }) => ({
					type: "REMOVE_ALERT",
					id,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		addAlert: assign({
			alerts: ({ event, context }) => {
				assertEvent(event, "ADD_ALERT");
				return [...context.alerts, event.alert];
			},
		}),
		removeAlert: assign({
			alerts: ({ event, context }) => {
				assertEvent(event, "REMOVE_ALERT");
				return context.alerts.filter((alert) => alert.id !== event.id);
			},
		}),
	},
}).createMachine({
	id: "notification:query",
	context: { alerts: [] },
	invoke: { src: "translateStoreEvents" },
	on: {
		ADD_ALERT: { actions: "addAlert" },
		REMOVE_ALERT: { actions: "removeAlert" },
	},
});

export default notificationQueryMachine;
