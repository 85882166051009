import { useEffect } from "react";
import { useAppInfo } from "../AppInfoProvider";

const verificationName = "google-site-verification";

const GoogleSearch = (): JSX.Element => {
	const { info } = useAppInfo();
	const verificationKey = info?.config?.[verificationName];

	useEffect(() => {
		if (verificationKey) {
			const meta = document.createElement("meta");
			meta.name = verificationName;
			meta.content = verificationKey;

			document.getElementsByTagName("head")[0].appendChild(meta);
		}
	}, [verificationKey]);

	return <></>;
};

export default GoogleSearch;
