import { createActorContext } from "@xstate/react";
import savePointMachine from "./savePoint.machine";

// export type SavePointEventSender = Sender<EventFrom<typeof savePointMachine>>;

const EditorSavePointContext = createActorContext(savePointMachine);

export function useIsProjectManuallySaved(): boolean {
	return EditorSavePointContext.useSelector((state) =>
		state.matches({ Active: { Manual: "Saved" } }),
	);
}
export function useIsProjectManuallyUnsaved(): boolean {
	return EditorSavePointContext.useSelector((state) =>
		state.matches({ Active: { Manual: "Unsaved" } }),
	);
}
export function useIsProjectAutoSaved(): boolean {
	return EditorSavePointContext.useSelector((state) =>
		state.matches({ Active: { Auto: "Saved" } }),
	);
}
export function useIsProjectAutoUnsaved(): boolean {
	return EditorSavePointContext.useSelector((state) =>
		state.matches({ Active: { Auto: "Unsaved" } }),
	);
}
export function useSendSavePointEvent() {
	const { send: sendSavePointEvent } = EditorSavePointContext.useActorRef();
	return sendSavePointEvent;
}

export default EditorSavePointContext;
