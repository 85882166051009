import type { KeyboardEventHandler } from "react";
import { useEffect, useMemo, useState } from "react";
import SegmentTrie from "@xoev/segment-trie";
import {
	useEventHandler,
	useStableNavigate,
	useSyncedState,
} from "../../../hooks";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectFilterValue, setFilter } from "../../../redux/uiSlice";
import type { useActiveNode } from "../../MessageProfilingView/useActiveNode";
import type { ProfilingTreeFilterGroup } from "./types";
import type {
	LiteId,
	LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import SearchWorkerBridge from "../../AppActor/actors/modellierungModel/search/worker/SearchWorkerBridge";
import { useGetTreeQuery } from "../../../redux/apiSlice";
import { selectModellContainer } from "../../../redux/treeSlice";
import { selectNodeFromModell } from "../../AppActor/actors/modellierungModel/selectors";
import type { RequestError } from "../../Api/types";
import { RequestStatus } from "../../Api/types";
import type { NodeEventArg } from "../../Tree/types";
import type { SearchResult } from "../../AppActor/actors/modellierungModel/search/types";
import { EMPTY_TRIE } from "../../AppActor/actors/modellierungModel/search/helpers";
import { shouldFilterNode } from "./helpers";

const SEARCH_DEBOUNCE_TIME = 500;
const MIN_SEARCH_LENGTH = 2;
const CLEAR_FOCUS_TIMEOUT = 1500;

function createSegmentTrie(paths: LiteId[][]) {
	const entries = paths.map((path, index) => [path, index] as const);
	const trie = new SegmentTrie((path) => path, entries);
	return trie;
}
type TreeEvent = NodeEventArg<LiteNode, LiteId>;

function useSearchFilter({
	rootId,
	getUrl,
	activeNodeState,
	filterGroup,
	standard,
}: {
	rootId: LiteId;
	getUrl: (node: TreeEvent) => string;
	activeNodeState: ReturnType<typeof useActiveNode>;
	filterGroup: ProfilingTreeFilterGroup;
	standard?: string;
}) {
	const { data: treeData } = useGetTreeQuery({
		standard,
	});

	const dispatch = useAppDispatch();
	const searchTermFilter = useAppSelector(
		selectFilterValue(filterGroup, "query"),
	);
	// Only set the serach term to redux when we also fetch the search results.
	// This way we don't need to update the while ui, that is connected to redux
	// on every key stroke
	const setSearchTermFilter = useEventHandler((term: string) => {
		dispatch(setFilter(filterGroup, { query: term }));
	});
	const [searchTerm, setSearchTerm] = useSyncedState(searchTermFilter);
	const navigate = useStableNavigate();
	const [currentIndex, setCurrentIndex] = useState(0);
	const { activeNode, activePath } = activeNodeState;

	const modell = useAppSelector(selectModellContainer(standard));

	const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle);
	const [error, setError] = useState<RequestError | null>(null);
	const [data, setData] = useState<SearchResult>();

	const searchWorker = useMemo(() => {
		if (!treeData) {
			return null;
		}

		return SearchWorkerBridge.fromProfilierung(
			treeData,
			() =>
				new Worker(
					new URL(
						"../../AppActor/actors/modellierungModel/search/worker/modellierungSearch.worker.ts",
						import.meta.url,
					),
				),
		);
	}, [treeData]);

	useEffect(() => {
		return () => {
			const terminateSearchWorker = async () => {
				(await searchWorker)?.worker.terminate();
			};
			terminateSearchWorker();
		};
	}, [searchWorker]);

	const trie = useMemo(
		() => (data?.paths ? createSegmentTrie(data?.paths) : EMPTY_TRIE),
		[data],
	);

	const focusNode = useEventHandler((delta: number) => {
		if (!data?.paths || !modell) return;
		const nextIndex =
			(currentIndex + data.paths.length + delta) % data.paths.length;
		const node = selectNodeFromModell(
			modell,
			data.paths[nextIndex]?.slice(-1)[0],
		);
		if (node) {
			setCurrentIndex(nextIndex);
			// Signal to the tree nodes (useUrlTreeState), that we don't want them
			// to be focused on navigation. We'll want to avoid this, so the search
			// input stays focused and we can adjust the serach term if we like
			navigate(
				`${getUrl({
					node,
					path: data.paths[nextIndex],
				})}?focus=false`,
			);
		}
	});

	useEffect(() => {
		focusNode(0);
	}, [data, focusNode]);

	const focusPrevious = useEventHandler(() => {
		focusNode(-1);
	});
	const focusNext = useEventHandler(() => {
		focusNode(1);
	});

	useEffect(() => {
		// Debounce the search requests, so we don't fetch too often, only after
		// the search term hasn't changed in a while
		const timeout = setTimeout(async () => {
			setStatus(RequestStatus.Idle);
			setSearchTermFilter(searchTerm);

			if (searchTerm.length < MIN_SEARCH_LENGTH) {
				return;
			}

			if (!modell) {
				setStatus(RequestStatus.Failure);
				setError({ message: "Modell steht nicht zur Verfügung" });
				return;
			}

			const search = await searchWorker;
			if (!search) {
				setStatus(RequestStatus.Failure);
				setError({ message: "Suche steht nicht zur Verfügung" });
				return;
			}

			setStatus(RequestStatus.Loading);

			const searchResults = await search.search(searchTerm);
			searchResults.paths = searchResults.paths
				.filter((path) => path.includes(rootId) && path.slice(-1)[0] !== rootId)
				.filter(
					(path) =>
						!path.some((id) => {
							const node = selectNodeFromModell(modell, id);

							if (!node) {
								return false;
							}

							return shouldFilterNode(modell, rootId, node);
						}),
				)
				.map((path) => {
					const index = path.findIndex((id) => id === rootId);
					return path.slice(index, index + path.length);
				});

			setData(searchResults);
			setStatus(RequestStatus.Success);
		}, SEARCH_DEBOUNCE_TIME);
		return () => clearTimeout(timeout);
	}, [modell, rootId, searchTerm, searchWorker, setSearchTermFilter]);

	const isMountedRef = useIsMountedRef();
	const handleClear = useEventHandler(() => {
		setSearchTerm("");
		setTimeout(() => {
			if (!isMountedRef.current) return;
			navigate(`${getUrl({ node: activeNode, path: activePath })}?focus=false`);
		}, CLEAR_FOCUS_TIMEOUT);
	});
	const handleChange = useEventHandler((newTerm: string) =>
		setSearchTerm(newTerm),
	);
	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useEventHandler(
		(e) => {
			if (e.key === "Enter") {
				if (e.shiftKey) {
					focusPrevious();
				} else {
					focusNext();
				}
			}
		},
	);

	const getInputProps = useMemo(() => {
		const props = {
			onClear: handleClear,
			onChange: handleChange,
			onKeyDown: handleKeyDown,
			value: searchTerm,
		};
		return () => props;
	}, [handleChange, handleClear, handleKeyDown, searchTerm]);

	return useMemo(
		() => ({
			getInputProps,
			trie,
			status,
			error,
			isActive: searchTermFilter.length >= MIN_SEARCH_LENGTH,
			focusPrevious,
			focusNext,
			setCurrentIndex,
		}),
		[
			error,
			focusNext,
			focusPrevious,
			getInputProps,
			searchTermFilter.length,
			status,
			trie,
			setCurrentIndex,
		],
	);
}

export default useSearchFilter;
