import { DialogTitle } from "@mui/material";
import CloseModalButton from "../CloseModalButton";
import "./ModalHeader.scss";

const ModalHeader = ({
	title,
	className,
	handleClose,
}: {
	className?: string;
	title: string;
	handleClose: () => void;
}): JSX.Element => (
	<div className="modal-header">
		<DialogTitle className={className ?? ""}>{title}</DialogTitle>
		<CloseModalButton handleClose={handleClose} />
	</div>
);

export default ModalHeader;
