import { useState } from "react";
import { FormControlLabel, FormGroup, IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { useApplyPatch, useEditorPatchCreators } from "../../../EditorState";
import useActiveDocTitle from "../../useActiveDocTitle";
import ConfigurationDialog from "./ConfigurationDialog";
import "./ChaptersListFooter.scss";

const ChaptersListFooter = ({
	isAddFieldVisible,
	setIsAddFieldVisible,
	showHiddenChapters,
	setShowHiddenChapters,
}: {
	isAddFieldVisible: boolean;
	setIsAddFieldVisible: (value: boolean) => void;
	showHiddenChapters: boolean;
	setShowHiddenChapters: (value: boolean) => void;
}): JSX.Element => {
	const applyPatch = useApplyPatch();
	const { deleteDocument } = useEditorPatchCreators();
	const [activeDocTitleKey] = useActiveDocTitle();
	const [isConfigOpen, setIsConfigOpen] = useState(false);

	const handleDelete = () => {
		if (!activeDocTitleKey) return;
		applyPatch(deleteDocument({ docTitle: activeDocTitleKey }));
	};

	const handleFooterAddButtonClick = () => {
		setIsAddFieldVisible(true);
	};

	const handleFooterConfigButtonClick = () => {
		setIsConfigOpen((prevState) => !prevState);
	};

	const handleSwitchChange = () => {
		setShowHiddenChapters(!showHiddenChapters);
	};

	return (
		<div className="chapters-list-footer" data-testid="chapters-list-footer">
			<IconButton data-testid="delete-button" onClick={handleDelete}>
				<DeleteIcon />
			</IconButton>
			<IconButton
				data-testid="add-button"
				onClick={handleFooterAddButtonClick}
				disabled={isAddFieldVisible}
			>
				<AddIcon />
			</IconButton>
			<IconButton
				data-testid="config-button"
				onClick={handleFooterConfigButtonClick}
			>
				<SettingsIcon />
			</IconButton>
			<FormGroup className="chapters-list-footer__show">
				<FormControlLabel
					control={
						<Switch
							checked={showHiddenChapters}
							onChange={handleSwitchChange}
							data-testid="chapter-switch"
						/>
					}
					label="Versteckte Kapitel"
				/>
			</FormGroup>
			<ConfigurationDialog
				isConfigOpen={isConfigOpen}
				setIsConfigOpen={setIsConfigOpen}
			/>
		</div>
	);
};

export default ChaptersListFooter;
