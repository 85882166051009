import type { Context } from "react";
import { createContext, useContext } from "react";
import type { NodeContextType, PathFragment } from "../types";

const TreeNodeContext = createContext<NodeContextType>(null as never);

// Wrap the context in a getter function, so we can dynamically assign a type
// to it
export function getNodeContext<
	TNode = unknown,
	TNodeId extends PathFragment = number,
>(): Context<NodeContextType<TNode, TNodeId>> {
	return TreeNodeContext as unknown as Context<NodeContextType<TNode, TNodeId>>;
}

export function useTreeNodeContext<
	TNode = unknown,
	TNodeId extends PathFragment = number,
>(): NodeContextType<TNode, TNodeId> {
	const NodeCtx = getNodeContext<TNode, TNodeId>();
	return useContext(NodeCtx);
}
