import { useContext } from "react";
import { TabContext } from "../../ui/Tabs/TabContexts";
import OverviewOptions from "./OverviewOptions/OverviewOptions";
import MessageOptions from "./MessageOptions";
import { useGetMessagesQuery } from "../../../redux/messagesApiSlice";
import "./MessageViewToolbar.scss";

function MessageViewToolbar({ openIds }: { openIds: string[] }): JSX.Element {
	const { activeIndex } = useContext(TabContext);
	const { data: messages } = useGetMessagesQuery();
	const activeMessage = messages?.find(
		({ id }) => id === openIds[activeIndex - 1],
	);

	return (
		<div className="message-view-toolbar">
			{activeMessage ? (
				<MessageOptions message={activeMessage} />
			) : (
				<OverviewOptions />
			)}
		</div>
	);
}

export default MessageViewToolbar;
