import classNames from "classnames";
import React from "react";
import type { HeaderNavListProps } from "./types";
import styles from "./HeaderNav.module.scss";

export default function HeaderNavList({
	children,
	className,
	...props
}: HeaderNavListProps): JSX.Element {
	return (
		<ul className={classNames(styles.navItemWrapper, className)} {...props}>
			{children}
		</ul>
	);
}
