import type { ReactNode } from "react";
import NavBarDisplay from "../NavBarDisplay";
import TabNavBarActions from "./TabNavBarActions";
import "./TabNavBarActions.scss";

interface TabNavBarProps {
	[k: `data-${string}`]: string;
	children: ReactNode;
}

function TabNavBar({ children, ...props }: TabNavBarProps) {
	return (
		<NavBarDisplay {...props}>
			<NavBarDisplay.Breadcrumbs>{children}</NavBarDisplay.Breadcrumbs>
			<NavBarDisplay.Actions>
				<TabNavBarActions />
			</NavBarDisplay.Actions>
		</NavBarDisplay>
	);
}

export default TabNavBar;
