import { TableCell, TableHead } from "@mui/material";
import DatatypeTableRow from "./DatatypeTableRow";
import "./DatatypeTableHeader.scss";

export default function DatatypeTableHeader({
	withActions = false,
} = {}): JSX.Element {
	return (
		<TableHead className="datatype-table-header">
			<DatatypeTableRow>
				<TableCell className="datatype-table-header__header-cell">
					<span>Name</span>
				</TableCell>
				<TableCell className="datatype-table-header__header-cell">
					<span>Beschreibung</span>
				</TableCell>
				{withActions && (
					<TableCell
						className="datatype-table-header__header-cell"
						align="center"
					>
						<span>Aktion</span>
					</TableCell>
				)}
			</DatatypeTableRow>
		</TableHead>
	);
}
