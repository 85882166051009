import { useMemo } from "react";
import type { EditorState } from "../EditorState/types";
import validateProfiles from "./validators/validateProfiles";
import validateRestrictions from "./validators/validateRestrictions";
import validateRestrictionProfiles from "./validators/validateRestrictionProfiles";
import type { MainValidators } from "./types";
import { ValidationResultGroup } from "./types";
import validateMetadata from "./validators/validateMetadata";
import { createEmptyValidator } from "./validationExecution";
import useCodelistRequest from "../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/CodeListRestrictionRenderer/CodeListComboBox/useCodelistRequest";
import type { RequestCodeListOptions } from "../../redux/apiSlice";
import type { CodelistStateResult } from "../../redux/codelistSlice";
import type { LiteModellContainer } from "../AppActor/actors/modellierungModel/types";

function createMainValidators({
	state,
	modell,
	getCodelist,
}: {
	state: EditorState;
	modell: LiteModellContainer;
	getCodelist: (
		requestOptions: Partial<RequestCodeListOptions>,
	) => Promise<CodelistStateResult>;
}): MainValidators {
	// Get the relevant inputs from the state
	const profiles = state.get("profile");
	const datatypes = state.get("datentypen");
	const metadata = state.get("metadaten");
	// Create the validators
	const profileValidator = validateProfiles({
		modell,
		profiles,
		datatypes,
		getCodelist,
	});
	const restrictionsValidator = validateRestrictions({
		modell,
		datatypes,
	});
	const restrictionProfilesValidator = validateRestrictionProfiles({
		modell,
		datatypes,
		getCodelist,
	});
	const metadataValidator = validateMetadata(metadata);
	return {
		[ValidationResultGroup.MessageProfiles]: profileValidator,
		[ValidationResultGroup.Restrictions]: restrictionsValidator,
		[ValidationResultGroup.RestrictionProfiles]: restrictionProfilesValidator,
		[ValidationResultGroup.Metadata]: metadataValidator,
	};
}

export function createEmptyMainValidators(): MainValidators {
	return {
		[ValidationResultGroup.MessageProfiles]: createEmptyValidator(),
		[ValidationResultGroup.Restrictions]: createEmptyValidator(),
		[ValidationResultGroup.RestrictionProfiles]: createEmptyValidator(),
		[ValidationResultGroup.Metadata]: createEmptyValidator(),
	};
}

export default function useMainValidators(
	state: EditorState,
	modell: LiteModellContainer | null,
): MainValidators {
	const { request: getCodelist } = useCodelistRequest();
	const validators = useMemo(() => {
		if (!modell) return createEmptyMainValidators();
		return createMainValidators({ state, modell, getCodelist });
	}, [getCodelist, modell, state]);
	return validators;
}
