// SVGs are taken from https://undraw.co/

const colVoid = "var(--illu-color-void)";
const colFloor = "var(--illu-color-floor)";
const colBalls = "var(--illu-color-balls)";

function NotFoundSvg(): JSX.Element {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 797.5 834.5"
			aria-hidden
		>
			<ellipse cx="308.5" cy="780" rx="308.5" ry="54.5" fill={colFloor} />
			<circle cx="496" cy="301.5" r="301.5" fill={colVoid} />
			<circle cx="496" cy="301.5" r="248.9" opacity=".05" />
			<circle cx="496" cy="301.5" r="203.99" opacity=".05" />
			<circle cx="496" cy="301.5" r="146.26" opacity=".05" />
			<path
				d="M197.17 328.48s-23.7 66.72-13.17 90.43 27.22 46.53 27.22 46.53-6.15-132.57-14.05-136.96Z"
				fill="#d0cde1"
			/>
			<path
				d="M197.17 328.48s-23.7 66.72-13.17 90.43 27.22 46.53 27.22 46.53-6.15-132.57-14.05-136.96Z"
				opacity=".1"
			/>
			<path
				d="M213.85 483s-1.76 16.68-2.63 17.56.87 2.63 0 5.26-1.76 6.15 0 7.03-9.66 78.13-9.66 78.13-28.1 36.87-16.68 94.82l3.51 58.82s27.22 1.75 27.22-7.9c0 0-1.76-11.42-1.76-16.68s4.39-5.27 1.76-7.9-2.64-4.4-2.64-4.4 4.4-3.5 3.51-4.38 7.9-63.21 7.9-63.21 9.66-9.66 9.66-14.93v-5.27s4.4-11.41 4.4-12.29 23.7-54.43 23.7-54.43l9.65 38.63 10.54 55.3s5.27 50.05 15.8 69.36c0 0 18.44 63.22 18.44 61.46s30.73-6.15 29.85-14.05-18.44-118.52-18.44-118.52l4.4-164.17Z"
				fill="#2f2e41"
			/>
			<path
				d="M190.15 740.23s-23.7 46.53-7.9 48.28 21.94 1.76 28.97-5.26c3.84-3.84 11.61-9 17.87-12.88a23.12 23.12 0 0 0 10.97-21.98c-.46-4.3-2.07-7.83-6.02-8.16-10.53-.88-22.82-10.54-22.82-10.54Zm130.81 34.24s-23.7 46.53-7.9 48.28 21.94 1.76 28.97-5.26c3.84-3.84 11.61-9 17.87-12.88a23.12 23.12 0 0 0 10.97-21.98c-.46-4.3-2.07-7.83-6.02-8.16-10.53-.88-22.82-10.54-22.82-10.54Z"
				fill="#2f2e41"
			/>
			<circle cx="295.9" cy="215.43" r="36.9" fill="#ffb8b8" />
			<path
				d="M272.18 227.56s-26.36 48.5-28.47 48.5 47.45 15.82 47.45 15.82 13.7-46.4 15.82-50.61Z"
				fill="#ffb8b8"
			/>
			<path
				d="M312.62 280.64s-52.68-28.98-57.95-28.1-61.45 50.04-60.57 70.24 7.9 53.55 7.9 53.55 2.63 93.06 7.9 93.94-.88 16.68.88 16.68 122.9 0 123.79-2.64-21.95-203.67-21.95-203.67Z"
				fill="#d0cde1"
			/>
			<path
				d="M342.03 489.14s16.68 50.92 2.63 49.17-20.2-43.9-20.2-43.9Z"
				fill="#ffb8b8"
			/>
			<path
				d="M297.25 277.56s-32.48 7.03-27.21 50.92 14.92 87.8 14.92 87.8l32.49 71.1 3.5 13.18 23.71-6.15-17.56-101.84s-6.14-108.86-14.04-112.37a34 34 0 0 0-15.8-2.64Z"
				fill="#d0cde1"
			/>
			<path opacity=".1" d="m277.5 414.96 40.39 71.99-34.03-75.86-6.36 3.87z" />
			<path
				d="m332.65 204.57.12-2.82 5.6 1.4a6.27 6.27 0 0 0-2.5-4.62l5.97-.34a64.48 64.48 0 0 0-43.12-26.65c-12.93-1.87-27.32.84-36.19 10.43-4.3 4.65-7 10.57-8.92 16.6-3.54 11.13-4.26 24.38 3.12 33.42 7.5 9.18 20.6 10.98 32.4 12.12 4.16.4 8.51.77 12.36-.84a29.72 29.72 0 0 0-1.65-13.04 8.69 8.69 0 0 1-.88-4.15c.52-3.51 5.2-4.4 8.73-3.92s7.75 1.2 10.06-1.5c1.6-1.85 1.5-4.55 1.7-7 .58-6.63 13.14-7.7 13.2-9.1Z"
				fill="#2f2e41"
			/>
			<circle cx="559" cy="744.5" r="43" fill={colBalls} />
			<circle cx="54" cy="729.5" r="43" fill={colBalls} />
			<circle cx="54" cy="672.5" r="31" fill={colBalls} />
			<circle cx="54" cy="624.5" r="22" fill={colBalls} />
		</svg>
	);
}

export default NotFoundSvg;
