import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import { translateStoreEvents } from "../../EventStore/helpers";
import type { LiteNodeLocator } from "./helpers";
import { EMPTY_EXPANSION_STATE, closeNode, openNode } from "./helpers";
import type { ExtractStoreEventPayload } from "../../EventStore/StoreEvent";
import type { ProjektMeta } from "../project/types";

// eslint-disable-next-line no-use-before-define
export type TreeStateQueryActorRef = ActorRefFrom<typeof treeStateQueryMachine>;
export type TreeStateQueryContext = ProjektMeta & {
	bausteinView: LiteNodeLocator;
	structureView: LiteNodeLocator;
};
export type TreeStateQueryEvent =
	| ({
			type: "ACTIVATE_TREE_NODE";
	  } & ExtractStoreEventPayload<"MODELLIERUNG_TREE.STATE.ACTIVATE">)
	| ({
			type: "TOGGLE_TREE_NODE";
	  } & ExtractStoreEventPayload<"MODELLIERUNG_TREE.STATE.CHANGE">);

const EMPTY_ACTIVE_NODES = {
	bausteinView: {
		expansionState: EMPTY_EXPANSION_STATE,
	},
	structureView: {
		expansionState: EMPTY_EXPANSION_STATE,
	},
};

const treeStateQueryMachine = setup({
	types: {
		events: {} as TreeStateQueryEvent,
		context: {} as TreeStateQueryContext,
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<TreeStateQueryEvent>(
			{
				"MODELLIERUNG_TREE.STATE.ACTIVATE": ({ payload }) => ({
					type: "ACTIVATE_TREE_NODE",
					...payload,
				}),
				"MODELLIERUNG_TREE.STATE.CHANGE": ({ payload }) => ({
					type: "TOGGLE_TREE_NODE",
					...payload,
				}),
			},
			{ replayEventLog: true },
		),
	},
	actions: {
		activateTreeNode: assign(({ event, context }) => {
			assertEvent(event, "ACTIVATE_TREE_NODE");
			const { bausteinPath, structurePath } = event;
			if (bausteinPath.length === 0) {
				throw new Error(
					"`bausteinPath` cannot be empty in `ACTIVATE_TREE_NODE`",
				);
			}
			if (structurePath.length === 0) {
				throw new Error(
					"`structurePath` cannot be empty in `ACTIVATE_TREE_NODE`",
				);
			}
			return {
				bausteinView: openNode(context.bausteinView, bausteinPath),
				structureView: openNode(context.structureView, structurePath),
			};
		}),
		toggleTreeNode: assign(({ context, event }) => {
			assertEvent(event, "TOGGLE_TREE_NODE");
			const { bausteinPath, structurePath, mode } = event;
			const toggle = { open: openNode, close: closeNode }[mode];
			return {
				bausteinView: toggle(context.bausteinView, bausteinPath),
				structureView: toggle(context.structureView, structurePath),
			};
		}),
	},
	guards: {
		isSelf: ({ event, context }) => event.projektId === context.projektId,
	},
}).createMachine({
	id: "treeState:query",
	context: ({ input }) => ({
		...input,
		...EMPTY_ACTIVE_NODES,
	}),
	invoke: { src: "translateEvents" },
	on: {
		ACTIVATE_TREE_NODE: {
			guard: "isSelf",
			actions: "activateTreeNode",
		},
		TOGGLE_TREE_NODE: {
			guard: "isSelf",
			actions: "toggleTreeNode",
		},
	},
});

export default treeStateQueryMachine;
