import { useEffect, useState } from "react";
import type { CustomRendererRegistry } from "./Renderer/types";
import type { MessageType } from "../MessageOverview/types";

const useGetRendererRegistry = (msg: MessageType) => {
	const [rendererRegistry, setRendererRegistry] =
		useState<CustomRendererRegistry>({});
	const [isRegistryComplete, setIsRegistryComplete] = useState(false);

	useEffect(() => {
		if (msg.standard) {
			const route = `/api/v1/standards/${msg.standard}/dateien/custom.js`;
			import(/* webpackIgnore: true */ /* @vite-ignore */ route)
				.then((mod: { default?: CustomRendererRegistry }) => {
					if (
						!mod.default ||
						typeof mod.default !== "object" ||
						!Object.values(mod.default).every((fn) => typeof fn === "function")
					) {
						// eslint-disable-next-line no-console
						console.error(
							"An error occurred while loading a custom renderer script for " +
								`"${msg.standard}":\n\nA custom script must default export an object ` +
								"of functions. Got:",
							mod.default,
						);
						return;
					}
					setRendererRegistry(mod.default);
				})
				.catch((e: unknown) =>
					// eslint-disable-next-line no-console
					console.error(
						"An error occurred while loading a custom renderer script for " +
							`"${msg.standard}":\n\n`,
						e,
					),
				)
				.finally(() => {
					setIsRegistryComplete(true);
				});
		}
	}, [msg.id, msg.standard]);

	return {
		rendererRegistry,
		isRegistryComplete,
	};
};

export default useGetRendererRegistry;
