/* eslint-disable import/prefer-default-export */

// Using `unknown` here does not work in this case, because
// `T extends AnyFn` would not match anymore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFn = (...a: any[]) => any;

/**
 * Check if a provided argument is a function. If so, call it with the
 * remaining arguments. Otherwise return the first argument.
 * This is useful for building React components that support both nodes or
 * render prop funtions as children.
 */
export function callOrReturn<T>(
	maybeFn: T,
	...args: T extends AnyFn ? Parameters<T> : unknown[]
): T extends AnyFn ? ReturnType<T> : T {
	return typeof maybeFn === "function" ? maybeFn(...args) : maybeFn;
}

export const composeEventHandlers =
	<F extends AnyFn>(...handlers: F[]) =>
	(...args: Parameters<F>): void => {
		handlers.forEach((handler) => {
			if (typeof handler === "function") {
				handler(...(args as unknown[]));
			}
		});
	};

export const activateKeys = ["Enter", " "];

export function compose<T extends unknown[], R>(
	fn: (...args: T) => R,
): (...args: T) => R;
export function compose<T extends unknown[], R1, R2>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
): (...args: T) => R2;
export function compose<T extends unknown[], R1, R2, R3>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
): (...args: T) => R3;
export function compose<T extends unknown[], R1, R2, R3, R4>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
	fn4: (args: R3) => R4,
): (...args: T) => R4;
export function compose<T extends unknown[], R1, R2, R3, R4, R5>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
	fn4: (args: R3) => R4,
	fn5: (args: R4) => R5,
): (...args: T) => R5;
export function compose<T extends unknown[], R1, R2, R3, R4, R5, R6>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
	fn4: (args: R3) => R4,
	fn5: (args: R4) => R5,
	fn6: (args: R5) => R6,
): (...args: T) => R6;
export function compose<T extends unknown[], R1, R2, R3, R4, R5, R6, R7>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
	fn4: (args: R3) => R4,
	fn5: (args: R4) => R5,
	fn6: (args: R5) => R6,
	fn7: (args: R6) => R7,
): (...args: T) => R7;
export function compose<T extends unknown[], R1, R2, R3, R4, R5, R6, R7, R8>(
	fn1: (...args: T) => R1,
	fn2: (args: R1) => R2,
	fn3: (args: R2) => R3,
	fn4: (args: R3) => R4,
	fn5: (args: R4) => R5,
	fn6: (args: R5) => R6,
	fn7: (args: R6) => R7,
	fn8: (args: R7) => R8,
): (...args: T) => R8;
export function compose<T extends unknown[]>(
	fn1: (...args: T) => unknown,
	...fns: ((a: unknown) => unknown)[]
): (...args: T) => unknown;
export function compose<T extends unknown[], R>(
	fn1: (...args: T) => R,
	...fns: ((a: unknown) => unknown)[]
) {
	const composed = fns.reduce(
		(prevFn, nextFn) => (value: unknown) => nextFn(prevFn(value)),
		(value) => value,
	);
	return (...args: T) => composed(fn1(...args));
}

export function pipe<T extends unknown[]>(...args: T) {
	function through<R>(fn: (...args: T) => R): R;
	function through<R1, R2>(fn1: (...args: T) => R1, fn2: (args: R1) => R2): R2;
	function through<R1, R2, R3>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
	): R3;
	function through<R1, R2, R3, R4>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
		fn4: (args: R3) => R4,
	): R4;
	function through<R1, R2, R3, R4, R5>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
		fn4: (args: R3) => R4,
		fn5: (args: R4) => R5,
	): R5;
	function through<R1, R2, R3, R4, R5, R6>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
		fn4: (args: R3) => R4,
		fn5: (args: R4) => R5,
		fn6: (args: R5) => R6,
	): R6;
	function through<R1, R2, R3, R4, R5, R6, R7>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
		fn4: (args: R3) => R4,
		fn5: (args: R4) => R5,
		fn6: (args: R5) => R6,
		fn7: (args: R6) => R7,
	): R7;
	function through<R1, R2, R3, R4, R5, R6, R7, R8>(
		fn1: (...args: T) => R1,
		fn2: (args: R1) => R2,
		fn3: (args: R2) => R3,
		fn4: (args: R3) => R4,
		fn5: (args: R4) => R5,
		fn6: (args: R5) => R6,
		fn7: (args: R6) => R7,
		fn8: (args: R7) => R8,
	): R8;
	function through(
		fn1: (...args: T) => unknown,
		...fns: ((a: unknown) => unknown)[]
	): unknown;
	function through(
		fn1: (...args: T) => unknown,
		...fns: ((a: unknown) => unknown)[]
	) {
		return compose(fn1, ...fns)(...args);
	}
	return { through };
}
