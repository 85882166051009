import { CircularProgress } from "@mui/material";
import type { ReactNode } from "react";
import StructureInlineInfo from "./StructureInlineInfo";
import "./StructureLoadingInfo.scss";

export default function StructureLoadingInfo({
	isActive,
	children,
}: {
	isActive: boolean;
	children: ReactNode;
}): JSX.Element {
	return (
		<StructureInlineInfo isActive={isActive}>
			<CircularProgress
				className="structure-loading-info__spinner"
				size="1.25em"
			/>
			<span className="structure-loading-info__overflow-text">{children}</span>
		</StructureInlineInfo>
	);
}
