import { useMatch } from "react-router-dom";
import type { ImmutableMap } from "@xoev/immutable-map";
import { useStateSelector } from "../EditorState";
import {
	selectRestrictionEntry,
	selectStandard,
} from "../EditorState/selectors";
import {
	RestrictionIdSchema,
	type RestrictionProfileValues,
} from "../EditorState/types";
import useActiveDataType from "./useActiveDataType";
import { useAppSelector } from "../../redux/hooks";
import { selectQName } from "../../redux/treeSlice";

export default function useActiveRestriction(): ImmutableMap<RestrictionProfileValues> | null {
	const { params } =
		useMatch("/profilierung/datentypen/:nodeId/profil/:restrictionId/*") || {};
	const { restrictionId = "" } = params || {};
	const { activeDataType, didFindMatch } = useActiveDataType();
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, activeDataType));
	const activeRestriction = useStateSelector(
		selectRestrictionEntry(qname, RestrictionIdSchema.parse(restrictionId)),
	);
	if (!didFindMatch || !activeDataType) return null;
	return activeRestriction;
}
