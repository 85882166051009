import { useEffect, useState } from "react";
import { Tooltip, IconButton, CircularProgress } from "@mui/material";
import AutoFixIcon from "@mui/icons-material/AutoFixHigh";
import classNames from "classnames";
import { validationSeverityShortNames } from "../helpers";
import type {
	AutoFixValue,
	FixerFn,
	ValidationResultBase,
	ValidationTargetField,
} from "../types";
import { ValidationStatus } from "../types";
import { useValidationContext } from "../hooks";
import "./ValidationFormatter.scss";

function ValidationFormatter<
	TargetField extends ValidationTargetField = ValidationTargetField,
>({
	results,
	value,
	onFix,
}: {
	results: ValidationResultBase<TargetField>[];
	value?: AutoFixValue<TargetField>;
	onFix?: (fixer: FixerFn<TargetField>) => void;
}): JSX.Element {
	const { status } = useValidationContext();
	const isValidationRunning = status === ValidationStatus.Running;
	const [isLoading, setIsLoading] = useState(isValidationRunning);
	useEffect(() => {
		setIsLoading(isValidationRunning);
	}, [isValidationRunning]);

	const createFixHandler =
		(result: ValidationResultBase<TargetField>) => () => {
			if (!(result.autoFix && value !== undefined && onFix) || isLoading) {
				return;
			}
			setIsLoading(true);
			onFix(result.autoFix(value));
		};

	return (
		<ul className="validation-formatter">
			{results.map((result) => (
				<li
					key={result.id}
					className={classNames(
						"validation-formatter__item",
						`validation-formatter__item--severity-${result.severity}`,
					)}
					data-rule-id={result.ruleId}
				>
					<span>
						<span className="validation-formatter__label">
							{validationSeverityShortNames[result.severity]}:{" "}
						</span>
						<span className="validation-formatter__value">
							{result.message}
						</span>
					</span>
					<span className="validation-formatter__auto-fix-wrapper">
						{result.autoFix && value !== undefined && onFix && (
							<Tooltip
								title={result?.autoFixDescription || "Automatisch beheben"}
							>
								<IconButton
									aria-label="Auto-Fix"
									onClick={createFixHandler(result)}
									size="small"
								>
									{isLoading ? (
										<CircularProgress size="1em" />
									) : (
										<AutoFixIcon fontSize="small" color="info" />
									)}
								</IconButton>
							</Tooltip>
						)}
					</span>
				</li>
			))}
		</ul>
	);
}

export default ValidationFormatter;
