import { Button } from "@mui/material";
import { useState } from "react";
import GenerationConfiguration from "../../GenerationConfiguration";
import UploadMessageDialog from "../../UploadMessageDialog";
import ValidationBaseSelect from "../ValidationBaseSelect";
import "./OverviewOptions.scss";

const OverviewOptions = (): JSX.Element => {
	const [isConfigOpen, setIsConfigOpen] = useState(false);
	const [isUploadOpen, setIsUploadOpen] = useState(false);

	const handleConfigButtonClick = () => {
		setIsConfigOpen((prevState) => !prevState);
	};

	const handleUploadClick = () => {
		setIsUploadOpen(true);
	};
	return (
		<div className="overview-options">
			<UploadMessageDialog isOpen={isUploadOpen} setIsOpen={setIsUploadOpen} />
			<Button
				variant="outlined"
				data-testid="open-message"
				className="overview-options__msg-button"
				onClick={handleUploadClick}
			>
				Nachricht öffnen
			</Button>

			<div>
				<Button
					variant="outlined"
					data-testid="generate-message"
					className="overview-options__msg-button"
					onClick={handleConfigButtonClick}
				>
					Nachrichten generieren
				</Button>
			</div>

			<ValidationBaseSelect />

			<GenerationConfiguration
				isConfigOpen={isConfigOpen}
				setIsConfigOpen={setIsConfigOpen}
			/>
		</div>
	);
};

export default OverviewOptions;
