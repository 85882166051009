import type { RestrictionRule } from "../../types";
import { ValidationTargetField } from "../../types";
import { addTargetField } from "../helpers";

const restrictionRules = addTargetField<
	ValidationTargetField.RestrictionName,
	RestrictionRule<ValidationTargetField.RestrictionName>
>(ValidationTargetField.RestrictionName, [
	{
		id: "restriction-name-is-not-whitespace",
		target: ["name"],
		isValid({ restriction }) {
			const name = restriction.get("name") || "";
			return name === name.trim();
		},
		message:
			"Der Name der Einschränkung darf keine umgebenden Leerzeichen enthalten.",
		autoFix: (invalidValue) => () => invalidValue.trim(),
		autoFixDescription: "Umgebende Leerzeichen entfernen",
	},
	{
		id: "restriction-name-is-not-standard-name",
		target: ["name"],
		isValid({ restriction, standardDatatype }) {
			const name = restriction.get("name") || "";
			return name !== standardDatatype.name;
		},
		message:
			"Der Name der Einschränkung darf nicht identisch zum Namen des " +
			"Datentypen aus dem Standard sein.",
	},
]);

export default restrictionRules;
