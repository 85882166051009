import { useContext } from "react";
import SystemBannerContext from "./SystemBannerContext";
import "./SystemBannerOutlet.scss";

function SystemBannerOutlet(): JSX.Element {
	const { setOutletRef } = useContext(SystemBannerContext);
	return <div className="system-banner-outlet" ref={setOutletRef} />;
}

export default SystemBannerOutlet;
