import { useEffect, useState } from "react";
import { useSyncedRef } from ".";

/**
 * Change a value only after a timeout has passed.
 * This can be used to debounce a value or to sync a state to
 * the duration of a css transition
 */
const useDelayedValue = <T>(value: T, delay: number): T => {
	const [state, setState] = useState(value);
	const stateRef = useSyncedRef(state);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (value !== stateRef.current) {
				setState(value);
			}
		}, delay);
		return () => clearTimeout(timeout);
	}, [delay, stateRef, value]);

	return state;
};

export default useDelayedValue;
