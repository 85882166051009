import type { Seq } from "immutable";
import type { DocumentationMap } from "../../EditorState/types";

export const filterTitlesBySearchterm = (
	docs: DocumentationMap,
	searchTerm: string,
): Seq.Indexed<string> => {
	return (docs.keySeq() as Seq.Indexed<string>).filter((docTitle) =>
		(docTitle as string).toLowerCase().includes(searchTerm.toLowerCase()),
	);
};

export const generateListItemText = (
	docs: DocumentationMap,
	docTitle: string,
): string => {
	return docs.get(docTitle)?.get("generiert")
		? `${docTitle} (generiert)`
		: docTitle;
};

export const canBeRenamed = (
	docs: DocumentationMap,
	docTitle: string,
): boolean => {
	const doc = docs.get(docTitle);
	return !!doc && !doc.get("generiert") && !doc.get("versteckt");
};
