import { useEffect } from "react";
import type { ReactNode } from "react";

export const useDocumentTitle = (title: string): void => {
	useEffect(() => {
		const originalTitle = document.title;
		document.title = title;

		return () => {
			document.title = originalTitle;
		};
	}, [title]);
};

const DocumentTitle = ({
	title,
	children,
}: {
	title: string;
	children: ReactNode;
}): JSX.Element => {
	useDocumentTitle(title);
	return <>{children}</>;
};

export default DocumentTitle;
