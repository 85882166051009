import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useModellierungProjekt } from "../../AppActor/actors/modellierungModel/hooks";
import {
	useActiveBausteinNodeId,
	useActiveStructureExpansionState,
	useActiveFullBausteinPath,
	useActiveTreePath,
} from "../../AppActor/actors/treeState/hooks";
import ModelTree from "../ModelTree";
import type {
	LiteId,
	LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import { isLitePaket } from "../../AppActor/actors/modellierungModel/schemas";
import { createSelectNode } from "../../AppActor/actors/modellierungModel/selectors";
import StructureNodeMenuItems from "./StructureToolbar/StructureNodeMenuItems";
import StructureToolbar from "./StructureToolbar";
import EditorSideBar from "../../EditorSideBar";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";
import "./StructureView.scss";

export default function StructureView(): JSX.Element {
	const isDisplayOnly = useIsProjektDisplayOnly();
	const parentNodeId = useActiveBausteinNodeId();
	const fullBausteinPath = useActiveFullBausteinPath();
	const activePath = useActiveTreePath();
	const expansionState = useActiveStructureExpansionState();
	const projekt = useModellierungProjekt();
	const projektId = projekt?.id;
	const rootModelId = projekt?.modell.rootModelId;
	const selectNode = createSelectNode(projekt);
	const activeParentNode = projekt && parentNodeId && selectNode(parentNodeId);

	const isFilterMatch = useCallback(
		(_node: LiteNode, path: LiteId[]) => {
			if (activeParentNode && isLitePaket(activeParentNode)) {
				return path.length === 1;
			}
			return true;
		},
		[activeParentNode],
	);

	const rootNodes = useMemo(
		() => (activeParentNode ? [activeParentNode] : []),
		[activeParentNode],
	);

	if (!projekt || !parentNodeId || !projektId || !rootModelId) {
		return (
			<>
				Es konnten keine Strukturdaten für <q>{projektId}</q> gefunden werden
			</>
		);
	}

	return (
		<div
			data-testid="structure-tree"
			className={classNames(
				"structure-view",
				isDisplayOnly && "structure-view--display-only",
			)}
			data-node-target="structure"
		>
			<EditorSideBar className="structure-view__sidebar">
				<EditorSideBar.Main className="structure-view__main">
					<EditorSideBar.Scroller>
						<ModelTree
							NodeMenu={StructureNodeMenuItems}
							activePath={activePath}
							project={projekt}
							rootNodes={rootNodes}
							// The last segment in the baustein path will always be the first
							// segment in the structure path, so we cut it off here to avoid
							// duplicate path segments
							rootPath={fullBausteinPath.slice(0, -1)}
							expansionState={expansionState}
							isFilterMatch={isFilterMatch}
						/>
					</EditorSideBar.Scroller>
				</EditorSideBar.Main>
				{!isDisplayOnly && (
					<EditorSideBar.Controls>
						<StructureToolbar />
					</EditorSideBar.Controls>
				)}
			</EditorSideBar>
		</div>
	);
}
