import { useLocation } from "react-router-dom";
import InfoRendererDisplay from "./InfoRendererDisplay";

const InfoRenderer = ({
	label,
	value,
	fallback,
}: {
	label?: string;
	value?: string | React.ReactNode;
	fallback?: string | React.ReactNode;
}): JSX.Element => {
	const location = useLocation();

	const formattedLabel = location.pathname.includes("profilierung")
		? `${label} des Standards`
		: label;

	return (
		<InfoRendererDisplay
			fallback={fallback}
			label={formattedLabel}
			value={value}
		/>
	);
};

export default InfoRenderer;
