import classNames from "classnames";
import type { ReactNode } from "react";
import "./StructureInlineInfo.scss";

export default function StructureInlineInfo({
	className,
	children,
	isActive,
}: {
	className?: string;
	children: ReactNode;
	isActive: boolean;
}): JSX.Element {
	return (
		<div
			className={classNames(
				"structure-inline-info",
				isActive && "structure-inline-info--active",
				className,
			)}
			aria-hidden={!isActive}
		>
			{children}
		</div>
	);
}
