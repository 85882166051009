import { isWithinInterval } from "date-fns";
import type { ReactNode } from "react";
import { useState, createContext, useContext, useMemo, useEffect } from "react";
import { useEventHandler } from "../../hooks";
import { RequestStatus, useResource } from "../Api";
import { RequestStatusCode } from "../Api/types";
import RequestErrorNotification from "../RequestErrorNotification";
import type {
	AppInfoContextType,
	AppInfoResponse,
	MaintenanceScheduleResponse,
} from "./types";

// Refresh every 5 minutes (1000ms = 1sec, 1sec * 60 = 1min, 1min * 5 = 5min)
const REFRESH_INFO_TIMEOUT = 1000 * 60 * 5;
// If we're in a maintenance period depends on the current time. We need to
// calculate that state every couple of minutes to see if that is the case
const REFRESH_MAINTENANCE_PERIOD_TIMEOUT = 1000 * 60 * 5;
// Try to re-fresh the information twice. If it does not work after that,
// reload the page. If the response is invalid, it is likely, that the app is
// in maintenance mode, so when we reload, we force the user to land on the
// maintenance page. This way, we won't end up with inconsistent profile data
// that an old version of the frontend tries to pass to the new version of the
// backend
const MAX_RETRIES = 2;

export const AppInfoContext = createContext<AppInfoContextType>({
	info: null,
	status: RequestStatus.Idle,
});

export function useAppInfo(): AppInfoContextType {
	const ctx = useContext(AppInfoContext);
	return ctx;
}

function calculateIsInMaintenancePeriod(
	schedules: MaintenanceScheduleResponse[] | undefined,
) {
	const now = new Date();
	return (
		!!schedules?.length &&
		schedules.some((schedule) => {
			if (!schedule.sichtbarAb) {
				return true;
			}
			const start = new Date(schedule.sichtbarAb);
			const end = schedule.sichtbarAb ? new Date(schedule.sichtbarAb) : now;
			return isWithinInterval(now, { start, end });
		})
	);
}

function useIsInMaintenancePeriod(
	schedules: MaintenanceScheduleResponse[] | undefined,
) {
	const [isInMaintenancePeriod, setIsInMaintenancePeriod] = useState(() =>
		calculateIsInMaintenancePeriod(schedules),
	);

	useEffect(() => {
		setIsInMaintenancePeriod(calculateIsInMaintenancePeriod(schedules));
		const interval = setInterval(() => {
			setIsInMaintenancePeriod(calculateIsInMaintenancePeriod(schedules));
		}, REFRESH_MAINTENANCE_PERIOD_TIMEOUT);
		return () => {
			clearInterval(interval);
		};
	}, [schedules]);

	return isInMaintenancePeriod;
}

// Globally keep track of the number of times we've tried to fetch the data,
// but got decoding errors, so we can reload after `MAX_RETRIES`
let retry = 0;

function AppInfoProvider({ children }: { children: ReactNode }): JSX.Element {
	const { data, request, status, error } = useResource<AppInfoResponse>();
	const isInMaintenancePeriod = useIsInMaintenancePeriod(data?.meldungen);

	// If we're inside a maintenance period and the requests to /info fail
	// multiple times, the maintenance is most likely currently in progress. If
	// that is the case, we forcefully reload the page so the user is redirected
	// to the maintenance page and cannot produce frontend states that will be
	// incompatible with the next version of the backend
	useEffect(() => {
		if (
			isInMaintenancePeriod &&
			status === RequestStatus.Failure &&
			error?.status === RequestStatusCode.DecodingError
		) {
			if (retry >= MAX_RETRIES) {
				window.location.reload();
			}
			retry += 1;
		}
	}, [error?.status, isInMaintenancePeriod, status]);

	const requestInfo = useEventHandler(() =>
		request("/actuator/info", {
			// Enforce that Content-Type: "application/json" is returned by server
			headers: { Accept: "application/json" },
		}),
	);

	useEffect(() => {
		requestInfo();
		const interval = setInterval(() => {
			requestInfo();
		}, REFRESH_INFO_TIMEOUT);
		return () => {
			clearInterval(interval);
		};
	}, [requestInfo]);

	const ctx = useMemo(() => ({ info: data, status }), [data, status]);

	return (
		<AppInfoContext.Provider value={ctx}>
			<RequestErrorNotification
				id="app-info-request-error"
				status={status}
				error={error}
				onRetry={requestInfo}
			>
				Beim Abrufen der Applikationsinformationen ist ein Fehler aufgetreten
			</RequestErrorNotification>
			{children}
		</AppInfoContext.Provider>
	);
}

export default AppInfoProvider;
