import type { SelectChangeEvent } from "@mui/material";
import {
	Tooltip,
	IconButton,
	TextField,
	TableCell,
	TableRow,
	Select,
	MenuItem,
} from "@mui/material";
import type { KeyboardEventHandler } from "react";
import { useRef, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import type {
	PropertyProfile,
	PropertyValues,
} from "../../../../../../EditorState/types";
import { VisuallyHidden } from "../../../../../../ui";
import KeyboardShortcut from "../../../../../../KeyboardShortcut";

export enum PropertySelectMode {
	Create = "Create",
	Edit = "Edit",
}

const emptyState = { name: "", value: "" };

function PropertySelectEditRow({
	propertyOptions,
	onConfirm,
	onCancel,
	initialState = emptyState,
	mode,
	isEditingDisabled,
	autoFocus = false,
}: {
	propertyOptions: PropertyValues[];
	onConfirm: (prop: PropertyProfile) => void;
	onCancel?: () => void;
	initialState?: PropertyProfile;
	mode: PropertySelectMode;
	isEditingDisabled?: boolean;
	autoFocus?: boolean;
}): JSX.Element {
	const [propertyName, setPropertyName] = useState(initialState.name);
	const [propertyValue, setPropertyValue] = useState(initialState.value || "");
	const selectedName =
		propertyName || propertyOptions.at(0)?.nameTechnisch || "";
	const firstInputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		setPropertyName(initialState.name);
		setPropertyValue(initialState.value || "");
	}, [initialState]);

	const handleChange = (event: SelectChangeEvent) => {
		setPropertyName(event.target.value);
	};

	const isConfirmDisabled =
		!selectedName.length || !propertyValue.trim().length;
	const handleConfirm = () => {
		if (isConfirmDisabled) return;
		onConfirm({ name: selectedName, value: propertyValue });
		setPropertyName(initialState.name);
		setPropertyValue(initialState.value || "");
		firstInputRef.current?.focus();
	};

	const handlePropertyValueChange = (e: { target: { value: string } }) => {
		const { value } = e.target;
		if (typeof value === "string") {
			setPropertyValue(value);
		}
	};

	const handlePropertyKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.key === "Enter") {
			handleConfirm();
		}
	};
	const handleCancel = () => {
		onCancel?.();
	};

	return (
		<TableRow
			data-testid="attribute-edit-row"
			data-mode={mode}
			data-initial-name={initialState.name}
		>
			<TableCell>
				<VisuallyHidden>
					<span id="attributes-select-label">Name der Eigenschaft</span>
				</VisuallyHidden>
				<Select
					aria-labelledby="attributes-select-label"
					data-testid="attributes-select"
					value={selectedName}
					onChange={handleChange}
					fullWidth
					autoFocus={autoFocus}
					inputRef={firstInputRef}
					disabled={isEditingDisabled}
				>
					{propertyOptions?.map((prop) => (
						<MenuItem
							key={prop.nameTechnisch}
							value={prop.nameTechnisch}
							data-testid="attributes-select-item"
							data-item-id={prop.nameTechnisch}
						>
							{prop.name}
						</MenuItem>
					))}
				</Select>
			</TableCell>
			<TableCell sx={{ display: "flex" }}>
				<VisuallyHidden>
					<span id="attributes-value-input-label">Wert der Eigenschaft</span>
				</VisuallyHidden>
				<TextField
					placeholder="Wert der Eigenschaft"
					aria-labelledby="attributes-value-input-label"
					value={propertyValue}
					onChange={handlePropertyValueChange}
					onKeyDown={handlePropertyKeyDown}
					data-testid="attributes-value-input"
					disabled={isEditingDisabled}
					fullWidth
				/>
			</TableCell>
			<TableCell>
				<span className="attribute-select-edit-row__actions">
					<Tooltip
						title={`Eigenschaft ${
							mode === PropertySelectMode.Create ? "hinzufügen" : "speichern"
						}`}
					>
						<span>
							<IconButton
								data-testid="attribute-add"
								onClick={handleConfirm}
								disabled={isConfirmDisabled || isEditingDisabled}
							>
								{mode === PropertySelectMode.Create ? (
									<AddIcon />
								) : (
									<SaveIcon />
								)}
							</IconButton>
						</span>
					</Tooltip>
					{mode === PropertySelectMode.Edit && (
						<>
							<Tooltip title="Bearbeitung abbrechen">
								<span>
									<IconButton
										onClick={handleCancel}
										disabled={isEditingDisabled}
									>
										<CancelIcon />
									</IconButton>
								</span>
							</Tooltip>
							<KeyboardShortcut
								shortcut={{ key: "Escape" }}
								onActivate={handleCancel}
								disabled={isEditingDisabled}
							/>
						</>
					)}
				</span>
			</TableCell>
		</TableRow>
	);
}

export default PropertySelectEditRow;
