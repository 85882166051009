import classNames from "classnames";
import type { ReactNode } from "react";
import "./TableContainer.scss";

function TableContainer({
	children,
	className,
	headerSurface = "normal",
	bodySurface = "normal",
}: {
	children: ReactNode;
	className?: string;
	headerSurface?: "normal" | "emphasized";
	bodySurface?: "normal" | "emphasized";
}): JSX.Element {
	return (
		<div
			className={classNames(
				"xui-table-container",
				headerSurface && `xui-table-container--header-surface-${headerSurface}`,
				bodySurface && `xui-table-container--body-surface-${bodySurface}`,
				className,
			)}
		>
			{children}
		</div>
	);
}

export default TableContainer;
