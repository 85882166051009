import { createContext } from "react";
import type { DescendantsContextType } from "../util/descendants";
import type { DropdownMenuContextType } from "./types";

export const DropdownMenuContext = createContext<DropdownMenuContextType>(
	// The context must be provided by an instance of DropdownMenu.
	// If this is not the case the app should fail early when the child
	// components try to access the context
	undefined as never,
);

export const DropdownMenuDescendantsContext =
	createContext<DescendantsContextType>(undefined as never);
