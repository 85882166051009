import { CircularProgress, Button } from "@mui/material";
import { RequestStatus } from "../../../Api";
import { useEditorData } from "../../../EditorData";
import { useStateSelector } from "../../../EditorState";
import { selectHasActiveProject } from "../../../EditorState/selectors";
import { getMaxSeverityFromResults } from "../../../Validation/helpers";
import { useValidationContext } from "../../../Validation/hooks";
import { Severity, ValidationStatus } from "../../../Validation/types";

const SaveButton = (): JSX.Element => {
	const { saveZip, statusZip } = useEditorData();
	const { result, status } = useValidationContext();
	const hasActiveProject = useStateSelector(selectHasActiveProject());

	const maxValidationSeverity = getMaxSeverityFromResults(
		Object.values(result).flat(),
	);
	const hasFatalValidationErrors = maxValidationSeverity === Severity.Error;
	const isSaveDisabled =
		!hasActiveProject ||
		hasFatalValidationErrors ||
		status === ValidationStatus.Running;

	const handleSaveToUser = () => saveZip();
	return (
		<>
			{statusZip === RequestStatus.Loading ? (
				<CircularProgress size="2em" data-testid="save-spinner" />
			) : (
				<Button
					disabled={isSaveDisabled}
					data-testid="save-button"
					onClick={handleSaveToUser}
				>
					Speichern
				</Button>
			)}
		</>
	);
};

export default SaveButton;
