import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { StateContainerProvider } from "@xoev/state-container-react";
import { useCreateEditorPatchCreators } from "./editorPatchCreators";
import EditorStateContext from "./EditorStateContext";
import useUrlSync from "./useUrlSync";
import createEmptyStateContainer from "./emptyStateContainer";

function EditorStateProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [stateContainer, setStateContainer] = useState(() =>
		createEmptyStateContainer(),
	);
	const patchCreators = useCreateEditorPatchCreators();
	const ctx = useMemo(
		() => ({ patchCreators, setStateContainer }),
		[patchCreators],
	);

	// On undo/redo, jump to the place where data has changed
	useUrlSync(stateContainer);

	return (
		<EditorStateContext.Provider value={ctx}>
			<StateContainerProvider container={stateContainer}>
				{children}
			</StateContainerProvider>
		</EditorStateContext.Provider>
	);
}

export default EditorStateProvider;
