import isEqual from "lodash/isEqual";
import WorkerBridge from "../WorkerBridge";
import type { AdocActionMap, AdocConfig } from "./types";

const createAdocWorker = () =>
	new Worker(new URL("./adoc.worker.ts", import.meta.url));

// Only initialize the converter when it is actually needed
let instance: WorkerBridge<AdocActionMap> | null = null;

// Cache the results, so we can avoid re-converting the same markup over and
// over again. Since the markup inputs and html outputs can be quite big, we
// limit the cache size and discard any stored data that is too old
const MAX_CACHE_SIZE = 5;
let cache: {
	markup: string;
	options?: AdocConfig;
	result: Promise<string>;
}[] = [];

function convert(markup: string, options?: AdocConfig): Promise<string> {
	if (!markup) return Promise.resolve("");
	if (!instance) {
		// Instanciate the converter, which intern loads the worker and asciidoctor
		// library
		instance = new WorkerBridge<AdocActionMap>(createAdocWorker, 2);
	}
	const cacheHit = cache.find(
		(entry) => isEqual(entry.options, options) && entry.markup === markup,
	);
	if (cacheHit) {
		return cacheHit.result;
	}
	const result = instance.enqueue("convert", { markup, options });
	cache.unshift({ markup, options, result });
	cache = cache.slice(0, MAX_CACHE_SIZE);
	return result;
}

export default convert;
