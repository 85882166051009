import AdocView from "../AdocView";
import HomeSubMenu from "../Menu/HomeSubMenu";
import "./HomeView.scss";

export default function HomeView({ doc }: { doc: string }): JSX.Element {
	return (
		<>
			<HomeSubMenu />
			<main id="main" className="home-view">
				<AdocView doc={doc} />
			</main>
		</>
	);
}
