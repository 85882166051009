import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, fromPromise, raise, setup } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../../EventStore/helpers";
import type {
	ExtractStoreEventPayload,
	ModellierungModelEvent,
} from "../../../EventStore/StoreEvent";
import type {
	EventStoreEntryMeta,
	EventStoreLog,
} from "../../../EventStore/eventStore.machine";
import { debugSearchResult } from "./helpers";
import { getEventLogFromSystem } from "../../../EventStore/hooks";
import type { SearchResult } from "./types";
import SearchWorkerBridge from "./worker/SearchWorkerBridge";
import { getModellierungProjektFromSystem } from "../hooks";
import { assertHasProject } from "../helpers";
import { getSearchQueryRef } from "./hooks";
import type { ProjektMeta } from "../../project/types";
import type { ProjektId } from "../schemas";

export type ModellierungSearchCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof modellierungSearchCommandMachine
>;
export type ModellierungSearchCommandInput = ProjektMeta;
export type ModellierungSearchCommandContext =
	ModellierungSearchCommandInput & {
		searchWorker: SearchWorkerBridge | null;
		lastSearchTerm: string | null;
	};
export type ModellierungSearchCommandEvent =
	| { type: "NOOP" }
	| { type: "SEARCH"; projektId: ProjektId; term: string }
	| ({
			type: "ACTIVATE_RESULT_NODE";
	  } & ExtractStoreEventPayload<"SEARCH.RESPONSE">)
	| { type: "SEND_SEARCH_RESULT"; result: SearchResult }
	| {
			type: "FORWARD_EVENT";
			projektId: ProjektId;
			event: ModellierungModelEvent;
			meta: EventStoreEntryMeta;
	  }
	| ({
			type: "SHIFT_RESULT_FOCUS";
	  } & ExtractStoreEventPayload<"SEARCH.SHIFT_RESULT_FOCUS">);

const modellierungSearchCommandMachine = setup({
	types: {
		events: {} as ModellierungSearchCommandEvent,
		context: {} as ModellierungSearchCommandContext,
		input: {} as ModellierungSearchCommandInput,
	},
	actors: {
		initializeWorkerState: fromPromise<
			SearchWorkerBridge,
			{ projektId: ProjektId; eventLog: EventStoreLog }
		>(({ input }) => {
			return SearchWorkerBridge.fromEventLog(
				input.projektId,
				input.eventLog,
				() =>
					new Worker(
						new URL("./worker/modellierungSearch.worker.ts", import.meta.url),
					),
			);
		}),
		performSearch: fromPromise<
			SearchResult,
			{ searchWorker: SearchWorkerBridge; term: string }
		>(({ input: { searchWorker, term } }) => {
			return searchWorker.search(term);
		}),
		translateSearchEvents: translateStoreEvents<ModellierungSearchCommandEvent>(
			{
				"SEARCH.REQUEST": ({ payload }) => ({ type: "SEARCH", ...payload }),
				"SEARCH.RESPONSE": ({ payload }) => ({
					type: "ACTIVATE_RESULT_NODE",
					...payload,
				}),
				"SEARCH.SHIFT_RESULT_FOCUS": ({ payload }) => ({
					type: "SHIFT_RESULT_FOCUS",
					...payload,
				}),
			},
		),
		translateModellEvents: translateStoreEvents<ModellierungSearchCommandEvent>(
			{
				"MODELLIERUNG.MODELL.APPLY": (event, meta) => ({
					type: "FORWARD_EVENT",
					projektId: event.payload.projektId,
					event,
					meta,
				}),
				"MODELLIERUNG.MODELL.UNDO": (event, meta) => ({
					type: "FORWARD_EVENT",
					projektId: event.payload.projektId,
					event,
					meta,
				}),
				"MODELLIERUNG.MODELL.REDO": (event, meta) => ({
					type: "FORWARD_EVENT",
					projektId: event.payload.projektId,
					event,
					meta,
				}),
			},
		),
	},
	actions: {
		recordSearchTerm: assign({
			lastSearchTerm: ({ event }) => {
				assertEvent(event, "SEARCH");
				return event.term;
			},
		}),
		terminateWorker: ({ context }) => context.searchWorker?.worker.terminate(),
		raiseSearchWithRecordedTerm: raise(({ context }) => {
			const term = context.lastSearchTerm;
			if (term === null) return { type: "NOOP" as const };
			return { type: "SEARCH" as const, projektId: context.projektId, term };
		}),
		sendSearchResult: ({ context, event, system }) => {
			assertEvent(event, "SEND_SEARCH_RESULT");
			sendToEventStore(system, {
				type: "SEARCH.RESPONSE",
				payload: {
					projektId: context.projektId,
					result: event.result,
					// Add the ids and paths as node names to the event for debugging purposes
					...debugSearchResult(system, context.projektId, event.result),
				},
			});
		},
		forwardEvent: ({ context, event }) => {
			assertEvent(event, "FORWARD_EVENT");
			context.searchWorker?.worker.forward(event.event, event.meta);
		},
		sendActivateNodeEvent: ({ event, system }) => {
			assertEvent(event, "ACTIVATE_RESULT_NODE");
			const { projektId, result } = event;
			const projekt = getModellierungProjektFromSystem(system, projektId);
			assertHasProject(projekt);
			const firstResult = result.paths.at(0);
			if (!firstResult) return;
			const payload = { projektId, fullPath: firstResult };
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.NODE.OPEN",
				payload,
			});
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.NODE.ACTIVATE",
				payload,
			});
		},
		shiftResultFocus: ({ event, system, context: { projektId } }) => {
			assertEvent(event, "SHIFT_RESULT_FOCUS");
			const { delta } = event;
			const queryRef = getSearchQueryRef(system, projektId);
			if (!queryRef) return;
			const { result, index } = queryRef.getSnapshot().context;
			const nextIndex =
				(index + result.paths.length + delta) % result.paths.length;
			const nextPath = result.paths.at(nextIndex);
			if (nextPath) {
				sendToEventStore(system, {
					type: "SEARCH.UPDATE_RESULT_INDEX",
					payload: { projektId, index: nextIndex },
				});
				sendToEventStore(system, {
					type: "MODELLIERUNG_TREE.NODE.ACTIVATE",
					payload: { projektId, fullPath: nextPath },
				});
			}
		},
	},
	guards: {
		isSelf: ({ context, event }) => {
			assertEvent(event, [
				"SEARCH",
				"FORWARD_EVENT",
				"ACTIVATE_RESULT_NODE",
				"SHIFT_RESULT_FOCUS",
			]);
			return context.projektId === event.projektId;
		},
	},
}).createMachine({
	id: "modellierungSearch:command",
	context: ({ input }) => ({
		...input,
		searchWorker: null,
		lastSearchTerm: null,
	}),
	initial: "Uninitialized",
	exit: "terminateWorker",
	states: {
		Uninitialized: {
			invoke: { src: "translateSearchEvents" },
			on: {
				SEARCH: {
					guard: "isSelf",
					target: "Initializing",
					actions: "recordSearchTerm",
				},
			},
		},
		Initializing: {
			invoke: [
				{ src: "translateSearchEvents" },
				{
					src: "initializeWorkerState",
					onDone: {
						target: "Active",
						actions: assign({ searchWorker: ({ event }) => event.output }),
					},
					input: ({ context, self }) => ({
						projektId: context.projektId,
						eventLog: getEventLogFromSystem(self.system),
					}),
				},
			],
			on: {
				SEARCH: { guard: "isSelf", actions: "recordSearchTerm" },
			},
		},
		Active: {
			entry: "raiseSearchWithRecordedTerm",
			invoke: [
				{ src: "translateSearchEvents" },
				{ src: "translateModellEvents" },
			],
			initial: "Idle",
			states: {
				Idle: {},
				Searching: {
					invoke: {
						src: "performSearch",
						onDone: {
							target: "Idle",
							actions: raise(({ event }) => ({
								type: "SEND_SEARCH_RESULT" as const,
								result: event.output,
							})),
						},
						input: ({ context, event }) => {
							assertEvent(event, "SEARCH");
							if (!context.searchWorker) {
								throw new Error(
									"Search can only be executed when a search worker is initialized, " +
										"but none was found",
								);
							}
							return { searchWorker: context.searchWorker, term: event.term };
						},
					},
				},
			},
			on: {
				SEARCH: { guard: "isSelf", target: ".Searching" },
				FORWARD_EVENT: { guard: "isSelf", actions: "forwardEvent" },
				SEND_SEARCH_RESULT: { actions: "sendSearchResult" },
			},
		},
	},
	on: {
		ACTIVATE_RESULT_NODE: {
			guard: "isSelf",
			actions: "sendActivateNodeEvent",
		},
		SHIFT_RESULT_FOCUS: {
			guard: "isSelf",
			actions: "shiftResultFocus",
		},
	},
});

export default modellierungSearchCommandMachine;
