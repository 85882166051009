import MenuIcon from "@mui/icons-material/Menu";
import ToolbarMenu from "../../Toolbar/ToolbarMenu";
import StructureNodeMenuItems from "./StructureNodeMenuItems";

export default function StructureToolbar(): JSX.Element {
	return (
		<ToolbarMenu icon={MenuIcon} label="Menü">
			{({ closeMenu, isOpen }) => (
				<StructureNodeMenuItems closeMenu={closeMenu} isOpen={isOpen} />
			)}
		</ToolbarMenu>
	);
}
