import { createComponents } from "./fieldRendererHelpers";
import type { FormFieldProps } from "./types";
import fieldRenderers from "./fieldRenderers";

const FormFieldRenderer = ({
	fields,
	onBlur,
	onFix,
	definition,
	activeNode,
	activePath,
	isStandardRenderer,
	profile,
}: FormFieldProps): JSX.Element => {
	if (!activeNode) return <></>;

	const components = createComponents(fieldRenderers, fields, {
		activeNode,
		activePath,
		onBlur,
		onFix,
		definition,
		isStandardRenderer,
		profile,
	});

	return <>{components}</>;
};

export default FormFieldRenderer;
