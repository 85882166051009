// See https://developer.mozilla.org/en-US/docs/web/api/ui_events/keyboard_event_key_values#navigation_keys
// for a list of special keys that can be used
import type { ShortcutEvent } from "../../EventStore/StoreEvent";
import type { KeyMap } from "./types";

const DEFAULT_PREFIX = "ctrlCmd+k";

const createShortcut = (shortcut: string) => `${DEFAULT_PREFIX} ${shortcut}`;
const createPrefixCommand = (
	shortcut: string,
	command: ShortcutEvent["type"],
) => ({
	shortcut: createShortcut(shortcut),
	command,
});
const createCommand = (shortcut: string, command: ShortcutEvent["type"]) => ({
	shortcut,
	command,
});

const keyMap: KeyMap = [
	createCommand("ctrlCmd+z", "SHORTCUT.UNDO"),
	createCommand("ctrlCmd+y", "SHORTCUT.REDO"),
	createCommand("ctrlCmd+shift+z", "SHORTCUT.REDO"),
	createPrefixCommand("ArrowLeft", "SHORTCUT.FOCUS_TAB.LEFT"),
	createPrefixCommand("ArrowRight", "SHORTCUT.FOCUS_TAB.RIGHT"),
	createPrefixCommand("Home", "SHORTCUT.FOCUS_TAB.FIRST"),
	createPrefixCommand("End", "SHORTCUT.FOCUS_TAB.LAST"),
	createPrefixCommand("1", "SHORTCUT.FOCUS_TAB.1"),
	createPrefixCommand("2", "SHORTCUT.FOCUS_TAB.2"),
	createPrefixCommand("3", "SHORTCUT.FOCUS_TAB.3"),
	createPrefixCommand("4", "SHORTCUT.FOCUS_TAB.4"),
	createPrefixCommand("5", "SHORTCUT.FOCUS_TAB.5"),
	createPrefixCommand("6", "SHORTCUT.FOCUS_TAB.6"),
	createPrefixCommand("7", "SHORTCUT.FOCUS_TAB.7"),
	createPrefixCommand("8", "SHORTCUT.FOCUS_TAB.8"),
	createPrefixCommand("9", "SHORTCUT.FOCUS_TAB.9"),
	createPrefixCommand("w", "SHORTCUT.CLOSE_PROJECT"),
	createPrefixCommand("b k p", "SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.PAKET"),
	createPrefixCommand("b k s", "SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.SCHEMA_PAKET"),
	createPrefixCommand("b k d", "SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.DATENTYP"),
	createPrefixCommand(
		"b k c",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.CODE_DATENTYP",
	),
	createPrefixCommand("b k n", "SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.NACHRICHT"),
	createPrefixCommand(
		"b k g",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.GLOBALE_EIGENSCHAFT",
	),
	createPrefixCommand("b o p", "SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.PAKET"),
	createPrefixCommand("b o s", "SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.SCHEMA_PAKET"),
	createPrefixCommand("b o d", "SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.DATENTYP"),
	createPrefixCommand(
		"b o c",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.CODE_DATENTYP",
	),
	createPrefixCommand("b o n", "SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.NACHRICHT"),
	createPrefixCommand(
		"b o g",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.GLOBALE_EIGENSCHAFT",
	),
	createPrefixCommand("b u p", "SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.PAKET"),
	createPrefixCommand("b u s", "SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.SCHEMA_PAKET"),
	createPrefixCommand("b u d", "SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.DATENTYP"),
	createPrefixCommand(
		"b u c",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.CODE_DATENTYP",
	),
	createPrefixCommand("b u n", "SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.NACHRICHT"),
	createPrefixCommand(
		"b u g",
		"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.GLOBALE_EIGENSCHAFT",
	),
	createPrefixCommand("Delete s", "SHORTCUT.STRUCTURE_VIEW.DELETE"),
	createPrefixCommand("Delete b", "SHORTCUT.BAUSTEIN_VIEW.DELETE"),
	createPrefixCommand("s k e", "SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.EIGENSCHAFT"),
	createPrefixCommand("s k a", "SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.ATTRIBUT"),
	createPrefixCommand("s o e", "SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.EIGENSCHAFT"),
	createPrefixCommand("s o a", "SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.ATTRIBUT"),
	createPrefixCommand("s u e", "SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.EIGENSCHAFT"),
	createPrefixCommand("s u a", "SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.ATTRIBUT"),
];

export default keyMap;
