import type { ReactNode } from "react";
import React from "react";
import type { AnyComponent, ExtendProps, PropsOf } from "../util";
import MenuItemLink from "./MenuItemLink";

export interface MenuItemOwnProps<TComponent extends AnyComponent = "li"> {
	children?: ReactNode;
	className?: string;
	as?: TComponent;
}

export type MenuItemProps<TComponent extends AnyComponent = "li"> = ExtendProps<
	PropsOf<TComponent>,
	MenuItemOwnProps<TComponent>
>;

export default function MenuItem<TComponent extends AnyComponent = "li">({
	as: As,
	children,
	className,
	...props
}: MenuItemProps<TComponent>): JSX.Element {
	const Comp = As || "li";
	return (
		<Comp className={className} {...props}>
			{children}
		</Comp>
	);
}

MenuItem.Link = MenuItemLink;
