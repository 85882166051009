import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@xoev/mui-theme";
import type { UnknownRendererImplementation } from "../types";
import createRendererComponent from "./createRendererComponent";
import type {
	RendererImplementationComponent,
	RendererImplementationFn,
} from "./types";

/**
 * Create a render function from a react component
 */
function createReactRenderer<
	RendererImpl extends UnknownRendererImplementation = UnknownRendererImplementation,
>(
	RendererComponent: RendererImplementationComponent<RendererImpl>,
): RendererImplementationFn<RendererImpl> {
	const reactRenderer: RendererImplementationFn<RendererImpl> = (
		domNode,
		markup,
		renderHelpers,
	) => {
		domNode.setAttribute("data-react-root", "true");
		ReactDOM.render(
			<StrictMode>
				<MuiThemeProvider>
					<RendererComponent
						markup={markup}
						renderer={createRendererComponent(renderHelpers.render)}
						renderHelpers={renderHelpers}
					/>
				</MuiThemeProvider>
			</StrictMode>,
			domNode,
		);
	};
	return reactRenderer;
}

export default createReactRenderer;
