import type {
	Locator,
	ValidationTargetField,
} from "../AppActor/actors/modellierungModel/validation/validators/types";
import ValidationFormatterLite from "./ValidationFormatterLite";
import useValidationResults from "./useValidationResults";

function ValidationDisplayLite<TargetField extends ValidationTargetField>({
	locator,
}: {
	locator?: Locator<TargetField>;
}): JSX.Element | null {
	const results = useValidationResults<TargetField>(locator);
	return results.length > 0 ? (
		<ValidationFormatterLite<TargetField> results={results} />
	) : null;
}

export default ValidationDisplayLite;
