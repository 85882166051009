import { Outlet, Route, Routes } from "react-router-dom";
import EditorMain from "../EditorMain";
import EditorPage, { EditorSection } from "../EditorPage";
import { LRSplit } from "../ui";
import DocumentationEditView from "./DocumentationEditView";
import DocumentationList from "./DocumentationList/DocumentationList";
import DocumentationNavBar from "./DocumentationNavBar";
import GeneratedChapterProvider from "./GeneratedChapterProvider";

const DocumentationView = (): JSX.Element => (
	<Routes>
		<Route
			path="/"
			element={
				<EditorPage
					data-testid="documentation-view"
					section={EditorSection.Documentation}
				>
					<DocumentationNavBar />
					<EditorMain>
						<LRSplit>
							<LRSplit.Left>
								<DocumentationList />
							</LRSplit.Left>
							<LRSplit.Right>
								<GeneratedChapterProvider>
									<DocumentationEditView />
								</GeneratedChapterProvider>
							</LRSplit.Right>
						</LRSplit>
					</EditorMain>
				</EditorPage>
			}
		>
			<Route index element={<Outlet />} />
			<Route path=":chapter" element={<Outlet />} />
		</Route>
	</Routes>
);

export default DocumentationView;
