import type { ActorRefFrom } from "xstate";
import { setup, assertEvent, raise } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import { getEffectApi } from "../effectApi/hooks";
import {
	getProjectsQueryFromSystem,
	selectProjektIds,
} from "../projects/hooks";
import { getNavigationQueryFromSystem, selectActiveProjektId } from "./hooks";
import type {
	ProjektId,
	LiteId,
	ProjektType,
} from "../modellierungModel/schemas";
import { ProjektIdSchema } from "../modellierungModel/schemas";
import { getTreePathVariants } from "../treeState/helpers";
import {
	getModellierungProjektFromSystem,
	getProjektIdByIdOrKennungFromSystem,
} from "../modellierungModel/hooks";
import {
	createDefaultTabHref,
	createNodeTargetHref,
	createTabHref,
	parseIdSegmentFromLocation,
	parseProjektLocation,
} from "./helpers";
import { getEventLogFromSystem } from "../../EventStore/hooks";

export type NavigationCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof navigationCommandMachine
>;

export type NavigationCommandEvent =
	| { type: "GOTO_HOME" }
	| {
			type: "OPEN_PROJECT_TAB";
			projektId: ProjektId;
			projektType: ProjektType;
			kennung: string;
			initialLocation?: string;
	  }
	| { type: "ROTATE_TAB"; offset: number }
	| { type: "GOTO_NTH_TAB"; index: number }
	| {
			type: "GOTO_TAB";
			projektId: ProjektId;
			projektType: ProjektType;
			kennung: string;
	  }
	| { type: "GOTO_BAUSTEIN_NODE"; projektId: ProjektId; path: LiteId[] }
	| { type: "FOCUS_TREE_PATH"; projektId: ProjektId; fullPath: LiteId[] }
	| { type: "LOCATION_CHANGE"; location: string }
	| {
			type: "LOCATION_PARSED";
			projektId: ProjektId | null;
			modellPath: LiteId[] | null;
	  };

const navigationCommandMachine = setup({
	types: {
		events: {} as NavigationCommandEvent,
	},
	actors: {
		translateEvents: translateStoreEvents<NavigationCommandEvent>({
			"PROJECT.OPEN_EXISTING.SUCCESS": ({ payload }) => ({
				type: "OPEN_PROJECT_TAB",
				projektId: payload.projektMeta.projektId,
				projektType: payload.projektMeta.projektType,
				kennung: payload.projektMeta.kennung,
				initialLocation: payload.initialLocation,
			}),
			"GOTO.HOME": () => ({ type: "GOTO_HOME" }),
			"GOTO.TAB": ({ payload }) => ({ type: "GOTO_TAB", ...payload }),
			"SHORTCUT.FOCUS_TAB.LEFT": () => ({ type: "ROTATE_TAB", offset: -1 }),
			"SHORTCUT.FOCUS_TAB.RIGHT": () => ({ type: "ROTATE_TAB", offset: 1 }),
			"SHORTCUT.FOCUS_TAB.FIRST": () => ({ type: "GOTO_NTH_TAB", index: 0 }),
			"SHORTCUT.FOCUS_TAB.LAST": () => ({ type: "GOTO_NTH_TAB", index: -1 }),
			"SHORTCUT.FOCUS_TAB.1": () => ({ type: "GOTO_NTH_TAB", index: 0 }),
			"SHORTCUT.FOCUS_TAB.2": () => ({ type: "GOTO_NTH_TAB", index: 1 }),
			"SHORTCUT.FOCUS_TAB.3": () => ({ type: "GOTO_NTH_TAB", index: 2 }),
			"SHORTCUT.FOCUS_TAB.4": () => ({ type: "GOTO_NTH_TAB", index: 3 }),
			"SHORTCUT.FOCUS_TAB.5": () => ({ type: "GOTO_NTH_TAB", index: 4 }),
			"SHORTCUT.FOCUS_TAB.6": () => ({ type: "GOTO_NTH_TAB", index: 5 }),
			"SHORTCUT.FOCUS_TAB.7": () => ({ type: "GOTO_NTH_TAB", index: 6 }),
			"SHORTCUT.FOCUS_TAB.8": () => ({ type: "GOTO_NTH_TAB", index: 7 }),
			"SHORTCUT.FOCUS_TAB.9": () => ({ type: "GOTO_NTH_TAB", index: 8 }),
			"MODELLIERUNG_TREE.NODE.ACTIVATE": ({ payload }) => ({
				type: "FOCUS_TREE_PATH",
				...payload,
			}),
			NAVIGATE: ({ payload }) => ({ type: "LOCATION_CHANGE", ...payload }),
			"NAVIGATE.PARSED": ({ payload }) => ({
				type: "LOCATION_PARSED",
				...payload,
			}),
		}),
	},
	actions: {
		navigateToHome: ({ event, system }) => {
			assertEvent(event, "GOTO_HOME");
			const { navigate } = getEffectApi(system);
			navigate("/");
		},
		navigateToTab: ({ event, system }) => {
			assertEvent(event, "GOTO_TAB");
			const defaultHref = createDefaultTabHref(event);
			const eventLog = getEventLogFromSystem(system);
			const tabHref = createTabHref({ ...event, eventLog });
			const href = tabHref || defaultHref;
			const { navigate } = getEffectApi(system);
			navigate(href);
		},
		navigateToNthTab: ({ event, system }) => {
			assertEvent(event, "GOTO_NTH_TAB");
			const projektIds = selectProjektIds(
				getProjectsQueryFromSystem(system).getSnapshot().context.projects,
			);
			const projektId = projektIds.at(event.index);
			if (!projektId) return;
			const projekt = getModellierungProjektFromSystem(system, projektId);
			if (!projekt) return;
			sendToEventStore(system, {
				type: "GOTO.TAB",
				payload: {
					kennung: projekt.kennung,
					projektId: projekt.id,
					projektType: projekt.type,
				},
			});
		},
		rotateTab: raise(({ event, system }) => {
			assertEvent(event, "ROTATE_TAB");
			const projektIds = selectProjektIds(
				getProjectsQueryFromSystem(system).getSnapshot().context.projects,
			);
			const currentId = selectActiveProjektId(
				getNavigationQueryFromSystem(system).getSnapshot(),
			);
			const currentIndex = projektIds.indexOf(
				currentId || ProjektIdSchema.parse(""),
			);
			if (currentIndex === -1)
				return {
					type: "GOTO_NTH_TAB" as const,
					index: event.offset === 1 ? 0 : -1,
				};
			const nextIndex =
				(projektIds.length + currentIndex + event.offset) % projektIds.length;
			return { type: "GOTO_NTH_TAB" as const, index: nextIndex };
		}),
		openProjectTab: ({ event, system }) => {
			assertEvent(event, "OPEN_PROJECT_TAB");
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { type: _omit, initialLocation, ...payload } = event;
			if (initialLocation) {
				// If there is a initial location, replay its navigate event, so the
				// rest of the event store can act accordingly and show the matching ui
				sendToEventStore(system, {
					type: "NAVIGATE",
					payload: { location: initialLocation },
				});
			} else {
				// Otherwise, just show the index view of the tab
				sendToEventStore(system, { type: "GOTO.TAB", payload });
			}
		},
		sendNavigationUpdate: ({ event, system }) => {
			assertEvent(event, "LOCATION_CHANGE");
			const { location } = event;
			const idSegment = parseIdSegmentFromLocation(location);
			const projektId = getProjektIdByIdOrKennungFromSystem(system, idSegment);
			const projekt = getModellierungProjektFromSystem(system, projektId);
			const modellPath = parseProjektLocation(projekt, location);
			sendToEventStore(system, {
				type: "NAVIGATE.PARSED",
				payload: { projektId, modellPath, location },
			});
		},
		sendTreeStateUpdate: ({ event, system }) => {
			assertEvent(event, "LOCATION_PARSED");
			const { projektId, modellPath } = event;
			const projekt = getModellierungProjektFromSystem(system, projektId);
			if (!projekt || !projektId || !modellPath) return;
			const {
				bausteinPath,
				structurePath,
				fullPath,
				fullBausteinPath,
				modellPath: normalizedModellPath,
			} = getTreePathVariants(projekt.modell, modellPath);
			if (!normalizedModellPath.length) return;
			sendToEventStore(system, {
				type: "MODELLIERUNG_TREE.STATE.ACTIVATE",
				payload: {
					projektId,
					bausteinPath,
					structurePath,
					fullPath,
					fullBausteinPath,
				},
			});
		},
		focusTreePath: ({ event, system }) => {
			assertEvent(event, "FOCUS_TREE_PATH");
			const { fullPath, projektId } = event;
			const { navigate } = getEffectApi(system);
			const projekt = getModellierungProjektFromSystem(system, projektId);
			if (!projekt) return;
			const href = createNodeTargetHref(projekt, fullPath);
			navigate(href);
		},
		tryParseProjektFromLink: async ({ system }) => {
			const eventLog = getEventLogFromSystem(system);
			const { requestProjektList } = getEffectApi(system);
			const lastEntry = eventLog.last();
			if (eventLog.size !== 1 || lastEntry?.event.type !== "NAVIGATE") {
				return;
			}
			const { event } = lastEntry;
			const idSegment = parseIdSegmentFromLocation(event.payload.location);
			const projektList = await requestProjektList();
			const projekt = projektList.find((p) => p.kennung === idSegment);
			if (!projekt) return;
			sendToEventStore(system, {
				type: "PROJECT.OPEN_EXISTING",
				payload: {
					projektMeta: {
						dbId: projekt.id,
						kennung: projekt.kennung,
						projektType: projekt.typ,
					},
					initialLocation: event.payload.location,
				},
			});
		},
	},
}).createMachine({
	id: "navigation:command",
	entry: "tryParseProjektFromLink",
	invoke: { src: "translateEvents" },
	on: {
		GOTO_HOME: { actions: "navigateToHome" },
		OPEN_PROJECT_TAB: { actions: "openProjectTab" },
		ROTATE_TAB: { actions: "rotateTab" },
		GOTO_NTH_TAB: { actions: "navigateToNthTab" },
		GOTO_TAB: { actions: "navigateToTab" },
		LOCATION_CHANGE: { actions: "sendNavigationUpdate" },
		LOCATION_PARSED: { actions: "sendTreeStateUpdate" },
		FOCUS_TREE_PATH: { actions: "focusTreePath" },
	},
});

export default navigationCommandMachine;
