import type {
	LiteNode,
	RawLiteBaustein,
	RawLiteEigenschaft,
	RawLiteModel,
	RawLitePaket,
} from "../../modellierungModel/schemas";
import {
	LiteNodeType,
	LitePaketType,
	NodeTypeAssertionError,
} from "../../modellierungModel/schemas";

export type RawLiteNode =
	| RawLiteModel
	| RawLitePaket
	| RawLiteBaustein
	| RawLiteEigenschaft;

export function isRawLiteModel(node: RawLiteNode): node is RawLiteModel {
	return "externeModelle" in node;
}
export function isRawLitePaket(node: RawLiteNode): node is RawLitePaket {
	return (
		"typ" in node &&
		(node.typ === LitePaketType.Schema || node.typ === LitePaketType.Simple)
	);
}
export function isRawLiteEigenschaft(
	node: RawLiteNode,
): node is RawLiteEigenschaft {
	return "typ" in node && node.typ === "EIGENSCHAFT";
}
export function isRawLiteBaustein(node: RawLiteNode): node is RawLiteBaustein {
	return (
		"typ" in node &&
		!isRawLiteEigenschaft(node) &&
		!isRawLitePaket(node) &&
		!isRawLiteModel(node)
	);
}

export function assertRawLiteModel(
	node: RawLiteNode,
): asserts node is RawLiteModel {
	if (!isRawLiteModel(node)) {
		throw new NodeTypeAssertionError(node as LiteNode, LiteNodeType.Model);
	}
}
export function assertRawLitePaket(
	node: RawLiteNode,
): asserts node is RawLitePaket {
	if (!isRawLitePaket(node)) {
		throw new NodeTypeAssertionError(node as LiteNode, LiteNodeType.Paket);
	}
}
export function assertRawLiteBaustein(
	node: RawLiteNode,
): asserts node is RawLiteBaustein {
	if (!isRawLiteBaustein) {
		throw new NodeTypeAssertionError(node as LiteNode, LiteNodeType.Baustein);
	}
}
export function assertRawLiteEigenschaft(
	node: RawLiteNode,
): asserts node is RawLiteEigenschaft {
	if (!isRawLiteEigenschaft(node)) {
		throw new NodeTypeAssertionError(
			node as LiteNode,
			LiteNodeType.Eigenschaft,
		);
	}
}

export const getModelChildren = (node: RawLiteModel) => node.externeModelle;
export const getPaketChildren = (node: RawLitePaket) => node.pakete;

export const EMPTY_RAW_EIGENSCHAFTEN: RawLiteEigenschaft[] = [];
export const getEigenschaftChildren = (node: RawLiteEigenschaft) =>
	node.eigenschaften ?? EMPTY_RAW_EIGENSCHAFTEN;

export const getRawChildren = (node: RawLiteNode) => {
	if (isRawLiteModel(node)) {
		return [...node.externeModelle, ...node.pakete];
	}
	if (isRawLitePaket(node)) {
		return [...node.pakete, ...node.bausteine];
	}
	return node.eigenschaften || [];
};
