import { MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useGridApiContext } from "@mui/x-data-grid";
import type { SetStateAction } from "react";
import type { MessageType } from "../MessageOverview/types";

const MessageEditMenuItem = ({
	message,
	onActivate,
	enableEdit,
	setEnableEdit,
}: {
	message: MessageType;
	enableEdit?: string;
	onActivate: () => void;
	setEnableEdit: React.Dispatch<SetStateAction<string>>;
}): JSX.Element => {
	const apiRef = useGridApiContext();

	const handleEditFileName = () => {
		// close open input field
		if (enableEdit) {
			apiRef.current.setCellMode(enableEdit, "filename", "view");
		}

		apiRef.current.setEditCellValue({
			id: message.id,
			field: "filename",
			value: message.name,
		});
		setEnableEdit(message.id);
		onActivate();
	};

	return (
		<MenuItem onClick={handleEditFileName} data-testid="edit-message">
			<ListItemIcon>
				<EditIcon />
			</ListItemIcon>
			<ListItemText>Dateinamen ändern</ListItemText>
		</MenuItem>
	);
};

export default MessageEditMenuItem;
