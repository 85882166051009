import { useCallback, useEffect } from "react";
import AdocRenderer from "../AdocRenderer";
import { ResponseDecodingMode, useResource } from "../Api";
import NotFound from "../NotFound";
import RequestErrorNotification from "../RequestErrorNotification";

const AdocView = ({
	doc,
	className,
}: {
	doc: string;
	className?: string;
}): JSX.Element => {
	const { data, request, status, error } = useResource<string>();

	const requestDoc = useCallback(() => {
		if (doc) {
			const docFile = doc.endsWith(".adoc") ? doc : `${doc}.adoc`;
			request(`/dokumentation/${docFile}`, {
				expect: ResponseDecodingMode.Text,
			});
		}
	}, [doc, request]);

	useEffect(() => {
		requestDoc();
	}, [requestDoc]);

	return (
		<>
			{error?.status === 404 ? (
				<NotFound />
			) : (
				<RequestErrorNotification
					id={`${doc}-request`}
					error={error}
					status={status}
					onRetry={requestDoc}
				>
					Fehler beim Abrufen der Seite
				</RequestErrorNotification>
			)}
			{data && <AdocRenderer className={className} markup={data} />}
		</>
	);
};

export default AdocView;
