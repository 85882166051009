import classNames from "classnames";
import React from "react";
import type { HeaderProps } from "./types";
import HeaderHeading from "./HeaderHeading";
import HeaderNav from "./HeaderNav";
import HeaderLogo from "./HeaderLogo";
import HeaderNavList from "./HeaderNavList";
import styles from "./Header.module.scss";

export default function Header({
	children,
	className,
	...props
}: HeaderProps): JSX.Element {
	return (
		<header className={classNames(styles.menuHeader, className)} {...props}>
			{children}
		</header>
	);
}

Header.Logo = HeaderLogo;
Header.Heading = HeaderHeading;
Header.Nav = HeaderNav;
Header.NavList = HeaderNavList;
