import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

/**
 * Store a local state, that automatically updates, when its initial value
 * changes. This is useful when a component can change its own state, but
 * also needs to change the state when a prop changes.
 */
const useSyncedState: <S>(
	initialState: S | (() => S),
) => [S, Dispatch<SetStateAction<S>>] = (value) => {
	const [state, setState] = useState(value);

	useEffect(() => {
		setState(value);
	}, [value]);

	return [state, setState];
};

export default useSyncedState;
