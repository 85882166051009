import type { Context, ReactNode } from "react";
import { useMemo } from "react";
import type { SessionContextValue } from "../Session/types";
import ApiContext from "./ApiContext";
import appFetch from "./fetch";

const ApiProvider = ({
	origin = "",
	base = "/",
	children = null,
	sessionContext,
}: {
	origin?: string;
	base?: string;
	children?: ReactNode;
	sessionContext: Context<SessionContextValue>;
}): JSX.Element => {
	const ctx = useMemo(
		() => ({ origin, base, fetch: appFetch, sessionContext }),
		[base, origin, sessionContext],
	);
	return <ApiContext.Provider value={ctx}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
