import { useStateSelector } from "../../EditorState";
import { selectHasActiveProject } from "../../EditorState/selectors";
import SubMenu from "../SubMenu";

function ProfilingSubMenu(): JSX.Element {
	const hasActiveProject = useStateSelector(selectHasActiveProject());

	const isDisabled = !hasActiveProject;

	return (
		<SubMenu label="Unternavigation der Profilierung">
			<SubMenu.Home
				to="/profilierung"
				label="Startseite der Profilierung"
				data-testid="home-profilierung"
			/>
			<SubMenu.Item
				to="/profilierung/projekt"
				data-testid="project"
				end={false}
				isDisabled={isDisabled}
			>
				Projekt
			</SubMenu.Item>
			<SubMenu.Item
				to="/profilierung/datentypen"
				data-testid="datatypes"
				end={false}
				isDisabled={isDisabled}
			>
				Datentypen
			</SubMenu.Item>
			<SubMenu.Item
				to="/profilierung/nachrichtenstrukturen"
				data-testid="message-structure"
				end={false}
				isDisabled={isDisabled}
			>
				Nachrichtenstrukturen
			</SubMenu.Item>
			<SubMenu.Item
				to="/profilierung/dokumentation"
				data-testid="documentation"
				end={false}
				isDisabled={isDisabled}
			>
				{/*
					Saving on this menu item is disabled, because the
					GeneratedChapterProvider will handle the save in this case.
				*/}
				Dokumentation
			</SubMenu.Item>
		</SubMenu>
	);
}

export default ProfilingSubMenu;
