import type { TextFieldProps } from "@mui/material";
import { IconButton, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useState, useEffect } from "react";
import { useGetStandardsQuery } from "../../../../redux/apiSlice";
import type { BreadcrumbItem } from "../../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import NavBarBreadcrumbs from "../../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import ReportButton from "../../MessageOverview/ReportButton";
import type { MessageType } from "../../MessageOverview/types";
import {
	CacheKeys,
	useUpdateMessageMutation,
} from "../../../../redux/messagesApiSlice";
import "./MessageOptions.scss";
import OutsideClick from "../../../ui/OutsideClick/OutsideClick";

const MessageOptions = ({ message }: { message: MessageType }): JSX.Element => {
	const { data: standards } = useGetStandardsQuery();
	const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
	const [updateMessage] = useUpdateMessageMutation({
		fixedCacheKey: CacheKeys.MessageUpdate,
	});
	const [value, setValue] = useState(message.name);
	const [isEditEnabled, setEnableEdit] = useState(false);
	const messageStandard = standards?.find(
		(standard) => standard.kennung === message.standard,
	);

	// Focus the input after we open it, so we can immediately start typing
	useEffect(() => {
		if (inputRef) {
			try {
				inputRef.focus();
				inputRef.select();
			} catch {
				// When focusing or selecting does not work, we'll just ignore it...
			}
		}
	}, [inputRef]);

	const handleUpdateMessage = () => {
		if (value !== message.name) {
			const newMessage = { ...message, name: value };
			updateMessage({ message: newMessage });
			setEnableEdit((prevState) => !prevState);
		}
	};

	const closeEdit = () => {
		setValue(message.name);
		setEnableEdit(false);
	};

	const handleKeyDown: TextFieldProps["onKeyDown"] = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			handleUpdateMessage();
		}
		if (e.key === "Escape") {
			closeEdit();
		}
	};
	const handleChange: TextFieldProps["onChange"] = (e) => {
		setValue(e.target.value);
	};

	const messageBreadcrumb = isEditEnabled ? (
		<>
			<OutsideClick onClickOutside={closeEdit}>
				<TextField
					value={value}
					inputRef={setInputRef}
					data-testid="update-message-input"
					onKeyDown={handleKeyDown}
					onChange={handleChange}
				/>
				<IconButton
					data-testid="update-message-button"
					onClick={handleUpdateMessage}
				>
					<SaveIcon />
				</IconButton>
			</OutsideClick>
		</>
	) : (
		message.name
	);
	const breadcrumbs: BreadcrumbItem[] = [
		{
			key: "__STANDARD__",
			content: `${messageStandard?.nameKurz} ${messageStandard?.version}`,
		},
		{
			key: "__MESSAGE__",
			content: messageBreadcrumb,
		},
	];
	return (
		<>
			<NavBarBreadcrumbs items={breadcrumbs} />
			<div className="message-options__report">
				<ReportButton
					message={message}
					standard={message.standard}
					setEnableSingleEdit={setEnableEdit}
				/>
			</div>
		</>
	);
};

export default MessageOptions;
