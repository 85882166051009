import { NestedMenuItem } from "mui-nested-menu";
import type { ReactNode } from "react";
import "./NestedMenu.scss";

export default function NestedMenu({
	children,
	icon,
	label,
	parentMenuOpen,
}: {
	children: ReactNode;
	icon: ReactNode;
	label: string;
	parentMenuOpen: boolean;
}): JSX.Element {
	return (
		<NestedMenuItem
			className="nested-menu"
			leftIcon={icon}
			label={label}
			parentMenuOpen={parentMenuOpen}
		>
			{children}
		</NestedMenuItem>
	);
}
