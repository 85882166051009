import classNames from "classnames";
import { useHtmlId } from "../../hooks";
import { Select } from "../ui";
import type { EditFormFieldProps } from "./types";
import EditFormFieldLabel from "./EditFormFieldLabel";
import useEditorField from "./useEditorField";
import "./EditFormField.scss";

const EditFormField = ({
	definition: { name, label, type, options, isRequired, readOnly },
	value: initialValue,
	error,
	onBlur,
	onChange,
	onFocus,
	onKeyDown,
	placeholder,
	className,
	labelClassName,
	inputClassName,
	errorClassName,
	inputProps,
	labelProps,
	"aria-describedby": ariaDescribedBy,
}: EditFormFieldProps): JSX.Element => {
	const id = useHtmlId();
	const errorId = useHtmlId();

	const { handleBlur, handleChange, value } = useEditorField({
		value: (initialValue || "") as string,
		name,
		onChange(_name, nextValue) {
			if (onChange) {
				onChange({ target: { value: nextValue, name } });
			}
		},
		onWrite(_name, nextValue) {
			if (onBlur) {
				onBlur(name, nextValue);
			}
		},
	});

	const field = {
		id,
		name,
		value,
		readOnly,
		"aria-describedby": ariaDescribedBy || (error ? errorId : undefined),
		onBlur: handleBlur,
		onFocus,
		onChange: handleChange,
	};
	return (
		<>
			<EditFormFieldLabel
				text={label}
				htmlFor={id}
				className={className}
				labelTextClassName={labelClassName}
				isRequired={isRequired}
				labelTextProps={labelProps}
				data-testid="edit-form-field"
			>
				{type === "textarea" && (
					<textarea
						{...field}
						{...inputProps}
						className={classNames(
							"edit-form-field__input edit-form-field__input--textarea",
							inputClassName,
						)}
						placeholder={placeholder || ""}
						aria-required={isRequired}
						data-field-id={name}
						onKeyDown={onKeyDown}
						rows={3}
					/>
				)}
				{(!type || type === "input") && (
					<input
						{...field}
						{...inputProps}
						className={classNames(
							"edit-form-field__input edit-form-field__input--input",
							inputClassName,
						)}
						type="text"
						placeholder={placeholder || ""}
						aria-required={isRequired}
						data-field-id={name}
						onKeyDown={onKeyDown}
					/>
				)}
				{type === "select" && (
					<Select
						className={classNames(
							"edit-form-field__input edit-form-field__input--select",
							inputClassName,
						)}
						label={label}
						isRequired={isRequired}
						data-field-id={name}
						{...field}
						{...inputProps}
						disabled={field.readOnly}
					>
						<Select.Button placeholder={label}>
							<Select.Arrow />
						</Select.Button>
						<Select.List>
							{options?.map(
								({ value: optValue, label: optLabel, ...optionProps }) => (
									<Select.Option
										{...optionProps}
										key={optValue}
										value={optValue}
									>
										{optLabel}
									</Select.Option>
								),
							)}
						</Select.List>
					</Select>
				)}
			</EditFormFieldLabel>
			{error && (
				<div
					id={errorId}
					className={classNames("edit-form-field__error", errorClassName)}
				>
					{error}
				</div>
			)}
		</>
	);
};

export default EditFormField;
