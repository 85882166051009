import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import type { TreeStateQueryActorRef } from "./treeState.query.machine";
import treeStateQueryMachine from "./treeState.query.machine";
import treeStateCommandMachine from "./treeState.command.machine";
import { createCommandId, createQueryId } from "./helpers";
import type { ProjektMeta } from "../project/types";

// eslint-disable-next-line no-use-before-define
export type TreeStateActorRef = ActorRefFrom<typeof treeStateMachine>;

const treeStateMachine = setup({
	types: {
		context: {} as ProjektMeta & { query: TreeStateQueryActorRef },
		input: {} as ProjektMeta,
	},
	actors: {
		query: treeStateQueryMachine,
		command: treeStateCommandMachine,
	},
}).createMachine({
	id: "treeState",
	context: ({ input, spawn }) => ({
		...input,
		query: spawn("query", { systemId: createQueryId(input.projektId), input }),
		command: spawn("command", {
			systemId: createCommandId(input.projektId),
			input,
		}),
	}),
});

export default treeStateMachine;
