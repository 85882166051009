import EditorMain from "../EditorMain";
import ConfigurationEditForm from "./ConfigurationEditForm/ConfigurationEditForm";
import ConfigurationNavBar from "./ConfigurationNavBar";
import "./ConfigurationView.scss";

const ConfigurationView = (): JSX.Element => {
	return (
		<>
			<ConfigurationNavBar />
			<EditorMain
				className="configuration-view"
				data-testid="configuration-main"
			>
				<ConfigurationEditForm />
			</EditorMain>
		</>
	);
};

export default ConfigurationView;
