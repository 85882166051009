import { createContext } from "react";
import { emptyValidationResults } from "./helpers";
import type { ValidationContextType } from "./types";
import { ValidationStatus } from "./types";

const ValidationContext = createContext<ValidationContextType>({
	result: emptyValidationResults,
	status: ValidationStatus.Idle,
});

export default ValidationContext;
