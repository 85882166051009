import type { SwitchProps } from "@mui/material";
import { FormControlLabel, Switch } from "@mui/material";
import "./ProfiledOnlyFilter.scss";

type ProfiledOnlyFilterProps = Pick<SwitchProps, "checked" | "onChange">;

function ProfiledOnlyFilter({
	checked,
	onChange,
}: ProfiledOnlyFilterProps): JSX.Element {
	return (
		<FormControlLabel
			classes={{ label: "profiled-only-filter__switch-label" }}
			className="profiled-only-filter__switch-wrapper"
			control={
				<Switch
					checked={checked}
					onChange={onChange}
					data-testid="filter-profiled-only"
					className="profiled-only-filter__button"
				/>
			}
			label="Profilierte Elemente anzeigen"
		/>
	);
}

export default ProfiledOnlyFilter;
