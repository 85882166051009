import { filterFalsy, toGenerator } from "./generator";
import type { Falsy, ToUnion, UnionToIntersection } from "./types";

type ToRecord<T> = { [K in keyof T]: T[K] };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtraProps<Props extends (Falsy | Record<string, any>)[]> = Partial<
	ToRecord<UnionToIntersection<Exclude<ToUnion<Props>, Falsy>>>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extraProps<Props extends (Falsy | Record<string, any>)[]>(
	...props: Props
): ExtraProps<Props> {
	return Object.assign({}, ...filterFalsy(toGenerator(props)));
}
