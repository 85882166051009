import { useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useDescendant } from "../util/descendants";
import type { TabProps } from "./types";
import { TabContext, TabDescendantsContext } from "./TabContexts";
import useSyncedRef from "../../../hooks/useSyncedRef";

const Tab = ({
	className = "",
	wrapperClassName = "",
	activeClassName = "",
	closeButtonClassName = "",
	children,
	onClose,
	onActivate,
	...props
}: TabProps) => {
	const { id, setRef } = useDescendant(TabDescendantsContext);
	const { tabs, panels, activeTab, setActiveIndex, orientation } =
		useContext(TabContext);

	const onActivateRef = useSyncedRef(onActivate);

	const isSelected = activeTab?.id === id;
	const ariaSelected = isSelected ? "true" : "false";
	const tabIndex = isSelected ? 0 : -1;

	const index = useMemo(
		() => tabs.findIndex((tab) => tab.id === id),
		[id, tabs],
	);

	const panelId = panels[index]?.id;

	useEffect(() => {
		if (isSelected && onActivateRef.current) {
			onActivateRef.current();
		}
	}, [isSelected, onActivateRef]);

	const handleClick = () => setActiveIndex(index);

	const handleClose = () => {
		setActiveIndex(0);
		if (onClose) {
			onClose();
		}
	};

	return (
		<div
			className={classNames(
				"xui-tab__wrapper",
				`xui-tab__wrapper--orientation-${orientation}`,
				isSelected && "xui-tab__wrapper--active",
				isSelected && activeClassName,
				wrapperClassName,
			)}
		>
			<button
				id={id}
				ref={setRef}
				className={classNames(
					"xui-tab",
					`xui-tab--orientation-${orientation}`,
					isSelected && "xui-tab--active",
					isSelected && activeClassName,
					className,
				)}
				type="button"
				role="tab"
				aria-controls={panelId}
				aria-selected={ariaSelected}
				tabIndex={tabIndex}
				onClick={handleClick}
				data-xui-component="tabs"
				data-xui-element="tab"
				{...props}
			>
				{children}
			</button>
			{onClose && (
				<IconButton
					className={classNames("xui-tab__close-button", closeButtonClassName)}
					size="small"
					disableRipple
					onClick={handleClose}
					aria-label="Tab schließen"
					data-testid="xui-tab-close"
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
		</div>
	);
};

export default Tab;
