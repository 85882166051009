import type { ReactNode } from "react";
import { useStateSelector } from "../EditorState";
import { selectStandard, selectState } from "../EditorState/selectors";
import { VisuallyHidden } from "../ui";
import useMainValidators from "./useMainValidators";
import useValidators from "./useValidators";
import ValidationContext from "./ValidationContext";
import { useAppSelector } from "../../redux/hooks";
import { selectModellContainer } from "../../redux/treeSlice";

const Validation = ({ children }: { children: ReactNode }): JSX.Element => {
	const state = useStateSelector(selectState());
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	const validators = useMainValidators(state, modell);
	const validationCtx = useValidators(validators);

	return (
		<ValidationContext.Provider value={validationCtx}>
			<VisuallyHidden>
				<span
					data-testid="validation-status"
					data-status={validationCtx.status}
					aria-hidden
				/>
			</VisuallyHidden>
			{children}
		</ValidationContext.Provider>
	);
};

export default Validation;
