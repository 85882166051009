import type { ReactNode } from "react";
import EditorDataProvider from "../EditorData";
import EditorSavePointProvider from "../EditorSavePoints/EditorSavePointProvider";
import EditorStateProvider from "../EditorState";
import Validation from "../Validation";

function EditorProvider({ children }: { children: ReactNode }): JSX.Element {
	return (
		<EditorStateProvider>
			<EditorSavePointProvider>
				<EditorDataProvider>
					<Validation>{children}</Validation>
				</EditorDataProvider>
			</EditorSavePointProvider>
		</EditorStateProvider>
	);
}

export default EditorProvider;
