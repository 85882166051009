import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

const STORAGE_KEY = "xoev-suite-api-origin";
const SESSION_KEY = uuid();

interface OriginPackage {
	value: string;
	sessionKey?: string;
}

const parseOriginPackage = (
	originJSON: string | null,
): OriginPackage | null => {
	if (!originJSON) return null;
	try {
		const pref: OriginPackage = JSON.parse(originJSON);
		return pref;
	} catch {
		return null;
	}
};

const getStoredOrigin = (): string => {
	const storedPref = localStorage.getItem(STORAGE_KEY);
	if (!storedPref) return "";
	const originPackage = parseOriginPackage(storedPref);
	if (!originPackage) return "";
	const { value } = originPackage;
	return value;
};

const setStoredOrigin = (origin: string) => {
	const originPackage = { value: origin, sessionKey: SESSION_KEY };
	localStorage.setItem(STORAGE_KEY, JSON.stringify(originPackage));
};

function useApiOrigin(): string {
	const [origin, setOrigin] = useState<string>(getStoredOrigin);

	useEffect(() => {
		const storageHandler = (e: StorageEvent) => {
			const originPackage = parseOriginPackage(e.newValue);
			if (originPackage?.sessionKey === SESSION_KEY) return;
			const nextOrigin = originPackage?.value;
			if (nextOrigin && nextOrigin !== origin) {
				setOrigin(nextOrigin);
			}
		};

		window.addEventListener("storage", storageHandler);
		return () => window.removeEventListener("storage", storageHandler);
	}, [origin]);

	useEffect(() => {
		// We'll only be able to access this global in development mode.
		// It will be stripped from the production bundle
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).xoevSuiteApi = {
			setOrigin(nextOrigin: string) {
				setOrigin(nextOrigin);
				setStoredOrigin(nextOrigin);
			},
			clearOrigin() {
				setOrigin("");
				setStoredOrigin("");
			},
		};
		return () => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			delete (window as any).xoevSuiteApi;
		};
	}, []);

	return origin;
}

export default useApiOrigin;
