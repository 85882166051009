import { createContext } from "react";
import type { PopoverMenuContextType } from "./types";

const PopoverMenuContext = createContext<PopoverMenuContextType>(
	// The context must be provided by an instance of PopoverMenu.
	// If this is not the case the app should fail early when the child
	// components try to access the context
	undefined as never,
);

export default PopoverMenuContext;
