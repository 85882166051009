import { useContext, useLayoutEffect } from "react";
import classNames from "classnames";
import { SelectContext } from "./SelectContext";
import PopoverMenu from "../PopoverMenu";
import PopoverMenuContext from "../PopoverMenu/PopoverMenuContext";
import type { SelectListProps } from "./types";
import { normObject } from "../util";
import "./SelectList.scss";

const SelectList = ({
	children,
	className,
	activeClassName,
	...props
}: SelectListProps): JSX.Element => {
	const { labelId, isOpen, activeItem, isRequired } = useContext(SelectContext);
	const { contentRef, position } = useContext(PopoverMenuContext);

	useLayoutEffect(() => {
		if (isOpen) {
			contentRef.current?.focus();
		}
	}, [contentRef, isOpen]);

	return (
		<PopoverMenu.Content
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			as="ul"
			className={classNames(
				"xui-select-list",
				`xui-select-list--position-${position.replace(" ", "-")}`,
				className,
			)}
			activeClassName={classNames("xui-select-list--open", activeClassName)}
			role="listbox"
			aria-labelledby={labelId}
			aria-activedescendant={activeItem?.id}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...normObject(isRequired, { "aria-required": true })}
		>
			{children}
		</PopoverMenu.Content>
	);
};

export default SelectList;
