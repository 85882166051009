import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useSyncedRef from "./useSyncedRef";

/**
 * React Router v6 has the "bug" (or feature) of re-rendering useEffect everytime
 * because `useNavigate` gives a different function everytime the URL changes.
 * This was different in v5 with the equivalent `useHistory` hook.
 *
 * See https://github.com/remix-run/react-router/issues/7634 for details.
 * When this ticket has been resolved we might be able to remove this workaround.
 */
const useStableNavigate: typeof useNavigate = () => {
	const navigate = useNavigate();
	const navigateRef = useSyncedRef(navigate);
	const stableNavigate = useCallback(
		(...args) => {
			navigateRef.current(
				...(args as Parameters<ReturnType<typeof useNavigate>>),
			);
		},
		[navigateRef],
	);
	return stableNavigate;
};

export default useStableNavigate;
