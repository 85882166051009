import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export type DebugState = {
	isDetailsDebugEnabled: boolean;
	isLoggingEnabled: boolean;
};

const initialState: DebugState = {
	isDetailsDebugEnabled: false,
	isLoggingEnabled: false,
};

const debugSlice = createSlice({
	name: "debug",
	initialState,
	reducers: {
		toggleLogging(state) {
			return { ...state, isLoggingEnabled: !state.isLoggingEnabled };
		},
		toggleDetailsDebug(state) {
			return { ...state, isDetailsDebugEnabled: !state.isDetailsDebugEnabled };
		},
	},
});

export const selectIsLoggingEnabled = (state: RootState) =>
	state.debug.isLoggingEnabled;

export const selectIsDetailsDebugEnabled = (state: RootState) =>
	state.debug.isDetailsDebugEnabled;

export const { toggleDetailsDebug, toggleLogging } = debugSlice.actions;

export default debugSlice.reducer;
