import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import projectsQueryMachine from "./projects.query.machine";
import projectsCommandMachine from "./projects.command.machine";

// eslint-disable-next-line no-use-before-define
export type ProjectsActorRef = ActorRefFrom<typeof projectsMachine>;

const projectsMachine = setup({
	types: {
		children: {} as {
			"projects:query": "query";
			"projects:command": "command";
		},
	},
	actors: { query: projectsQueryMachine, command: projectsCommandMachine },
}).createMachine({
	id: "projects",
	invoke: [
		{ src: "query", systemId: "projects:query", id: "projects:query" },
		{ src: "command", systemId: "projects:command", id: "projects:command" },
	],
});

export default projectsMachine;
