import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export type SelectedTab = "edit" | "preview";

export type DocumentationState = {
	isFullPreview: boolean;
	selectedTab: SelectedTab;
};

const initialState: DocumentationState = {
	isFullPreview: true,
	selectedTab: "edit",
};

const documentationSlice = createSlice({
	name: "documentation",
	initialState,
	reducers: {
		toggleIsFullPreview(state) {
			return { ...state, isFullPreview: !state.isFullPreview };
		},
		setSelectedTab(state, action: PayloadAction<{ newTab: SelectedTab }>) {
			const { newTab } = action.payload;
			return { ...state, selectedTab: newTab };
		},
	},
});

export const selectIsFullPreview = () => (state: RootState) =>
	state.documentation.isFullPreview;

export const selectSelectedTab = () => (state: RootState) =>
	state.documentation.selectedTab;

export const { toggleIsFullPreview, setSelectedTab } =
	documentationSlice.actions;

export default documentationSlice.reducer;
