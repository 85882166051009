// CoreJS does not provide type definitions, so we need to shim them in `react-app-env.d.ts`
import objectFromEntries from "core-js-pure/features/object/from-entries";
import arrayAt from "core-js-pure/features/array/at";
import arrayFindLast from "core-js-pure/features/array/find-last";
import arrayFindLastIndex from "core-js-pure/features/array/find-last-index";
import arrayToReversed from "core-js-pure/features/array/to-reversed";
import arrayToSorted from "core-js-pure/features/array/to-sorted";
import arrayToSpliced from "core-js-pure/features/array/to-spliced";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function bindToPrototype(proto: any, name: string, fn: any) {
	// eslint-disable-next-line no-param-reassign, func-names, @typescript-eslint/no-explicit-any
	proto[name] = function (...args: any[]) {
		return fn(this, ...args);
	};
}

// Since we don't target IE or legacy edge, we need next to no polyfills 🎉
if (typeof Object.fromEntries !== "function") {
	Object.fromEntries = objectFromEntries;
}
if (typeof Array.prototype.at !== "function") {
	bindToPrototype(Array.prototype, "at", arrayAt);
}
if (typeof Array.prototype.findLast !== "function") {
	bindToPrototype(Array.prototype, "findLast", arrayFindLast);
}
if (typeof Array.prototype.findLastIndex !== "function") {
	bindToPrototype(Array.prototype, "findLastIndex", arrayFindLastIndex);
}
if (typeof Array.prototype.toReversed !== "function") {
	bindToPrototype(Array.prototype, "toReversed", arrayToReversed);
}
if (typeof Array.prototype.toSorted !== "function") {
	bindToPrototype(Array.prototype, "toSorted", arrayToSorted);
}
if (typeof Array.prototype.toSpliced !== "function") {
	bindToPrototype(Array.prototype, "toSpliced", arrayToSpliced);
}

export {};
