import EditorMain from "../../../EditorMain";
import { LRSplit } from "../../../ui";
import useActiveDataType from "../../useActiveDataType";
import StandardTypeDetailsView from "./StandardTypeDetailsView";
import StandardTypeList from "./StandardTypeList";

const StandardTypeView = (): JSX.Element => {
	// Check if the node exists here, so we can still render properly use hooks
	// in the child components
	const { activeDataType } = useActiveDataType();
	if (!activeDataType) return <></>;

	return (
		<EditorMain>
			<LRSplit>
				<LRSplit.Left>
					<StandardTypeList />
				</LRSplit.Left>
				<LRSplit.Right>
					<StandardTypeDetailsView />
				</LRSplit.Right>
			</LRSplit>
		</EditorMain>
	);
};

export default StandardTypeView;
