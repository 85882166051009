import type { Context, ReactNode } from "react";
import { useMemo, useState } from "react";
import type { SessionContextValue, SessionResponse } from "./types";

const SessionProvider = ({
	children,
	context: Ctx,
}: {
	context: Context<SessionContextValue>;
	children: ReactNode;
}): JSX.Element => {
	const [session, setSession] = useState<SessionResponse | null>(null);
	const ctx = useMemo(() => ({ session, setSession }), [session, setSession]);
	return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>;
};

export default SessionProvider;
