import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Tooltip } from "@mui/material";
import { GlobalSnackbarItem } from "../../../GlobalSnackbar";
import { useAlerts } from "./hooks";
import { useSendStoreEvent } from "../../EventStore/hooks";

export default function NotificationCenter(): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const alerts = useAlerts();

	const createHandleClose = (id: string) => () => {
		sendStoreEvent({ type: "NOTIFICATION.ALERT.REMOVE", payload: { id } });
	};

	return (
		<>
			{alerts.map((alert) => (
				<GlobalSnackbarItem id={alert.id} key={alert.id}>
					<Alert
						severity={alert.severity}
						action={
							<>
								<Tooltip title="Schließen">
									<IconButton
										color={alert.severity}
										size="small"
										aria-label="Schließen"
										onClick={createHandleClose(alert.id)}
									>
										<CloseIcon fontSize="small" fill="currentColor" />
									</IconButton>
								</Tooltip>
							</>
						}
					>
						{alert.text}
					</Alert>
				</GlobalSnackbarItem>
			))}
		</>
	);
}
