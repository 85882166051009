import React from "react";
import classNames from "classnames";
import type { AnyComponent } from "../util";
import VisuallyHidden from "../VisuallyHidden";
import type { HeaderLogoProps } from "./types";
import styles from "./HeaderLogo.module.scss";

export default function HeaderLogo<TComponent extends AnyComponent = "a">({
	as: As,
	children,
	label,
	className,
	...props
}: HeaderLogoProps<TComponent>): JSX.Element {
	const Comp = As || "a";
	return (
		<Comp className={classNames(styles.logo, className)} {...props}>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</Comp>
	);
}
