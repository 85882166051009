import { createContext } from "react";
import { RequestStatus } from "../../Api";
import type { GeneratedChapterContextType } from "./types";

const GeneratedChapterContext = createContext<GeneratedChapterContextType>({
	chapters: {},
	status: RequestStatus.Idle,
	error: null,
});

export default GeneratedChapterContext;
