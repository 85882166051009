import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";
import type { SearchTrie } from "./types";
import { RequestStatus } from "../../Api";
import SearchNav from "../../SearchNav";

function StructureTreeSearchInfo({
	trie,
	treeStatus,
	activePath,
	focusPrevious,
	focusNext,
	setCurrentIndex,
	id,
}: {
	trie: SearchTrie;
	treeStatus: RequestStatus;
	activePath: LiteId[];
	focusPrevious: () => void;
	focusNext: () => void;
	setCurrentIndex: (index: number) => void;
	id: string;
}): JSX.Element {
	return (
		<SearchNav
			activePath={activePath || null}
			focusNext={focusNext}
			focusPrevious={focusPrevious}
			id={id}
			isLoading={treeStatus === RequestStatus.Loading}
			setCurrentIndex={setCurrentIndex}
			trie={trie}
		/>
	);
}

export default StructureTreeSearchInfo;
