import type { ReactNode } from "react";
import "./InfoRendererDisplay.scss";

const InfoRendererDisplay = ({
	label,
	value,
	fallback,
}: {
	label?: string;
	value?: string | ReactNode;
	fallback?: string | ReactNode;
}): JSX.Element => {
	return (
		<div className="info-renderer">
			{label && <div className="info-renderer__label">{label}</div>}
			{!!value && <div className="info-renderer__value">{value}</div>}
			{!value && !!fallback && (
				<em className="info-renderer__fallback">{fallback}</em>
			)}
		</div>
	);
};

export default InfoRendererDisplay;
