import type ErrorMessagesType from "../../../../../../types/ErrorMessages";

// eslint-disable-next-line import/prefer-default-export
export const cardinalityMsgSelector = (
	errorMessages?: ErrorMessagesType,
	nodeName?: string,
	lowerBound?: string,
	upperBound?: string,
): string => {
	if (errorMessages) {
		if (nodeName && lowerBound === "0" && upperBound === "0") {
			return errorMessages.cardinality.forced_empty.replace("{0}", nodeName);
		}
		if (
			nodeName &&
			lowerBound &&
			Number(lowerBound) > 0 &&
			Number.isNaN(Number(upperBound))
		) {
			return errorMessages.cardinality.minimum
				.replace("{0}", nodeName)
				.replace("{1}", lowerBound);
		}
		if (nodeName && lowerBound === "1" && upperBound === "1") {
			return errorMessages.cardinality.forced_required.replace("{0}", nodeName);
		}
		if (
			nodeName &&
			lowerBound &&
			Number(lowerBound) >= 0 &&
			!Number.isNaN(Number(upperBound)) &&
			upperBound &&
			Number(upperBound) > Number(lowerBound)
		) {
			return errorMessages.cardinality.range
				.replace("{0}", nodeName)
				.replace("{1}", lowerBound)
				.replace("{2}", upperBound);
		}
	}

	return "";
};
