import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
} from "@mui/material";
import type { ChangeEvent, SetStateAction } from "react";
import type { GridSelectionModel } from "@mui/x-data-grid";
import { useEditorData } from "../../../../EditorData";
import MessageSelection from "../../../../Configuration/MessageSelection";
import ModalHeader from "../../../../ui/Modal/ModalHeader";
import { useStateSelector } from "../../../../EditorState";
import { selectStandard } from "../../../../EditorState/selectors";
import {
	selectDocumentationConfgiuration,
	setDocumentationConfiguration,
} from "../../../../../redux/configuration/configurationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { QNameSchema } from "../../../../AppActor/actors/modellierungModel/schemas";
import "./ConfigurationDialog.scss";

const ConfigurationDialog = ({
	isConfigOpen,
	setIsConfigOpen,
}: {
	isConfigOpen: boolean;
	setIsConfigOpen: (value: SetStateAction<boolean>) => void;
}): JSX.Element => {
	const { saveToSession } = useEditorData();

	const configuration = useAppSelector(selectDocumentationConfgiuration());
	const dispatch = useAppDispatch();

	const standard = useStateSelector(selectStandard());

	const selectionModel = configuration.nachrichten || [];

	const handleSelectionChange = (newGridSelection: GridSelectionModel) => {
		dispatch(
			setDocumentationConfiguration({
				configuration: {
					nachrichten: QNameSchema.array().parse(newGridSelection),
				},
			}),
		);
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		dispatch(
			setDocumentationConfiguration({
				configuration: { nurVorhandeneElemente: checked },
			}),
		);
	};
	const handleClose = () => {
		saveToSession();
		setIsConfigOpen(false);
	};

	return (
		<Dialog
			className="configuration-dialog"
			data-testid="configuration-dialog"
			open={isConfigOpen}
			onClose={handleClose}
			maxWidth="md"
		>
			<ModalHeader
				className="configuration-dialog__title"
				title="Konfiguration der Dokumentation"
				handleClose={handleClose}
			/>
			<DialogContent>
				<FormGroup className="configuration-dialog__content">
					<FormControlLabel
						control={
							<Checkbox
								name="existingOnly"
								checked={configuration.nurVorhandeneElemente || false}
								onChange={handleChange}
							/>
						}
						label="Profilierte Elemente mit der Kardinalität 0 nicht in der Dokumentation berücksichtigen"
					/>
					{standard && (
						<MessageSelection
							standard={standard}
							headerTitle="Nachrichtenelemente für die Dokumentation auswählen"
							selectionModel={selectionModel}
							handleSelectionChange={handleSelectionChange}
						/>
					)}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfigurationDialog;
