import type { ReactNode } from "react";
import { createContext, useContext } from "react";

export interface GlobalSnackbarContextType {
	registerAlert(id: string, alert: ReactNode): void;
	unregisterAlert(id: string): void;
}

const GlobalSnackbarContext = createContext<GlobalSnackbarContextType | null>(
	null,
);

export function useGlobalSnackbar(): GlobalSnackbarContextType {
	const ctx = useContext(GlobalSnackbarContext);
	if (!ctx) {
		throw new Error(
			"Could not access GlobalSnackbarContext. Did you forget to wrap your " +
				"app in a `GlobalSnackbar` element?",
		);
	}
	return ctx;
}

export default GlobalSnackbarContext;
