import { useMemo } from "react";
import { useActiveProjectId } from "../../AppActor/actors/navigation/hooks";
import { useSendStoreEvent } from "../../AppActor/EventStore/hooks";
import { createConfigurationFormHelpers } from "./configurationFormHelpers";
import type { ModelKonfiguration } from "../../AppActor/actors/modellierungModel/schemas";
import { ModelKonfigurationSchema } from "../../AppActor/actors/modellierungModel/schemas";
import { useKonfiguration } from "../../AppActor/actors/modellierungModel/hooks";
import EditForm from "../../EditForm";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";

const ConfigurationEditForm = (): JSX.Element => {
	const projektId = useActiveProjectId();
	const configuration = useKonfiguration();
	const sendStoreEvent = useSendStoreEvent();
	const isReadOnly = useIsProjektDisplayOnly();

	const fields = useMemo(() => {
		const { visibleFields } = createConfigurationFormHelpers({
			readOnly: isReadOnly,
		});
		return visibleFields;
	}, [isReadOnly]);

	if (!projektId || configuration.isEmpty()) return <></>;

	const handleWrite = (
		name: keyof ModelKonfiguration,
		value: ModelKonfiguration[keyof ModelKonfiguration],
	) => {
		if (projektId) {
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId,
					patch: {
						type: "changeConfigurationValue",
						payload: {
							name,
							value: value ?? "",
						},
					},
				},
			});
		}
	};
	return (
		<div data-testid="project-configuration-form" data-project-id={projektId}>
			<EditForm<typeof ModelKonfigurationSchema>
				values={configuration}
				fields={fields}
				onWrite={handleWrite}
				schema={ModelKonfigurationSchema}
			/>
		</div>
	);
};

export default ConfigurationEditForm;
