import { useMemo } from "react";
import type { StandardProjekt } from "../../AppActor/actors/project/types";
import type { BreadcrumbItem } from "./NavBarBreadcrumbs";
import { LiteNodeType } from "../../AppActor/actors/modellierungModel/schemas";
import { createProjektBaseSegment } from "../../AppActor/actors/navigation/helpers";

export default function useProjectBreadcrumb(
	activeProject: StandardProjekt | null,
): BreadcrumbItem {
	const projectName = activeProject?.modell.nodes
		.get(LiteNodeType.Model)
		.get(activeProject.modell.rootModelId)?.name;
	return useMemo(
		() => ({
			key: "__PROJECT__",
			content: projectName ?? (
				<em data-testid="no-project-breadcrumb">Kein Projekt geöffnet</em>
			),
			to: projectName && `${createProjektBaseSegment(activeProject)}/metadaten`,
		}),
		[activeProject, projectName],
	);
}
