import { useCallback, useMemo, useState } from "react";
import type { FileError } from "react-dropzone";

interface DropzoneHelpers {
	selectedFiles: File[];
	rejectedFiles: File[];
	rejectedError: FileError[];
	hasSelectedFiles: boolean;
	getProps: () => {
		onDrop: (accepted: File[], rejected: File[], error: FileError[]) => void;
		multiple: boolean;
		accept: string | string[];
		hasSelectedFiles: boolean;
	};
	reset: () => void;
}

function useDropzone({
	multiple = false,
	accept,
}: {
	multiple?: boolean;
	accept: string | string[];
}): DropzoneHelpers {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
	const [rejectedError, setRejectedError] = useState<FileError[]>([]);

	const hasSelectedFiles = selectedFiles.length > 0;

	const getProps = useMemo(() => {
		const handleDrop = (
			accepted: File[],
			rejected: File[],
			error: FileError[],
		) => {
			setSelectedFiles(accepted);
			if (rejected.length === 0) {
				setRejectedFiles([]);
				setRejectedError([]);
			} else {
				setRejectedFiles(rejected);
				setRejectedError(error);
			}
		};
		return () => ({
			onDrop: handleDrop,
			multiple,
			accept,
			hasSelectedFiles,
		});
	}, [accept, hasSelectedFiles, multiple]);

	const reset = useCallback(() => {
		setSelectedFiles([]);
		setRejectedFiles([]);
		setRejectedError([]);
	}, []);

	return {
		selectedFiles,
		rejectedFiles,
		hasSelectedFiles,
		rejectedError,
		getProps,
		reset,
	};
}

export default useDropzone;
