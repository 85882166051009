import { useEffect } from "react";
import { createPath, useLocation } from "react-router-dom";
import type { UpdateListener } from "@xoev/state-container";
import { PatchAction } from "@xoev/state-container";
import { useEventHandler, useStableNavigate } from "../../hooks";
import type { EditorPatchMap, EditorStateContainer } from "./types";

/**
 * When the user presses undo/redo, we always want to jump to the part of the
 * app, where that change is reflected. Each patch that modifies the editor
 * state stores its related url in the patch's `meta` field. On undo/redo we
 * navigate to that url
 */
export default function useUrlSync(container: EditorStateContainer): void {
	const { listen, getState } = container;
	const navigate = useStableNavigate();
	const location = useLocation();

	const updateListener: UpdateListener<EditorPatchMap> = useEventHandler(
		({ action }) => {
			if (action !== PatchAction.Undo && action !== PatchAction.Redo) return;
			// On undo, we want to show the place, where the change was undone. As
			// we're taking a step back in the history stack when we click undo, the
			// place where the change was undone is now one step in the future of the
			// history stack. When a change is re-done, the change is at the same
			// position in the history, where we're currently at
			const stateDelta = action === PatchAction.Undo ? 1 : 0;
			const patch = getState(stateDelta).patches.at(0);
			if (!patch) return;
			const { url } = patch.meta;
			// Only jump to a different page, when we're not already on that page, so
			// we don't create unnecessary and potentially confusing entries in the
			// browser history
			if (url !== createPath(location)) {
				navigate(url);
			}
		},
	);

	useEffect(() => {
		const unlisten = listen(updateListener);
		return () => unlisten();
	}, [listen, updateListener]);
}
