import { useMatch } from "react-router-dom";

export enum ProjectSection {
	Metadata = "metadaten",
	Properties = "eigenschaften",
}

export default function useActiveProjectSection(): ProjectSection {
	const match = useMatch("/profilierung/projekt/:tabName");
	if (match?.params?.tabName === ProjectSection.Properties) {
		return ProjectSection.Properties;
	}
	return ProjectSection.Metadata;
}
