import ProjectTab from "./ProjectTab";
import { useProjectList } from "../../AppActor/actors/projects/hooks";

const ProjectTabs = (): JSX.Element => {
	const projects = useProjectList();

	return (
		<>
			{projects.map((project) => (
				<ProjectTab key={project.projektId} projektId={project.projektId} />
			))}
		</>
	);
};

export default ProjectTabs;
