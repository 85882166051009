var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _primitives, _nonPrimitives, _getMap, getMap_fn, _preventGarbageCollection, _root, _internalGet, internalGet_fn, _i, _cache, _cacheSize, _equalityFn, _preventGarbageCollection2, _areCacheKeysEqual, areCacheKeysEqual_fn, _getIndexOf, getIndexOf_fn;
function isPrimitive(key) {
  return !(key && (typeof key === "object" || typeof key === "function"));
}
class AutoWeakMap {
  constructor({
    preventGarbageCollection = false
  } = {}) {
    __privateAdd(this, _getMap);
    __privateAdd(this, _primitives, void 0);
    __privateAdd(this, _nonPrimitives, void 0);
    __privateSet(this, _primitives, /* @__PURE__ */ new Map());
    __privateSet(this, _nonPrimitives, preventGarbageCollection ? /* @__PURE__ */ new Map() : /* @__PURE__ */ new WeakMap());
  }
  has(key) {
    return __privateMethod(this, _getMap, getMap_fn).call(this, key).has(key);
  }
  get(key) {
    return __privateMethod(this, _getMap, getMap_fn).call(this, key).get(key);
  }
  set(key, value) {
    return __privateMethod(this, _getMap, getMap_fn).call(this, key).set(key, value);
  }
}
_primitives = new WeakMap();
_nonPrimitives = new WeakMap();
_getMap = new WeakSet();
getMap_fn = function(key) {
  return isPrimitive(key) ? __privateGet(this, _primitives) : __privateGet(this, _nonPrimitives);
};
function createNode(preventGarbageCollection) {
  return {
    map: new AutoWeakMap({ preventGarbageCollection }),
    valueWrapper: null
  };
}
class CacheTree {
  constructor({
    preventGarbageCollection = false
  } = {}) {
    /**
     * Get the cached value. Returns `undefined` if the value is not in the map
     */
    __privateAdd(this, _internalGet);
    __privateAdd(this, _preventGarbageCollection, void 0);
    __privateAdd(this, _root, void 0);
    __privateSet(this, _preventGarbageCollection, preventGarbageCollection);
    __privateSet(this, _root, createNode(preventGarbageCollection));
  }
  /**
   * Check whether or not an entry exists in the cache
   */
  has(keys) {
    return !!__privateMethod(this, _internalGet, internalGet_fn).call(this, keys);
  }
  /**
   * Get the cached value. Returns `undefined` if the value is not in the map
   */
  get(keys) {
    var _a;
    return (_a = __privateMethod(this, _internalGet, internalGet_fn).call(this, keys)) == null ? void 0 : _a.value;
  }
  /**
   * Set a value in the cache
   */
  set(keys, value) {
    let node = __privateGet(this, _root);
    for (const key of keys) {
      const nextNode = node.map.get(key) || createNode(__privateGet(this, _preventGarbageCollection));
      node.map.set(key, nextNode);
      node = nextNode;
    }
    node.valueWrapper = { value };
  }
  /**
   * Get a value from the cache or insert it, should it not exist. The value
   * will be calculated only if it does not already exist in the cache
   */
  getOrInsert(keys, calculateValue) {
    const cachedValueWrapper = __privateMethod(this, _internalGet, internalGet_fn).call(this, keys);
    if (cachedValueWrapper)
      return cachedValueWrapper.value;
    const value = calculateValue();
    this.set(keys, value);
    return value;
  }
}
_preventGarbageCollection = new WeakMap();
_root = new WeakMap();
_internalGet = new WeakSet();
internalGet_fn = function(keys) {
  let node = __privateGet(this, _root);
  for (const key of keys) {
    if (node === null)
      return void 0;
    node = node.map.get(key) || null;
  }
  if (node && node.valueWrapper)
    return node.valueWrapper;
  return void 0;
};
class FixedSizeCache {
  constructor({
    cacheSize,
    equalityFn = Object.is,
    preventGarbageCollection = false
  }) {
    __privateAdd(this, _areCacheKeysEqual);
    /**
     * Get the index of the cached value in the cache. Using this we can detect
     * whether a value exists in the map, even if the value is `undefined`. It id
     * also a little bit more performant, because we don't need to compare the
     * keys twice in `getOrInsert` when checking for existance and for retrieving
     * the value
     */
    __privateAdd(this, _getIndexOf);
    // Keep track of where we are in the cache array so we can overwrite the
    // oldest items if the cache grows over the `cacheSize`
    __privateAdd(this, _i, -1);
    __privateAdd(this, _cache, []);
    __privateAdd(this, _cacheSize, void 0);
    __privateAdd(this, _equalityFn, void 0);
    __privateAdd(this, _preventGarbageCollection2, void 0);
    __privateSet(this, _cacheSize, cacheSize);
    __privateSet(this, _equalityFn, equalityFn);
    __privateSet(this, _preventGarbageCollection2, preventGarbageCollection);
  }
  /**
   * Check whether or not an entry exists in the cache
   */
  has(keys) {
    const cachedItemIndex = __privateMethod(this, _getIndexOf, getIndexOf_fn).call(this, keys);
    return cachedItemIndex !== -1;
  }
  /**
   * Get the cached value. Returns `undefined` if the value is not in the map
   */
  get(keys) {
    const cachedItemIndex = __privateMethod(this, _getIndexOf, getIndexOf_fn).call(this, keys);
    if (cachedItemIndex !== -1)
      return __privateGet(this, _cache)[cachedItemIndex].value;
    return void 0;
  }
  /**
   * Set a value in the cache. Old values might be overwritten when the cache
   * size is exceeded
   */
  set(keys, value) {
    const refKeys = keys.map((key) => {
      const isWeakRef = !!key && !__privateGet(this, _preventGarbageCollection2) && typeof key === "object";
      return {
        // A `WeakRef` allows the object held within to be garbage collected
        // which is great for us because we don't need to worry about memory
        // leaks caused by the cache this way
        key: isWeakRef ? new WeakRef(key) : key,
        // Store a flag whether the item is a `WeakRef`, so we don't need to
        // perform `instanceof` checks later on
        isWeakRef
      };
    });
    __privateSet(this, _i, (__privateGet(this, _i) + 1) % __privateGet(this, _cacheSize));
    __privateGet(this, _cache)[__privateGet(this, _i)] = { keys: refKeys, value };
  }
  /**
   * Get a value from the cache or insert it, should it not exist. The value
   * will be calculated only if it does not already exist in the cache
   */
  getOrInsert(keys, calculateValue) {
    const cachedItemIndex = __privateMethod(this, _getIndexOf, getIndexOf_fn).call(this, keys);
    if (cachedItemIndex !== -1)
      return __privateGet(this, _cache)[cachedItemIndex].value;
    const value = calculateValue();
    this.set(keys, value);
    return value;
  }
}
_i = new WeakMap();
_cache = new WeakMap();
_cacheSize = new WeakMap();
_equalityFn = new WeakMap();
_preventGarbageCollection2 = new WeakMap();
_areCacheKeysEqual = new WeakSet();
areCacheKeysEqual_fn = function(mappedKeys, keys) {
  if (mappedKeys.length !== keys.length)
    return false;
  return mappedKeys.every((elemA, i) => {
    const keyA = elemA.isWeakRef ? elemA.key.deref() : elemA.key;
    const keyB = keys[i];
    if (elemA.isWeakRef && !keyA)
      return false;
    return __privateGet(this, _equalityFn).call(this, keyA, keyB);
  });
};
_getIndexOf = new WeakSet();
getIndexOf_fn = function(keys) {
  for (let iOffset = __privateGet(this, _cache).length - 1; iOffset >= 0; iOffset -= 1) {
    const i = (__privateGet(this, _cache).length + iOffset + __privateGet(this, _i) + 1) % __privateGet(this, _cache).length;
    const cachedEntry = __privateGet(this, _cache)[i];
    if (__privateMethod(this, _areCacheKeysEqual, areCacheKeysEqual_fn).call(this, cachedEntry.keys, keys)) {
      return i;
    }
  }
  return -1;
};
const defaultGetCacheKeys = (...args) => args;
function memoizeWith(fn, cache, options) {
  const { getCacheKeys } = options || {};
  const memoized = (...args) => {
    const keys = getCacheKeys ? getCacheKeys(...args) : defaultGetCacheKeys(...args);
    return cache.getOrInsert(keys, () => fn(...args));
  };
  return memoized;
}
const DEFAULT_CACHE_SIZE = 16;
function memoize(fn, options) {
  const {
    equalityFn = Object.is,
    cacheSize = DEFAULT_CACHE_SIZE,
    getCacheKeys,
    preventGarbageCollection = false
  } = options || {};
  const cacheMap = new FixedSizeCache({
    cacheSize,
    equalityFn,
    preventGarbageCollection
  });
  return memoizeWith(fn, cacheMap, { getCacheKeys });
}
function memoizeOnce(fn, options) {
  return memoize(fn, { ...options, cacheSize: 1 });
}
export {
  AutoWeakMap,
  CacheTree,
  FixedSizeCache,
  memoize,
  memoizeOnce,
  memoizeWith
};
