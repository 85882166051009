import { Snackbar } from "@mui/material";
import type { ReactNode } from "react";
import { Fragment, useMemo, useState } from "react";
import type { GlobalSnackbarContextType } from "./GlobalSnackbarContext";
import GlobalSnackbarContext from "./GlobalSnackbarContext";
import "./GlobalSnackbar.scss";

export default function GlobalSnackbar({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [alerts, setAlerts] = useState<{ id: string; alert: ReactNode }[]>([]);

	const key = alerts.map((alert) => alert.id).join(",");

	const ctx: GlobalSnackbarContextType = useMemo(
		() => ({
			registerAlert(id, alert) {
				setAlerts((prevAlerts) => [...prevAlerts, { id, alert }]);
			},
			unregisterAlert(id) {
				setAlerts((prevAlerts) =>
					prevAlerts.filter((alert) => alert.id !== id),
				);
			},
		}),
		[],
	);

	return (
		<GlobalSnackbarContext.Provider value={ctx}>
			{children}
			<Snackbar open={alerts.length > 0} key={key}>
				<div className="global-snackbar__stack">
					{alerts.map(({ alert, id }) => (
						<Fragment key={id}>{alert}</Fragment>
					))}
				</div>
			</Snackbar>
		</GlobalSnackbarContext.Provider>
	);
}
