import { useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowPrevIcon from "@mui/icons-material/ArrowUpward";
import ArrowNextIcon from "@mui/icons-material/ArrowDownward";
import type SegmentTrie from "@xoev/segment-trie";
import { getFocusIndex } from "../AppActor/actors/modellierungModel/search/helpers";
import "./SearchNav.scss";

export default function SearchNav<TPath, TSegment>({
	trie,
	isLoading,
	activePath,
	focusPrevious,
	focusNext,
	setCurrentIndex,
	id,
}: {
	trie: SegmentTrie<TPath, TSegment, number>;
	isLoading: boolean;
	activePath: TPath;
	focusPrevious: () => void;
	focusNext: () => void;
	setCurrentIndex?: (index: number) => void;
	id: string;
}): JSX.Element {
	const focusIndex = getFocusIndex(activePath, trie);
	const hasFocusIndex = focusIndex !== undefined;

	useEffect(() => {
		if (hasFocusIndex) {
			setCurrentIndex?.(focusIndex);
		}
	}, [focusIndex, hasFocusIndex, setCurrentIndex]);

	return (
		<div className="search-nav">
			<span id={id}>
				{hasFocusIndex && !isLoading ? (
					<>
						Ergebnis {focusIndex + 1} von {trie.size}
					</>
				) : (
					<>Ergebnisse: {trie.size}</>
				)}
			</span>
			<div>
				<Tooltip title="Vorriges Ergebnis">
					<IconButton
						onClick={focusPrevious}
						data-testid="prev-search-result"
						disableRipple
					>
						<ArrowPrevIcon fontSize="small" />
					</IconButton>
				</Tooltip>
				<Tooltip title="Nächstes Ergebnis">
					<IconButton
						onClick={focusNext}
						data-testid="next-search-result"
						disableRipple
					>
						<ArrowNextIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</div>
		</div>
	);
}
