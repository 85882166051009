import type { NodeTarget } from "../../../AppActor/actors/modellierungModel/types";
import {
	useActiveFullBausteinPath,
	useActiveTreePath,
} from "../../../AppActor/actors/treeState/hooks";

// eslint-disable-next-line import/prefer-default-export
export function useTargetPath(target: NodeTarget) {
	const fullBausteinPath = useActiveFullBausteinPath();
	const fullPath = useActiveTreePath();
	return { baustein: fullBausteinPath, structure: fullPath }[target];
}
