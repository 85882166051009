import { useState } from "react";

/**
 * Store a constant value, that is persisted over the lifetime of
 * a component.
 *
 * @param value A value, that should be stored as a constant
 * or a function, that returns the value. It will be called once on
 * component initialization.
 */
function useConst<T>(value: T | (() => T)): T {
	const [constValue] = useState(value);
	return constValue;
}

export default useConst;
