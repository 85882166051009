import React from "react";
import classNames from "classnames";
import type { AnyComponent } from "../util";
import type { SubMenuProps } from "./types";
import SubMenuList from "./SubMenuList";
import styles from "./SubMenu.module.scss";

function SubMenu<TComponent extends AnyComponent = "nav">({
	as: As,
	children,
	className,
	...props
}: SubMenuProps<TComponent>): JSX.Element {
	const Comp = As || "nav";
	return (
		<Comp {...props} className={classNames(styles.subMenu, className)}>
			{children}
		</Comp>
	);
}

SubMenu.List = SubMenuList;

export default SubMenu;
