import classNames from "classnames";
import SelectOption from "./SelectOption";
import SelectArrow from "./SelectArrow";
import SelectButton from "./SelectButton";
import SelectList from "./SelectList";
import PopoverMenu from "../PopoverMenu";
import SelectImpl from "./SelectImpl";
import type { SelectProps } from "./types";
import "./Select.scss";

/**
 * Render a select component.
 *
 * @example
 * ```tsx
 * import { Select } from "../ui";
 * import type { SelectChangeHandler } from "../ui";
 *
 * // Possible types for `value` are string, number or null
 * const [value, setValue] = useState<string>("");
 *
 * const handleChange: SelectChangeHandler = (event) => {
 *   setValue(event.value);
 * };
 *
 * <Select label="Standard auswählen" value={value} onChange={handleChange}>
 *   <Select.Button placeholder="Standard auswählen">
 *     <Select.Arrow />
 *   </Select.Button>
 *   <Select.List>
 *     <Select.Option value="aaa-111">Option 1</Select.Option>
 *     <Select.Option value="bbb-222">Option 2</Select.Option>
 *   </Select.List>
 * </Select>
 * ```
 */
const Select = ({
	children = null,
	activeClassName,
	className,
	disabledClassName,
	label,
	isOpen = false,
	onOpen,
	onClose,
	onChange,
	position = "bottom left",
	value,
	name,
	fluid = false,
	closeOnChange = true,
	disabled = false,
	isRequired,
	...props
}: SelectProps): JSX.Element => (
	<PopoverMenu
		// We have strictly typed the props to inlcude the prop definitions of
		// `PopoverMenu`, so we can safely spread the props here
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		className={classNames(
			"xui-select",
			fluid && "xui-select--fluid",
			disabled && "xui-select--disabled",
			disabled && disabledClassName,
			className,
		)}
		activeClassName={classNames("xui-select--open", activeClassName)}
		isOpen={isOpen}
		onOpen={onOpen}
		onClose={onClose}
		position={position}
	>
		<SelectImpl
			closeOnChange={closeOnChange}
			value={value}
			name={name}
			onChange={onChange}
			label={label}
			disabled={disabled}
			isRequired={isRequired}
		>
			{children}
		</SelectImpl>
	</PopoverMenu>
);

Select.Option = SelectOption;
Select.Arrow = SelectArrow;
Select.Button = SelectButton;
Select.List = SelectList;

export default Select;
