import { setup } from "xstate";
import notificationQueryMachine from "./notification.query.machine";
import notificationCommandMachine from "./notification.command.machine";

const notificationMachine = setup({
	types: {
		children: {} as {
			"notification:query": "query";
			"notification:command": "command";
		},
	},
	actors: {
		query: notificationQueryMachine,
		command: notificationCommandMachine,
	},
}).createMachine({
	id: "notification",
	invoke: [
		{
			src: "query",
			systemId: "notification:query",
			id: "notification:query",
		},
		{
			src: "command",
			systemId: "notification:command",
			id: "notification:command",
		},
	],
});

export default notificationMachine;
