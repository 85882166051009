import { v4 as uuid } from "uuid";
import type { ActorRefFrom } from "xstate";
import { assertEvent, fromCallback, raise, setup, spawnChild } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import type { AlertPayload } from "./types";

export type NotificationCommandRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof notificationCommandMachine
>;
export type NotificationCommandContext = { alerts: AlertPayload[] };
type NotificationCommandEvent =
	| { type: "SEND_ALERT"; alert: AlertPayload }
	| { type: "ID_CREATED"; alert: AlertPayload; id: string }
	| { type: "REMOVE_ALERT"; id: string };

const AUTO_HIDE_TIMEOUT = 5000;

const notificationCommandMachine = setup({
	types: {
		events: {} as NotificationCommandEvent,
		context: {} as NotificationCommandContext,
	},
	actors: {
		translateStoreEvents: translateStoreEvents<NotificationCommandEvent>({
			"NOTIFICATION.ALERT": ({ payload }) => ({
				type: "SEND_ALERT",
				alert: payload,
			}),
		}),
		autoHideTimer: fromCallback<NotificationCommandEvent, { id: string }>(
			({ sendBack, input }) => {
				const timeout = setTimeout(() => {
					sendBack({
						type: "REMOVE_ALERT",
						id: input.id,
					} satisfies NotificationCommandEvent);
				}, AUTO_HIDE_TIMEOUT);
				return () => clearTimeout(timeout);
			},
		),
	},
	actions: {
		sendAddAlert: ({ event, system }) => {
			assertEvent(event, "ID_CREATED");
			const { alert, id } = event;
			sendToEventStore(system, {
				type: "NOTIFICATION.ALERT.ADD",
				payload: { ...alert, id },
			});
		},
		sendRemoveAlert: ({ event, system }) => {
			assertEvent(event, "REMOVE_ALERT");
			const { id } = event;
			sendToEventStore(system, {
				type: "NOTIFICATION.ALERT.REMOVE",
				payload: { id },
			});
		},
		raiseCreateId: raise(({ event }) => {
			assertEvent(event, "SEND_ALERT");
			return { type: "ID_CREATED" as const, alert: event.alert, id: uuid() };
		}),
		setAutoHideTimer: spawnChild("autoHideTimer", {
			input: ({ event }) => {
				assertEvent(event, "ID_CREATED");
				return { id: event.id };
			},
		}),
	},
	guards: {
		shouldSetTimer: ({ event }) => {
			assertEvent(event, "ID_CREATED");
			return !!event.alert.autoHide;
		},
	},
}).createMachine({
	id: "notification:query",
	context: { alerts: [] },
	invoke: { src: "translateStoreEvents" },
	on: {
		SEND_ALERT: { actions: "raiseCreateId" },
		ID_CREATED: [
			{
				guard: "shouldSetTimer",
				actions: ["setAutoHideTimer", "sendAddAlert"],
			},
			{ actions: "sendAddAlert" },
		],
		REMOVE_ALERT: { actions: "sendRemoveAlert" },
	},
});

export default notificationCommandMachine;
