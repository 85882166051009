import type { AnyStateMachine } from "xstate";
import { setup } from "xstate";
import type { AppActorMap } from "./appActors";

// We need to supply the return type definition manually, because otherwise TS
// hits a limit of how much type information it can infer and errors
const createAppMachine = (actors: AppActorMap): AnyStateMachine =>
	setup({
		actors,
		types: {
			children: {} as { [K in keyof AppActorMap]: K },
		},
	}).createMachine({
		id: "app",
		invoke: [
			{
				src: "actorSystem",
				systemId: "actorSystem",
				id: "actorSystem",
				input: ({ self }) => ({ system: self.system }),
			},
			{ src: "notification", systemId: "notification", id: "notification" },
			{ src: "effectApi", systemId: "effectApi", id: "effectApi" },
			{ src: "eventStore", systemId: "eventStore", id: "eventStore" },
			{ src: "navigation", systemId: "navigation", id: "navigation" },
			{ src: "projects", systemId: "projects", id: "projects" },
			{
				src: "keyboardShortcuts",
				systemId: "keyboardShortcuts",
				id: "keyboardShortcuts",
			},
		],
	});

export default createAppMachine;
