import { useContext } from "react";
import type {
	ValidationContextType,
	ValidationResultGroup,
	Locator,
	ResultMapping,
	ResultType,
	ValidationTargetField,
} from "./types";
import ValidationContext from "./ValidationContext";

export function useValidationContext(): ValidationContextType {
	return useContext(ValidationContext);
}

type Entry<Obj> = { [K in keyof Obj]: [key: K, value: Obj[K]] }[keyof Obj];

export function useValidationResults<
	ResultGroup extends ValidationResultGroup,
	TargetField extends ValidationTargetField = ValidationTargetField,
>(
	resultGroup: ResultGroup | ResultGroup[],
	locator?: Locator<ResultGroup, TargetField>,
): ResultMapping<TargetField>[ResultGroup][] {
	type Result = ResultMapping<TargetField>[ResultGroup];
	const { result } = useValidationContext();
	const groups = Array.isArray(resultGroup) ? resultGroup : [resultGroup];
	const resultList = groups.flatMap((group) => result[group] as ResultType[]);
	const locatorEntries = (
		locator
			? [...Object.entries(locator).filter(([, value]) => value !== undefined)]
			: []
	) as Entry<ResultType>[];
	return resultList.filter((subResult) =>
		locatorEntries.every((entry) => {
			const [key, value] = entry as [
				keyof ResultType,
				ResultType[keyof ResultType],
			];
			return key in subResult && subResult[key] === value;
		}),
	) as Result[];
}
