import type { SetStateAction, Dispatch } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportIcon from "@mui/icons-material/Description";
import MessageIcon from "@mui/icons-material/Email";
import type { MessageType } from "../MessageOverview/types";
import AComponentMenutItem from "../AComponentMenuItem";
import ButtonMenuItem from "../ButtonMenuItem";
import MessageEditMenuItem from "./MessageEditMenuItem";
import SingleMenuEditItem from "./SingleMenuEditItem";
import useMessageContextMenu from "./useMessageContextMenut";

const MessageContextMenu = ({
	open,
	message,
	anchorEl,
	handleClose,
	menuId,
	labelId,
	handleDownloadMessages,
	handleDownloadReports,
	enableEdit,
	setEnableEdit,
	setEnableSingleEdit,
}: {
	open: boolean;
	anchorEl: HTMLElement | null;
	handleClose: () => void;
	menuId: string;
	labelId: string;
	message?: MessageType;
	handleDownloadMessages?: () => void;
	handleDownloadReports?: () => void;
	enableEdit?: string;
	setEnableEdit?: Dispatch<SetStateAction<string>>;
	setEnableSingleEdit?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
	const { handleDeleteMessages } = useMessageContextMenu(handleClose, message);

	return (
		<Menu
			id={menuId}
			data-testid="message-context-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": labelId,
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
		>
			{handleDownloadReports ? (
				<ButtonMenuItem
					onClick={handleDownloadReports}
					title="Validierungsberichte herunterladen"
				>
					<MessageIcon />
				</ButtonMenuItem>
			) : (
				<AComponentMenutItem
					uri={`/api/v1/viewer/nachrichten/${message?.id}/validation-report.xml`}
					data-testid="download-validation-report"
					title="Validierungsbericht herunterladen"
				>
					<ReportIcon />
				</AComponentMenutItem>
			)}

			{handleDownloadMessages ? (
				<ButtonMenuItem
					onClick={handleDownloadMessages}
					title="Nachrichten herunterladen"
				>
					<MessageIcon />
				</ButtonMenuItem>
			) : (
				<AComponentMenutItem
					uri={`/api/v1/viewer/nachrichten/${message?.id}`}
					messageName={message?.name}
					title="Nachricht herunterladen"
					data-testid="download-message"
				>
					<MessageIcon />
				</AComponentMenutItem>
			)}
			{setEnableEdit && message && (
				<MessageEditMenuItem
					message={message}
					onActivate={() => handleClose()}
					enableEdit={enableEdit}
					setEnableEdit={setEnableEdit}
				/>
			)}
			{setEnableSingleEdit && (
				<SingleMenuEditItem
					setEnableEdit={setEnableSingleEdit}
					handleMenuClose={handleClose}
				/>
			)}
			<MenuItem onClick={handleDeleteMessages} data-testid="delete-message">
				<ListItemIcon>
					<DeleteIcon />
				</ListItemIcon>
				<ListItemText>Nachricht löschen</ListItemText>
			</MenuItem>
		</Menu>
	);
};

export default MessageContextMenu;
