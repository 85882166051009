import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import type { ProjectQueryActorRef } from "./project.query.machine";
import projectQueryMachine from "./project.query.machine";
import type { ProjectCommandActorRef } from "./project.command.machine";
import projectCommandMachine from "./project.command.machine";
import { createCommandId, createQueryId } from "./helpers";
import type { ProjektMeta } from "./types";

// eslint-disable-next-line no-use-before-define
export type ProjectActorRef = ActorRefFrom<typeof projectMachine>;

const projectMachine = setup({
	types: {
		context: {} as ProjektMeta & {
			query: ProjectQueryActorRef;
			command: ProjectCommandActorRef;
		},
		input: {} as ProjektMeta,
	},
	actors: {
		query: projectQueryMachine,
		command: projectCommandMachine,
	},
}).createMachine({
	id: "project",
	context: ({ input, spawn }) => ({
		...input,
		query: spawn("query", { systemId: createQueryId(input.projektId), input }),
		command: spawn("command", {
			systemId: createCommandId(input.projektId),
			input,
		}),
	}),
});

export default projectMachine;
