import type { SvgIconComponent } from "@mui/icons-material";
import classNames from "classnames";
import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";

export default function LiteNodeIcon({
	title,
	icon: Icon,
	iconClassName,
	className,
}: {
	title: string;
	icon: SvgIconComponent;
	iconClassName?: string;
	className?: string;
}): JSX.Element {
	return (
		<Tooltip title={title}>
			<NodeIcon className={className}>
				<Icon className={classNames("lite-node-icon", iconClassName)} />
			</NodeIcon>
		</Tooltip>
	);
}
