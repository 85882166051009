import { useState } from "react";
import HiddenChaptersList from "./HiddenChaptersList";
import DraggableChaptersList from "./DraggableChaptersList";
import ChaptersListFooter from "./ChaptersListFooter";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../EditorState";
import {
	selectHiddenDocs,
	selectVisibleDocs,
} from "../../EditorState/selectors";
import useActiveDocTitle from "../useActiveDocTitle";
import EditorSideBar from "../../EditorSideBar";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
	selectFilterValue,
	setFilter,
	UiFilterGroup,
} from "../../../redux/uiSlice";

const FilterGroup = UiFilterGroup.DocumentationList as const;

const DocumentationList = (): JSX.Element => {
	const applyPatch = useApplyPatch();
	const { changeDocumentTitle } = useEditorPatchCreators();

	const dispatch = useAppDispatch();
	const [isAddFieldVisible, setIsAddFieldVisible] = useState(false);
	const searchTerm = useAppSelector(selectFilterValue(FilterGroup, "query"));
	const showHiddenChapters = useAppSelector(
		selectFilterValue(FilterGroup, "showHiddenChapters"),
	);
	const setSearchTerm = (term: string) => {
		dispatch(setFilter(FilterGroup, { query: term }));
	};
	const setShowHiddenChapters = (nextShowHiddenChapters: boolean) => {
		dispatch(
			setFilter(FilterGroup, { showHiddenChapters: nextShowHiddenChapters }),
		);
	};

	const docs = useStateSelector(selectVisibleDocs());
	const hiddenDocs = useStateSelector(selectHiddenDocs());

	const [, setActiveDocTitle] = useActiveDocTitle();

	const handleSearchChange = (value: string) => {
		setSearchTerm(value);
	};

	const handleDocTitleClick = (docTitle: string) => {
		setActiveDocTitle(docTitle);
	};

	const changeTitle = (oldTitle: string, newTitle: string) => {
		if (newTitle.length) {
			applyPatch(changeDocumentTitle({ newTitle, oldTitle }));
			setActiveDocTitle(newTitle);
		}
	};

	return (
		<EditorSideBar data-testid="documentation-list">
			<EditorSideBar.Main>
				<EditorSideBar.Search
					onClear={() => setSearchTerm("")}
					onChange={handleSearchChange}
					value={searchTerm}
					label="Dokumentationskapitel durchsuchen"
				/>
				<EditorSideBar.Scroller className="documentation-list__wrapper">
					{showHiddenChapters && (
						<HiddenChaptersList
							docs={hiddenDocs}
							searchTerm={searchTerm}
							onActivate={handleDocTitleClick}
						/>
					)}
					<DraggableChaptersList
						docs={docs}
						searchTerm={searchTerm}
						isAddFieldVisible={isAddFieldVisible}
						setIsAddFieldVisible={setIsAddFieldVisible}
						onActivate={handleDocTitleClick}
						onRename={changeTitle}
					/>
				</EditorSideBar.Scroller>
			</EditorSideBar.Main>
			<EditorSideBar.Controls>
				<ChaptersListFooter
					isAddFieldVisible={isAddFieldVisible}
					setIsAddFieldVisible={setIsAddFieldVisible}
					showHiddenChapters={showHiddenChapters}
					setShowHiddenChapters={setShowHiddenChapters}
				/>
			</EditorSideBar.Controls>
		</EditorSideBar>
	);
};

export default DocumentationList;
