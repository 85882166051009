import {
	useModellierungProjekt,
	useProjektBaseSegment,
} from "../../AppActor/actors/modellierungModel/hooks";
import type { BreadcrumbItem } from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import NavBarBreadcrumbs from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import useProjectBreadcrumb from "../../EditorNavBar/NavBarBreadcrumbs/breadCrumbsLite";
import TabNavBar from "../../TabNavBar";

const ConfigurationNavBar = (): JSX.Element => {
	const activeProject = useModellierungProjekt();
	const baseSegment = useProjektBaseSegment();
	const projectBreadcrumb = useProjectBreadcrumb(activeProject);

	const breadCrumbs: BreadcrumbItem[] = [
		projectBreadcrumb,
		{
			key: "__CONFIGURATION__",
			content: "Konfiguration",
			to: `${baseSegment}/konfiguration`,
		},
	];

	return (
		<TabNavBar>
			<NavBarBreadcrumbs items={breadCrumbs} />
		</TabNavBar>
	);
};

export default ConfigurationNavBar;
