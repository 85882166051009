import { LRSplit } from "../ui";
import BausteinView from "./BausteinView";
import DetailsView from "./DetailsView";
import StructureView from "./StructureView";
import TabNavBar from "../TabNavBar";
import LiteModelBreadcrumbs from "./LiteModelBreadcrumbs";

export default function LiteModelView(): JSX.Element {
	return (
		<>
			<TabNavBar>
				<LiteModelBreadcrumbs />
			</TabNavBar>
			<LRSplit sizes={[25, 75]}>
				<LRSplit.Left>
					<BausteinView />
				</LRSplit.Left>
				<LRSplit.Right>
					<LRSplit sizes={[33, 67]}>
						<LRSplit.Left>
							<StructureView />
						</LRSplit.Left>
						<LRSplit.Right>
							<DetailsView />
						</LRSplit.Right>
					</LRSplit>
				</LRSplit.Right>
			</LRSplit>
		</>
	);
}
