import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useEventHandler } from "../../../hooks";

const OutsideClick = ({
	children,
	onClickOutside,
}: {
	children: ReactNode;
	onClickOutside: () => void;
}): JSX.Element => {
	const [ref, setRef] = useState<HTMLDivElement | null>(null);
	const eventHandler = useEventHandler(onClickOutside);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref && event.target instanceof Node && !ref.contains(event.target)) {
				eventHandler();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [eventHandler, ref]);

	return <div ref={setRef}>{children}</div>;
};

export default OutsideClick;
