import { lazy, Suspense, useEffect, useState } from "react";
import { isPast, isFuture, isEqual } from "date-fns";
import type { MaintenanceScheduleResponse } from "../AppInfoProvider";
import { useAppInfo } from "../AppInfoProvider";
import SystemBanner, { useGlobalClass } from "../SystemBanner";

const MaintenanceInfo = lazy(() => import("./MaintenanceInfo"));

const EMPTY_ARRAY: MaintenanceScheduleResponse[] = [];

export function filterSchedules(
	schedules: MaintenanceScheduleResponse[],
	closed: string[],
) {
	const tmpSchedules = schedules.map((schedule) => ({
		...schedule,
		key: `${schedule.sichtbarAb}/${schedule.sichtbarBis}/${schedule.beschreibung}`,
	}));
	return tmpSchedules
		.filter((schedule) => {
			const now = new Date();
			const visibleDate = new Date(schedule?.sichtbarAb || new Date());
			const expiryDate = new Date(schedule?.sichtbarBis || new Date());
			return (
				!closed.includes(schedule.key) &&
				(isPast(visibleDate) || isEqual(visibleDate, now)) &&
				(isFuture(expiryDate) || isEqual(expiryDate, now))
			);
		})
		.slice(0, 1);
}

function MaintenanceBanner(): JSX.Element {
	const { info } = useAppInfo();

	const schedules = info?.meldungen || EMPTY_ARRAY;
	const showBanner = !!schedules.length;

	const [closed, setClosed] = useState<string[]>([]);

	// Add maintanance status class to html element, so styling can
	// easily be adjusted depending on env
	useGlobalClass("maintenance", showBanner ? `active` : `inactive`);

	const filteredSchedules = filterSchedules(schedules, closed);

	// Set the number of banners as a css variable, so we can dynamically
	// calculate the total size of the used area
	const numBanners = filteredSchedules.length;
	useEffect(() => {
		document.documentElement.style.setProperty(
			"--_num-maintenance-banners",
			`${numBanners}`,
		);
		return () => {
			document.documentElement.style.removeProperty(
				"--_num-maintenance-banners",
			);
		};
	}, [numBanners]);

	const handleClose = (key: string) => {
		setClosed((prevClosed) => [...prevClosed, key]);
	};

	return (
		<Suspense fallback={null}>
			<SystemBanner heightProp="--height-maintenance-banner">
				{filteredSchedules.map((schedule) => (
					<MaintenanceInfo
						key={schedule.key}
						id={schedule.key}
						schedule={schedule}
						onClose={handleClose}
					/>
				))}
			</SystemBanner>
		</Suspense>
	);
}

export default MaintenanceBanner;
