import { z } from "zod";

const iconInfoSchema = z.object({
	tooltip: z.string(),
	url: z.string().optional(),
});

export type IconInfo = z.infer<typeof iconInfoSchema>;

export const iconInfosSchema = z.record(iconInfoSchema);

export type IconInfos = z.infer<typeof iconInfosSchema>;

export function opaquePassthrough<TSchema extends z.AnyZodObject>(
	schema: TSchema,
) {
	return schema.passthrough() as TSchema;
}
