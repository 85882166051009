import { useMemo, useState } from "react";
import { useEventHandler } from "../../hooks";
import type { PathFragment } from "./types";

export type ExpansionTree<TFragment extends PathFragment = PathFragment> = {
	[K in TFragment]?: ExpansionTree<TFragment> | undefined | 0;
};

export function isExpanded<TFragment extends PathFragment = PathFragment>(
	currentState: ExpansionTree<TFragment>,
	path: TFragment[],
): boolean {
	let current = currentState;
	for (const fragment of path) {
		const next = current[fragment];
		if (!next) return false;
		current = next;
	}
	return true;
}

export function openPath<TFragment extends PathFragment = PathFragment>(
	currentState: ExpansionTree<TFragment>,
	path: TFragment[],
): ExpansionTree {
	const prevValue = isExpanded(currentState, path);
	if (prevValue) return currentState;

	const nextState = structuredClone(currentState);
	let currentEntry = nextState;
	for (const fragment of path) {
		const nextEntry = currentEntry[fragment] || {};
		currentEntry[fragment] = nextEntry;
		currentEntry = nextEntry;
	}
	return nextState;
}

export function closePath<TFragment extends PathFragment = PathFragment>(
	currentState: ExpansionTree<TFragment>,
	path: TFragment[],
): ExpansionTree {
	const prevValue = isExpanded(currentState, path);
	if (!prevValue) return currentState;

	const nextState = structuredClone(currentState);
	let currentEntry = nextState;
	const parentPath = path.slice(0, -1);
	for (const fragment of parentPath) {
		const nextEntry = currentEntry[fragment] || {};
		currentEntry[fragment] = nextEntry;
		currentEntry = nextEntry;
	}
	const lastSegment = path[path.length - 1];
	delete currentEntry[lastSegment];
	return nextState;
}

export function setIsExpanded<TFragment extends PathFragment = PathFragment>(
	currentState: ExpansionTree<TFragment>,
	path: TFragment[],
	value: boolean | ((prev: boolean) => boolean),
): ExpansionTree {
	const prevValue = isExpanded(currentState, path);
	const nextValue = typeof value === "function" ? value(prevValue) : value;
	return nextValue
		? openPath(currentState, path)
		: closePath(currentState, path);
}

export function createExpansionState<
	TFragment extends PathFragment = PathFragment,
>(): ExpansionTree<TFragment>;
export function createExpansionState<
	TFragment extends PathFragment = PathFragment,
>(initialPath: TFragment[]): ExpansionTree<TFragment>;
export function createExpansionState<
	TFragment extends PathFragment = PathFragment,
>(...initialPaths: TFragment[][]): ExpansionTree<TFragment>;
export function createExpansionState<
	TFragment extends PathFragment = PathFragment,
>(...initialPaths: TFragment[][]): ExpansionTree<TFragment> {
	let state = openPath({}, []);
	for (const path of initialPaths) {
		state = openPath(state, path);
	}
	return state;
}

export function useExpansionState<
	TFragment extends PathFragment = PathFragment,
>(
	initialPath: TFragment[],
): {
	isExpanded: (path: TFragment[]) => boolean;
	setIsExpanded: (
		path: TFragment[],
		value: boolean | ((prev: boolean) => boolean),
	) => void;
	openPath: (path: TFragment[]) => void;
	closePath: (path: TFragment[]) => void;
	expansionState: ExpansionTree;
} {
	const [expansionState, setExpansionState] = useState(() =>
		createExpansionState(initialPath),
	);

	const openPathInner = useEventHandler((path: TFragment[]) => {
		setExpansionState((prev) => openPath(prev, path));
	});
	const closePathInner = useEventHandler((path: TFragment[]) => {
		setExpansionState((prev) => closePath(prev, path));
	});

	const isExpandedInner = useEventHandler((path: TFragment[]) =>
		isExpanded(expansionState, path),
	);

	const setIsExpandedInner = useEventHandler(
		(path: TFragment[], value: boolean | ((prev: boolean) => boolean)) => {
			setExpansionState((prev) => setIsExpanded(prev, path, value));
		},
	);

	return useMemo(
		() => ({
			isExpanded: isExpandedInner,
			setIsExpanded: setIsExpandedInner,
			openPath: openPathInner,
			closePath: closePathInner,
			expansionState,
		}),
		[
			expansionState,
			isExpandedInner,
			setIsExpandedInner,
			openPathInner,
			closePathInner,
		],
	);
}
