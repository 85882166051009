import EditorMain from "../EditorMain";
import { LRSplit } from "../ui";
import DatatypeEditView from "./DatatypeEditView";
import DatatypesList from "./DatatypesList";

const DatatypesListView = (): JSX.Element => (
	<EditorMain>
		<LRSplit>
			<LRSplit.Left>
				<DatatypesList />
			</LRSplit.Left>
			<LRSplit.Right>
				<DatatypeEditView />
			</LRSplit.Right>
		</LRSplit>
	</EditorMain>
);

export default DatatypesListView;
