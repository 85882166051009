import { createContext } from "react";
import type { FieldRendererProps } from "../types";

const FieldRendererContext = createContext<
	Pick<FieldRendererProps, "isStandardRenderer">
>({
	isStandardRenderer: false,
});

export default FieldRendererContext;
