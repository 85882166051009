import type { Dispatch, SetStateAction } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const SingleMenuEditItem = ({
	setEnableEdit,
	handleMenuClose,
}: {
	setEnableEdit: Dispatch<SetStateAction<boolean>>;
	handleMenuClose: () => void;
}): JSX.Element => {
	const handleClick = () => {
		setEnableEdit((prevState) => !prevState);
		handleMenuClose();
	};
	return (
		<MenuItem onClick={handleClick} data-testid="edit-message">
			<ListItemIcon>
				<EditIcon />
			</ListItemIcon>
			<ListItemText>Dateinamen ändern</ListItemText>
		</MenuItem>
	);
};

export default SingleMenuEditItem;
