import { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutelinedIcon from "@mui/icons-material/HomeOutlined";
import { Tooltip } from "@mui/material";
import { MenuItem, SubMenu as UiSubMenu } from "@xoev/ui";
import classNames from "classnames";
import type { MessageType } from "./MessageOverview/types";
import Tabs from "../ui/Tabs";
import MessageViewToolbar from "./MessageViewToolbar";
import MessageOverview from "./MessageOverview";
import MessageViewer from "./Viewer";
import { VisuallyHidden } from "../ui";
import CloseAllButton from "./CloseAllButton";
import { useGetMessagesQuery } from "../../redux/messagesApiSlice";
import MessageRequestNotifications from "./MessageRequestNotifications";
import {
	removeTabId,
	removeAllTabIds,
	selectActiveTab,
	setActiveTab,
	selectOpenTabs,
	setTabId,
} from "../../redux/messagesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./MessageView.scss";

class MessageNotFoundError extends Error {
	constructor(messageId: string, messages?: MessageType[]) {
		const expected = messages?.map(({ id }) => `"${id}"`).join(", ");
		super(
			`No message with id "${messageId}" could be found. Expected one of [${expected}]`,
		);
	}
}

const MessageView = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const { data: messages } = useGetMessagesQuery();
	const selectedTabs = useAppSelector<string[]>(selectOpenTabs());
	const activeTab = useAppSelector(selectActiveTab());

	const openTabs = selectedTabs.filter((id) =>
		messages?.find((message) => message.id === id),
	);

	const [tabListRef, setTabListRef] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!tabListRef) return;
		const handler = (e: WheelEvent) => {
			// Translate the vertical scroll to a horizontal scroll
			tabListRef.scrollLeft += e.deltaY;
		};
		tabListRef.addEventListener("wheel", handler);
		// eslint-disable-next-line consistent-return
		return () => tabListRef.removeEventListener("wheel", handler);
	});

	const handleMessageClick = async (message: MessageType) => {
		dispatch(setTabId({ id: message.id }));
	};

	const handleClose = (messageId: string) =>
		dispatch(removeTabId({ id: messageId }));

	const handleCloseAll = () => dispatch(removeAllTabIds());

	const handleIndexChange = (index: number) =>
		dispatch(setActiveTab({ activeTab: index }));

	return (
		<MessageRequestNotifications>
			<UiSubMenu
				as={Tabs}
				className="message-view"
				activeIndex={activeTab}
				onIndexChange={handleIndexChange}
			>
				<div className="message-view__tab-list-wrapper">
					<UiSubMenu.List ref={setTabListRef} as={Tabs.List}>
						<MenuItem>
							<MenuItem.Link
								as={Tabs.Tab}
								data-testid="home-nachrichten"
								wrapperClassName="message-view__tab-wrapper message-view__tab-wrapper--home"
								className="message-view__tab message-view__tab--home"
								closeButtonClassName="message-view__tab-close-button"
								isActive={activeTab === 0}
								disableWidthCompensation
							>
								<VisuallyHidden>
									Startseite der Nachrichten-Ansicht
								</VisuallyHidden>
								<div className="message-view__icon-wrapper">
									<HomeIcon
										className={classNames(
											"message-view__icon",
											activeTab === 0 && "message-view__icon--active",
										)}
									/>
								</div>
								<div className="message-view__icon-wrapper">
									<HomeOutelinedIcon
										className={classNames(
											"message-view__icon",
											activeTab !== 0 && "message-view__icon--active",
										)}
									/>
								</div>
							</MenuItem.Link>
						</MenuItem>
						{openTabs.map((tab, index: number) => {
							const message = messages?.find(({ id }) => id === tab);
							// This should not be possible, as the `openIds` are derived from
							// the contents of `messages`, but TS doesn't know that...
							if (!message) {
								throw new MessageNotFoundError(tab, messages);
							}
							const isActive = activeTab === index + 1;
							return (
								<MenuItem key={tab}>
									<MenuItem.Link
										as={Tabs.Tab}
										wrapperClassName="message-view__tab-wrapper message-view__tab-wrapper--closable"
										className={classNames(
											"message-view__tab",
											"message-view__tab--closable",
											isActive && "message-view__tab--active",
										)}
										closeButtonClassName="message-view__tab-close-button"
										data-testid="message-viewer-tab"
										data-message-name={message.name}
										onClose={() => handleClose(tab)}
										isActive={isActive}
										disableWidthCompensation
									>
										<Tooltip title={message.name}>
											<span className="message-view__tab-text">
												{message.name}
											</span>
										</Tooltip>
									</MenuItem.Link>
								</MenuItem>
							);
						})}
					</UiSubMenu.List>
					{openTabs.length > 0 && (
						<div className="message-view__close-all-wrapper">
							<CloseAllButton
								onClose={handleCloseAll}
								className="message-view__close-all-button"
							/>
						</div>
					)}
				</div>

				<div data-testid="message-view">
					<MessageViewToolbar openIds={openTabs} />
					<main id="main" className="message-view__main">
						<Tabs.Panels className="message-view__tab-panels">
							<Tabs.Panel className="message-view__tab-panel">
								{!activeTab && (
									<MessageOverview onMsgClick={handleMessageClick} />
								)}
							</Tabs.Panel>
							{openTabs.map((openTab) => {
								const message = messages?.find(({ id }) => id === openTab);
								if (!message) {
									throw new MessageNotFoundError(openTab, messages);
								}
								return (
									<Tabs.Panel key={openTab} className="message-view__tab-panel">
										<MessageViewer
											className="message-view__markup-viewer"
											message={message}
										/>
									</Tabs.Panel>
								);
							})}
						</Tabs.Panels>
					</main>
				</div>
			</UiSubMenu>
		</MessageRequestNotifications>
	);
};

export default MessageView;
