/* eslint-disable max-classes-per-file */
import { QNamePathSchema } from "../../../../../AppActor/actors/modellierungModel/schemas";
import { RestrictionIdSchema } from "../../../../../EditorState/types";
import type { SpecificRefSelectionItem } from "./types";
import { RefType } from "./types";

const REF_TYPE_VALUES = Object.values(RefType);

export class InvalidRefTypeError extends Error {
	constructor(type: string | RefType) {
		super(
			`Invalid ref type found in ref selection item. Expected one of ` +
				`"${REF_TYPE_VALUES.join(", ")}", but received "${type}"`,
		);
	}
}

export class InvalidRefIdError extends Error {
	static DATATYPE_FORMAT = '"RefType.Datatype|<syntaxPath>|<restrictionId>"';

	static MESSAGE_ELEMENT_FORMAT = '"RefType.MessageElement|<syntaxPath>"';

	static getFormat(type?: string | RefType) {
		if (type === RefType.Datatype) return InvalidRefIdError.DATATYPE_FORMAT;
		if (type === RefType.MessageElement)
			return InvalidRefIdError.MESSAGE_ELEMENT_FORMAT;
		return `${InvalidRefIdError.DATATYPE_FORMAT} or ${InvalidRefIdError.MESSAGE_ELEMENT_FORMAT}`;
	}

	constructor(refId: string, type?: string | RefType) {
		const format = InvalidRefIdError.getFormat(type);
		super(
			`Invalid ref id: Expected to match format ${format}, but received ` +
				`"${refId}"`,
		);
	}
}

export function parseRefId(refId: string): SpecificRefSelectionItem {
	const [type, qnamePath, restrictionId] = refId.split("|");
	if (type === RefType.Datatype) {
		if (!qnamePath || !restrictionId) {
			throw new InvalidRefIdError(refId, type);
		}
		return {
			type,
			qnamePath: QNamePathSchema.parse(qnamePath),
			restrictionId: RestrictionIdSchema.parse(restrictionId),
		};
	}
	if (type === RefType.MessageElement) {
		if (!qnamePath) {
			throw new InvalidRefIdError(refId, type);
		}
		return { type, qnamePath: QNamePathSchema.parse(qnamePath) };
	}
	throw new InvalidRefTypeError(type);
}

export function stringifyRefId<RefSelection extends SpecificRefSelectionItem>(
	ref: RefSelection,
): string {
	const { type, qnamePath } = ref;
	if (type === RefType.Datatype) {
		const { restrictionId } = ref;
		return `${type}|${qnamePath}|${restrictionId}`;
	}
	if (type === RefType.MessageElement) {
		return `${type}|${qnamePath}`;
	}
	throw new InvalidRefTypeError(type);
}

export function mapRefIds(refIds: string[]): SpecificRefSelectionItem[] {
	return refIds.map((refId) => parseRefId(refId));
}
