/* eslint-disable react/jsx-props-no-spreading */
import { lazy, Suspense } from "react";
import { useAppInfo } from "../AppInfoProvider";
import SystemBanner, { useGlobalClass } from "../SystemBanner";

const EnvInfo = lazy(() => import("./EnvInfo"));

const EnvBanner = (): JSX.Element => {
	const { info } = useAppInfo();

	const envInfo = {
		applicationVersion: info?.build?.version || "",
		branch: info?.build?.branch || "",
		build: info?.build?.number || "",
		buildTimestamp: info?.build?.time || "",
		environment: info?.environment?.name || "",
	};

	const env = (envInfo.environment || "production").toLowerCase();

	// Add environment class to html element, so styling can
	// easily be adjusted depending on env
	useGlobalClass("environment", env);

	return (
		<SystemBanner
			className={`env-info env-info--env-${env}`}
			heightProp="--height-env-banner"
			aria-hidden
		>
			{env !== "production" && (
				<Suspense fallback={null}>
					<EnvInfo {...envInfo} environment={env} />
				</Suspense>
			)}
		</SystemBanner>
	);
};

export default EnvBanner;
