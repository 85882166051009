import { extractNodeProps, extractChildNodes } from "../helpers";
import { RendererImplementation } from "../types";
import createReactRenderer from "./createReactRenderer";
import {
	ValueGroupRenderer,
	TabGroupRenderer,
	TreeRenderer,
	HeaderRenderer,
} from "./RendererImplementations";
import type {
	CustomRendererRegistry,
	ImplementationMap,
	RendererImplementationFn,
} from "./types";

// We'll reuse the container renderer multiple times. Wrap it in a factory
// function, so ts can correctly infer the implementation
function createContainerRenderer<
	Impl extends RendererImplementation,
>(): RendererImplementationFn<Impl> {
	return (domNode, markup, { render }) => {
		extractChildNodes(markup).forEach((node) => {
			const div = document.createElement("div");
			domNode.append(div);
			render(div, node);
		});
	};
}

// This works similarly to a container renderer, but does not create a new dom
// node. Instead, it uses the provided dom node as a container
function createSkipLevelRenderer<
	Impl extends RendererImplementation,
>(): RendererImplementationFn<Impl> {
	return (domNode, markup, { render }) => {
		extractChildNodes(markup).forEach((node) => {
			render(domNode, node);
		});
	};
}

/**
 * Create a map of the supported renderer implementations. It will be used to
 * access the renderer implementation that is referenced in a markup node
 */
function createImplementationMap(
	registry: CustomRendererRegistry,
): ImplementationMap {
	// Define the renderer functions here. Depending on the `xoev-suite:renderer`
	// the matching renderer funciton this map will be used, to create the next
	// subtree
	const implMap: ImplementationMap = {
		// React component renderers can be converted to a render function using
		// the `createReactRenderer` helper
		[RendererImplementation.Tree]: createReactRenderer(TreeRenderer),

		[RendererImplementation.TabGroup]: createReactRenderer(TabGroupRenderer),
		// The `TabGroup` renderer already provides a container for each tab panel,
		// So we just skip the tab level and put all tab markup children in the
		// container provided by `TabGroup`
		[RendererImplementation.Tab]: createSkipLevelRenderer(),

		// A container just takes a group of elements and sticks them in a div.
		// This can be necessary, when a node has multiple sub nodes, that can each
		// be rendererd by a separate renderer, but need some container as a
		// starting point
		[RendererImplementation.Container]: createContainerRenderer(),

		// The document node is always the root node in the markup. It should
		// always contain a `Body` node, which it takes from the markup and
		// delegates to the body renderer
		[RendererImplementation.Document]: (domNode, markup, { render }) => {
			const body = markup.Body;
			render(domNode, body);
		},

		// The body can contain any number and kind of nodes, so we just put them
		// in a container and let the other renderers take care of the rest
		[RendererImplementation.Body]: createContainerRenderer(),

		[RendererImplementation.ValueGroup]:
			createReactRenderer(ValueGroupRenderer),

		[RendererImplementation.Header]: createReactRenderer(HeaderRenderer),

		// Custom renderers are user defined render functions, that are provided
		// through the registry. Since we're calling user code here, we need to
		// make sure the specified renderers actually exist in the registry
		[RendererImplementation.CustomRendering]: (
			domNode,
			markup,
			renderHelpers,
		) => {
			const { "xoev-suite:implementation": customImplementation } =
				extractNodeProps(markup);
			if (!customImplementation) {
				// eslint-disable-next-line no-console
				console.error(
					"Invalid custom renderer implementation.\nA value for " +
						'"xoev-suite:implementation" is required when "xoev-suite:renderer" ' +
						`is set to ${RendererImplementation.CustomRendering}, but none was ` +
						"provided.\nThe subtree will be ignored.\n",
				);
				return;
			}
			if (!(customImplementation in registry)) {
				const possibleRenderers = Object.keys(registry)
					.map((key) => `"${key}"`)
					.join(", ");
				// eslint-disable-next-line no-console
				console.error(
					"Invalid custom renderer implementation.\nThe specified custom renderer " +
						`could not be found. Expected one of ${possibleRenderers}, but got ` +
						`"${customImplementation}".\nThe subtree will be ignored.\n`,
				);
				return;
			}
			const customRenderer = registry[customImplementation];
			customRenderer(domNode, markup, renderHelpers);
		},
	};
	return implMap;
}

export default createImplementationMap;
