import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { useState } from "react";

const DeleteDialoag = ({
	handleDelete,
}: {
	handleDelete: () => void;
}): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleDeleteClick = () => {
		handleDelete();
		handleClose();
	};

	return (
		<>
			<Dialog open={isOpen}>
				<DialogTitle>Profilierung wirklich zurücksetzen?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Alle Eingaben in diesem Zusamenhang gehen damit verloren und werden
						auf den Standard zurück gesetzt
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						onClick={handleClose}
						data-testid="close-delete-modal"
					>
						Nicht zurücksetzen
					</Button>
					<Button
						variant="contained"
						onClick={handleDeleteClick}
						data-testid="delete-profile"
					>
						Zurücksetzen
					</Button>
				</DialogActions>
			</Dialog>
			<Tooltip title="Profilierung zurücksetzen">
				<IconButton onClick={handleOpen} data-testid="open-delete">
					<ReplayIcon />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default DeleteDialoag;
