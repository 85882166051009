import type { ReactNode } from "react";
import React from "react";
import styles from "./HeaderHeading.module.scss";

export default function HeaderHeading({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	return <h1 className={styles.menuHeading}>{children}</h1>;
}
