import { memoize as xoevMemoize } from "@xoev/memo";

export const memoize = xoevMemoize;

const defaultGetCacheKeys = <Args extends unknown[]>(...args: Args): Args =>
	args;

function areCacheKeysEqual<T extends unknown[]>(
	a: T,
	b: T,
	equalityFn = Object.is,
) {
	if (a.length !== b.length) return false;
	return a.every((elem, i) => equalityFn(elem, b[i]));
}

/**
 * Memoize a function, that is likely to be called with the same input a lot
 * consecutively.
 */
export function memoizeOnce<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Fn extends (...args: any[]) => any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	CacheKeys extends any[] = Parameters<Fn>,
>(
	fn: Fn,
	getCacheKeys?: (...args: Parameters<Fn>) => CacheKeys,
	equalityFn = Object.is,
): Fn {
	let cache: { keys: CacheKeys; value: ReturnType<Fn> } | null = null;
	const memoized = (...args: Parameters<Fn>) => {
		const keys = getCacheKeys
			? getCacheKeys(...args)
			: (defaultGetCacheKeys(...args) as unknown as CacheKeys);
		if (cache && areCacheKeysEqual(cache.keys, keys, equalityFn)) {
			return cache.value;
		}
		const value: ReturnType<Fn> = fn(...args);
		cache = { keys, value };
		return value;
	};
	return memoized as Fn;
}

interface MemoMap<K, V> {
	has(key: K): boolean;
	get(key: K): V | undefined;
	set(key: K, value: V): this;
}

const createMapMemoize =
	<K, V>(createMemoMap: () => MemoMap<K, V>) =>
	(fn: (arg: K) => V): ((arg: K) => V) => {
		const cacheMap = createMemoMap();
		const memoized = (arg: K): V => {
			if (cacheMap.has(arg)) return cacheMap.get(arg) as V;
			const res = fn(arg);
			cacheMap.set(arg, res);
			return res;
		};
		return memoized;
	};

/**
 * Memoize a function, that takes one object with a stable reference as an input.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const weakMemoize = <Fn extends (arg: any) => any>(fn: Fn): Fn =>
	createMapMemoize<Parameters<Fn>[0], ReturnType<Fn>>(() => new WeakMap())(
		fn,
	) as Fn;

/**
 * Memoize a function, that takes one argument and is expected to be called
 * with only a few distinct arguments.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const strongMemoize = <Fn extends (arg: any) => any>(fn: Fn): Fn =>
	createMapMemoize<Parameters<Fn>[0], ReturnType<Fn>>(() => new Map())(
		fn,
	) as Fn;
