import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import SystemBannerContext from "./SystemBannerContext";

function SystemBannerProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const [outletRef, setOutletRef] = useState<HTMLDivElement | null>(null);
	const ctx = useMemo(() => ({ outletRef, setOutletRef }), [outletRef]);
	return (
		<SystemBannerContext.Provider value={ctx}>
			{children}
		</SystemBannerContext.Provider>
	);
}

export default SystemBannerProvider;
