var _a;
var PatchAction = /* @__PURE__ */ ((PatchAction2) => {
  PatchAction2["Init"] = "Init";
  PatchAction2["Change"] = "Change";
  PatchAction2["Undo"] = "Undo";
  PatchAction2["Redo"] = "Redo";
  return PatchAction2;
})(PatchAction || {});
function clamp(value, min, max) {
  return Math.max(Math.min(value, max), min);
}
const createStateId = ((_a = globalThis == null ? void 0 : globalThis.crypto) == null ? void 0 : _a.randomUUID) ? (
  // Try to use the browsers built-in uuid generator
  () => crypto.randomUUID()
) : (
  // Fall back to a `Math.random` based id generator
  () => (
    // This is only used in tests in the node environment or cypress
    // eslint-disable-next-line @microsoft/sdl/no-insecure-random
    Math.random().toString(36).replace(/0\./, "").padEnd(12, "0").slice(0, 12)
  )
);
function createStateContainer(stateReducer, initialState) {
  let history = [
    {
      // The current state value
      value: initialState,
      // The list of patches, that lead to the current state.
      // The full state can always be derived from the initial state and all
      // patches that were applied to it over time. Should the state ever be
      // required to be persisted somewhere, storing the initial state and the
      // patches would suffice to re-create the state and the full edit history
      patches: [],
      // A unique id, that identifies a single state entry
      stateId: createStateId()
    }
  ];
  let cursor = 0;
  let generation = 0;
  const getState = (delta = 0) => {
    const index = clamp(cursor + delta, 0, history.length - 1);
    return history[index];
  };
  const getHistoryLength = () => history.length;
  const getHistoryIndex = () => cursor;
  const getStateId = () => getState().stateId;
  const getStateGeneration = () => generation;
  const canUndo = () => history.length > 0 && cursor > 0;
  const canRedo = () => cursor < history.length - 1;
  let latestUpdate = {
    action: PatchAction.Init,
    patches: getState().patches
  };
  const updateListeners = /* @__PURE__ */ new Set();
  const listen = (listener) => {
    updateListeners.add(listener);
    listener(latestUpdate, true);
    return () => {
      updateListeners.delete(listener);
    };
  };
  const notifyListeners = (action) => {
    latestUpdate = { patches: getState().patches, action };
    updateListeners.forEach((listener) => listener(latestUpdate, false));
  };
  const applyPatches = (patches) => {
    const nextState = patches.reduce(
      (acc, patch) => ({
        // Use the user defined state reducer to create the next version of
        // the state
        value: stateReducer(acc.value, patch),
        // Keep the patches around, so they can be reviewed by listeners when
        // undo/redo updates are triggered
        patches: [...acc.patches, patch],
        stateId: acc.stateId
      }),
      { ...getState(), patches: [], stateId: createStateId() }
    );
    cursor += 1;
    generation += 1;
    history = history.slice(0, cursor);
    history.push(nextState);
    notifyListeners(PatchAction.Change);
  };
  const undo = () => {
    if (!canUndo())
      return;
    cursor -= 1;
    generation += 1;
    notifyListeners(PatchAction.Undo);
  };
  const redo = () => {
    if (!canRedo())
      return;
    cursor += 1;
    generation += 1;
    notifyListeners(PatchAction.Redo);
  };
  const batch = (runBatch) => {
    const patches = [];
    const applyPatch2 = (patch) => {
      patches.push(patch);
    };
    runBatch({
      getHistoryIndex,
      getHistoryLength,
      getStateId,
      getStateGeneration,
      getState,
      applyPatch: applyPatch2
    });
    applyPatches(patches);
  };
  const applyPatch = (patch) => {
    applyPatches([patch]);
  };
  return {
    applyPatch,
    getState,
    getHistoryLength,
    getHistoryIndex,
    getStateId,
    getStateGeneration,
    listen,
    batch,
    canUndo,
    canRedo,
    undo,
    redo
  };
}
function createStateReducer(reducerMap) {
  const reducer = (state, patch) => {
    if (!(patch.type in reducerMap)) {
      const typeStr = String(patch.type);
      const expectedTypes = Object.keys(reducerMap).map((key) => `"${key}"`).join(",\n");
      throw new Error(
        `Unknown patch type: The passed patch of type "${typeStr}", cannot be applied. Expected one of:

${expectedTypes}`
      );
    }
    const patchHandler = reducerMap[patch.type];
    return patchHandler(state, patch);
  };
  return reducer;
}
function createPatchCreators(reducerMap) {
  const entries = Object.keys(reducerMap).map((key) => {
    const patchCreator = (payload, meta) => ({ type: key, payload, meta });
    return [key, patchCreator];
  });
  return Object.fromEntries(entries);
}
function createSelector(selector) {
  return (...args) => (state) => selector(state, ...args);
}
export {
  PatchAction,
  createPatchCreators,
  createSelector,
  createStateContainer,
  createStateReducer
};
