// While the structure data is loading, we show the loading indicator. Since
// multiple cascading loading states might all be combined for displaying the
// loading indicator, the overall loading state might be a little unstable.
// There can be points in time where a parentStatus is "success", and the
// structureStatus is "idle", but just about to turn to "loading". We smooth
// over that by delaying the change from "loading" to "success" (or "failure")
// slightly. 300ms is the duration of the element fade in duration, so it
// should be a good value.
// eslint-disable-next-line import/prefer-default-export
export const STRUCTURE_LOADING_SMOOTHING_DELAY = 300;
