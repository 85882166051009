import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./CloseModalButton.scss";

const CloseModalButton = ({
	handleClose,
}: {
	handleClose: () => void;
}): JSX.Element => (
	<span className="close-button">
		<IconButton type="button" size="large" onClick={handleClose}>
			<CloseIcon />
		</IconButton>
	</span>
);

export default CloseModalButton;
