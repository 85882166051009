import { createPath, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSendStoreEvent } from "../AppActor/EventStore/hooks";

export default function NavigationListener(): JSX.Element {
	const location = useLocation();
	const locationString = createPath(location);
	const sendStoreEvent = useSendStoreEvent();

	useEffect(() => {
		sendStoreEvent({ type: "NAVIGATE", payload: { location: locationString } });
	}, [locationString, sendStoreEvent]);

	return <></>;
}
