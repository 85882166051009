export function base64Encode(id: string): string {
	return window.btoa(id).replace(/\//g, "-").replace(/\+/g, "_");
}
export function base64DecodeId(encodedId: string): string {
	return window.atob(encodedId.replace(/-/g, "/").replace(/_/g, "+"));
}

function forceUrlEncoding(char: string) {
	const hexCode = char.charCodeAt(0).toString(16).toUpperCase();
	return `%${hexCode}`;
}

function createSubstitutions(substitutionCharacters: string[]) {
	return substitutionCharacters.map((char) => [char, forceUrlEncoding(char)]);
}

function createEncoder(substitutionCharacters: string[]) {
	const substitutions = createSubstitutions(substitutionCharacters);
	return (str: string) => {
		let encoded = str;
		substitutions.forEach(([char, replacement]) => {
			encoded = encoded.replaceAll(char, replacement);
		});
		return encoded;
	};
}
function createDecoder(substitutionCharacters: string[]) {
	const substitutions = createSubstitutions(substitutionCharacters);
	return (str: string) => {
		let decoded = str;
		substitutions.forEach(([char, replacement]) => {
			decoded = decoded.replaceAll(replacement, char);
		});
		return decoded;
	};
}

const defaultSubstitutions = import.meta.env.DEV ? ["."] : [];

// By default `encodeURIComponent` does not encode the `.` character. This
// breaks vite's spa handling, when the url contains a `.`, since vite
// interprets everything after the `.` as a file extension. Vite thinks we want
// a specific file and does not give us back the `index.html`. This can be
// fixed by forcing the `.` characters to be url encoded
export const forceEncode = createEncoder(defaultSubstitutions);
export const forceDecode = createDecoder(defaultSubstitutions);

const decodeColons = createDecoder([":"]);

export function encodeXPath(id: string): string {
	return decodeColons(forceEncode(encodeURIComponent(id)));
}
export function decodeXPath(encodedId: string): string {
	return decodeURIComponent(forceDecode(encodedId));
}

export function stripLeadingSlash(str: string): string {
	return str.replace(/^\//, "");
}

export function stripTrailingSlash(str: string): string {
	return str.replace(/\/$/, "");
}

export function join(...pathSegments: string[]): string {
	return pathSegments
		.map((segment, i) =>
			i > 0
				? stripLeadingSlash(stripTrailingSlash(segment))
				: stripTrailingSlash(segment),
		)
		.filter(Boolean)
		.join("/");
}
