import classNames from "classnames";
import React, { forwardRef } from "react";
import type { AnyComponent } from "../util";
import type { SubMenuListProps } from "./types";
import styles from "./SubMenuList.module.scss";

type SubMenuListComponent = <TComponent extends AnyComponent = "ul">(
	props: SubMenuListProps<TComponent>,
) => JSX.Element;

// We can't generically type a component coming from `forwardRef`
// unfortunately, so we create a component accepting a broad range of input
// types and cast the correct type to it later
const RawSubMenuList = forwardRef<unknown, SubMenuListProps<AnyComponent>>(
	function SubMenuList({ as: As, children, className, ...props }, ref) {
		const Comp = As || "ul";
		return (
			<Comp
				{...props}
				ref={ref}
				className={classNames(styles.submenuList, className)}
			>
				{children}
			</Comp>
		);
	},
);

const SubMenuList = RawSubMenuList as SubMenuListComponent;

export default SubMenuList;
