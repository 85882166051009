import { useMatch } from "react-router-dom";

export enum MetadataSection {
	Standard = "standard",
	Version = "version",
}

export default function useActiveMetadataSection(): MetadataSection {
	const match = useMatch("/:projektType/:projektId/metadaten/:tabName");
	if (match?.params.tabName === MetadataSection.Version) {
		return MetadataSection.Version;
	}
	return MetadataSection.Standard;
}
