/* eslint-disable import/prefer-default-export */

/**
 * Constrain a value to a minimum and a maximum value.
 *
 * @param value The value, that should be clamped between `min` and `max`
 * @param min The minimum value that will be returned, when `value` is
 * less than `min`
 * @param max The maximum value that will be returned, when `value` is
 * greater than `max`
 * @returns The clamped value
 *
 * @example
 * ```js
 * clamp(5, 0, 10) // => 5
 * clamp(-1, 0, 10) // => 0
 * clamp(11, 0, 10) // => 10
 * ```
 */
export function clamp(value: number, min: number, max: number): number {
	return Math.max(Math.min(value, max), min);
}
