import "./FontExample.scss";

const LOREM =
	"Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique dolores non omnis reprehenderit odit dignissimos commodi minima temporibus. Consectetur quae vel a nobis optio aliquam provident temporibus facilis quis unde porro autem, aliquid quasi ad commodi, asperiores, minus molestiae voluptatem obcaecati! Atque, quibusdam? Corrupti velit tempore minima itaque! Culpa velit tempora distinctio eos accusantium ea quaerat doloribus vel rem blanditiis? Autem distinctio soluta perferendis cumque amet velit pariatur nisi explicabo aliquam, quia et aliquid. At, iure, odio magnam beatae voluptates aliquam cumque dicta unde consequatur, corrupti dolorum consectetur similique accusamus qui eum aliquid? Aliquid porro nostrum sed aperiam velit harum.";

const fontDiv = document.createElement("div");
fontDiv.style.display = "none";
document.body.append(fontDiv);

function getFontStack(fontVar: string) {
	fontDiv.style.fontFamily = fontVar;
	return window.getComputedStyle(fontDiv).fontFamily;
}

function FontExample({
	title,
	fontVar,
}: {
	title: string;
	fontVar: string;
}): JSX.Element {
	return (
		<>
			<h2>{title}</h2>
			<textarea
				className="font-example__textarea"
				rows={10}
				spellCheck={false}
				defaultValue={LOREM}
				style={{ fontFamily: fontVar }}
			/>
			<details>
				<summary>CSS-Fontstack</summary>
				<code>{getFontStack(fontVar)}</code>
			</details>
		</>
	);
}

export default FontExample;
