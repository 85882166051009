import { useContext, useEffect, useMemo, useRef } from "react";
import type { Context, ReactNode } from "react";
import { RequestMethods, RequestStatus, useResource } from "../Api";
import type { SessionContextValue, SessionResponse } from "./types";
import { useEventHandler } from "../../hooks";
import { AppSessionContext } from "./AppSessionContext";

/** Convert seconds to milliseconds */
const toMs = (seconds: number) => seconds * 1000;

/**
 * We must request a new session, before the old one expires. Since the request
 * might take some time we'll start the request 10 seconds before that happens
 */
const RECREATE_BEFORE_EXPIRES_MS = toMs(10);
const NUM_RETRIES_ON_FAILURE = 2;

const Session = ({
	children,
	context: Ctx,
}: {
	children: ReactNode;
	context: Context<SessionContextValue>;
}): JSX.Element => {
	const { setSession } = useContext(Ctx);
	const logoutTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const {
		request: requestCreateNewSession,
		data: session,
		status,
	} = useResource<SessionResponse, RequestMethods.Post>();

	/** Request a new session */
	const requestSession = useEventHandler(() => {
		requestCreateNewSession("/v1/session", {
			method: RequestMethods.Post,
			data: "",
		});
	});
	/** Delete the session */
	const logout = useEventHandler(() => {
		setSession(null);
	});

	// Request a session on mount
	useEffect(() => {
		if (status === RequestStatus.Idle) {
			requestSession();
		}
	}, [requestSession, status]);

	const retriesRef = useRef(NUM_RETRIES_ON_FAILURE);
	// Log out when the session request fails more than twice
	useEffect(() => {
		// Reset the re-tries on success
		if (status === RequestStatus.Success) {
			retriesRef.current = NUM_RETRIES_ON_FAILURE;
		}
		// If there is a failure, we re-try the request a fixed number of times.
		// If that does not work, we log out
		if (status === RequestStatus.Failure) {
			if (retriesRef.current > 0) {
				retriesRef.current -= 1;
				requestSession();
			} else {
				logout();
			}
		}
	}, [logout, requestSession, status]);

	// Store the new session
	useEffect(() => {
		if (status === RequestStatus.Success && session) {
			setSession(session);
		}
	}, [session, status, setSession]);

	// Re-create a new session before it becomes invalid.
	// We set two timers here. One, that ends the current session, when the
	// session expires. The second one runs 10s earlier and requests a new
	// session. The earlier one should succeed before the session is ended.
	// When that happens, we execute this effect again, stop the logout timer
	// and set both timers again.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (status === RequestStatus.Success && session) {
			const { maxAgeInSeconds } = session;
			// Stop the logout timeout. We've got the new session, which means that
			// the reNewSessionTimeout was fast enough and successful, so we now need
			// to set the timers again.
			if (logoutTimeoutRef.current !== null) {
				clearTimeout(logoutTimeoutRef.current);
			}
			const maxAgeInMs = toMs(maxAgeInSeconds);
			// When the max age is smaller than our timeout padding, run the
			// request immediately
			const reNewSessionDelay = Math.max(
				maxAgeInMs - RECREATE_BEFORE_EXPIRES_MS,
				0,
			);
			// The logout needs to run after the reNewSession request has had a
			// chance to run, so we add the timeout padding to it. Theoretically,
			// that means, that the logout runs a short while, after the session has
			// already timed out. But any potential requests in between would not
			// work anyways, so we can risk a slightly late logout, while giving a
			// late reNewSession request the chance to succeed
			const logoutDelay = reNewSessionDelay + RECREATE_BEFORE_EXPIRES_MS;
			const reNewSessionTimeout = setTimeout(() => {
				requestSession();
			}, reNewSessionDelay);
			// Store the timeout in a ref, so we can access it in the next round when
			// the session request has succeeded
			logoutTimeoutRef.current = setTimeout(() => {
				logout();
			}, logoutDelay);

			// Cancel any leftover session requests. Since a loading status of
			// "success" indicates the end of a request, this does not cancel the
			// next request. It will be canceled on unmount, though
			return () => {
				clearTimeout(reNewSessionTimeout);
			};
		}
	}, [logout, requestSession, session, status]);

	const ctx = useMemo(() => ({ session }), [session]);

	return (
		<AppSessionContext.Provider value={ctx}>
			{children}
		</AppSessionContext.Provider>
	);
};

export default Session;
