import { useContext } from "react";
import { TabContext } from "../../ui/Tabs/TabContexts";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
	setMessageUnchecked,
	setAllUnchecked,
	selectCheckedMessageIds,
} from "../../../redux/messagesSlice";
import type { MessageType } from "../MessageOverview/types";
import {
	useDeleteMessageMutation,
	CacheKeys,
	useDeleteMultipleMessagesMutation,
} from "../../../redux/messagesApiSlice";

export default function useMessageContextMenu(
	handleClose: () => void,
	message?: MessageType,
) {
	const { setActiveIndex } = useContext(TabContext);
	const dispatch = useAppDispatch();
	const checkedMessages = useAppSelector(selectCheckedMessageIds());

	const [requestDelete] = useDeleteMessageMutation({
		fixedCacheKey: CacheKeys.MessageDeletion,
	});
	const [requestDeleteMultiple] = useDeleteMultipleMessagesMutation({
		fixedCacheKey: CacheKeys.MultipleMessageDeletion,
	});

	const deleteMessage = (currentMessage: MessageType) => {
		const { id: messageId } = currentMessage;
		requestDelete({ messageId });
		dispatch(setMessageUnchecked({ messageId }));
	};

	const deleteMultipleMessages = () => {
		if (checkedMessages.length) {
			requestDeleteMultiple({ messageIds: checkedMessages });
			dispatch(setAllUnchecked());
		}
	};

	const handleDeleteMessages = () => {
		if (message) {
			deleteMessage(message);
		} else {
			deleteMultipleMessages();
		}
		handleClose();
		setActiveIndex(0);
	};

	return { handleDeleteMessages };
}
