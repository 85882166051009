// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unknownErrorToString(error: any): string {
	let message = String(error);
	if (typeof error === "string") {
		message = error;
	} else if (typeof error === "object" && error) {
		if ("message" in error && typeof error.message === "string") {
			message = error.message;
		} else {
			try {
				message = error?.toString?.();
			} catch {
				// If this doesn't work, we'll just use the `String(error)` fallback
			}
		}
	}
	return message;
}

export interface ErrorSerialization {
	message: string;
	name?: string;
	stack?: string;
}

export interface ErrorParams {
	message: string;
	name: string;
	cause?: unknown;
	stack?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function getErrorMessage(error: unknown): string {
	if (error && typeof error === "string") {
		return error;
	}
	if (error && error instanceof Error) {
		return error.message;
	}
	return String(error);
}

function createSerializableErrorMessage(params: ErrorParams) {
	const { name, message } = params;
	return `${name}: ${message}`;
}

type PropertyType<Type extends "string" | "number"> = Type extends "string"
	? string
	: Type extends "number"
	? number
	: never;
type ExtractedProperty<
	Type extends "string" | "number",
	Default extends PropertyType<Type> | undefined,
> = Default extends undefined
	? PropertyType<Type> | undefined
	: PropertyType<Type>;

function isType<Type extends "string" | "number">(
	thing: unknown,
	type: Type,
): thing is PropertyType<Type> {
	// eslint-disable-next-line valid-typeof
	return typeof thing === type;
}

/** Try to extract a property from an unknown object */
function extractProperty<
	Type extends "string" | "number",
	Default extends PropertyType<Type> | undefined =
		| PropertyType<Type>
		| undefined,
>(
	thing: unknown,
	key: string,
	type: Type,
	defaultValue?: Default,
): ExtractedProperty<Type, Default> {
	const attr =
		thing &&
		typeof thing === "object" &&
		key in thing &&
		(thing as Record<PropertyKey, unknown>)[key];
	return (isType(attr, type) ? attr : defaultValue) as ExtractedProperty<
		Type,
		Default
	>;
}

/**
 * A flexible error class, that can be transferred from server to client and
 * includes useful meta information for common errors, such as a special name
 * status codes and status texts
 */
export class SerializableError extends Error {
	name: string;

	customStack?: string;

	constructor(params: ErrorParams) {
		super(createSerializableErrorMessage(params), { cause: params.cause });
		const { name, stack } = params;
		this.name = name;
		this.customStack = stack;
	}

	/**
	 * Create a serializable object representation of the error, so it can be
	 * passed between different contexts, such as workers
	 * @returns A serializable object representation of the error
	 */
	toSerializable(): ErrorSerialization {
		return {
			message: this.message,
			name: this.name,
			stack: this.customStack ?? this.stack,
		};
	}

	/**
	 * Serialize the error to a string
	 * @returns A serialized representation of the error
	 */
	serialize(): string {
		return JSON.stringify(this.toSerializable(), null, 2);
	}

	/**
	 * Extract as much information from an unknown error cause as possible
	 * @param error An unknown error
	 * @returns A docs error that includes the causing errors information
	 */
	static from(error: unknown): SerializableError {
		if (error instanceof SerializableError) return error;
		const message = getErrorMessage(error);
		const stack = extractProperty(error, "stack", "string");
		const name = extractProperty(error, "name", "string", "UnknownError");
		const cause =
			error && typeof error === "object" && "cause" in error && error.cause;
		return new SerializableError({
			message,
			name,
			cause,
			stack,
		});
	}
}

export class UnreachableError extends SerializableError {
	constructor(message: string, options?: ErrorOptions) {
		super({
			name: "UnreachableError",
			message: `Impossible state detected. This code path should never have been executed. ${message}`,
			...options,
		});
	}
}

export class AssertionError extends SerializableError {
	constructor(message: string, options?: ErrorOptions) {
		super({ name: "AssertionError", message, ...options });
	}

	static notNull<T>(thing: T, message = ""): asserts thing is Exclude<T, null> {
		if (thing === null) {
			throw new AssertionError(`Expected value not to be \`null\`. ${message}`);
		}
	}

	static notUndefined<T>(
		thing: T,
		message = "",
	): asserts thing is Exclude<T, undefined> {
		if (thing === undefined) {
			throw new AssertionError(
				`Expected value not to be \`undefined\`. ${message}`,
			);
		}
	}

	static notNullish<T>(
		thing: T,
		message = "",
	): asserts thing is Exclude<T, null | undefined> {
		AssertionError.notUndefined(thing, message);
		AssertionError.notNull(thing, message);
	}

	static asNotNull<T>(thing: T, message = ""): Exclude<T, null> {
		AssertionError.notNull(thing, message);
		return thing;
	}

	static asNotUndefined<T>(thing: T, message = ""): Exclude<T, undefined> {
		AssertionError.notUndefined(thing, message);
		return thing;
	}

	static asNotNullish<T>(
		thing: T,
		message?: string,
	): Exclude<T, null | undefined> {
		AssertionError.notNull(thing, message);
		AssertionError.notUndefined(thing, message);
		return thing as Exclude<T, null | undefined>;
	}
}
