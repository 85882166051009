import { createContext } from "react";
import type { InternalApiContext } from "./types";

const ApiContext = createContext<InternalApiContext>({
	origin: null as never,
	base: null as never,
	fetch: null as never,
	sessionContext: null as never,
});

export default ApiContext;
