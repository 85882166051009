import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import type {
	GenerierteNachrichtId,
	MessageType,
} from "../components/MessageView/MessageOverview/types";
import type { RequestError } from "../components/Api";

const notifications: {
	[endpointName: string]: string;
} = {
	getMessages: "Fehler beim Abrufen der Nachrichten",
	generateMessages:
		"Beim Generieren der Nachrichten ist ein Fehler aufgetreten",
	updateMessage: "Fehler beim Aktualisieren der Nachricht",
	deleteMessage: "Beim Löschen der Nachricht ist ein Fehler aufgetreten",
	deleteMultipleMessages: "Fehler beim Löschen der Nachrichten",
	downloadMultipleReports: "Fehler beim Herunterladen der Validierungsberichte",
	downloadMultipleMessages:
		"Beim Herunterladen der Nachrichten ist ein Fehler aufgetreten",
	refreshViewer: "Fehler beim Aktualisieren der Validierung",
};

export enum ValidationBase {
	Standard = "STANDARD",
	ActiveProfile = "AKTIVES_PROFIL",
}

export enum CheckStatus {
	None = "NONE",
	Partial = "PARTIAL",
	All = "ALL",
}

export type MessagesState = {
	validierungsBasis: ValidationBase;
	checkedMessageIds: GenerierteNachrichtId[];
	selectedTabs: string[];
	activeTab: number;
	rejectedRequests: {
		endpointName: string;
		error: RequestError;
		notification: string;
	}[];
};

const initialState: MessagesState = {
	validierungsBasis: ValidationBase.Standard,
	selectedTabs: [],
	activeTab: 0,
	checkedMessageIds: [],
	rejectedRequests: [],
};

const messagesSlice = createSlice({
	name: "messages",
	initialState,
	reducers: {
		setValidationBase(
			state,
			action: PayloadAction<{ validierungsBasis: ValidationBase }>,
		) {
			const { validierungsBasis } = action.payload;
			return {
				...state,
				validierungsBasis,
			};
		},
		setMessageChecked(
			state,
			action: PayloadAction<{ messageId: GenerierteNachrichtId }>,
		) {
			const { messageId } = action.payload;
			const checkedMessageIds = [...state.checkedMessageIds, messageId];
			return {
				...state,
				checkedMessageIds,
			};
		},
		setTabId(
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) {
			const { id } = action.payload;
			if (state.selectedTabs.includes(id)) return state;
			const selectedTabs = [...state.selectedTabs, id];
			return {
				...state,
				selectedTabs,
			};
		},
		setAllTabIds(state, action: PayloadAction<{ ids: string[] }>) {
			const { ids } = action.payload;
			return {
				...state,
				selectedTabs: ids,
			};
		},
		setActiveTab(state, action: PayloadAction<{ activeTab: number }>) {
			const { activeTab } = action.payload;
			return {
				...state,
				activeTab,
			};
		},
		setMessageUnchecked(
			state,
			action: PayloadAction<{ messageId: GenerierteNachrichtId }>,
		) {
			const { messageId } = action.payload;
			const checkedMessageIds = state.checkedMessageIds.filter(
				(checkedMessageId) => checkedMessageId !== messageId,
			);
			return {
				...state,
				checkedMessageIds,
			};
		},
		setAllChecked(state, action: PayloadAction<{ messages: MessageType[] }>) {
			const { messages } = action.payload;
			const checkedMessageIds = messages.map((message) => message.id);
			return {
				...state,
				checkedMessageIds,
			};
		},
		setAllUnchecked(state) {
			return {
				...state,
				checkedMessageIds: [],
			};
		},
		setRejectedRequest(
			state,
			action: PayloadAction<{
				endpointName: string;
				error: RequestError;
			}>,
		) {
			const { endpointName, error } = action.payload;
			const notification = notifications[endpointName];
			return {
				...state,
				rejectedRequests: [
					...state.rejectedRequests,
					{ endpointName, error, notification },
				],
			};
		},
		removeTabId(state, action: PayloadAction<{ id: string }>) {
			const { id } = action.payload;
			const { selectedTabs } = state;
			return {
				...state,
				selectedTabs: selectedTabs.filter((tab) => tab !== id),
			};
		},
		removeAllTabIds(state) {
			return {
				...state,
				selectedTabs: [],
			};
		},
		removeRejectedRequest(
			state,
			action: PayloadAction<{ endpointName: string }>,
		) {
			const { rejectedRequests } = state;
			const { endpointName } = action.payload;
			return {
				...state,
				rejectedRequests: rejectedRequests.filter(
					(rejectedRequest) => rejectedRequest.endpointName !== endpointName,
				),
			};
		},
	},
});

export const {
	setValidationBase,
	setMessageChecked,
	setMessageUnchecked,
	setAllChecked,
	setAllUnchecked,
	setTabId,
	setActiveTab,
	setRejectedRequest,
	setAllTabIds,
	removeRejectedRequest,
	removeTabId,
	removeAllTabIds,
} = messagesSlice.actions;

export const selectValidationBase = () => (state: RootState) =>
	state.messages.validierungsBasis;

export const selectCheckedMessageIds = () => (state: RootState) =>
	state.messages.checkedMessageIds;

export const selectOpenTabs = () => (state: RootState) =>
	state.messages.selectedTabs;

export const selectIsMessageChecked =
	(messageId: GenerierteNachrichtId) => (state: RootState) =>
		state.messages.checkedMessageIds.includes(messageId);

export const selectActiveTab = () => (state: RootState) =>
	state.messages.activeTab;

export const selectCheckedAllStatus =
	(messages?: MessageType[]) => (state: RootState) => {
		const { checkedMessageIds } = state.messages;
		if (!messages || !checkedMessageIds.length) return CheckStatus.None;
		const messageIds = messages.map((message) => message.id);
		if (messageIds.every((id) => checkedMessageIds.includes(id)))
			return CheckStatus.All;
		return CheckStatus.Partial;
	};

export const selectRejectedRequests = () => (state: RootState) =>
	state.messages.rejectedRequests;

export default messagesSlice.reducer;
