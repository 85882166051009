import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import navigationQueryMachine from "./navigation.query.machine";
import navigationCommandMachine from "./navigation.command.machine";

// eslint-disable-next-line no-use-before-define
export type NavigationActorRef = ActorRefFrom<typeof navigationMachine>;

const navigationMachine = setup({
	types: {
		children: {} as {
			"navigation:query": "query";
			"navigation:command": "command";
		},
	},
	actors: {
		query: navigationQueryMachine,
		command: navigationCommandMachine,
	},
}).createMachine({
	id: "navigation",
	invoke: [
		{ src: "query", systemId: "navigation:query", id: "navigation:query" },
		{
			src: "command",
			systemId: "navigation:command",
			id: "navigation:command",
		},
	],
});

export default navigationMachine;
