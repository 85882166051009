import "./Surfaces.scss";

const DEFAULT_TEXT = "text-main";

function Surfaces({
	varName,
	range,
}: {
	varName: string;
	range: (number | { level: number; textVar: string })[];
}): JSX.Element {
	const boxes = range.reduceRight((acc, item) => {
		const level = typeof item === "number" ? item : item.level;
		const textVar = typeof item === "number" ? DEFAULT_TEXT : item.textVar;
		return (
			<div
				data-surface={`${varName}-${level}`}
				className="surfaces__surface"
				style={{
					backgroundColor: `var(--${varName}-${level})`,
					color: `var(--${textVar})`,
				}}
			>
				{acc}
			</div>
		);
	}, <></>);

	return <div className="surfaces">{boxes}</div>;
}

export default Surfaces;
