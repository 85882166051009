import { useMemo } from "react";
import { useActiveProjectId } from "../../AppActor/actors/navigation/hooks";
import { createVersionMetadataFields } from "./metadataFormHelpersLite";
import { useSendStoreEvent } from "../../AppActor/EventStore/hooks";
import { useMetadataVersion } from "../../AppActor/actors/modellierungModel/hooks";
import {
	MetadatenVersionSchema,
	type MetadatenVersion,
} from "../../AppActor/actors/modellierungModel/schemas";
import EditForm from "../../EditForm";
import { useIsProjektDisplayOnly } from "../../AppActor/actors/project/hooks";
import "./MetadataEditFormLite.scss";

const MetadataVersionEditFormLite = (): JSX.Element => {
	const projektId = useActiveProjectId();
	const metadata = useMetadataVersion();
	const isReadOnly = useIsProjektDisplayOnly();

	const fields = useMemo(() => {
		const { visibleFields } = createVersionMetadataFields({
			readOnly: isReadOnly,
		});
		return visibleFields;
	}, [isReadOnly]);

	const sendStoreEvent = useSendStoreEvent();

	if (!projektId) {
		throw new Error("No active project found");
	}

	if (metadata.isEmpty()) {
		return <></>;
	}

	const handleWrite = (
		name: keyof MetadatenVersion,
		value: MetadatenVersion[keyof MetadatenVersion],
	) => {
		if (projektId) {
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId,
					patch: {
						type: "changeMetadataVersionValue",
						payload: {
							name,
							value: value ?? "",
						},
					},
				},
			});
		}
	};

	return (
		<div data-testid="project-metadata-form-lite" data-project-id={projektId}>
			<EditForm
				fields={fields}
				onWrite={handleWrite}
				schema={MetadatenVersionSchema}
				values={metadata}
			/>
		</div>
	);
};
export default MetadataVersionEditFormLite;
