import type SavedProfileData from "../../types/SavedProfileData";
import type { RequestStatus } from "../Api";
import type { RequestError } from "../Api/types";

export enum SaveContentType {
	Zip = "application/zip",
	Json = "application/json",
}

export type EditorDataContextType = {
	saveZip: () => void;
	saveToSession: () => void;
	statusZip: RequestStatus;
	statusSaveToSession: RequestStatus;
	errorZip: RequestError | null;
	errorSaveToSession: RequestError | null;
	initContainer: (data: SavedProfileData) => void;
	initStatus: RequestStatus;
	initError: RequestError | null;
	resetContainer: () => void;
};
