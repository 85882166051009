import Icon from "@mui/icons-material/AllInclusive";
import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";

const RecursionIcon = ({
	isRecursive,
}: {
	isRecursive: boolean;
}): JSX.Element => (
	<>
		{isRecursive && (
			<Tooltip title="Das Element ist rekursiv">
				<NodeIcon className="recursion-icon">
					<VisuallyHidden>Information: Das Element ist rekursiv</VisuallyHidden>
					<Icon />
				</NodeIcon>
			</Tooltip>
		)}
	</>
);

export default RecursionIcon;
