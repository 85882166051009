import { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { useDescendantList } from "../util/descendants";
import type { TabsProps } from "./types";
import {
	TabContext,
	TabDescendantsContext,
	TabPanelDescendantsContext,
} from "./TabContexts";
import useSyncedState from "../../../hooks/useSyncedState";
import useSyncedRef from "../../../hooks/useSyncedRef";
import Tab from "./Tab";
import TabList from "./TabList";
import TabPanels from "./TabPanels";
import TabPanel from "./TabPanel";

const Tabs = ({
	className = "",
	children,
	activeIndex: activeIndexProp = 0,
	orientation = "horizontal",
	onIndexChange,
	...props
}: TabsProps) => {
	const { items: tabs, Provider: TabProvider } = useDescendantList(
		TabDescendantsContext,
	);
	const { items: panels, Provider: PanelProvider } = useDescendantList(
		TabPanelDescendantsContext,
	);

	const [activeIndex, setActiveIndexState] = useSyncedState(activeIndexProp);
	const activeTab = tabs[activeIndex];
	const activePanel = panels[activeIndex];
	const activeTabRef = activeTab?.ref;
	const onIndexChangeRef = useSyncedRef(onIndexChange);
	const setActiveIndex = useCallback(
		(i) => {
			setActiveIndexState(i);
			onIndexChangeRef.current?.(i);
		},
		[onIndexChangeRef, setActiveIndexState],
	);
	const isInitialRenderRef = useRef(true);

	useEffect(() => {
		if (!isInitialRenderRef.current) {
			activeTabRef?.focus();
		}
		if (activeTabRef) {
			isInitialRenderRef.current = false;
		}
	}, [activeTabRef]);

	const ctx = useMemo(
		() => ({
			activeIndex,
			setActiveIndex,
			activeTab,
			activePanel,
			tabs,
			panels,
			orientation,
		}),
		[
			activeIndex,
			setActiveIndex,
			activeTab,
			activePanel,
			tabs,
			panels,
			orientation,
		],
	);

	return (
		<TabContext.Provider value={ctx}>
			<TabProvider>
				<PanelProvider>
					<div
						className={classNames(
							"xui-tabs",
							`xui-tabs--orientation-${orientation}`,
							className,
						)}
						data-xui-component="tabs"
						data-xui-element="tabs"
						{...props}
					>
						{children}
					</div>
				</PanelProvider>
			</TabProvider>
		</TabContext.Provider>
	);
};

Tabs.List = TabList;
Tabs.Panel = TabPanel;
Tabs.Panels = TabPanels;
Tabs.Tab = Tab;

export default Tabs;
