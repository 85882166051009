import { useContext } from "react";
import classNames from "classnames";
import { TabContext } from "./TabContexts";
import type { TabPanelsProps } from "./types";

const TabPanels = ({ className, children, ...props }: TabPanelsProps) => {
	const { orientation } = useContext(TabContext);

	return (
		<div
			className={classNames(
				"xui-tab-panels",
				`xui-tab-panels--orientation-${orientation}`,
				className,
			)}
			data-xui-component="tabs"
			data-xui-element="tab-panels"
			{...props}
		>
			{children}
		</div>
	);
};

export default TabPanels;
