/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import type { DropEvent, FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { DropzoneProps } from "./types";
import "./Dropzone.scss";

const Dropzone = ({
	accept,
	children,
	className,
	activeClassName,
	multiple = false,
	name,
	onDrop,
	hasSelectedFiles,
	...props
}: DropzoneProps): JSX.Element => {
	const handleDrop = (
		accepted: File[],
		fileRejections: FileRejection[],
		event: DropEvent,
	) => {
		const rejected = fileRejections.map((rejection) => rejection.file);
		const error = fileRejections.map((rejection) => rejection.errors).flat();
		onDrop(accepted, rejected, error, event);
	};
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({ accept, multiple, onDrop: handleDrop, ...props });
	return (
		<div
			{...getRootProps()}
			className={classNames(
				"xui-dropzone",
				isDragActive && "xui-dropzone--active",
				hasSelectedFiles && "xui-dropzone--has-files",
				className,
				isDragActive && activeClassName,
			)}
			data-testid="dropzone"
			data-is-drag-active={isDragActive}
			data-is-drag-accepted={isDragAccept}
			data-is-drag-rejected={isDragReject}
			data-has-files={hasSelectedFiles}
		>
			<div
				className={classNames(
					"xui-dropzone__file-hover-preview",
					isDragActive && "xui-dropzone__file-hover-preview--active",
				)}
			>
				<InsertDriveFileIcon className="xui-dropzone__file-hover-icon" />
			</div>
			{children}
			<input name={name} {...getInputProps()} data-testid="dropzone-input" />
		</div>
	);
};

export default Dropzone;
