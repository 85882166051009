import { Autocomplete, TextField } from "@mui/material";
import useGenerationBaseSelect from "./useGenerationBaseSelect";
import DynamicInfo from "../../../ui/DynamicInfo";
import "./GenerationBaseSelect.scss";

const GenerationBaseSelect = (): JSX.Element => {
	const {
		selectedOption,
		standardsList: options,
		handleStandardChange,
	} = useGenerationBaseSelect();

	return (
		<>
			{options && (
				<div className="generation-base-select">
					<Autocomplete
						className="generation-base-select__autocomplete"
						disablePortal
						id="generation-base-select"
						data-testid="generation-base-select"
						options={options}
						value={selectedOption}
						onChange={handleStandardChange}
						renderInput={(params) => <TextField {...params} label="Standard" />}
					/>
					<DynamicInfo infoKey="generationBaseSelect" inline="end" />
				</div>
			)}
		</>
	);
};

export default GenerationBaseSelect;
