import { isCollection, Set as ISet } from "immutable";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import type { EditorState } from "./types";

export const EMPTY_INNER_EDITOR_STATE = {
	metadaten: createImmutableMap({}),
	profile: createImmutableMap({}),
	dokumentation: createImmutableMap({}),
	datentypen: createImmutableMap({}),
	konfiguration: createImmutableMap({ profilierung: createImmutableMap({}) }),
};

export const EMPTY_EDITOR_STATE: EditorState = createImmutableMap(
	EMPTY_INNER_EDITOR_STATE,
);

// eslint-disable-next-line import/prefer-default-export
export function isStateEqual<NodeType = unknown>(
	stateA: ImmutableMap<NodeType> | undefined | null,
	stateB: ImmutableMap<NodeType> | undefined | null,
): boolean {
	// both or either state can be undefined at the beginning
	if (!stateA && !stateB) return true;
	if (!stateA && stateB) return false;
	if (stateA && !stateB) return false;
	// This check is not necessary, but otherwise, TS thinks that either stateA
	// or stateB could still be undefined and complain further down
	if (!stateA || !stateB) return false;

	// Make sure to compare every entry that is in either of the maps, without
	// checking an entry twice. The `ISet` will take care of filtering the
	// duplicate keys
	const keySet = ISet(stateA.keys()).concat(stateB.keys());
	return keySet.every(
		// Treat `null` and `""` equally
		(key) => (stateA.get(key) || "") === (stateB.get(key) || ""),
	);
}

/**
 * Check if an object only contains empty sub-objects. Empty sub-objects are
 * `""`, `[]`, `{}`, `null`, `undefined` as well as empty immutable `Map`s,
 * `Set`s and `Seq`s. `boolean`s, `number`s and non-empty `string`s count as
 * non-empty.
 *
 * @param obj The object you want to check
 * @returns Whether the object only contains empty sub-objects
 *
 * @example
 * ```js
 * isDeepEmpty({}) // -> true
 * isDeepEmpty({ a: [] }) // -> true
 * isDeepEmpty({ a: [""] }) // -> true
 * isDeepEmpty(createImmutableMap({ a: createImmutableMap({ x: null }) })) // -> true
 * isDeepEmpty({ a: 0 }) // -> false
 * isDeepEmpty(createImmutableMap({ a: "some value" })) // -> false
 * isDeepEmpty(createImmutableMap({ a: false })) // -> false
 * ```
 */
export function isDeepEmpty(obj: unknown): boolean {
	if (obj === false) return false;
	if (obj === 0) return false;
	if (!obj) return true;
	if (isCollection(obj) && obj.isEmpty()) return true;
	if (
		(isCollection(obj) || Array.isArray(obj)) &&
		obj.every((value) => isDeepEmpty(value))
	)
		return true;
	return (
		typeof obj === "object" &&
		Object.values(obj).every((value) => isDeepEmpty(value))
	);
}
