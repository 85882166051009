import DeleteIcon from "@mui/icons-material/Delete";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useStateSelector } from "../../../EditorState";
import { selectPropertyReferences } from "../../../EditorState/selectors";
import type { PropertyValues } from "../../../EditorState/types";
import RefTable from "../../../RefTable";
import "./DeletePropertyButton.scss";

export default function DeletePropertyButton({
	onDelete,
	property,
}: {
	onDelete: (propertyNameTechnisch: string) => void;
	property: PropertyValues;
}): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);

	const refs = useStateSelector(
		selectPropertyReferences(property.nameTechnisch),
	);

	const handleClose = () => setIsOpen(false);
	const handleOpen = () => setIsOpen(true);
	const handleConfirm = () => {
		onDelete(property.nameTechnisch);
		handleClose();
	};

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle>Eigenschaft löschen</DialogTitle>
				<DialogContent>
					<DialogContentText component="div">
						{refs.count() > 0 && (
							<>
								Das Eigenschaft <b>{property.name}</b> wird bereits in Profilen
								verwendet. Wenn das Eigenschaft gelöscht wird, wird es auch aus
								allen Profilen, in denen es verwendet wird, gelöscht.
								<RefTable refs={refs} />
							</>
						)}
						<>Möchten Sie das Eigenschaft wirklich löschen?</>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						autoFocus
						variant="outlined"
						data-testid="close-button"
					>
						Abbrechen
					</Button>
					<Button
						onClick={handleConfirm}
						variant="contained"
						data-attribute-id={property.nameTechnisch}
						data-testid="confirm-delete-button"
					>
						Löschen
					</Button>
				</DialogActions>
			</Dialog>
			<Tooltip title="Eigenschaft löschen">
				<span className="delete-attribute-button__button-wrapper">
					<IconButton onClick={handleOpen} data-testid="delete-button">
						<DeleteIcon />
					</IconButton>
				</span>
			</Tooltip>
		</>
	);
}
