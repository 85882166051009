import { useMemo } from "react";
import type { ReactNode } from "react";
import FieldRendererContext from "./FieldRendererContext";

const FieldRendererProvider = ({
	isStandardRenderer,
	children,
}: {
	isStandardRenderer?: boolean;
	children: ReactNode;
}): JSX.Element => {
	const ctx = useMemo(() => ({ isStandardRenderer }), [isStandardRenderer]);
	return (
		<FieldRendererContext.Provider value={ctx}>
			{children}
		</FieldRendererContext.Provider>
	);
};

export default FieldRendererProvider;
