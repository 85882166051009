import type { FormEventHandler, SyntheticEvent } from "react";
import { useMemo, useEffect, useState } from "react";
import {
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Switch,
	Tab,
	Tooltip,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import classNames from "classnames";
import createImmutableMap from "@xoev/immutable-map";
import { PatchAction } from "@xoev/state-container";
import { useEventHandler, useSyncedRef } from "../../../hooks";
import type { SavedProfileDocumentation } from "../../../types/SavedProfileData";
import {
	isStateEqual,
	useApplyPatch,
	useEditorPatchCreators,
	useStateContainerContext,
	useStateSelector,
	useUpdateListener,
} from "../../EditorState";
import {
	selectAdocMarkup,
	selectDocChapter,
	selectMetadata,
} from "../../EditorState/selectors";
import useActiveDocTitle from "../useActiveDocTitle";
import EditorContent from "../../EditorContent";
import DownloadPdfButton from "./DownloadPdfButton";
import { useGeneratedChapters } from "../GeneratedChapterProvider";
import { RequestStatus } from "../../Api";
import AdocRenderer from "../../AdocRenderer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import type { SelectedTab } from "../../../redux/documentationSlice";
import {
	toggleIsFullPreview,
	setSelectedTab,
	selectIsFullPreview,
	selectSelectedTab,
} from "../../../redux/documentationSlice";
import "./DocumentationEditView.scss";

function createAdocConfig(kennung: string) {
	const basedir = `/api/v1/projekt/${kennung}/resources`;
	return {
		base_dir: basedir,
		attributes: {
			imagesdir: basedir,
		},
	};
}

const DocumentationEditView = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isFullPreview = useAppSelector(selectIsFullPreview());
	const tabIndex = useAppSelector(selectSelectedTab());

	const kennung = useStateSelector(selectMetadata()).get("kennung");

	const [activeDocTitleKey] = useActiveDocTitle();
	const { chapters: generatedChapters, status } = useGeneratedChapters();

	const { getState } = useStateContainerContext();
	const applyPatch = useApplyPatch();
	const { setDocumentation } = useEditorPatchCreators();

	const getMarkup = isFullPreview
		? selectAdocMarkup(generatedChapters)
		: selectAdocMarkup(generatedChapters, activeDocTitleKey);
	const markup = useStateSelector(getMarkup);
	const adocMarkup = status === "success" ? markup : undefined;
	const adocConfig = useMemo(
		() => createAdocConfig(kennung || "__EMPTY__"),
		[kennung],
	);

	const values = useStateSelector(selectDocChapter(activeDocTitleKey));
	const [localState, setLocalState] = useState(values);
	const localStateRef = useSyncedRef(localState);

	const writeLocalState = useEventHandler(() => {
		const currentChapter = selectDocChapter(activeDocTitleKey)(
			getState().value,
		);
		const localChapter = localStateRef.current;
		// Only add an entry to the stack when something has changed.
		// We cannot just check for referential equality here because
		// a value of `null` and a value of `""` would not pass that check,
		// but seem to be identical to the user
		if (
			localChapter &&
			!isStateEqual<SavedProfileDocumentation>(currentChapter, localChapter)
		) {
			applyPatch(
				setDocumentation({
					name: activeDocTitleKey,
					documentation: localChapter,
				}),
			);
		}
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			writeLocalState();
		}, 1000);
		return () => clearTimeout(timeout);
	}, [localState, writeLocalState]);

	useEffect(
		() => () => {
			writeLocalState();
		},
		[writeLocalState],
	);

	// When clicking another node, then we need to re-init the local state for this new node
	useEffect(() => {
		setLocalState(values);
	}, [values]);

	// Sync the state to the global values, so we update correctly when the
	// global state changes (i.e. undo/redo)
	useUpdateListener(({ action }) => {
		// Set the local state on undo/redo/reset
		if (action !== PatchAction.Change) {
			setLocalState(selectDocChapter(activeDocTitleKey)(getState().value));
		}
	});

	const handleChange = (event: {
		target: { name?: string; value: string };
	}) => {
		const { name, value } = event.target;
		setLocalState((prevState) =>
			(prevState || createImmutableMap()).set(
				name as keyof SavedProfileDocumentation,
				value as string,
			),
		);
	};

	const handleBlur = () => writeLocalState();

	const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
	};

	const handleTabChange = (event: SyntheticEvent, newTab: SelectedTab) => {
		dispatch(setSelectedTab({ newTab }));
	};

	const isGenerated = !!localState?.get("generiert");
	const textAreaValue =
		(!isGenerated
			? localState?.get("inhalt")
			: generatedChapters[activeDocTitleKey]?.inhalt) || "";

	return (
		<EditorContent
			className="documentation-edit-view"
			data-testid="documentation-edit-view"
			data-active-doc={activeDocTitleKey}
			padTop={false}
		>
			<TabContext value={tabIndex}>
				<div className="documentation-edit-view__tablist-wrapper">
					<TabList
						className="documentation-edit-view__tablist"
						onChange={handleTabChange}
						aria-label="Dokumentation Tabs"
					>
						<Tab
							data-testid="documentation-edit-view__tab-edit"
							label="Bearbeitung"
							value="edit"
						/>
						<Tab
							data-testid="documentation-edit-view__tab-preview"
							label="Preview"
							value="preview"
						/>
					</TabList>

					<div className="documentation-edit-view__actions">
						<DownloadPdfButton />
						<FormGroup>
							<Tooltip
								title={
									"Mit diesem Schalter wählen Sie aus, ob die Vorschau der gesamten " +
									"Dokumentation angezeigt wird, oder nur die Vorschau des jeweils " +
									"ausgewählten Abschnitts."
								}
								placement="bottom"
							>
								<FormControlLabel
									control={
										<Switch
											checked={isFullPreview}
											onChange={() => dispatch(toggleIsFullPreview())}
											data-testid="documentation-edit-view__full-preview-toggle"
										/>
									}
									label="Gesamtvorschau"
								/>
							</Tooltip>
						</FormGroup>
					</div>
				</div>
				<TabPanel value="edit" className="documentation-edit-view__panel">
					<form
						onSubmit={handleSubmit}
						className="documentation-edit-view__form"
					>
						<div
							className={classNames(
								"documentation-edit-view__loader",
								isGenerated &&
									status === RequestStatus.Loading &&
									"documentation-edit-view__loader--loading",
							)}
						>
							<CircularProgress />
						</div>
						<textarea
							className={classNames(
								"documentation-edit-view__textarea",
								isGenerated && "documentation-edit-view__textarea--read-only",
							)}
							data-testid="documentation-edit-view__textarea"
							name="inhalt"
							value={textAreaValue}
							onChange={handleChange}
							onBlur={handleBlur}
							readOnly={isGenerated}
							spellCheck="false"
						/>
					</form>
				</TabPanel>
				<TabPanel
					value="preview"
					className="documentation-edit-view__panel documentation-edit-view__panel--preview"
				>
					<AdocRenderer
						className="documentation-edit-view__preview"
						markup={adocMarkup}
						config={adocConfig}
					/>
				</TabPanel>
			</TabContext>
		</EditorContent>
	);
};

export default DocumentationEditView;
