import type { GridRenderCellParams } from "@mui/x-data-grid";
import type { RestrictionId } from "../../../../../EditorState/types";
import type {
	LiteId,
	QName,
	QNamePath,
} from "../../../../../AppActor/actors/modellierungModel/schemas";

export enum RefType {
	Datatype = "Datatype",
	MessageElement = "MessageElement",
}

export interface RefItem {
	id: string;
	qnamePath: QNamePath;
	idPath: LiteId[];
	rootQName: QName;
	elementName: string;
	type: RefType;
}

export interface DatatypeRefItem extends RefItem {
	type: RefType.Datatype;
	restrictionId: RestrictionId;
	restrictionName: string;
}

export interface MessageElementRefItem extends RefItem {
	type: RefType.MessageElement;
}

export type SpecificRefItem = DatatypeRefItem | MessageElementRefItem;

export interface RefSelectionItem {
	type: RefType;
	qnamePath: QNamePath;
}

export interface DatatypeRefSelectionItem extends RefSelectionItem {
	type: RefType.Datatype;
	restrictionId: RestrictionId;
}

export interface MessageElementRefSelectionItem extends RefSelectionItem {
	type: RefType.MessageElement;
}

export type SpecificRefSelectionItem =
	| DatatypeRefSelectionItem
	| MessageElementRefSelectionItem;

export interface LinkSegment {
	segment: string;
	label: string | JSX.Element;
	path: string;
	to: string;
}

export type CellParams<ValueT = string> = GridRenderCellParams<
	ValueT,
	SpecificRefItem
>;
