import { DocumentTitle } from "../ui";
import NotFoundSvg from "./NotFoundSvg";
import getHelpPath from "../../adocBase";
import { useAppInfo } from "../AppInfoProvider";
import "./NotFound.scss";

function NotFound(): JSX.Element {
	const { info } = useAppInfo();
	const basePath = info?.config?.["docs-plattform"];
	return (
		<DocumentTitle title="404 - Not Found">
			<div className="not-found" data-testid="not-found">
				<NotFoundSvg />
				<div className="not-found__text-content">
					<h1>
						<span className="not-found--faded">404</span> &ndash; Die Seite
						konnte nicht gefunden werden
					</h1>
					<p>
						Besuchen Sie doch eine unserer anderen Seiten, wie zum Beispiel den{" "}
						<a
							href={getHelpPath(basePath)}
							target="_blank"
							rel="noopener noreferrer"
						>
							Hilfebereich
						</a>
						. Falls es sich um einen Fehler handelt, wenden Sie sich bitte and
						die Administrator:innen.
					</p>
				</div>
			</div>
		</DocumentTitle>
	);
}

export default NotFound;
