import type { ReactNode } from "react";
import StructureInlineInfo from "./StructureInlineInfo";
import "./StructureNoDataInfo.scss";

export default function StructureNoDataInfo({
	isActive,
	children,
}: {
	isActive: boolean;
	children: ReactNode;
}): JSX.Element {
	return (
		<StructureInlineInfo isActive={isActive}>
			<em className="structure-no-data-info__overflow-text">{children}</em>
		</StructureInlineInfo>
	);
}
