const baseColors = {
  "blue-0": "206 100% 95%",
  "blue-1": "206 100% 80%",
  "blue-2": "206 100% 65%",
  "blue-3": "218 63% 50%",
  "blue-4": "216 73% 33%",
  "blue-5": "214 75% 15%",
  "blue-6": "212 80% 7%",
  "gray-0": "216 33% 100%",
  "gray-1": "216 33% 97%",
  "gray-2": "220 21% 95%",
  "gray-3": "220 21% 92%",
  "gray-4": "212 15% 86%",
  "gray-5": "212 15% 70%",
  "gray-6": "212 15% 45%",
  "gray-7": "212 15% 30%",
  "gray-8": "212 15% 21%",
  "gray-9": "212 15% 13%",
  "red-0": "357 80% 96%",
  "red-1": "357 80% 89%",
  "red-2": "357 80% 75%",
  "red-3": "357 80% 60%",
  "red-4": "357 80% 40%",
  "red-5": "357 60% 22%",
  "orange-0": "46 80% 90%",
  "orange-1": "46 80% 80%",
  "orange-2": "46 80% 68%",
  "orange-3": "46 80% 40%",
  "orange-4": "46 80% 25%",
  "orange-5": "46 80% 10%",
  "green-0": "162 100% 93%",
  "green-1": "162 100% 74%",
  "green-2": "162 100% 45%",
  "green-3": "162 100% 30%",
  "green-4": "162 100% 20%",
  "green-5": "162 100% 10%"
};
const themeColors = {
  // Brand
  "color-brand": "blue-5",
  "color-brand-secondary": "blue-3",
  // Surface colors
  "surface-0": "gray-0",
  "surface-1": "gray-1",
  "surface-2": "gray-2",
  "surface-3": "gray-3",
  "surface-4": "gray-4",
  "surface-5": "gray-5",
  "surface-accent-0": "blue-0",
  "surface-accent-1": "blue-1",
  "surface-accent-2": "blue-2",
  "surface-accent-3": "blue-3",
  "surface-accent-4": "blue-4",
  "surface-accent-5": "blue-5",
  // Text colors
  "text-main": "gray-9",
  "text-faded": "gray-6",
  "text-ghost": "gray-4",
  "text-inverse-main": "gray-0",
  "text-inverse-faded": "gray-3",
  "text-inverse-ghost": "gray-4",
  "text-accent": "blue-5",
  "text-accent-faded": "blue-4",
  "text-negative": "red-3",
  "text-error": "red-3",
  "text-warning": "orange-3",
  "text-info": "blue-3",
  "text-success": "green-3",
  // Links
  "text-link": "blue-3",
  "text-link-hover": "blue-4",
  "text-link-visited": "blue-3",
  // Border
  "color-border-faded": "gray-3",
  "color-border-faded-hover": "gray-4",
  "color-border": "gray-4",
  "color-border-hover": "gray-5",
  "color-border-accent": "blue-2",
  "color-border-accent-hover": "blue-2",
  "color-border-error": "red-3",
  "color-border-warning": "orange-3",
  "color-border-info": "blue-3",
  divider: "gray-4"
};
const elementColors = {
  // The body element
  "surface-body": "surface-1",
  // Navbar
  "surface-navbar": "color-brand",
  "surface-navbar-secondary": "color-brand-secondary",
  "surface-navbar-tertiary": "gray-0",
  "surface-navbar-highlight": "color-brand-secondary",
  "surface-navbar-secondary-highlight": "gray-0",
  "text-navbar": "gray-0",
  "text-faded-navbar": "gray-3",
  "text-ghost-navbar": "gray-4",
  "text-navbar-secondary": "gray-0",
  "text-faded-navbar-secondary": "gray-3",
  "text-ghost-navbar-secondary": "gray-4",
  "text-navbar-tertiary": "gray-9",
  "text-faded-navbar-tertiary": "gray-6",
  "text-ghost-navbar-tertiary": "gray-4",
  // Footer
  "surface-footer": "color-brand",
  "text-footer": "gray-0",
  "text-faded-footer": "gray-3",
  "text-ghost-footer": "gray-4",
  // Editor
  "surface-editor-main": "surface-1",
  "surface-editor-sidebar": "surface-1",
  "surface-editor-details": "surface-2",
  // Editor
  "surface-info-main": "surface-1",
  "surface-info-sidebar": "surface-1",
  "surface-info-details": "surface-2",
  // Message view
  "surface-message-view-main": "surface-2",
  "surface-message-view-nav-bar": "surface-0",
  "surface-message-view-tab-bar": "surface-1",
  "surface-adoc-preview": "surface-0",
  // Home page
  "surface-home": "surface-1",
  // Misc elements
  "surface-dialog": "surface-0",
  "surface-table-landmark": "surface-2",
  "surface-table-landmark-emphasized": "surface-3",
  "surface-table-row-hover": "surface-3",
  "surface-error-boundary": "surface-2",
  "surface-maintenance-banner": "surface-accent-1",
  "surface-skip-link": "surface-accent-3",
  // Inputs
  "background-input": "surface-0",
  "background-input-disabled": "surface-2",
  // Buttons
  "background-button-primary": "surface-accent-3",
  "background-button-primary-hover": "surface-accent-4",
  "background-button-secondary": "surface-0",
  "background-button-secondary-hover": "surface-0",
  "background-button-disabled": "surface-2",
  "text-button-primary": "text-inverse-main",
  "text-button-primary-hover": "text-inverse-main",
  "text-button-secondary": "blue-3",
  "text-button-secondary-hover": "blue-4",
  "text-button-disabled": "text-faded",
  "border-color-button-disabled": "gray-4",
  // Popups
  "background-popup": "gray-9",
  "text-popup": "text-inverse-main",
  // Misc elements
  "color-scrollthumb": "gray-4"
};
const DEFAULT_CONFIG = {
  hslPrefix: "hsl-",
  rawPrefix: "raw-",
  prefix: ""
};
const hsl = (hslValue) => `hsl(${hslValue})`;
const rawHsl = (hslValue) => hsl(hslValue.split(" ").join(", "));
const variable = (variableName) => `var(${variableName})`;
const ident = (identName) => `--${identName}`;
function createColors(colorPalette, config) {
  const { hslPrefix, prefix, rawPrefix } = config;
  const hslMap = {};
  for (const [colorPropName, hslValue] of Object.entries(colorPalette)) {
    const hslPropName = `${prefix}${hslPrefix}${colorPropName}`;
    hslMap[`${prefix}${rawPrefix}${colorPropName}`] = rawHsl(hslValue);
    hslMap[hslPropName] = hslValue;
    hslMap[`${prefix}${colorPropName}`] = hsl(variable(ident(hslPropName)));
  }
  return hslMap;
}
const MAX_ITERATIONS = 1e3;
function resolveColorsRefs(colorPalette, themeDefinition) {
  const resolved = /* @__PURE__ */ new Map();
  const queue = Object.entries(themeDefinition);
  let iterations = 0;
  while (queue.length > 0) {
    if (iterations > MAX_ITERATIONS) {
      throw new Error(
        "Maximum number of iterations for resolving color theme exceded. Make sure all references to css variables in your theme definition reference a defined css variable other than itself."
      );
    }
    const entry = queue.shift();
    const [key, ref] = entry;
    if (ref in colorPalette) {
      resolved.set(key, ref);
      continue;
    }
    if (!(ref in themeDefinition)) {
      throw new Error(
        `Unknown reference "${ref}" found in theme. You can only reference keys from the color definitions or the theme definition.`
      );
    }
    const resolvedVar = resolved.get(ref);
    if (resolvedVar) {
      resolved.set(key, resolvedVar);
    } else {
      queue.push(entry);
    }
    iterations += 1;
  }
  return resolved;
}
function createThemeColors(colorPalette, themeDefinition, generationConfig) {
  const config = { ...DEFAULT_CONFIG, ...generationConfig };
  const { hslPrefix, prefix, rawPrefix } = config;
  const resolvedTheme = resolveColorsRefs(colorPalette, themeDefinition);
  const colors = createColors(colorPalette, config);
  const compiledTheme = {};
  for (const [key, ref] of Object.entries(themeDefinition)) {
    const plainHslColor = colors[`${prefix}${rawPrefix}${resolvedTheme.get(ref) || ref}`];
    compiledTheme[`${prefix}${rawPrefix}${key}`] = plainHslColor;
    compiledTheme[`${prefix}${hslPrefix}${key}`] = variable(
      ident(`${prefix}${config.hslPrefix}${ref}`)
    );
    compiledTheme[`${prefix}${key}`] = hsl(
      variable(ident(`${prefix}${hslPrefix}${key}`))
    );
  }
  return { ...colors, ...compiledTheme };
}
function buildColorCss(theme) {
  const varEntries = Object.entries(theme).map(
    ([key, value]) => `	--${key}: ${value};`
  );
  const vars = varEntries.join("\n");
  return `:where(html) {
${vars}
}
`;
}
function injectTheme(theme) {
  const css = buildColorCss(theme);
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.dataset.xoevTheme = "true";
  style.append(document.createTextNode(css));
  head.append(style);
}
function rawVar(theme, varName, generationConfig) {
  const config = { ...DEFAULT_CONFIG, ...generationConfig };
  const value = theme[`${config.prefix}${config.rawPrefix}${varName}`];
  if (!value) {
    const existing = Object.keys(theme).join(", ");
    throw new Error(
      `Could not find css variable "${varName}". 

Possible values:
${existing}`
    );
  }
  return value;
}
function createRawVar(theme, generationConfig) {
  return (varName) => {
    return rawVar(theme, varName, generationConfig);
  };
}
const defaultTheme = createThemeColors(baseColors, {
  ...themeColors,
  ...elementColors
});
export {
  DEFAULT_CONFIG,
  baseColors,
  buildColorCss,
  createRawVar,
  createThemeColors,
  defaultTheme,
  elementColors,
  injectTheme,
  rawVar,
  themeColors
};
