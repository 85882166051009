import Icon from "@mui/icons-material/Lock";
import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";

const DATATYPE_TITLE =
	"Aufgrund der Auswahl des profilierten Datentyps, können die Kind-Elemente nicht weiter profiliert werden";
const CARDINALITY_TITLE =
	"Aufgrund der Profilierung des Elements mit einer Karidnalität von 0..0," +
	" können die Kind-Elemente nicht weiter profiliert werden";

const RestrictionIcon = ({
	hasRestriction,
	hasZeroCardinality,
}: {
	hasRestriction: boolean;
	hasZeroCardinality?: boolean;
}): JSX.Element => {
	const title = hasZeroCardinality ? CARDINALITY_TITLE : DATATYPE_TITLE;
	return (
		<>
			{(hasRestriction || hasZeroCardinality) && (
				<Tooltip title={title}>
					<NodeIcon>
						<VisuallyHidden>
							Information: Aufgrund der Auswahl des profilierten Datentyps,
							können die Kind-Elemente nicht weiter profiliert werden
						</VisuallyHidden>
						<Icon />
					</NodeIcon>
				</Tooltip>
			)}
		</>
	);
};

export default RestrictionIcon;
