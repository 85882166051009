import { baseColors, elementColors, themeColors } from "@xoev/theme";
import Surfaces from "./Surfaces";
import ColorTable from "./ColorTable";
import FontExample from "./FontExample";
import "./Styleguide.scss";

const inverse = (level: number) => ({ level, textVar: "text-inverse-main" });

const fontDiv = document.createElement("div");
fontDiv.style.display = "none";
document.body.append(fontDiv);

function getFontWeight(fontWeightVar: string) {
	fontDiv.style.fontWeight = fontWeightVar;
	return window.getComputedStyle(fontDiv).fontWeight;
}

function Styleguide(): JSX.Element {
	return (
		<div className="styleguide">
			<h1>Styleguide</h1>
			<h2>Schriftstärken</h2>
			<p style={{ fontWeight: "var(--font-light)" }}>
				Schriftstärke Light: {getFontWeight("var(--font-light)")}
			</p>
			<p style={{ fontWeight: "var(--font-regular)" }}>
				Schriftstärke Regular: {getFontWeight("var(--font-regular)")}
			</p>
			<p style={{ fontWeight: "var(--font-semi-bold)" }}>
				Schriftstärke Semi-Bold: {getFontWeight("var(--font-semi-bold)")}
			</p>
			<p style={{ fontWeight: "var(--font-bold)" }}>
				Schriftstärke Bold: {getFontWeight("var(--font-bold)")}
			</p>
			<FontExample
				title="Hauptschriftart (Open Sans)"
				fontVar="var(--font-main)"
			/>
			<FontExample
				title="Monospace Schriftart: (verfügbare System Fonts)"
				fontVar="var(--font-mono)"
			/>
			<ColorTable title="Basis Farben" colors={baseColors} />
			<ColorTable title="Theme Farben" colors={themeColors} />
			<ColorTable title="App-Element Farben" colors={elementColors} />
			<h2>Oberflächenfarben</h2>
			<Surfaces range={[0, 1, 2, 3, 4, 5]} varName="surface" />
			<h2>Akzent-Oberflächenfarben</h2>
			<Surfaces
				range={[0, 1, 2, inverse(3), inverse(4), inverse(5)]}
				varName="surface-accent"
			/>
		</div>
	);
}

export default Styleguide;
