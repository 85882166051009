import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStateSelector } from "../../../EditorState";
import {
	selectDatatypeIdByRestriction,
	selectDatatypeRestrictionReferences,
} from "../../../EditorState/selectors";
import RefTable from "../../../RefTable";
import type { RestrictionId } from "../../../EditorState/types";
import "./DeleteButton.scss";

const DeleteButton = ({
	restrictionId,
	restrictionName,
	onDelete,
}: {
	restrictionId: RestrictionId;
	restrictionName: string;
	onDelete: (restrictionId: RestrictionId) => void;
}): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const handleDelete = () => {
		handleClose();
		onDelete(restrictionId);
	};

	const datatypeId = useStateSelector(
		selectDatatypeIdByRestriction(restrictionId),
	);

	const dtRestrictionRefs = useStateSelector(
		selectDatatypeRestrictionReferences(restrictionId),
	);

	return (
		<>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle>Profilierung löschen</DialogTitle>
				<>
					<DialogContent className="datatype-delete-button__content">
						<DialogContentText component="div">
							{dtRestrictionRefs.count() > 0 && (
								<>
									Die Einschränkung <b>{restrictionName}</b> des Datentyps{" "}
									<b>{datatypeId}</b> wird bereits in Profilen verwendet. Wenn
									die Einschränkung gelöscht wird, wird sie auch aus allen
									Profilen, in denen sie verwendet wird, gelöscht.
									<RefTable refs={dtRestrictionRefs} />
								</>
							)}
							<>
								Möchten Sie <b>{restrictionName}</b> wirklich löschen?
							</>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={handleClose} autoFocus>
							Abbrechen
						</Button>
						<Button
							variant="contained"
							onClick={handleDelete}
							data-testid="restriction-delete-confirm"
						>
							Löschen
						</Button>
					</DialogActions>
				</>
			</Dialog>
			<div>
				<Tooltip title="Einschränkung löschen">
					<IconButton
						size="small"
						data-testid="restriction-delete-modal"
						onClick={handleOpen}
					>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			</div>
		</>
	);
};

export default DeleteButton;
