import { createContext, useContext } from "react";
import type { EditorDataContextType } from "./types";

const EditorDataContext = createContext<EditorDataContextType>(null as never);

export function useEditorData(): EditorDataContextType {
	const ctx = useContext(EditorDataContext);
	return ctx;
}

export default EditorDataContext;
