import type { Context } from "react";
import { createContext } from "react";
import type { SessionContextValue, SessionResponse } from "./types";

function createSessionContext(
	initialSession?: SessionResponse | null,
): Context<SessionContextValue> {
	const SessionContext = createContext<SessionContextValue>({
		session: initialSession || null,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		setSession: () => {},
	});
	return SessionContext;
}

export default createSessionContext;
