import { useContext, type ReactNode } from "react";
import ToolbarMenuContext from "./ToolbarMenuContext";
import NestedMenu from "../../NestedMenu";

export default function ToolbarMenuNested({
	children,
	icon,
	label,
}: {
	children: ReactNode;
	icon: ReactNode;
	label: string;
}): JSX.Element {
	const { isOpen } = useContext(ToolbarMenuContext);
	return (
		<NestedMenu icon={icon} label={label} parentMenuOpen={isOpen}>
			{children}
		</NestedMenu>
	);
}
