import { useEffect } from "react";
import type { ShortcutDefinition } from "./createShortcutHandler";
import createShortcutHandler from "./createShortcutHandler";

interface KeyboardShortcutProps {
	onActivate?: () => void;
	shortcut: ShortcutDefinition;
	disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const useShortcut = ({
	onActivate = noop,
	shortcut,
	disabled = false,
}: KeyboardShortcutProps): void => {
	useEffect(() => {
		if (!shortcut) return;
		const handler = (e: KeyboardEvent) => {
			const handlerFn = createShortcutHandler({
				...shortcut,
				handler: () => {
					if (!disabled) {
						e.stopPropagation();
						e.preventDefault();
						onActivate();
					}
				},
			});
			handlerFn(e);
		};
		document.body.addEventListener("keydown", handler);
		// eslint-disable-next-line consistent-return
		return () => document.body.removeEventListener("keydown", handler);
	}, [disabled, onActivate, shortcut]);
};

const KeyboardShortcut = ({
	onActivate = noop,
	shortcut,
	disabled = false,
}: KeyboardShortcutProps): JSX.Element => {
	useShortcut({ onActivate, shortcut, disabled });
	return <>{null}</>;
};

export default KeyboardShortcut;
