import classNames from "classnames";
import type { ReactNode } from "react";
import "./EditorMain.scss";

function EditorMain({
	children,
	className,
	testId,
}: {
	children?: ReactNode;
	className?: string;
	testId?: string;
}): JSX.Element {
	return (
		<main
			id="main"
			className={classNames("editor-main", className)}
			data-testid={testId}
		>
			{children}
		</main>
	);
}

export default EditorMain;
