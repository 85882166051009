import { VisuallyHidden } from "@xoev/ui";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	useCodelisteData,
	useCodelisteNode,
} from "../../../../../AppActor/actors/modellierungModel/hooks";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import { useAvailableInXRepository } from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/fieldRendererHelpers";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import type { DisplayRendererProps } from "../../types";
import "./CodelisteDisplay.scss";

export default function CodelisteDisplay({
	activeNode,
}: DisplayRendererProps<LiteEigenschaft>): JSX.Element {
	const codelisteNode = useCodelisteNode(activeNode);
	const codelisteData = useCodelisteData(activeNode);
	const availableInXrepository = useAvailableInXRepository(
		codelisteData?.kennung,
		codelisteData?.version,
	);
	if (!codelisteNode || !codelisteData) return <></>;
	const { kennung, nutzungsArt, version } = codelisteData;
	return (
		<>
			<h3 className="lite-codeliste-display__heading">
				Daten der Codelistennutzung
			</h3>
			<InfoRendererDisplay
				label="Nutzungsart"
				value={nutzungsArt}
				fallback="Keine Nutzungsart vorhanden"
			/>
			<InfoRendererDisplay
				label="Codelistenkennung"
				value={kennung}
				fallback="Keine Codelistenkennung vorhanden"
			/>
			<InfoRendererDisplay
				label="Codelistenversion"
				value={version}
				fallback="Keine Codelistenversion vorhanden"
			/>
			{availableInXrepository && (
				<InfoRendererDisplay
					label="Verweis zum XRepository"
					value={
						<a
							href={`https://www.xrepository.de/details/${kennung}`}
							target="_blank"
							rel="noopener noreferrer"
							className="lite-codeliste-display__external-link"
						>
							<span aria-hidden>Link</span>
							<VisuallyHidden>
								{activeNode.name} im XRepository öffnen
							</VisuallyHidden>
							<OpenInNewIcon fontSize="inherit" />
						</a>
					}
				/>
			)}
		</>
	);
}
