import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import SegmentTrie from "@xoev/segment-trie";
import type { LiteId, ProjektId } from "../schemas";
import type { SearchResult } from "./types";
import { getModellierungProjektFromSystem } from "../hooks";
import { createSelectNode } from "../selectors";

export type LiteSearchTrie = SegmentTrie<LiteId[], LiteId, number>;

export const createQueryId = (projektId: ProjektId) =>
	`modellierungSearch:query:${projektId}`;
export const createCommandId = (projektId: ProjektId) =>
	`modellierungSearch:command:${projektId}`;

export const identity = <T>(thing: T) => thing;
export const EMPTY_TRIE: LiteSearchTrie = new SegmentTrie(identity);

export function isExactSearchMatch(
	trie: LiteSearchTrie,
	fullPath: LiteId[],
): boolean {
	return trie.has(fullPath);
}

export function isSearchMatch(
	trie: LiteSearchTrie,
	fullPath: LiteId[],
): boolean {
	return (
		isExactSearchMatch(trie, fullPath) ||
		trie.hasParentOf(fullPath) ||
		trie.hasChildOf(fullPath)
	);
}

export function debugSearchResult(
	system: AnyActorSystem,
	projektId: ProjektId,
	result: SearchResult,
) {
	const projekt = getModellierungProjektFromSystem(system, projektId);
	if (!projekt) {
		return {};
	}
	const selectNode = createSelectNode(projekt);
	const debugIds = result.ids.map((id) => selectNode(id)?.name);
	const debugPaths = result.paths.map((path) =>
		path.map((id) => selectNode(id)?.name ?? "UNKNOWN").join("/"),
	);
	return { debugIds, debugPaths };
}

export function getFocusIndex<TPath, TSegment>(
	activeId: TPath | null,
	trie: SegmentTrie<TPath, TSegment, number>,
) {
	const exactFocusIndex = activeId ? trie.get(activeId) : undefined;
	if (exactFocusIndex !== undefined) return exactFocusIndex;
	const parentFocusIndex = activeId ? trie.getParentOf(activeId) : undefined;
	if (parentFocusIndex !== undefined) return parentFocusIndex;
	return undefined;
}
