import classNames from "classnames";
import { useContext } from "react";
import type { ReactNode } from "react";
import { FieldRendererContext } from "../../context";
import "./FieldRenderer.scss";

const FieldRenderer = ({ children }: { children: ReactNode }): JSX.Element => {
	const { isStandardRenderer } = useContext(FieldRendererContext);
	return (
		<div
			className={classNames(
				"field-renderer",
				isStandardRenderer && "field-renderer--standard",
			)}
		>
			{children}
		</div>
	);
};

export default FieldRenderer;
