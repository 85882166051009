import { setup } from "xstate";
import keyboardShortcutsCommandMachine from "./keyboardShortcuts.command.machine";

const keyboardShortcutsMachine = setup({
	types: {
		children: {} as {
			"keyboardShortcuts:command": "command";
		},
	},
	actors: {
		command: keyboardShortcutsCommandMachine,
	},
}).createMachine({
	id: "keyboardShortcuts",
	invoke: [
		{
			src: "command",
			systemId: "keyboardShortcuts:command",
			id: "keyboardShortcuts:command",
		},
	],
});

export default keyboardShortcutsMachine;
