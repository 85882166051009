import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";

/**
 * Sync the value of a ref to a value, so it can be used inside
 * a `useEffect` without the effect re-running when the value changes.
 */
function useSyncedRef<T>(value: T): MutableRefObject<T> {
	const ref = useRef<T>(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref;
}

export default useSyncedRef;
