import type { ReactNode } from "react";
import classNames from "classnames";
import "./NavBarDisplay.scss";

interface NavBarDisplayProps {
	className?: string;
	children?: ReactNode;
	[k: `data-${string}`]: string;
}

function NavBarDisplay({ children, className, ...props }: NavBarDisplayProps) {
	return (
		<div
			className={classNames("navbar-display", className)}
			data-testid="editor-navbar"
			{...props}
		>
			{children}
		</div>
	);
}

function Group({ children }: { children: ReactNode }) {
	return <div>{children}</div>;
}

NavBarDisplay.Breadcrumbs = Group;
NavBarDisplay.Actions = Group;
export default NavBarDisplay;
