import { useContext } from "react";
import { createStateContainerHooks } from "@xoev/state-container-react";
import EditorStateContext from "./EditorStateContext";
import type {
	EditorPatchCreators,
	EditorPatchMap,
	EditorState,
	EditorStateContainerContext,
} from "./types";

export const {
	useStateContainerContext,
	useStateSelector,
	useApplyPatch,
	useUpdateListener,
	useCanRedo,
	useCanUndo,
	usePatchSelector,
	useHistoryIndex,
	useHistoryLength,
	useStateGeneration,
	useStateId,
} = createStateContainerHooks<EditorState, EditorPatchMap>();

export function useEditorStateContext(): EditorStateContainerContext {
	return useContext(EditorStateContext);
}

export function useEditorPatchCreators(): EditorPatchCreators {
	const { patchCreators } = useEditorStateContext();
	return patchCreators;
}
