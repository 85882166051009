import "./SkipLink.scss";

function SkipLink({
	label,
	target,
}: {
	label: string;
	target: string;
}): JSX.Element {
	return (
		<a className="skip-link" href={target}>
			{label}
		</a>
	);
}

export default SkipLink;
