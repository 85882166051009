import { Box, Popper, Paper, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { memo, useEffect, useRef, useState } from "react";

interface GridCellExpandProps {
	value: ReactNode;
	width: number;
}

function isOverflown(element: Element): boolean {
	return (
		element.scrollHeight > element.clientHeight ||
		element.scrollWidth > element.clientWidth
	);
}

/**
 * A compoment to expand a grid cell on hover. Necessary to display long
 * syntax paths in data grids.
 * Reference: https://mui.com/x/react-data-grid/column-definition/#expand-cell-renderer
 */

const GridCellExpand = memo(function GridCellExpand(
	props: GridCellExpandProps,
) {
	const { width, value } = props;
	const wrapper = useRef<HTMLDivElement | null>(null);
	const cellDiv = useRef(null);
	const cellValue = useRef(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [showFullCell, setShowFullCell] = useState(false);
	const [showPopper, setShowPopper] = useState(false);

	const handleMouseEnter = () => {
		if (cellValue.current) {
			const isCurrentlyOverflown = isOverflown(cellValue.current);
			setShowPopper(isCurrentlyOverflown);
			setAnchorEl(cellDiv.current);
			setShowFullCell(true);
		}
	};

	const handleMouseLeave = () => {
		setShowFullCell(false);
	};

	useEffect(() => {
		if (!showFullCell) {
			return undefined;
		}

		function handleKeyDown(nativeEvent: KeyboardEvent) {
			if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
				setShowFullCell(false);
			}
		}

		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [setShowFullCell, showFullCell]);

	return (
		<Box
			ref={wrapper}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			sx={{
				alignItems: "center",
				lineHeight: "24px",
				width: 1,
				height: 1,
				position: "relative",
				display: "flex",
			}}
		>
			<Box
				ref={cellDiv}
				sx={{
					height: 1,
					width,
					display: "block",
					position: "absolute",
					top: 0,
				}}
			/>
			<Box
				ref={cellValue}
				sx={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					zIndex: 1,
				}}
			>
				{value}
			</Box>
			{showPopper && (
				<Popper
					open={showFullCell && anchorEl !== null}
					anchorEl={anchorEl}
					style={{ width }}
				>
					<Paper
						elevation={1}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
					>
						<Typography variant="body2" style={{ padding: 8 }}>
							{value}
						</Typography>
					</Paper>
				</Popper>
			)}
		</Box>
	);
});

export default GridCellExpand;
