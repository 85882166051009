import type { Context } from "react";
import { useContext, createContext } from "react";
import type { PathFragment, TreeContextType } from "./types";

const TreeContext = createContext<TreeContextType>(null as never);

// Wrap the context in a getter function, so we can dynamically assign a type
// to it
export function getTreeContext<
	TNode = unknown,
	TNodeId extends PathFragment = number,
>(): Context<TreeContextType<TNode, TNodeId>> {
	return TreeContext as unknown as Context<TreeContextType<TNode, TNodeId>>;
}

export function useTreeContext<
	TNode = unknown,
	TNodeId extends PathFragment = number,
>(): TreeContextType<TNode, TNodeId> {
	const Ctx = getTreeContext<TNode, TNodeId>();
	return useContext(Ctx);
}
