import type ValueOf from "../../../types/ValueOf";

/**
 * Possible values for `"xoev-suite:renderer"`. The renderer implementation
 * determines which renderer is used to display a certain part of the markup.
 */
export enum RendererImplementation {
	TabGroup = "TabGroup",
	Tab = "Tab",
	Tree = "Tree",
	CustomRendering = "CustomRendering",
	Body = "Body",
	Document = "Document",
	Container = "Container",
	ValueGroup = "ValueGroup",
	Header = "Header",
}

/**
 * Possible values for the orientation of a `TabGroup` element defined in
 * `"xoev-suite:orientation"`
 */
export enum TabGroupOrientation {
	Horizontal = "HORIZONTAL",
	Vertical = "VERTICAL",
}

export type RendererPropKey = `xoev-suite:${string}`;

export interface RendererMarkupPropTypes {
	[RendererImplementation.TabGroup]: {
		"xoev-suite:orientation": TabGroupOrientation;
	};
	[RendererImplementation.CustomRendering]: {
		"xoev-suite:implementation": string;
		[prop: RendererPropKey]: string;
	};
	[RendererImplementation.Tab]: {
		"xoev-suite:label": string;
		Header: RendererImplementation.Header;
	};
	[RendererImplementation.Header]: {
		"xoev-suite:value": string;
		"xoev-suite:level": string;
	};
	[RendererImplementation.ValueGroup]: {
		Header: RendererImplementation.Header;
	};
	[RendererImplementation.Tree]: {
		Header: RendererImplementation.Header;
		"xoev-suite:index"?: number;
		"xoev-suite:node-count"?: number;
		"xoev-suite:index-padding-format"?: string;
	};
}

export interface RendererMarkupKnownEntries {
	[RendererImplementation.TabGroup]: {
		// eslint-disable-next-line no-use-before-define
		Tab: Markup<RendererImplementation.Tab>[];
	};
}

export type ExtractNodeProps<
	MarkupPropType extends ValueOf<RendererMarkupPropTypes>,
> = Pick<MarkupPropType, Extract<keyof MarkupPropType, RendererPropKey>>;

export type UnknownRendererImplementation =
	| RendererImplementation
	| string
	| undefined;

/**
 * The name of the type of a markup node.
 *
 * A node can be not defined ("Empty"), it can be a plain string ("String"),
 * an array of strings ("StringArray"), a `Markup` object, that contains some
 * data ("Node") or an array of `Markup` objects
 */
export enum MarkupNodeType {
	Empty = "Empty",
	String = "String",
	StringArray = "StringArray",
	Node = "Node",
	NodeArray = "NodeArray",
}

export interface InternalMarkupNodeTypeMap<M> {
	[MarkupNodeType.Empty]: undefined;
	[MarkupNodeType.String]: string;
	[MarkupNodeType.StringArray]: string[];
	[MarkupNodeType.Node]: M;
	[MarkupNodeType.NodeArray]: M[];
}

type InternalMarkupNode<M> = ValueOf<InternalMarkupNodeTypeMap<M>>;

export type Markup<
	RendererImpl extends UnknownRendererImplementation = UnknownRendererImplementation,
> = {
	[key: string]: InternalMarkupNode<Markup>;
} & (RendererImpl extends RendererImplementation
	? { "xoev-suite:renderer": RendererImpl }
	: { [key: string]: InternalMarkupNode<Markup> }) &
	(RendererImpl extends keyof RendererMarkupPropTypes
		? RendererMarkupPropTypes[RendererImpl]
		: { [key: string]: InternalMarkupNode<Markup> }) &
	(RendererImpl extends keyof RendererMarkupKnownEntries
		? RendererMarkupKnownEntries[RendererImpl]
		: { [key: string]: InternalMarkupNode<Markup> });

export type MarkupNode = InternalMarkupNode<Markup>;

export type MarkupNodeTypeMap = InternalMarkupNodeTypeMap<Markup>;
