import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMemo } from "react";
import { useModellierungProjekt } from "../../../AppActor/actors/modellierungModel/hooks";
import ToolbarMenuItem from "../ToolbarMenuItem";
import type { DeleteNodeShortcutEvent } from "../../../AppActor/EventStore/StoreEvent";
import {
	canDeleteNode,
	getDeleteInstructionsFromEvent,
} from "../../../AppActor/actors/modellierungModel/helpers";
import { useTargetPath } from "./helpers";
import { useIsNodeReadOnly } from "../../../AppActor/actors/treeState/hooks";

export default function DeleteNodeButton({
	command,
	onClick,
}: {
	command: DeleteNodeShortcutEvent["type"];
	onClick?: () => void;
}): JSX.Element {
	const { target } = getDeleteInstructionsFromEvent(command);
	const deletePath = useTargetPath(target);
	const projekt = useModellierungProjekt();
	const isDisabled = useMemo(
		() => !canDeleteNode(projekt, deletePath, target),
		[deletePath, projekt, target],
	);
	const isReadOnlyByPath = useIsNodeReadOnly(deletePath);

	return (
		<ToolbarMenuItem
			onClick={onClick}
			isDisabled={isDisabled || isReadOnlyByPath}
			icon={DeleteOutlineIcon}
			command={command}
		>
			Löschen
		</ToolbarMenuItem>
	);
}
