import type { ReactNode } from "react";
import { useState, useMemo } from "react";
import { GridFeatureModeConstant } from "@mui/x-data-grid";
import type { SelectionTableRenderProps } from "./types";

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IS_CYPRESS_TEST = !!(window as any).Cypress;

const SelectionTableProvider = ({
	children,
}: {
	children: (renderProps: SelectionTableRenderProps) => ReactNode;
}): JSX.Element => {
	const [pageSize, setPageSize] = useState(25);

	const handlePageSizeChange = (nextPageSize: number) =>
		setPageSize(nextPageSize);

	const renderProps = useMemo(() => {
		return {
			getProps() {
				return {
					...(IS_CYPRESS_TEST
						? {
								paginationMode: GridFeatureModeConstant.server,
								disableVirtualization: true,
						  }
						: {
								rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
								pagination: true as const,
								pageSize,
						  }),
					onPageSizeChange: handlePageSizeChange,
				};
			},
		};
	}, [pageSize]);

	return <>{children(renderProps)}</>;
};

export default SelectionTableProvider;
