import EditorContent from "../../EditorContent";
import useActiveDataType from "../useActiveDataType";
import DatatypeDetails from "./DatatypeDetails";

const DatatypeEditView = (): JSX.Element => {
	const { activeDataType, didFindMatch } = useActiveDataType();
	if (!didFindMatch || !activeDataType) return <></>;
	return (
		<EditorContent data-testid="datatype-edit-view">
			<h3 data-testid="datatype-edit-view__header">{activeDataType.name}</h3>
			<DatatypeDetails />
		</EditorContent>
	);
};

export default DatatypeEditView;
