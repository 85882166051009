import { IconButton, Tooltip } from "@mui/material";
import CloseAllIcon from "@mui/icons-material/PlaylistRemove";
import { useContext } from "react";
import { TabContext } from "../../ui/Tabs/TabContexts";

const CloseAllButton = ({
	onClose,
	className,
}: {
	onClose: () => void;
	className?: string;
}): JSX.Element => {
	const { setActiveIndex } = useContext(TabContext);
	const handleClick = () => {
		onClose();
		setActiveIndex(0);
	};
	return (
		<Tooltip title="Alle Tabs schließen" className="close-all">
			<IconButton
				className={className}
				size="small"
				data-testid="close-all-message-tabs"
				disableRipple
				onClick={handleClick}
			>
				<CloseAllIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
};

export default CloseAllButton;
