import AddIcon from "@mui/icons-material/Add";
import { Divider } from "@mui/material";
import AddNodeButton from "../../Toolbar/ToolbarButton/AddNodeButton";
import NestedMenu from "../../NestedMenu";
import DeleteNodeButton from "../../Toolbar/ToolbarButton/DeleteNodeButton";

export default function BausteinNodeMenuItems({
	isOpen,
	closeMenu,
}: {
	isOpen: boolean;
	closeMenu: () => void;
}): JSX.Element {
	return (
		<>
			<NestedMenu
				parentMenuOpen={isOpen}
				icon={<AddIcon />}
				label="Als Kind hinzufügen"
			>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.SCHEMA_PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.NACHRICHT"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.CODE_DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.GLOBALE_EIGENSCHAFT"
				/>
			</NestedMenu>
			<NestedMenu
				parentMenuOpen={isOpen}
				icon={<AddIcon />}
				label="Oberhalb hinzufügen"
			>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.SCHEMA_PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.NACHRICHT"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.CODE_DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.GLOBALE_EIGENSCHAFT"
				/>
			</NestedMenu>
			<NestedMenu
				parentMenuOpen={isOpen}
				icon={<AddIcon />}
				label="Unterhalb hinzufügen"
			>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.SCHEMA_PAKET"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.NACHRICHT"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.CODE_DATENTYP"
				/>
				<AddNodeButton
					onClick={closeMenu}
					command="SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.GLOBALE_EIGENSCHAFT"
				/>
			</NestedMenu>
			<Divider sx={{ borderColor: "var(--text-ghost)" }} />
			<DeleteNodeButton
				onClick={closeMenu}
				command="SHORTCUT.BAUSTEIN_VIEW.DELETE"
			/>
		</>
	);
}
