import { assertEvent, assign, raise, setup } from "xstate";

interface SearchInputCallbacks {
	onSearch: (term: string) => void;
	onClear: () => void;
}

const DEBOUNCE_DELAY = 500;

const searchInputMachine = setup({
	types: {
		context: {} as { value: string; callbacks: SearchInputCallbacks },
		input: {} as SearchInputCallbacks,
		events: {} as
			| { type: "CHANGE"; value: string }
			| { type: "CLEAR" }
			| { type: "SET_TERM"; value: string },
	},
	actions: {
		writeValue: assign({
			value: ({ event }) => {
				assertEvent(event, ["CHANGE", "SET_TERM"]);
				return event.value;
			},
		}),
		clearValue: assign({ value: "" }),
		handleSearch: ({ context }) => {
			context.callbacks.onSearch(context.value);
		},
		handleClear: ({ context }) => {
			context.callbacks.onClear();
		},
	},
	guards: {
		isCleared: ({ event }) => {
			assertEvent(event, "CHANGE");
			return event.value.trim().length === 0;
		},
	},
}).createMachine({
	id: "searchInput",
	context: ({ input }) => ({
		value: "",
		callbacks: { ...input },
	}),
	initial: "Idle",
	states: {
		Idle: {},
		Typing: {
			after: {
				[DEBOUNCE_DELAY]: { actions: "handleSearch", target: "Idle" },
			},
		},
	},
	on: {
		SET_TERM: {
			actions: "writeValue",
		},
		CHANGE: [
			{
				guard: "isCleared",
				actions: raise({ type: "CLEAR" }),
			},
			{
				target: ".Typing",
				actions: "writeValue",
			},
		],
		CLEAR: {
			target: ".Idle",
			actions: ["clearValue", "handleClear"],
		},
	},
});

export default searchInputMachine;
