import { useEffect } from "react";
import {
	getRendererImplementation,
	helperBag,
	isMarkupNodeType,
} from "../helpers";
import type { MarkupNode } from "../types";
import { MarkupNodeType } from "../types";
import type {
	CustomRendererRegistry,
	RendererComponent,
	RendererImplementationFn,
	RenderHelpers,
} from "./types";
import createRendererComponent from "./createRendererComponent";
import createImplementationMap from "./createImplementationMap";

function createRootRenderer(
	registry: CustomRendererRegistry,
): RendererComponent {
	const implMap = createImplementationMap(registry);

	const getRenderer = (markup: MarkupNode): RendererImplementationFn | null => {
		const impl = getRendererImplementation(markup);
		if (!impl || !(impl in implMap)) return null;
		return (implMap[impl] as RendererImplementationFn) || null;
	};

	// Store the mounting state of the root renderer
	let isMounted = false;
	// Save mount handlers in a set as long as the root renderer is not mounted
	// yet
	const mountHandlers = new Set<() => void | (() => void)>();
	// Save unmount handlers in a set so they can all be called on unmount
	const unmountHandlers = new Set<() => void>();

	const renderHelpers: RenderHelpers = {
		...helperBag,
		render(root, markup) {
			if (!isMarkupNodeType(markup, MarkupNodeType.Node)) return;
			const renderer = getRenderer(markup);
			if (renderer) {
				renderer(root, markup, renderHelpers);
			}
		},
		onMount(handler) {
			// If the root renderer is not mounted, store the handler, so that it can
			// be called on mount
			if (!isMounted) {
				mountHandlers.add(handler);
			} else {
				// If the root renderer is mounted, call the handler directly
				const unmountHandler = handler();
				// The mount handler can optionally return an unmount handler
				if (unmountHandler) {
					unmountHandlers.add(unmountHandler);
				}
			}
		},
		onUnmount(handler) {
			// Store the handler, so that it can be called on unmount
			unmountHandlers.add(handler);
		},
	};

	const Renderer = createRendererComponent(renderHelpers.render);

	const RootRenderer: RendererComponent = (props) => {
		useEffect(() => {
			isMounted = true;
			mountHandlers.forEach((handler) => {
				const unmountHandler = handler();
				if (unmountHandler) {
					unmountHandlers.add(unmountHandler);
				}
			});
			mountHandlers.clear();

			return () => {
				unmountHandlers.forEach((handler) => handler());
				unmountHandlers.clear();
			};
		}, []);

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Renderer {...props} />;
	};

	return RootRenderer;
}

export default createRootRenderer;
