import type { ReactNode } from "react";
import { useEffect } from "react";
import { useGlobalSnackbar } from "./GlobalSnackbarContext";

export default function GlobalSnackbarItem({
	children,
	id,
}: {
	children: ReactNode;
	id: string;
}): JSX.Element {
	const { registerAlert, unregisterAlert } = useGlobalSnackbar();
	useEffect(() => {
		registerAlert(id, children);
		return () => unregisterAlert(id);
	}, [children, id, registerAlert, unregisterAlert]);
	return <></>;
}
