import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import type { RequestError } from "../components/Api";
import { RequestStatus } from "../components/Api";
import { RequestStatusCode } from "../components/Api/types";

export type RequestMeta = {
	isError: boolean;
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	error: FetchBaseQueryError | SerializedError | undefined;
};

export function toRequestStatus({
	isError,
	isLoading,
	isSuccess,
	isFetching,
}: {
	isError: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	isFetching?: boolean;
}): RequestStatus {
	if (isLoading || isFetching) return RequestStatus.Loading;
	if (isError) return RequestStatus.Failure;
	if (isSuccess) return RequestStatus.Success;
	return RequestStatus.Idle;
}

export function toRequestError(
	error: FetchBaseQueryError | SerializedError | undefined,
): RequestError | null {
	if (!error) return null;
	if ("status" in error) {
		const status =
			typeof error.status === "number"
				? error.status
				: RequestStatusCode.UnknownError;
		const message = "error" in error ? error.error : String(error.data);
		return { status, message };
	}
	return {
		status: RequestStatusCode.UnknownError,
		message: error.message || "Ein unbekannter Fehler ist aufgetreten.",
	};
}

export function useTranslateRequestMeta(requestMeta: RequestMeta): {
	status: RequestStatus;
	error: RequestError | null;
} {
	const status = toRequestStatus(requestMeta);
	const error = useMemo(
		() => toRequestError(requestMeta.error),
		[requestMeta.error],
	);
	return useMemo(() => ({ status, error }), [error, status]);
}
