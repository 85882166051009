import classNames from "classnames";
import { Tooltip } from "@mui/material";
import { useHtmlId } from "../../hooks";
import { Select } from "../ui";
import type { EditFormFieldLiteProps } from "./types";
import useEditorFieldLite from "./useEditorFieldLite";
import EditFormFieldLabel from "./EditFormFieldLabel";
import "./EditFormFieldLite.scss";

const EditFormFieldLite = ({
	definition: { name, label, type, options, isRequired, readOnly },
	value: initialValue,
	error,
	onBlur,
	onChange,
	onFocus,
	onKeyDown,
	placeholder,
	className,
	labelClassName,
	inputClassName,
	errorClassName,
	inputProps,
	labelProps,
	"aria-describedby": ariaDescribedBy,
}: EditFormFieldLiteProps): JSX.Element => {
	const id = useHtmlId();
	const errorId = useHtmlId();

	const { handleBlur, handleChange, value } = useEditorFieldLite({
		value: (initialValue || "") as string,
		name,
		onChange(_name, nextValue) {
			if (onChange) {
				onChange({ target: { value: nextValue, name } });
			}
		},
		onWrite(_name, nextValue) {
			if (onBlur) {
				onBlur(name, nextValue);
			}
		},
	});

	const field = {
		id,
		name,
		value,
		readOnly,
		"aria-describedby": ariaDescribedBy || (error ? errorId : undefined),
		onBlur: handleBlur,
		onFocus,
		onChange: handleChange,
	};
	return (
		<>
			<EditFormFieldLabel
				text={label}
				htmlFor={id}
				className={className}
				labelTextClassName={labelClassName}
				isRequired={isRequired}
				labelTextProps={labelProps}
				data-testid="edit-form-field"
			>
				{type === "textarea" && (
					<textarea
						{...field}
						{...inputProps}
						className={classNames(
							"edit-form-field-lite__input edit-form-field-lite__input--textarea",
							inputClassName,
						)}
						placeholder={placeholder || ""}
						aria-required={isRequired}
						data-field-id={name}
						onKeyDown={onKeyDown}
						rows={3}
					/>
				)}
				{(!type || type === "input") && (
					<input
						{...field}
						{...inputProps}
						className={classNames(
							"edit-form-field-lite__input edit-form-field-lite__input--input",
							inputClassName,
						)}
						type="text"
						placeholder={placeholder || ""}
						aria-required={isRequired}
						data-field-id={name}
						onKeyDown={onKeyDown}
					/>
				)}
				{type === "select" && (
					<Select
						className={classNames(
							"edit-form-field-lite__input edit-form-field-lite__input--select",
							inputClassName,
						)}
						label={label}
						isRequired={isRequired}
						data-field-id={name}
						{...field}
						{...inputProps}
						disabled={field.readOnly}
					>
						<Select.Button placeholder={label}>
							<Select.Arrow />
						</Select.Button>
						<Select.List>
							{options?.map(
								({ value: optValue, label: optLabel, ...optionProps }) => (
									<Select.Option
										{...optionProps}
										key={optValue}
										value={optValue}
									>
										{optionProps.tooltip ? (
											<Tooltip
												title={optionProps.tooltip}
												placement="left"
												arrow={false}
											>
												<span className="edit-form-field-lite__input--select--tooltip">
													{optLabel}
												</span>
											</Tooltip>
										) : (
											<>{optLabel}</>
										)}
									</Select.Option>
								),
							)}
						</Select.List>
					</Select>
				)}
			</EditFormFieldLabel>
			{error && (
				<div
					id={errorId}
					className={classNames("edit-form-field__error", errorClassName)}
				>
					{error}
				</div>
			)}
		</>
	);
};

export default EditFormFieldLite;
