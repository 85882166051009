import { useEffect } from "react";
import { createPath, useLocation } from "react-router-dom";
import { useStableNavigate, useSyncedRef } from "../../hooks";
import type { CacheProviderProps } from "./types";

const locationCache: { [section: string]: string } = {};

const CacheProvider = ({
	mainPaths,
	children,
	section,
}: CacheProviderProps): JSX.Element => {
	const location = useLocation();
	const navigate = useStableNavigate();
	const currentPath = createPath(location);
	const currentPathRef = useSyncedRef(currentPath);

	// When we change the view, we want to cache where we left off in the last
	// view, so we can load the same view again, when coming back.
	// We first check to see if we have cached a location for the current section
	// and re-load it
	useEffect(() => {
		// Only re-load the view if we navigate to the parent page, such as
		// "/profilierung/datentypen" and not when navigating to a specific view,
		// such as "/profilierung/datentypen/xdomea%253AAkteType/standard", so we
		// don't overwrite jump links
		if (!mainPaths.includes(currentPathRef.current)) return;
		// If there is a cached entry, re-load that view
		const prevPath = locationCache[section];
		if (prevPath && currentPathRef.current !== prevPath) {
			navigate(prevPath, { replace: true });
		}
	}, [currentPathRef, mainPaths, navigate, section]);

	// Cache the location everytime it changes. Make sure to do this AFTER the
	// re-load effect, so we don't accidentally overwrite a cached path right
	// before trying to reload it
	useEffect(() => {
		locationCache[section] = currentPath;
	}, [currentPath, section]);
	return <div>{children}</div>;
};

export default CacheProvider;
