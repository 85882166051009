import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import type { MouseEventHandler, ReactNode } from "react";
import type { SvgIconComponent } from "@mui/icons-material";
import { getChordStringFor } from "../../../AppActor/actors/keyboardShortcuts/helpers";
import keyMap from "../../../AppActor/actors/keyboardShortcuts/keyMap";
import type { ShortcutEvent } from "../../../AppActor/EventStore/StoreEvent";
import { useSendStoreEvent } from "../../../AppActor/EventStore/hooks";

export default function ToolbarMenuItem({
	children,
	icon: Icon,
	command,
	isDisabled,
	tooltip,
	onClick,
}: {
	children: ReactNode;
	icon: SvgIconComponent;
	command: ShortcutEvent["type"];
	isDisabled?: boolean;
	tooltip?: string;
	onClick?: MouseEventHandler<HTMLLIElement>;
}): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();

	const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
		if (isDisabled) return;
		sendStoreEvent({ type: command } as ShortcutEvent);
		onClick?.(e);
	};

	return (
		<Tooltip title={tooltip} placement="right">
			<span>
				<MenuItem onClick={handleClick} disabled={isDisabled}>
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
					<ListItemText>{children}</ListItemText>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ paddingInlineStart: "1em" }}
					>
						<code>{getChordStringFor(keyMap, command)}</code>
					</Typography>
				</MenuItem>
			</span>
		</Tooltip>
	);
}
