import type { ModifierKey } from "react";
import type { ExtractEvent } from "xstate";
import type {
	Chord,
	KeyMap,
	Modifier,
	ModifierState,
	ShorcutCommand,
	ShorcutCommandParsingIssue,
	ShorcutCommandParsingResult,
	Shortcut,
} from "./types";
import type { ShortcutEvent } from "../../EventStore/StoreEvent";

export const DEFAULT_CHORD_DELAY = 2500;

const isMac = navigator.platform
	? /^(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
	: /(Macintosh|iPhone|iPod|iPad)/.test(navigator.userAgent);

export const allModifierKeys: { [K in ModifierKey]: K } = {
	Alt: "Alt",
	AltGraph: "AltGraph",
	CapsLock: "CapsLock",
	Control: "Control",
	Fn: "Fn",
	FnLock: "FnLock",
	Hyper: "Hyper",
	Meta: "Meta",
	NumLock: "NumLock",
	ScrollLock: "ScrollLock",
	Shift: "Shift",
	Super: "Super",
	Symbol: "Symbol",
	SymbolLock: "SymbolLock",
};

const modifiers: { [TModifier in Modifier]: ModifierKey } = {
	altOpt: "Alt",
	// On Mac, the `Command` key maps to the `Meta` modifier. Use it instread of
	// `Control` on other systems
	ctrlCmd: isMac ? "Meta" : "Control",
	shift: "Shift",
};
const relevantModifierKeys = Object.values(modifiers);
const reverseModifiers = Object.fromEntries(
	Object.entries(modifiers).map(
		([key, mod]) => [mod, key as Modifier] as const,
	),
) as { [K in ModifierKey]?: Modifier };

/** Check whether a key is a modifier, such as `Shift` or `Control` */
export function isModifierKey(
	maybeModifierKey: string,
): maybeModifierKey is ModifierKey {
	return maybeModifierKey in allModifierKeys;
}

/** Check if any of the relevant modifiers are pressed during the event */
export function getActiveModifiers(event: KeyboardEvent): ModifierState {
	// Fixed error when using chrome autofill
	// https://github.com/Shopify/quilt/pull/1578/files/7ea896ef4b879d2109851138e57d5b01613e1d9c
	const entries = relevantModifierKeys.map((modifier) => {
		return [
			reverseModifiers[modifier] as Modifier,
			event.getModifierState && event.getModifierState(modifier),
		] as const;
	});
	return Object.fromEntries(entries) as ModifierState;
}

/** Turn a parsed shortcut object into a string, so it is easier to compare */
export function stringifyShortcut(
	key: string,
	modifierState: Partial<ModifierState>,
): string {
	const modifierString = Object.entries(modifierState)
		.filter(([, isActive]) => isActive)
		.map(([modifier]) => `${modifier}+`)
		.sort()
		.join("");
	return `${modifierString}${key?.toLowerCase()}`;
}

export function extendMatch(
	currentMatch: string[],
	nextMatch: string,
): string[] {
	return [...currentMatch, nextMatch];
}

/**
 * We have a full match, when all shortcuts of a chord match the shortcuts that
 * were pressed exactly.
 */
export function getFullMatch(
	definitionList: ShorcutCommand[],
	activeMatch: string[],
) {
	const match = definitionList.find((def) => {
		const shortcutList = def.shortcut.map((shortcut) =>
			stringifyShortcut(shortcut.key, shortcut.modifiers || {}),
		);
		return (
			activeMatch.length > 0 &&
			activeMatch.length === shortcutList.length &&
			activeMatch.every((shortcut, i) => shortcut === shortcutList[i])
		);
	});
	return match || null;
}

/**
 * We have a partial match, when the first or first few shortcuts of a chord
 * match the shortcuts that were pressed exactly, but the pressed chord is
 * still shorter than any of the defined chords
 */
export function getPartialMatches(
	definitionList: ShorcutCommand[],
	activeMatch: string[],
) {
	return definitionList.filter((def) => {
		const shortcutList = def.shortcut.map((shortcut) =>
			stringifyShortcut(shortcut.key, shortcut.modifiers || {}),
		);
		return (
			activeMatch.length > 0 &&
			activeMatch.length < shortcutList.length &&
			activeMatch.every((shortcut, i) => shortcut === shortcutList[i])
		);
	});
}

function parseKeyInto(
	shortcut: Shortcut,
	shortcutString: string,
	keyFragment: string,
) {
	if (keyFragment in modifiers) {
		throw new Error(
			`Modifiers may only appear at the beginning of a shortcut. ` +
				`"${shortcutString}" ended in "${keyFragment}". Make sure the last ` +
				`fragment of a shortcut is the key (for example "ctrlCmd+c" or ` +
				`"shift+alt+q").`,
		);
	}
	// eslint-disable-next-line no-param-reassign
	shortcut.key = keyFragment;
}

function parseModifierInto(
	shortcut: Shortcut,
	shortcutString: string,
	modifierFragment: string,
) {
	if (!(modifierFragment in modifiers)) {
		throw new Error(
			`Invalid modifier "${modifierFragment}" in "${shortcutString}" cannot be ` +
				`parsed. Valid modifiers are "crtlCmd", "shift" and "altOpt".`,
		);
	}
	const modifier = modifierFragment as keyof typeof shortcut.modifiers;
	if (shortcut.modifiers[modifier]) {
		throw new Error(
			`Duplicate modifier "${modifierFragment}" in "${shortcutString}" cannot be ` +
				`parsed. Make sure no modifier occurs more than once.`,
		);
	}
	// eslint-disable-next-line no-param-reassign
	shortcut.modifiers[modifier] = true;
}

/**
 * Parse a shortcut string into a shortcut object.
 * @example
 * ```js
 * parseShortcutString("ctrlCmd+z")
 * // -> {
 * //   modifiers: { ctrlCmd: true, shift: false, altOpt: false },
 * //   key: "z",
 * // }
 * ```
 */
export function parseShortcut(shortcutString: string): Shortcut {
	const shortcut = {
		modifiers: { ctrlCmd: false, shift: false, altOpt: false },
		key: "",
	};
	const fragments = shortcutString.split("+");
	for (const [i, fragment] of fragments.entries()) {
		const isLastFragment = i === fragments.length - 1;
		if (isLastFragment) {
			parseKeyInto(shortcut, shortcutString, fragment);
		} else {
			parseModifierInto(shortcut, shortcutString, fragment);
		}
	}
	return shortcut;
}

/**
 * Parse a chord string into a list shortcut objects.
 * @example
 * ```js
 * parseChordString("ctrlCmd+p shift+x")
 * // -> [{
 * //   modifiers: { ctrlCmd: true, shift: false, altOpt: false },
 * //   key: "p",
 * // }, {
 * //   modifiers: { ctrlCmd: false, shift: true, altOpt: false },
 * //   key: "x"
 * // }]
 * ```
 */
export function parseChord(chordString: string): Chord {
	if (chordString.trim().length === 0) {
		throw new Error("Cannot parse empty chord string");
	}
	const shortcutStringList = chordString.split(" ").filter(Boolean);
	return shortcutStringList.map((shortcutString) =>
		parseShortcut(shortcutString),
	);
}

export function safeParseKeyMap(keyMap: KeyMap): ShorcutCommandParsingResult {
	const commands: ShorcutCommand[] = [];
	const issues: ShorcutCommandParsingIssue[] = [];
	for (const { command, shortcut } of keyMap) {
		try {
			const parsedCommand = { command, shortcut: parseChord(shortcut) };
			commands.push(parsedCommand);
		} catch (error) {
			issues.push({ command, shortcut, error });
		}
	}
	return { commands, issues };
}

export function parseKeyMap(keyMap: KeyMap): ShorcutCommand[] {
	const { commands, issues } = safeParseKeyMap(keyMap);
	if (issues.length) {
		throw new Error("Parsing keyMap failed", {
			cause: new AggregateError(issues.map((issue) => issue.error)),
		});
	}
	return commands;
}

export function getStoreEventFromCommand<
	TEventType extends ShortcutEvent["type"],
>(type: ShortcutEvent["type"]): ExtractEvent<ShortcutEvent, TEventType> {
	return { type } as ExtractEvent<ShortcutEvent, TEventType>;
}

export function prettyPrintShortcut(shortcut: Shortcut) {
	const fragments: string[] = [];
	if (shortcut.modifiers.ctrlCmd) {
		fragments.push(isMac ? "⌘" : "Strg");
	}
	if (shortcut.modifiers.altOpt) {
		fragments.push(isMac ? "⌥" : "Alt");
	}
	if (shortcut.modifiers.shift) {
		fragments.push(isMac ? "⇧" : "Shift");
	}
	fragments.push(shortcut.key);
	return fragments.join("+");
}

export function prettyPrintChord(chord: Chord) {
	return chord.map((shortcut) => prettyPrintShortcut(shortcut)).join(" ");
}

export function getChordFor(keyMap: KeyMap, command: ShortcutEvent["type"]) {
	const shortcutCommand = keyMap.find((entry) => entry.command === command);
	if (!shortcutCommand) return null;
	return parseChord(shortcutCommand.shortcut);
}

export function getChordStringFor(
	keyMap: KeyMap,
	command: ShortcutEvent["type"],
) {
	const chord = getChordFor(keyMap, command);
	if (!chord) return "";
	return prettyPrintChord(chord);
}
