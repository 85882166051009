import type { Context } from "react";
import type { SessionContextValue } from "../Session/types";

export enum RequestStatus {
	Idle = "idle",
	Loading = "loading",
	Success = "success",
	Failure = "failure",
}

export enum RequestStatusCode {
	UnknownError = 0,
	Aborted = 1,
	DecodingError = 2,
}

export interface RequestError {
	status?: number;
	message: string;
}

export interface RequestState<ResponseData> {
	data: ResponseData | null;
	error: RequestError | null;
	headers: Record<string, string> | null;
	status: RequestStatus;
}

export enum RequestMethods {
	Get = "GET",
	Post = "POST",
	Put = "PUT",
	Delete = "DELETE",
}

export enum ResponseDecodingMode {
	Json = "Json",
	Text = "Text",
	Blob = "Blob",
}

interface SharedRequestOptions {
	headers?: { [k: string]: string };
	expect?: ResponseDecodingMode;
	/**
	 * Some requests require a session to be present to work. If the option is
	 * set, the request will remain in the `loading` state until the session
	 * request has succeeded. Then the request will be executed
	 */
	requireSession?: boolean;
}

export interface GetRequestOptions extends SharedRequestOptions {
	method?: RequestMethods.Get;
}

export interface PostRequestOptions<RequestData> extends SharedRequestOptions {
	method: RequestMethods.Post;
	data: RequestData;
}

export interface PutRequestOptions<RequestData> extends SharedRequestOptions {
	method: RequestMethods.Put;
	data: RequestData;
}

export interface DeleteRequestOptions<RequestData>
	extends SharedRequestOptions {
	method: RequestMethods.Delete;
	data?: RequestData;
}

export type RequestOptionsWithData<RequestData> =
	| PostRequestOptions<RequestData>
	| PutRequestOptions<RequestData>;

export type UnknownRequestOptions<RequestData> =
	| GetRequestOptions
	| DeleteRequestOptions<RequestData>
	| PostRequestOptions<RequestData>
	| PutRequestOptions<RequestData>;

export type RequestOptions<
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
> = Method extends RequestMethods.Get
	? GetRequestOptions
	: Method extends RequestMethods.Post
	? PostRequestOptions<RequestData>
	: Method extends RequestMethods.Put
	? PutRequestOptions<RequestData>
	: Method extends RequestMethods.Delete
	? DeleteRequestOptions<RequestData>
	: never;

export interface Resource<
	ResponseData,
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
> extends RequestState<ResponseData> {
	request(
		endpoint: string,
		options?: RequestOptions<Method, RequestData> | undefined,
	): void;
}

export interface FetchResult<ResponseData> {
	data: ResponseData | null;
	error: RequestError | null;
	headers: Record<string, string> | null;
	status: RequestStatus;
}

export type InternalFetch<
	ResponseData = unknown,
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
> = (
	origin: string,
	base: string,
	endpoint: string,
	options?: RequestOptions<Method, RequestData>,
) => Promise<FetchResult<ResponseData>>;

export interface InternalApiContext {
	origin: string;
	base: string;
	fetch: InternalFetch;
	sessionContext: Context<SessionContextValue>;
}

export interface FetchHandlers<ResponseData, FetchContext> {
	onRequest?(ctx: FetchContext): void;
	onSuccess?(
		data: ResponseData,
		headers: Record<string, string> | null,
		ctx: FetchContext,
	): void;
	onFailure?(error: RequestError, ctx: FetchContext): void;
}

export type ApiFetch<
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
	FetchContext = undefined,
> = (
	endpoint: string,
	options?: RequestOptions<Method, RequestData> & { ctx: FetchContext },
) => void;

export type ApiFetchOptions<
	Method extends RequestMethods,
	RequestData,
	FetchContext,
> = RequestOptions<Method, RequestData> & { ctx: FetchContext };

export interface TestSuccessResponse {
	data: unknown;
	headers?: Record<string, string> | null;
}

export interface TestErrorResponse {
	error: {
		status: number;
		message: string;
	};
}

export type TestResponse = TestSuccessResponse | TestErrorResponse;

export interface TestConfiguration {
	[endpoint: string]:
		| TestResponse
		| ((options?: RequestOptions<RequestMethods>) => TestResponse);
}

export type SuccessDecodingResult<ResponseData> = {
	data: ResponseData;
	status: null;
	error: null;
};
export type ErrorDecodingResult = {
	data: null;
	status: RequestStatusCode;
	error: unknown;
};
export type DecodingResult<ResponseData> =
	| SuccessDecodingResult<ResponseData>
	| ErrorDecodingResult;
