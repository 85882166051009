import { useEffect } from "react";

function UnloadPrompt({ when }: { when: boolean }): JSX.Element {
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (when) {
			const handler = (e: BeforeUnloadEvent) => {
				e.preventDefault();
				e.returnValue = "";
			};
			window.addEventListener("beforeunload", handler);
			return () => window.removeEventListener("beforeunload", handler);
		}
	}, [when]);
	return <></>;
}

export default UnloadPrompt;
