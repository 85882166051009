import { Alert, IconButton, Snackbar, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RetryIcon from "@mui/icons-material/Replay";
import type { ReactNode } from "react";
import { useSyncedState } from "../../hooks";

function AlertPopup({
	id,
	children,
	onRetry,
	onClose,
	isOpen = false,
	isClosable = true,
}: {
	id: string;
	children: ReactNode;
	onRetry?: () => void;
	onClose?: () => void;
	isOpen?: boolean;
	isClosable?: boolean;
}): JSX.Element {
	const [showError, setShowError] = useSyncedState(isOpen);

	const handleErrorClose = () => {
		if (!isClosable) return;
		if (onClose) {
			onClose();
		} else {
			setShowError(false);
		}
	};

	return (
		<Snackbar open={showError} key={id}>
			<Alert
				severity="error"
				action={
					(onRetry || isClosable) && (
						<>
							{onRetry && (
								<Tooltip title="Erneut versuchen">
									<IconButton
										color="error"
										size="small"
										aria-label="Erneut versuchen"
										onClick={onRetry}
									>
										<RetryIcon fontSize="small" fill="currentColor" />
									</IconButton>
								</Tooltip>
							)}
							{isClosable && (
								<Tooltip title="Schließen">
									<IconButton
										color="error"
										size="small"
										aria-label="Schließen"
										onClick={handleErrorClose}
									>
										<CloseIcon fontSize="small" fill="currentColor" />
									</IconButton>
								</Tooltip>
							)}
						</>
					)
				}
			>
				{children}
			</Alert>
		</Snackbar>
	);
}

export default AlertPopup;
