/* eslint-disable import/prefer-default-export, no-plusplus */
export enum KeyboardModifier {
	Alt = "Alt",
	Ctrl = "Ctrl",
	Meta = "Meta",
	Shift = "Shift",
}

interface ShortcutKeyDefinition {
	keys?: string[];
	key?: string;
	modifiers?: KeyboardModifier[];
	modifier?: KeyboardModifier;
}

export interface ShortcutDefinition extends ShortcutKeyDefinition {
	not?: ShortcutKeyDefinition;
}

export interface ShortcutHandler<
	ArgT extends unknown[] = unknown[],
	ReturnT = unknown,
> extends ShortcutDefinition {
	handler: (
		match: { key: string; modifiers: KeyboardModifier[] },
		event: KeyboardEvent,
		...restArgs: ArgT
	) => ReturnT;
}

/**
 * Mapping from modifier name to keyboard event property name
 */
const modifierMap = {
	[KeyboardModifier.Alt]: "altKey",
	[KeyboardModifier.Ctrl]: "ctrlKey",
	[KeyboardModifier.Meta]: "metaKey",
	[KeyboardModifier.Shift]: "shiftKey",
} as const;

const modifierMapEntries = Object.entries(modifierMap) as [
	keyof typeof modifierMap,
	(typeof modifierMap)[keyof typeof modifierMap],
][];

function mergeListAndSingle<T>(list?: T[], single?: T): T[] {
	const listCopy = [...(list || [])];
	if (single) {
		listCopy.push(single);
	}
	return listCopy;
}

const ieKeyMap: { [k: string]: string } = {
	ArrowUp: "Up",
	ArrowRight: "Right",
	ArrowDown: "Down",
	ArrowLeft: "Left",
};

const reversIeKeyMap: { [k: string]: string } = {
	Up: "ArrowUp",
	Right: "ArrowRight",
	Down: "ArrowDown",
	Left: "ArrowLeft",
};

const mapKeys = (keys?: string[], key?: string) =>
	mergeListAndSingle(keys, key)
		.map((k) => (ieKeyMap[k] ? [k, ieKeyMap[k]] : k))
		.flat()
		.map((k) => k.toLowerCase());

type ShortcutHandlerFunction<
	ArgT extends unknown[] = unknown[],
	ReturnT = unknown,
> = (event: KeyboardEvent, ...args: ArgT) => ReturnT | null;

/**
 * Create a event handler function, that calls the provided handlers,
 * that match a given shortcut
 */
function createShortcutHandler<
	ArgT extends unknown[] = unknown[],
	ReturnT = unknown,
>(
	handlers: ShortcutHandler<ArgT, ReturnT> | ShortcutHandler<ArgT, ReturnT>[],
): ShortcutHandlerFunction<ArgT, ReturnT> {
	const handlerList = Array.isArray(handlers) ? handlers : [handlers];
	/**
	 * Run all handlers, matching the given keyboard event.
	 * @param event The event dispatched by the keyboard interaction
	 * @returns The return value of a matched handler. Returns `null` if no handler matched
	 */
	const shortcutHandler: ShortcutHandlerFunction<ArgT, ReturnT> = (
		event: KeyboardEvent,
		...args: ArgT
	) => {
		// Check if the event can be analyzed by the matching algorithm. In theory,
		// every KeyDown event should have a `key` property. In practice it seems
		// as though chromium based browsers sometimes don't supply the `key`
		// property if the event is triggered when selecting a entry from the list
		// of suggestions the browser displays on input elements (see #568)
		if (
			!event ||
			!event.key ||
			event.type !== "keydown" ||
			typeof event.key !== "string"
		) {
			if (event?.type !== "keydown") {
				throw new Error(
					`Event handlers created by \`createShortcutHandler\` can only be ` +
						`used with \`keydown\` events. Received \`${event?.type}\` instead.`,
				);
			}
			return null;
		}
		const eventKey = event.key;
		const normalizedKey = eventKey.toLowerCase();
		for (let i = 0; i < handlerList.length; i++) {
			const {
				keys,
				key,
				modifiers,
				modifier,
				not = {},
				handler,
			} = handlerList[i];
			const keyList = mapKeys(keys, key);
			const modList = mergeListAndSingle(modifiers, modifier);
			const notKeyList = mapKeys(not.keys, not.key);
			const notModList = mergeListAndSingle(not.modifiers, not.modifier);
			const hasKeyMatch =
				keyList.length === 0 || keyList.includes(normalizedKey);
			const hasModifier = (mod: KeyboardModifier) => {
				const modifierPropName = modifierMap[mod];
				return event[modifierPropName];
			};
			const allModifiersMatch = modList.every(hasModifier);
			const hasNotKeyMatch = notKeyList.includes(normalizedKey);
			const hasNotModifiersMatch = notModList.some(hasModifier);
			if (
				hasKeyMatch &&
				allModifiersMatch &&
				!hasNotKeyMatch &&
				!hasNotModifiersMatch
			) {
				const activeModifiers = modifierMapEntries
					.filter(([, modifierPropName]) => event[modifierPropName])
					.map(([mod]) => mod);
				return handler(
					{
						key: reversIeKeyMap[eventKey] || eventKey,
						modifiers: activeModifiers,
					},
					event,
					...args,
				);
			}
		}
		return null;
	};
	return shortcutHandler;
}

export default createShortcutHandler;
