import classNames from "classnames";
import type { ReactNode } from "react";
import Split from "react-split";
import "./LRSplit.scss";

const Left = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}): JSX.Element => (
	<div className={classNames("lr-split__left", className)}>{children}</div>
);
const Right = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}): JSX.Element => (
	<div className={classNames("lr-split__right", className)}>{children}</div>
);

const LRSplit = ({
	children,
	sizes = [33, 67],
	className,
}: {
	children: ReactNode;
	sizes?: number[];
	className?: string;
}): JSX.Element => (
	<Split
		className={classNames("lr-split", className)}
		direction="horizontal"
		sizes={sizes}
		gutterSize={10}
	>
		{children}
	</Split>
);

LRSplit.Left = Left;
LRSplit.Right = Right;

export default LRSplit;
