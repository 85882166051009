import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, fromCallback, setup } from "xstate";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";

// eslint-disable-next-line no-use-before-define
export type ActorSystemRef = ActorRefFrom<typeof actorSystemMachine>;
type ActorSystemEvent = { type: "UPDATE" };
export type ActorSystemContext = { systemRef: { current: AnyActorSystem } };

const actorSystemMachine = setup({
	types: {
		events: {} as ActorSystemEvent,
		context: {} as ActorSystemContext,
		input: {} as { system: AnyActorSystem },
	},
	actors: {
		inspectSystem: fromCallback(({ system, sendBack }) => {
			// Any time the system changes, we send an event to update the context.
			// We'll use this context to access the system in react hooks, so we are
			// updated when a new actor is added to the system. Otherwise hooks, that
			// access the `system` property and try to get an actor from it might
			// return stale values, since they don't re-render when the system is
			// updated because its reference is stable.
			// We create a new object everytime the system is updated, so we know
			// react will force re-renders correctly.
			system.inspect({
				next: (event) => {
					if (event.type !== "@xstate.actor") return;
					sendBack({ type: "UPDATE" } satisfies ActorSystemEvent);
				},
			});
		}),
	},
	actions: {
		updateSystemRef: assign({
			systemRef: ({ event, system }) => {
				assertEvent(event, "UPDATE");
				// Create a new wrapper object, so we get a new reference. The `system`
				// will always have the same reference though
				return { current: system };
			},
		}),
	},
}).createMachine({
	id: "actorSystem",
	invoke: { src: "inspectSystem" },
	context: ({ input }) => ({ systemRef: { current: input.system } }),
	on: {
		UPDATE: { actions: "updateSystemRef" },
	},
});

export default actorSystemMachine;
