import { useContext } from "react";
import { useEventHandler } from "../../hooks";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import ApiContext from "./ApiContext";
import type {
	ApiFetch,
	ApiFetchOptions,
	FetchHandlers,
	InternalFetch,
	RequestError,
	RequestMethods,
	RequestOptions,
} from "./types";
import { RequestStatusCode, RequestStatus } from "./types";
import useWaitForSession from "./useWaitForSession";

/**
 * Request data from an enpoint and react to the request's lifecycle using
 * callbacks. This is usefull when you need fine grained control about
 * the request's lifecycle or if you need to manipulate data depending on
 * additional data that is different on every request.
 * If you don't need that much control pleade consider using the more
 * declarative `useResource` instead.
 */
function useFetch<
	ResponseData,
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
	FetchContext = undefined,
>(
	handlers: FetchHandlers<ResponseData, FetchContext>,
): ApiFetch<Method, RequestData, FetchContext> {
	const {
		origin,
		base,
		fetch: internalFetch,
		sessionContext,
	} = useContext(ApiContext);
	const fetch = internalFetch as InternalFetch<
		ResponseData,
		Method,
		RequestData
	>;

	// Check if the component is still mounted, so that we don't try to update
	// any state after the component has unmounted
	const isMountedRef = useIsMountedRef();

	const waitForSession = useWaitForSession(sessionContext);

	const fetchFn = useEventHandler(
		async (
			endpoint: string,
			options?: ApiFetchOptions<Method, RequestData, FetchContext>,
		) => {
			const { ctx, requireSession = false, ...fetchOptions } = options || {};
			handlers.onRequest?.(ctx as FetchContext);

			// If the users specifies, that a session is required for the request to
			// work, we'll wait for the session request to succeed, before runing the
			// fetch request
			if (requireSession) {
				await waitForSession();
			}
			// Cancel the request when the component unmounts
			if (!isMountedRef.current) {
				handlers.onFailure?.(
					{ status: RequestStatusCode.Aborted, message: "Request aborted" },
					ctx as FetchContext,
				);
				return;
			}

			fetch(
				origin,
				base,
				endpoint,
				fetchOptions as RequestOptions<Method, RequestData>,
			).then((result) => {
				// Cancel the request when the component unmounts
				if (!isMountedRef.current) return;
				if (result.status === RequestStatus.Success) {
					handlers.onSuccess?.(
						result.data as ResponseData,
						result.headers,
						ctx as FetchContext,
					);
				}
				if (result.status === RequestStatus.Failure) {
					handlers.onFailure?.(
						result.error as RequestError,
						ctx as FetchContext,
					);
				}
			});
		},
	);

	return fetchFn;
}

export default useFetch;
