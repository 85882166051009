import { Fade } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Fade ref={ref} {...props} />;
});

export default Transition;
