export interface StandardInfo {
	id: string;
	version: string;
}

/**
 * Extract the base id and version from an xoev standard id.
 *
 * @example
 * ```ts
 * const xoevId = "xoev-de:standard:kennung_1.3.2";
 * const idInfo = parseId(xoevId);
 * // -> { id: "xoev-de:standard:kennung", version: "1.3.2" }
 * ```
 */
export function parseId(idString?: string | null): StandardInfo | null {
	if (!idString) return null;
	// `id` and `version` are separated by a `_`
	const [id, version] = idString.split("_");
	return { id, version };
}

/**
 * Turn an id info object into a xoev id, that includes the base id and the
 * version.
 *
 * @example
 * ```ts
 * const idInfo = { id: "xoev-de:standard:kennung", version: "1.3.2" };
 *
 * // Call `stringifyId` with an object...
 * const xoevId1 = stringifyId(idInfo);
 * // -> "xoev-de:standard:kennung_1.3.2"
 *
 * // Or call it with an id and a version individually
 * const xoevId2 = stringifyId(idInfo.id, idInfo.version);
 * // -> "xoev-de:standard:kennung_1.3.2"
 * ```
 */
export function stringifyId(id?: string, version?: string): string;
export function stringifyId(standardInfo: StandardInfo): string;
export function stringifyId(
	standardInfoOrId?: string | StandardInfo,
	version?: string,
): string {
	let idFrag: string;
	let versionFrag: string;
	if (typeof standardInfoOrId === "string") {
		idFrag = standardInfoOrId;
		versionFrag = version as string;
	} else {
		idFrag = (standardInfoOrId as StandardInfo).id;
		versionFrag = (standardInfoOrId as StandardInfo).version;
	}
	return `${idFrag}_${versionFrag}`;
}

export function segmentizeId(syntaxPath: string): string[] {
	if (syntaxPath.length === 0) return [];
	return syntaxPath.split("/");
}
export function joinIdSegments(segments: string[]): string {
	return segments.join("/");
}

export function isChildIdOrSelf(parentId: string, childId: string): boolean {
	const parentSegments = segmentizeId(parentId);
	const childSegments = segmentizeId(childId);
	return parentSegments.every(
		(segment, i) => childSegments[i] && segment === childSegments[i],
	);
}
export function isChildId(parentId: string, childId: string): boolean {
	return parentId !== childId && isChildIdOrSelf(parentId, childId);
}
export function isParentIdOrSelf(childId: string, parentId: string): boolean {
	return isChildIdOrSelf(parentId, childId);
}
export function isParentId(childId: string, parentId: string): boolean {
	return isChildId(parentId, childId);
}

export function getRootKennung(kennung: string): string {
	return kennung.split("_").at(0) as string;
}
