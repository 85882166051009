var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class SegmentTrieNode {
  constructor(level) {
    // eslint-disable-next-line no-use-before-define
    __publicField(this, "children", null);
    __publicField(this, "value", null);
    __publicField(this, "isValueNode", false);
    __publicField(this, "level");
    this.level = level;
  }
  has(segment) {
    return this.children !== null ? this.children.has(segment) : false;
  }
  get(segment) {
    return this.children !== null ? this.children.get(segment) : void 0;
  }
  insert(segment, node) {
    if (this.children === null) {
      this.children = /* @__PURE__ */ new Map();
    }
    this.children.set(segment, node);
  }
  get size() {
    return this.children !== null ? this.children.size : 0;
  }
}
class SegmentTrie {
  constructor(segmentizePath, entries) {
    __publicField(this, "root", new SegmentTrieNode(0));
    __publicField(this, "numNodes", 0);
    __publicField(this, "segmentizePath");
    this.segmentizePath = segmentizePath;
    if (entries) {
      for (const [path, value] of entries) {
        this.insert(path, value);
      }
    }
  }
  unsafeSegmentize(path) {
    const segmentIter = this.segmentizePath(path);
    const segments = Array.isArray(segmentIter) ? segmentIter : [...segmentIter];
    return segments;
  }
  segmentize(path) {
    const segments = this.unsafeSegmentize(path);
    if (segments.length === 0) {
      let pathLabel = "";
      try {
        pathLabel = JSON.stringify(path);
      } catch {
        pathLabel = String(path);
      }
      throw new TypeError(
        `Paths must not be empty. Found empty path: ${pathLabel}`
      );
    }
    return segments;
  }
  isPathEmpty(path) {
    return this.unsafeSegmentize(path).length === 0;
  }
  insert(path, value) {
    const segments = this.segmentize(path);
    let currentNode = this.root;
    for (const segment of segments) {
      const segmentNode = currentNode.get(segment);
      if (!segmentNode) {
        const nextNode = new SegmentTrieNode(
          currentNode.level + 1
        );
        currentNode.insert(segment, nextNode);
        currentNode = nextNode;
      } else {
        currentNode = segmentNode;
      }
    }
    if (!currentNode.isValueNode) {
      this.numNodes += 1;
    }
    currentNode.isValueNode = true;
    currentNode.value = value;
  }
  getNode(segments) {
    let currentNode = this.root;
    for (const segment of segments) {
      const segmentNode = currentNode.get(segment);
      if (!segmentNode) {
        return void 0;
      }
      currentNode = segmentNode;
    }
    return currentNode;
  }
  getNodeByPath(path) {
    return this.getNode(this.segmentize(path));
  }
  has(path) {
    const node = this.getNodeByPath(path);
    return !!node && node.isValueNode;
  }
  hasChildOf(path) {
    const node = this.getNodeByPath(path);
    return !!node && node.size > 0;
  }
  getParentNode(path) {
    let segments = this.segmentize(path);
    for (let i = segments.length - 1; i >= 0; i -= 1) {
      segments = segments.slice(0, -1);
      if (segments.length === 0) {
        return void 0;
      }
      const node = this.getNode(segments);
      if (!!node && node.isValueNode) {
        return node;
      }
    }
    return void 0;
  }
  getParentOf(path) {
    const parent = this.getParentNode(path);
    return parent !== void 0 ? parent.value : void 0;
  }
  hasParentOf(path) {
    const parent = this.getParentNode(path);
    return parent !== void 0;
  }
  get(path) {
    const node = this.getNodeByPath(path);
    return node && node.isValueNode ? node.value : void 0;
  }
  getOr(path, fallback) {
    const node = this.getNodeByPath(path);
    return node && node.isValueNode ? node.value : fallback;
  }
  get size() {
    return this.numNodes;
  }
}
export {
  SegmentTrie as default
};
