import { IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {
	useCanRedo,
	useCanUndo,
	useStateContainerContext,
} from "../../EditorState";
import KeyboardShortcut, { KeyboardModifier } from "../../KeyboardShortcut";
import ValidationDialog from "./ValidationDialog";
import SaveButton from "./SaveButton";
import "./EditorNavBarActions.scss";

function EditorNavBarActions(): JSX.Element {
	const { undo, redo } = useStateContainerContext();
	const canUndo = useCanUndo();
	const canRedo = useCanRedo();

	return (
		<div
			className="editor-navbar-actions"
			data-testid="datatypes-navbar__buttons"
		>
			<ValidationDialog />
			<SaveButton />
			<IconButton
				onClick={undo}
				aria-label="Rückgängig"
				disabled={!canUndo}
				data-testid="undo-button"
			>
				<UndoIcon />
			</IconButton>
			<IconButton
				onClick={redo}
				aria-label="Wiederherstellen"
				disabled={!canRedo}
				data-testid="redo-button"
			>
				<RedoIcon />
			</IconButton>
			<KeyboardShortcut
				shortcut={{ key: "Z", modifier: KeyboardModifier.Ctrl }}
				onActivate={undo}
				disabled={!canUndo}
			/>
			<KeyboardShortcut
				shortcut={{ key: "Y", modifier: KeyboardModifier.Ctrl }}
				onActivate={redo}
				disabled={!canRedo}
			/>
		</div>
	);
}

export default EditorNavBarActions;
