import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, fromCallback, setup } from "xstate";
import { List } from "immutable";
import { v4 as uuid } from "uuid";
import { findNonSerializableValue } from "@reduxjs/toolkit";
import type { StoreEvent } from "./StoreEvent";

const EVENT_STORE_KEY = "suite-event-store" as const;

export type EventStoreEntryMeta = {
	timestamp: string;
	index: number;
	id: string;
};

export type EventStoreEntry = {
	event: StoreEvent;
	meta: EventStoreEntryMeta;
};

export type EventStoreLog = List<EventStoreEntry>;

// eslint-disable-next-line no-use-before-define
export type EventStoreActorRef = ActorRefFrom<typeof eventStoreMachine>;

const eventStoreMachine = setup({
	types: {
		events: {} as
			| { type: "APPEND_EVENT"; event: StoreEvent }
			| { type: "PERSIST" }
			| {
					type: "RESTORE";
					context: { sequenceNumber: number; eventLog: EventStoreLog };
			  },
		context: {} as {
			sequenceNumber: number;
			eventLog: EventStoreLog;
		},
	},
	actions: {
		addEvent: assign(({ context, event }) => {
			assertEvent(event, "APPEND_EVENT");
			// In dev mode, check if all events are serializable, so we can safely
			// store the events in session storage
			if (import.meta.env.DEV) {
				const nonSerializableValue = findNonSerializableValue(event.event);
				if (nonSerializableValue) {
					const { keyPath, value } = nonSerializableValue;
					// eslint-disable-next-line no-console
					console.warn(
						`Event of type "${event.event.type}" is not serializable at \`${keyPath}\`\n\n`,
						value,
					);
				}
			}
			const nextSequenceNumber = context.sequenceNumber + 1;
			return {
				eventLog: context.eventLog.push({
					event: event.event,
					meta: {
						id: uuid(),
						timestamp: new Date().toISOString(),
						index: nextSequenceNumber,
					},
				}),
				sequenceNumber: nextSequenceNumber,
			};
		}),
		restore: assign(({ event }) => {
			assertEvent(event, "RESTORE");
			return {
				eventLog: List(event.context.eventLog),
				sequenceNumber: event.context.sequenceNumber,
			};
		}),
		persist: ({ context }) => {
			sessionStorage.setItem(EVENT_STORE_KEY, JSON.stringify(context));
		},
	},
	actors: {
		persistEventStore: fromCallback(({ sendBack }) => {
			const handleBeforeUnload = () => {
				sendBack({ type: "PERSIST" });
			};
			window.addEventListener("beforeunload", handleBeforeUnload);
			return () =>
				window.removeEventListener("beforeunload", handleBeforeUnload);
		}),
		restoreEventStore: fromCallback(({ sendBack }) => {
			const persistedEventStore = sessionStorage.getItem(EVENT_STORE_KEY);
			const context = persistedEventStore
				? JSON.parse(persistedEventStore)
				: null;
			if (context) {
				sendBack({ type: "RESTORE", context });
			}
		}),
	},
}).createMachine({
	id: "eventStore",
	context: {
		eventLog: List(),
		sequenceNumber: -1,
	},
	invoke: [
		{ src: "restoreEventStore" },
		{
			src: "persistEventStore",
		},
	],
	on: {
		APPEND_EVENT: { actions: "addEvent" },
		RESTORE: { actions: "restore" },
		PERSIST: { actions: "persist" },
	},
});

export default eventStoreMachine;
