import type { UiFilterGroup } from "../../../redux/uiSlice";
import EditorSideBar from "../../EditorSideBar";
import { useListSearch } from "./hooks";

export default function BaseDatatypesListSearch({
	filterGroup,
}: {
	filterGroup: UiFilterGroup;
}): JSX.Element {
	const { getSearchInputProps } = useListSearch(filterGroup);
	return (
		<EditorSideBar.Search
			{...getSearchInputProps()}
			label="Datentypen durchsuchen"
		/>
	);
}
