import { useActiveNode } from "../useActiveNode";
import EditorNavBar from "../../EditorNavBar";
import NavBarBreadcrumbs from "../../EditorNavBar/NavBarBreadcrumbs";
import { useProjectBreadcrumb } from "../../EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import { useStateSelector } from "../../EditorState";
import { selectStandard } from "../../EditorState/selectors";
import { useAppSelector } from "../../../redux/hooks";
import { selectNachrichtenBreadcrumbs } from "../../../redux/treeSlice";

const MessageProfilingNavBar = (): JSX.Element => {
	const standard = useStateSelector(selectStandard());
	const projectBreadcrumb = useProjectBreadcrumb();
	const { activePath } = useActiveNode();
	const links = useAppSelector(
		selectNachrichtenBreadcrumbs(
			standard,
			activePath,
			"/profilierung/nachrichtenstrukturen/",
		),
	);

	const breadcrumbs = [
		projectBreadcrumb,
		{
			key: "__MESSAGE_STRUCTURES__",
			content: "Nachrichten",
			to: "/profilierung/nachrichtenstrukturen",
		},
		...links.map(({ name, link }) => ({
			key: link,
			to: link,
			content: name,
		})),
	];

	return (
		<EditorNavBar data-testid="message-profiling-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
};

export default MessageProfilingNavBar;
