import type { ReactNode } from "react";
import { useMemo, useEffect } from "react";
import type SavedProfileData from "../../../types/SavedProfileData";
import { RequestMethods, useResource } from "../../Api";
import type { PostRequestOptions } from "../../Api/types";
import { createSaveData } from "../../EditorData/helpers";
import { useStateContainerContext } from "../../EditorState";
import RequestErrorNotification from "../../RequestErrorNotification";
import GeneratedChapterContext from "./GeneratedChapterContext";
import type { ChapterMap, GeneratedChapterContextType } from "./types";
import { useAppSelector } from "../../../redux/hooks";
import { selectConfiguration } from "../../../redux/configuration/configurationSlice";

function GeneratedChapterProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const { request, data, status, error } = useResource<
		SavedProfileData,
		RequestMethods.Post,
		SavedProfileData
	>();
	const { getState } = useStateContainerContext();
	const configuration = useAppSelector(selectConfiguration());

	useEffect(() => {
		const state = getState().value;
		if (!state.get("metadaten").get("standard")) return;
		const saveData = createSaveData({ state, configuration });

		const options: PostRequestOptions<SavedProfileData> = {
			method: RequestMethods.Post,
			requireSession: true,
			data: saveData,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		};

		request(`/v1/projekt/${saveData.metadaten.kennung}`, options);
	}, [configuration, getState, request]);

	const chapters: ChapterMap = useMemo(() => {
		if (data?.dokumentation) {
			const generatedChapters: ChapterMap = Object.fromEntries(
				data.dokumentation.map((chapter) => [chapter.name, chapter] as const),
			);
			return generatedChapters;
		}
		return {};
	}, [data?.dokumentation]);

	const ctx: GeneratedChapterContextType = useMemo(
		() => ({ error, status, chapters }),
		[error, status, chapters],
	);

	return (
		<GeneratedChapterContext.Provider value={ctx}>
			<RequestErrorNotification
				id="generated-chapter-request-error"
				error={error}
				status={status}
			>
				Fehler beim Abrufen der generierten Kapitel
			</RequestErrorNotification>
			{children}
		</GeneratedChapterContext.Provider>
	);
}

export default GeneratedChapterProvider;
