import { createActor, fromCallback } from "xstate";
import type { ModellierungModellQueryActorRef } from "../modellierungModel.query.machine";
import modellierungModellQueryMachine, {
	translateMap,
} from "../modellierungModel.query.machine";
import type {
	ModellEventLogEntry,
	ModellActionMap,
	ModellHandlerMap,
} from "./types";
import WorkerBridge from "../../../../WorkerBridge";
import { createLiteProject } from "../helpers";
import type { AnyActionMap, HandlerMap } from "../../../../WorkerBridge/types";

const queryMachine = modellierungModellQueryMachine.provide({
	// Replace the `translateEvents` actor with a noop. We'll forward the events
	// manually, since the rest of the actor system will not be available in the
	// worker scope
	actors: { translateEvents: fromCallback(() => {}) },
});

let actor: ModellierungModellQueryActorRef | null = null;

function assertHasModell(modellActor: typeof actor): asserts modellActor {
	if (!modellActor) {
		throw new Error("Query modell was expected to be initialized");
	}
}

function forwardEvent(
	modellActor: ModellierungModellQueryActorRef,
	{ event, meta }: ModellEventLogEntry,
) {
	const translatedEvent = translateMap[event.type]?.(event as never, meta);
	if (translatedEvent) {
		modellActor.send(translatedEvent);
	}
}

const defaultHandlerMap: HandlerMap<ModellActionMap> = {
	init({ rawModel, modellEvents, ...projektMeta }) {
		const projekt = createLiteProject(
			rawModel,
			projektMeta,
			"Neues Modellierungsprojekt",
		);
		// Start the actor with the initial projekt data
		actor = createActor(queryMachine, {
			input: { ...projektMeta, projekt },
		}).start();
		// Update the context with all modell actions from the event log
		for (const entry of modellEvents) {
			forwardEvent(actor, entry);
		}
	},
	sendModellEvent(eventLogEntry) {
		assertHasModell(actor);
		forwardEvent(actor, eventLogEntry);
	},
};

export default function connectModellWorkerBridge<
	TActionMap extends AnyActionMap,
>(handlerMap: ModellHandlerMap<TActionMap>) {
	WorkerBridge.connectWorker<ModellActionMap & TActionMap>({
		...(Object.fromEntries(
			Object.entries(handlerMap).map(([key, handler]) => [
				key,
				(...args) => {
					assertHasModell(actor);
					return handler(actor, ...args);
				},
			]),
		) as HandlerMap<TActionMap>),
		...defaultHandlerMap,
	});
}
