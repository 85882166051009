import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import type { CodeListValues } from "../../../../../../types/InfoNodeValues";
import type { FieldDefinition } from "../../../../../EditFormField";
import EditFormField from "../../../../../EditFormField";
import { VisuallyHidden } from "../../../../../ui";
import {
	getCodeListIconKey,
	useAvailableInXRepository,
	useCodeliste,
	useReferenceProfile,
} from "../../fieldRendererHelpers";
import type { FieldRendererComponentProps } from "../../types";
import InfoRenderer from "../InfoRenderer";
import DynamicInfo from "../../../../../ui/DynamicInfo";

const VersionRenderer = ({
	activeNode,
	profile,
	onBlur,
	fields,
	isStandardRenderer,
	definition: customDefinition,
}: FieldRendererComponentProps): JSX.Element => {
	const { kennung, version, nutzungsArt } = useCodeliste(activeNode);
	const url = `https://www.xrepository.de/details/${kennung}`;
	const [definition] = fields;

	const availableInXrepository = useAvailableInXRepository(kennung, version);

	const readOnlyByKind = nutzungsArt === "1" || nutzungsArt === "2";
	const referenceProfile = useReferenceProfile(profile, activeNode);
	const versionState = referenceProfile
		? referenceProfile.getIn(["konfiguration", "codeliste", "version"])
		: profile?.getIn(["konfiguration", "codeliste", "version"]);
	const value = (readOnlyByKind ? version : versionState) ?? "";
	const infoKey = getCodeListIconKey("version", nutzungsArt);

	return (
		<>
			{!isStandardRenderer && (
				<EditFormField
					definition={
						{
							...customDefinition,
							...definition,
							readOnly:
								readOnlyByKind || definition?.readOnly || isStandardRenderer,
							label: (
								<>
									{definition.label}{" "}
									{infoKey && <DynamicInfo infoKey={infoKey} />}
								</>
							),
						} as FieldDefinition<CodeListValues>
					}
					onBlur={onBlur}
					value={value}
				/>
			)}

			<div>
				<InfoRenderer
					label="Codelistenversion"
					value={version}
					fallback="Keine Angabe im Standard"
				/>
				{availableInXrepository && (
					<InfoRenderer
						label="Verweis zum XRepository"
						value={
							<a
								href={url}
								target="_blank"
								rel="noopener noreferrer"
								className="info-renderer__external-link"
							>
								<span aria-hidden>Link</span>
								<VisuallyHidden>
									{activeNode.name} im XRepository öffnen
								</VisuallyHidden>
								<OpenInNewIcon fontSize="inherit" />
							</a>
						}
					/>
				)}
			</div>
		</>
	);
};

export default VersionRenderer;
