import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type {
	ConfigurationState,
	DocumentationConfiguration,
	GenerationConfiguration,
} from "./types";

const initialState: ConfigurationState = {
	dokumentation: {},
	generierung: {
		optionaleElementeGenerieren: false,
		rekursionsTiefe: 0,
	},
};

const configurationSlice = createSlice({
	name: "configuration",
	initialState,
	reducers: {
		setConfiguration(
			state,
			action: PayloadAction<{
				configuration: Partial<ConfigurationState>;
			}>,
		) {
			const { configuration } = action.payload;
			return {
				...state,
				...configuration,
			};
		},
		setGenerationConfiguraton(
			state,
			action: PayloadAction<{
				configuration: Partial<GenerationConfiguration>;
			}>,
		) {
			const { configuration } = action.payload;
			return {
				...state,
				generierung: {
					...state.generierung,
					...configuration,
				},
			};
		},
		setDocumentationConfiguration(
			state,
			action: PayloadAction<{
				configuration: Partial<DocumentationConfiguration>;
			}>,
		) {
			const { configuration } = action.payload;
			return {
				...state,
				dokumentation: {
					...state.dokumentation,
					...configuration,
				},
			};
		},
	},
});

export const {
	setConfiguration,
	setDocumentationConfiguration,
	setGenerationConfiguraton,
} = configurationSlice.actions;

export const selectConfiguration = () => (state: RootState) =>
	state.configuration;

export const selectGenerationConfiguration = () => (state: RootState) =>
	state.configuration.generierung;

export const selectDocumentationConfgiuration = () => (state: RootState) =>
	state.configuration.dokumentation;

export default configurationSlice.reducer;
