import type { SyntheticEvent } from "react";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { RendererImplementation, TabGroupOrientation } from "../../types";
import type { RendererImplementationComponent } from "../types";
import { extractNodeProps } from "../../helpers";
import "./TabGroupRenderer.scss";

const TabGroupRenderer: RendererImplementationComponent<
	RendererImplementation.TabGroup
> = ({ markup, renderer: Renderer }) => {
	const { "xoev-suite:orientation": orientation } = extractNodeProps(markup);
	const tabList = markup[RendererImplementation.Tab];
	const [tabIndex, setTabIndex] = useState("0");

	const labels = tabList.map((entry) => entry["xoev-suite:label"]);

	const handleTabChange = (event: SyntheticEvent, newIndex: string) => {
		setTabIndex(newIndex);
	};
	return (
		<TabContext value={tabIndex}>
			<Box
				component="div"
				className={
					orientation === TabGroupOrientation.Vertical
						? "tab-group-vertical__tabs"
						: "tab-group-horizontal"
				}
				data-testid="tab-group-renderer"
			>
				<TabList
					orientation={
						orientation === TabGroupOrientation.Vertical
							? "vertical"
							: "horizontal"
					}
					onChange={handleTabChange}
				>
					{labels.map(
						(label, index) =>
							label && (
								<Tab
									value={String(index)}
									label={label}
									key={label}
									data-orientation={
										orientation === TabGroupOrientation.Vertical
											? "vertical"
											: "horizontal"
									}
									data-label={label}
									data-testid="tab"
								/>
							),
					)}
				</TabList>
				{tabList.map((tab, index) => (
					<TabPanel
						value={String(index)}
						key={tab["xoev-suite:label"]}
						data-orientation={
							orientation === TabGroupOrientation.Vertical
								? "vertical"
								: "horizontal"
						}
						data-label={tab["xoev-suite:label"]}
						data-testid="tab-panel"
					>
						{tab.Header && <Renderer key={tab.Header} markup={tab.Header} />}
						<Renderer markup={tab} />
					</TabPanel>
				))}
			</Box>
		</TabContext>
	);
};

export default TabGroupRenderer;
