import type { ReactNode } from "react";
import { SubMenu as UiSubMenu } from "@xoev/ui";
import SubMenuItem from "./SubMenuItem";
import SubMenuHome from "./SubMenuHome";

const SubMenu = ({
	children,
	label,
}: {
	children: ReactNode;
	label: string;
}): JSX.Element => {
	return (
		<UiSubMenu data-testid="submenu" aria-label={label}>
			<UiSubMenu.List>{children}</UiSubMenu.List>
		</UiSubMenu>
	);
};

SubMenu.Item = SubMenuItem;
SubMenu.Home = SubMenuHome;

export default SubMenu;
