/* eslint-disable react/jsx-props-no-spreading */
import type { KeyboardEventHandler } from "react";
import { useContext } from "react";
import classNames from "classnames";
import PopoverMenuContext from "./PopoverMenuContext";
import { normObject } from "../util";
import type { PopoverMenuButtonProps } from "./types";
import "./PopoverMenuButton.scss";

const PopoverMenuButton = ({
	activeClassName,
	className,
	children,
	popupType,
	...props
}: PopoverMenuButtonProps): JSX.Element => {
	const { buttonId, buttonRef, contentId, isOpen, setIsOpen, position } =
		useContext(PopoverMenuContext);

	const toggleMenu = () => setIsOpen((prevIsOpen) => !prevIsOpen);
	const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
		const isSpace = e.key === " ";
		const isEnter = e.key === "Enter";
		if (isSpace || isEnter) {
			if (isSpace) {
				// Prevent scrolling the page on space press
				e.preventDefault();
			}
			toggleMenu();
		}
	};

	return (
		<button
			className={classNames(
				"xui-popover-menu-button",
				`xui-popover-menu-button--position-${position.replace(" ", "-")}`,
				className,
				isOpen && "xui-popover-menu-button--open",
				isOpen && activeClassName,
			)}
			type="button"
			onMouseUp={toggleMenu}
			onTouchEnd={toggleMenu}
			onKeyDown={handleKeyDown}
			id={buttonId}
			aria-haspopup={popupType || "true"}
			aria-controls={contentId}
			data-testid="popover-menu-button"
			data-is-open={isOpen}
			{...normObject(isOpen, { "aria-expanded": "true" as const })}
			{...props}
			ref={buttonRef}
		>
			{children}
		</button>
	);
};

export default PopoverMenuButton;
